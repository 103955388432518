import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/custom/Header";
import moment from "moment";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { SessionKey, LanguageOption } from "../../util/Constant";

const WalletTransferHistory = () => {
    let _history = useHistory();
    var _location = useLocation();
    const { t, i18n } = useTranslation();
    const _dispatch = useDispatch();
    const [walletUrl, setWalletUrl] = useState("");
    const [startDate, setStartDate] = useState(
        moment().startOf("week").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("week").format("YYYY-MM-DD")
    );
  const [selectedDate, setSelectedDate] = useState("");
  const languageCode = localStorage.getItem(SessionKey._LANGUAGE)

    useEffect(() => {
      getWalletTransfer(startDate, endDate);
      
    }, []);

    async function getWalletTransfer(start, end, selected = "") {

      var apiUrl = ApiUrl._API_GET_WALLET_TRANSFER + "?dateFrom=" + moment(start).format('YYYY-MM-DD HH:mm:ss') + "&dateTo=" + moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      let langId = LanguageOption.find(
        (x) => x.code === ((localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? localStorage.getItem(SessionKey._LANGUAGE) : 'en'))
      ).languageId;
      if (langId == 1) moment.locale("en");
      else if (langId == 2) moment.locale("zh-cn");
      else if (langId == 3) moment.locale("ms");
      else if (langId == 5) moment.locale("th");
      else if (langId == 6) moment.locale("vi");
      else if (langId == 7) moment.locale("id");

        if (!stringIsNullOrEmpty(selected)) {
            apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD HH:mm:ss");
        }
        setWalletUrl(apiUrl);
    }

    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getWalletTransfer(startDate, endDate, selectedDate);
        }
    }, [selectedDate]);

    return (
        <>
            <Header
                history={_history}
                url={!window.ReactNativeWebView ? _location.state && _location.state.url ? _location.state.url : WebUrl._URL_TRANSACTION_HISTORY : null}
                title={t("WALLET_TRANSFER")}
            />
            <div className="container" style={{ marginBottom: "10px", padding: "0" }}>
                <DateRangeFilter2
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onSave={(start, end) => {
                        getWalletTransfer(start, end);
                        setSelectedDate("");
                    }}
                    options={true}
                />
                <div className="card card-package">
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table reportTable">
                                <thead>
                                    <tr>
                                        <th>{t("DATE")}</th>
                                        <th>{t("AMOUNT")}</th>
                                        <th>{t("FROMWALLET")}</th>
                                        <th>{t("TOWALLET")}</th>
                                        <th>{t("BONUS")}</th>
                                        <th>{t("FROMWALLETBALANCEBEFORE")}</th>
                                        <th>{t("FROMWALLETBALANCEAFTER")}</th>
                                        <th>{t("TOWALLETBALANCEBEFORE")}</th>
                                        <th>{t("TOWALLETBALANCEAFTER")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <DynamicLoadTable
                                        pageSize={20}
                                        apiQuery={walletUrl}
                                        searchEnabled={true}
                                        isTable={true}
                                        render={(item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {moment(item["createdTime"]).format("YYYY-MM-DD")}
                                                    </td>
                                                    <td>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["depositAmount"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item["transferPerson"]}
                                                    </td>
                                                    <td>
                                                        {item["productName"]}
                                                    </td>
                                                    <td>
                                                        {item["bonus"]}
                                                    </td>
                                                    <td>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["walletBefore"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["walletAfter"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["gameWalletBefore"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["gameWalletAfter"]),
                                                            2
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletTransferHistory;
