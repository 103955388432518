import React, { useEffect, useState, useCallback, useMemo } from "react";
import "react-simple-keyboard/build/css/index.css";
//import { bannerList } from "../../config/game-configs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Modal,
    ModalBody
} from "reactstrap";
import {
    setIsShowBigWinning
} from "../../redux/AppAction";

import ApiEngine from "./../../util/ApiEngine.js";
import {
    ApiKey,
    ApiUrl,
    SessionKey,
    WebUrl
} from "./../../util/Constant.js";
import { numberWithCurrencyFormat, maskUsername, stringIsNullOrEmpty } from "../../util/Util";
import moment from "moment";
import Header from '../../components/custom/Header';


const BigWinningModal = () => {
    const { t, i18n } = useTranslation();
    const _history = useHistory();
    const _dispatch = useDispatch();
    const isShowBigWinning = useSelector((state) => state.appState.isShowBigWinning);
    const [bigWinning, setBigWinning] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeLang, setActiveLang] = useState("id");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [inputValue, setInputValue] = useState(currentPage);
    const itemsPerPage = 10;
    const pagesToShow = 5;

    useEffect(() => {
        getBigWinning();
        updateMemberBigWinningViewHistoryCount();
    }, [])

    useEffect(() => {
        getBigWinning();
        setInputValue(currentPage);
    }, [currentPage]);


    const updateMemberBigWinningViewHistoryCount = async () => {
        await ApiEngine.get(ApiUrl._API_UPDATE_MEMBER_BIG_WINNING_VIEW_HISTORY_COUNT);
    }

    useEffect(() => {
        if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
            setActiveLang(localStorage.getItem(SessionKey._LANGUAGE))
        } else {
            setActiveLang("id");
        }
    }, [localStorage.getItem(SessionKey._LANGUAGE)])

    const handleCloseBigWinningModal = useCallback(() => {
        _dispatch(setIsShowBigWinning(false));
    }, [_dispatch]);

    const toggleAccordion = useCallback((index) => {
        setActiveIndex(activeIndex === index ? null : index);
    }, [activeIndex]);

    const handleOpenResult = useCallback((url) => {
        window.open(url, '_blank', 'result url');
    }, []);

    const getBigWinning = async () => {
        let bigWinningRecord = await ApiEngine.get(ApiUrl._API_GET_MEMBER_BIG_WINNING + "?Start=" + (currentPage - 1) * itemsPerPage + "&Count=" + itemsPerPage);
        if (bigWinningRecord[ApiKey._API_SUCCESS_KEY] && bigWinningRecord[ApiKey._API_DATA_KEY] !== null) {
            setBigWinning(bigWinningRecord[ApiKey._API_DATA_KEY]["data"]);
            setTotalPage(Math.ceil(bigWinningRecord[ApiKey._API_DATA_KEY]["totalCount"] / itemsPerPage));
        }
    }

    const handleBlur = useCallback((e) => {
        let value = e.target.value ? Number(e.target.value) : 1;
        if (value < 1) {
            value = 1;
        } else if (value > totalPage) {
            value = totalPage;
        }
        setCurrentPage(value);
    }, [totalPage]);

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setInputValue(value);
        if (Number(value) >= 1 && Number(value) <= totalPage) {
            setCurrentPage(Number(value));
        }
    }, [totalPage]);

    const handlePageClick = useCallback((pageNumber) => {
        setCurrentPage(pageNumber);
    }, []);

    const renderFirstPage = useCallback(() => {
        return (
            [<li key={1}>
                <button className={1 === currentPage ? 'active' : ''} onClick={() => handlePageClick(1)}>1</button>
            </li>]
        );
    }, [currentPage, handlePageClick]);

    const renderLastPage = useCallback(() => {
        return (
            <li key={totalPage}>
                <button className={totalPage === currentPage ? 'active' : ''} onClick={() => handlePageClick(totalPage)}>{totalPage}</button>
            </li>
        );
    }, [currentPage, totalPage, handlePageClick]);

    const renderEllipsis = useCallback((index) => {
        return (
            <li key={"ellipsis-" + index}>
                <span>...</span>
            </li>
        );
    }, []);

    const renderPagesRange = useCallback((start, end) => {
        let pageNumbers = [];
        for (let number = start; number <= end; number++) {
            pageNumbers.push(
                <li key={number}>
                    <button className={number === currentPage ? 'active' : ''} onClick={() => handlePageClick(number)}>{number}</button>
                </li>
            );
        }
        return pageNumbers;
    }, [currentPage, handlePageClick]);

    const renderPageNumbers = useMemo(() => {
        const middleRange = pagesToShow - 2;

        if (totalPage <= pagesToShow) {
            return renderPagesRange(1, totalPage);
        } else if (currentPage <= middleRange) {
            return renderPagesRange(1, middleRange + 1).concat(renderEllipsis(2), renderLastPage());
        } else if (currentPage >= totalPage - middleRange) {
            return renderFirstPage().concat(renderEllipsis(1), renderPagesRange(totalPage - middleRange, totalPage));
        } else {
            const start = currentPage - 1;
            const end = currentPage + 1;
            return renderFirstPage().concat(renderEllipsis(1), renderPagesRange(start, end), renderEllipsis(2), renderLastPage());
        }
    }, [currentPage, totalPage, pagesToShow, renderFirstPage, renderLastPage, renderPagesRange, renderEllipsis]);



    return (

        <div>
            <Header
                history={_history}
                url={WebUrl._URL_MAIN}
                title={t("BIG_WINNING")}
                hasBackButton={true}
            />
     {/*       <div className="big-winner-title">{t("BIG_WINNING")}</div>*/}
            <div className="big-winner-banner">
                {/*<img src={require("../../assets/img/v3/big-winning/banner_" + activeLang + ".jpg")} alt="banner" />*/}
                <img className="img-100 big-winner-banner-img" src={require("../../assets/img/v3/big-winning/banner_en.png")} alt="banner" />
            </div>
            {bigWinning.length > 0 ?
                <div className="big-winning-container">
                    <div className="accordion">
                        {bigWinning.map((item, index) => (
                            <div key={index} className="accordion-item">
                                <div
                                    className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <div className="accordion-header-item">
                                        <div className="rank-badge-container">
                                            <div className="rank-badge">
                                                {item.rank >= 1 && item.rank <= 3 ? <img src={require("../../assets/img/v3/big-winning/" + item.rank + ".svg")} alt="rank-badge" /> : <img src={require("../../assets/img/v3/big-winning/default-bigwinning-icon.svg")} alt="rank-badge" />}

                                            </div>
                                            {/*{item.rank >= 1 && item.rank <= 3 ? <img className="rank-badge" src={require("../../assets/img/v3/big-winning/" + item.rank + ".svg")} alt="rank-badge" /> : <div className="rank-badge"><div className="rank-badge-dot"></div></div>}*/}
                                            </div>
                                        <div>
                                           {/* <div className="text-bold">{t("USERNAME")}</div>*/}
                                            <div className="text-bold"> {item.username}</div>
                                            <div className="item-data">Win Amount:  {item.currency + " " + numberWithCurrencyFormat(item.amount, 3)}</div>
                                            </div>
                                        <div className="accordion-header-item-second">
                                        {/*   
                                        </div>
                                        <div className="accordion-header-item-second">
                                            {/*<div>*/}
                                            {/*    <div className="text-bold">{t("WIN_AMOUNT")}</div>*/}
                                            {/*    <div> {item.currency + " " + numberWithCurrencyFormat(item.amount, 3)}</div>*/}

                                            {/*</div>*/}
                                            <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>
                                                <img src={require("../../assets/img/v3/big-winning/arrow.svg")} alt="accordian-arrow" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {activeIndex === index &&
                                    <div className="accordion-seperator-container">
                                        <div className="accordion-seperator-border"></div>
                                    </div>
                                }
                                
                                <div className={`accordion-content ${activeIndex === index ? 'open' : 'closed'}`}>          
                                    <div className="accordion-content-item">
                                        <div className="">{t("BIG_WINNING_TIME")}</div>
                                        <div className="item-data">
                                            {moment(item.winningDate).format("YYYY-MM-DD HH:mm:ss")}
                                        </div>
                                    </div>
                                    <div className="accordion-content-item">
                                        <div className="">{t("BIG_WINNING_PROVIDER")}</div>
                                        <div className="item-data"> {item.providerName}</div>
                                    </div>
                                    <div className="accordion-content-item">
                                        <div className="">{t("BIG_WINNING_GAME_NAME")}</div>
                                        <div className="item-data"><div className="">{item.gameName}</div> {!stringIsNullOrEmpty(item.resultUrl) && <img className="icon" onClick={() => { handleOpenResult(item.resultUrl) }} src={require("../../assets/img/v3/big-winning/result-url.svg")} alt="country-flag" />}</div>
                                    </div>
                                    <div className="accordion-content-item">
                                        <div className="">{t("BIG_WINNING_SPIN_TYPE")}</div>
                                        <div className="item-data"> {item.spinType}</div>
                                    </div>
                                    <div className="accordion-content-item">
                                        <div className="">{t("BIG_WINNING_COUNTRY")}</div>
                                        <div className="item-data"><img className="currency-icon" src={require("../../assets/img/v3/country/" + item.country + "-circle.svg")} alt="country-flag" /><div className="">{t(item.country)}</div></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div><div className="pagination-container">
                        <ul className="pagination">
                            <li>
                                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? "disabled" : ""}>
                                    <img className="big-winning-pagination-arrow" src={require("../../assets/img/v3/big-winning/prev-arrow.svg")} alt="prev-arrow" />
                                </button>
                            </li>
                            {renderPageNumbers}
                            <li>
                                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPage} className={currentPage === totalPage ? "disabled" : ""} >
                                    <img className="big-winning-pagination-arrow" src={require("../../assets/img/v3/big-winning/next-arrow.svg")} alt="next-arrow" />
                                </button>
                            </li>
                        </ul>
                        <div className="pagination-page-details">
                            <span>
                                {t("PAGE")}{" "}
                                <strong>
                                    <input
                                        id="toPageInput"
                                        type="number"
                                        value={inputValue}
                                        max={totalPage}
                                        min={1}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    /> of {totalPage}
                                </strong>{" "}
                            </span>
                        </div>
                    </div>
                </div>
                : <div className="no-winner">
                    {t("CURRENTLY_NO_BIG_WINNER")}
                </div>
            }
        </div>

        //<Modal
        //    id="big-winning-modal"
        //    isOpen={isShowBigWinning}
        //    toggle={handleCloseBigWinningModal}
        //    centered
        //>
        //    <ModalBody>
        //        <button
        //            type="button"
        //            style={{
        //                alignSelf: "flex-end",
        //                backgroundColor: "transparent",
        //                border: "none",
        //                fontSize: "20px",
        //                color: "#ffffff",
        //                float: "right",
        //                padding: "0px",
        //            }}
        //            onClick={() => {
        //                handleCloseBigWinningModal();
        //            }}
        //            data-dismiss="modal"
        //            aria-hidden="true"
        //        >
        //            &times;
        //        </button>


        //    </ModalBody>
        //</Modal>
    )
}

export default BigWinningModal;
