import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import DynamicLoadTable2 from '../../components/custom/DynamicLoadTable2';
import Header from '../../components/custom/Header';
import { WebUrl, ApiUrl, ApiKey, SessionKey, LanguageOption, AlertTypes } from '../../util/Constant';
import { debounce } from "lodash";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import ApiEngine from "../../util/ApiEngine.js";
import { setBusy, setIdle, showMessage, showResponseMessage, setThemeName, setBannerPopupImage, setBannerPopupModal } from "../../redux/AppAction.js";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../../util/Util.js";
import {
  Alert,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { startGame, setProductCategory } from "../../redux/GameAction.js";
import NonSeamlessGameWalletModal from "../../components/custom/NonSeamlessGameWalletModal";

const ProviderGameLobby = props => {
  const { t, i18n } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [activeLanguageId, setActiveLanguageId] = useState(5);
  const [categoryActive, setCategoryActive] = useState("");
  const [activeProviderName, setActiveProviderName] = useState("");
  var { isLoggedIn } = useSelector((state) => state.authState);
  var _history = useHistory();
  var _dispatch = useDispatch();
  //const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  //const [productCategory, setProductCategory] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const providerName = params.get('name');
  const [memberFavGame, setMemberFavGame] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [selectedFavouriteGameId, setSelectedFavouriteGameId] = useState(0);
  const [selectedFavouriteGameName, setSelectedFavouriteGameName] = useState("");
  const [isCreateMessage, setIsCreateMessage] = useState(false);
  const { productCategory, rolloverTarget, isSpecialRollover, playableCategory, showNonSeamlessTransferConfirmation } = useSelector((state) => state.gameState);

  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  useEffect(() => {
    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != "undefined") {
      let tempLangId = LanguageOption.filter(x => x.code === localStorage.getItem(SessionKey._LANGUAGE))[0].languageId;
      setActiveLanguageId(tempLangId);
    }
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  //useEffect(() => {
  //  if (isLoggedIn) {
  //    getProductCategory();
  //    //getMemberSpecialRollover();
  //    //getBonusDepositProductCategory();
  //  }
  //}, [isLoggedIn])

  const onChangeKeyword = useCallback(
    debounce(async (keyword) => {
      setSearchKeyword(keyword);
      setLoadingSearchData(false);
    }, 1000), []
  );

  //const startGame = async (productGame) => {
  //  if (isLoggedIn) {
  //    // console.log('come hererererere');
  //    // console.log("productGame");
  //    // console.log(productGame);
  //    const { id, isSeamless, isNewWindow, productCategory } = productGame;
  //    if (isSpecialRollover) {
  //      let productCategoryListArr = await getBonusDepositProductCategory();
  //      if (productCategoryListArr.includes(productCategory.originalTitle)) {
  //        if (isSeamless == false) {
  //        }
  //        else {
  //          if (!window.ReactNativeWebView) {
  //            window.open(
  //              `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
  //              }?gameId=${id}&isApp=0&device=m`
  //            );
  //          } else {
  //            if (isNewWindow === true) {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                    }${ApiUrl._API_START_GAME}?gameId=${id}&device=m`,
  //                })
  //              );
  //            } else {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                    }${WebUrl._URL_GAME_PAGE}?gameId=${id}`,
  //                })
  //              );
  //            }
  //          }
  //        }
  //      }
  //      else {
  //        _dispatch(
  //          showMessage({
  //            type: AlertTypes._INFO,
  //            content: t("ROLLOVER_EXIST"),
  //            onConfirm: () => {
  //            },
  //          })
  //        );
  //      }
  //    }
  //    else {
  //      if (isSeamless == false) {
  //      }
  //      else {
  //        if (!window.ReactNativeWebView) {
  //          window.open(
  //            `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
  //            }?gameId=${id}&isApp=0&device=m`
  //          );
  //        } else {
  //          if (isNewWindow === true) {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                  }${ApiUrl._API_START_GAME}?gameId=${id}&device=m`,
  //              })
  //            );
  //          } else {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                  }${WebUrl._URL_GAME_PAGE}?gameId=${id}`,
  //              })
  //            );
  //          }
  //        }
  //      }
  //    }
  //    // window.open(ApiUrl._API_START_GAME + "?gameId=" + productGame.id + "&isApp=" + (productGame.isApp ? 1 : 0) + "&device=m");
  //  }
  //  else {
  //    _dispatch(
  //      showMessage({
  //        type: AlertTypes._INFO,
  //        content: t("PLEASE_LOGIN_FIRST"),
  //        onConfirm: () => {
  //          //console.log("hi");
  //        },
  //      })
  //    );
  //  }
  //}

  //const getMemberSpecialRollover = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
  //  let data = "";

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    data = responseJson[ApiKey._API_DATA_KEY];
  //    //setIsSpecialRollover(data);

  //    _dispatch(setPlayableCategory([]));
  //    _dispatch(setIsSpecialRollover(data));
  //  }
  //  return data;
  //}

  //const getBonusDepositProductCategory = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    if (!stringIsNullOrEmpty(data)) {
  //      let productCategoryArr = [];

  //      if (data.includes(",")) {
  //        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
  //      }
  //      else {
  //        productCategoryArr.push(data);
  //      }

  //      let tempArr = [];
  //      [...productCategory].forEach((x) => {
  //        if (productCategoryArr.includes(x.value)) {
  //          tempArr.push(x.originalTitle);
  //        }
  //      })

  //      //return tempArr;
  //      _dispatch(setPlayableCategory(tempArr));
  //    }
  //  }
  //}

  //const getProductCategory = async () => {
  //  let tempArr = [];
  //  var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
  //  if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = productCategoryJson[ApiKey._API_DATA_KEY];
  //    data.forEach(x => {
  //      if (x.id > 0) {
  //        tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
  //      }
  //    });
  //    //setProductCategory(tempArr);
  //    _dispatch(setProductCategory(tempArr));
  //  }
  //}

  const getMemberFavouriteGame = async () => {
    _dispatch(setBusy());
    var favGamesJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FAVOURITE_GAMES +
      "?selectedLanguage=" +
      selectedLanguage + ("&start=0&count=30"));
    if (favGamesJson[ApiKey._API_SUCCESS_KEY]) {
      let data = favGamesJson[ApiKey._API_DATA_KEY]["data"];
      setMemberFavGame(data);
    }
    _dispatch(setIdle());
  }

  async function toggleLike(gameId, isCreate) {
    _dispatch(setBusy());
    let params = {
      gameId: gameId
    };
    var responseJson = "";
    if (isCreate) {
      responseJson = await ApiEngine.post(ApiUrl._API_CREATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    } else {
      responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
      getMemberFavouriteGame();
      setConfirmMessage(false);
      setSelectedFavouriteGameId(0);
      setSelectedFavouriteGameName("");
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };


  return (
    <div id={"provider-lobby"} className={providerName + "-lobby "}>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={providerName}
        hasBackButton={true}
      />
      <div>
        <div className="search-container">
          <input
            type="text"
            placeholder={t("SEARCH_GAMES") + "..."}
            onInput={(e) => {
              let keyword = e.target.value;
              setLoadingSearchData(true);
              onChangeKeyword(keyword);
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="load-table-wrapper">
          <DynamicLoadTable2
            pageSize={24}
            apiQuery={ApiUrl._API_GET_PRODUCT_GAMES_LIST_DASHBOARD + "?languageId=" + activeLanguageId + (categoryActive === "" ? "" : "&category=" + categoryActive) + (searchKeyword === "" ? "" : "&keyword=" + searchKeyword) + ("&providerName=" + providerName)}
            searchEnabled={true}
            autoscrollEnabled={true}
            render={(game, i) => {
              let image = new Image();
              image.src = game["image"];
              window[game["image"]] = image;
              if (game["masterProductHasOwnLobby"] === false) {
                return (
                  <div className="load-table-wrapper-section">
                    <div className="hot-games-tile" key={i}>
                      <a
                        key={i}
                        onClick={() => {
                          //startGame(game);
                          _dispatch(startGame(game));
                        }}
                        className={
                          isLoggedIn && game["gameUm"] == true ? "gameum" : ""
                        }
                        target="_blank"
                      >


                        <div
                          className="lobby-games-img"
                          style={{
                            backgroundImage: `url(${game.image})`
                          }}
                        >
                          {game["isNewGame"] || game["isRecommended"] ? (
                            <div className="tag">
                              <img
                                src={
                                  game["isRecommended"]
                                    ? require("../../assets/img/assets/hot.png")
                                    : require("../../assets/img/assets/new.png")
                                }
                                className="img-responsive"
                                alt={game["isRecommended"] ? "hot" : "new"}
                              />
                            </div>
                          ) : null}
                          {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                          {isLoggedIn && game["gameUm"] == true && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                className="v2-um-tag game-category-label"
                              ></div>
                              <div
                                style={{
                                  backgroundImage: `url(${UMIcon})`,
                                }}
                                className="v2-um-tag-icon"
                              ></div>
                            </>
                          )}
                        </div>
                        <div className="hot-games-name">
                          <div className="hot-games-text">
                            {game.gameName}
                          </div>
                        </div>
                      </a>
                    </div>
                    {isLoggedIn ?
                      <>
                        {!stringIsNullOrEmpty(memberFavGame.filter(x => x.gameId == game["id"] && x.favStatus)) ?
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["id"]); setSelectedFavouriteGameName(game["gameName"]); setIsCreateMessage(false) }}>
                              <img
                                src={require("../../assets/img/assets//yellow-star.svg")}
                                className="heart-icon"
                                alt="yellow-star"
                              />
                            </div>
                          </>
                          :
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["id"]); setSelectedFavouriteGameName(game["gameName"]); setIsCreateMessage(true) }}>
                              <img
                                src={require("../../assets/img/assets/grey-star.svg")}
                                className="heart-icon"
                                alt="grey-star"
                              />
                            </div>
                          </>
                        }
                      </>
                      :
                      <></>
                    }
                  </div>
                );
              }
              else {
                return (
                  <div className="hot-games-tile" key={i}>
                    <a
                      key={i}
                      onClick={() => {
                        //startGame(game);
                        _dispatch(startGame(game));
                      }}
                      className={
                        isLoggedIn && game["gameUm"] == true ? "gameum" : "hot-games-link"
                      }
                      target="_blank"
                    >
                      <div
                        className="lobby-games-img"
                        style={{
                          backgroundImage: `url(${game.image})`
                        }}
                      >
                        {game["isNewGame"] || game["isRecommended"] ? (
                          <div className="providerTag">
                            <img
                              src={
                                game["isRecommended"]
                                  ? require("../../assets/img/assets/hot.png")
                                  : require("../../assets/img/assets/new.png")
                              }
                              className="img-responsive"
                              alt={game["isRecommended"] ? "hot" : "new"}
                            />
                          </div>
                        ) : null}

                        <div className="gamelist-img-inner-shadow"></div>
                        {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                        {isLoggedIn && game["gameUm"] == true && (
                          <>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              className="v2-um-tag game-category-label"
                            ></div>
                            <div
                              style={{
                                backgroundImage: `url(${UMIcon})`,
                              }}
                              className="v2-um-tag-icon"
                            ></div>
                          </>
                        )}
                      </div>
                      <div className="hot-games-name">
                        <div className="hot-games-text">
                          {game.gameName}
                        </div>
                      </div>
                    </a>
                  </div>
                )
              }
            }}
          />
        </div>
      </div>
      <Modal
        id="success-modal"
        fade={true}
        isOpen={confirmMessage}
        toggle={() => {
          setConfirmMessage(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">
            <h4 className="modal-success-title mt-4">
              {isCreateMessage ? t("CONFIRM_CREATE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName }) : t("CONFIRM_REMOVE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName })}
            </h4>
            <div className="fav-game-model">
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  setConfirmMessage(false);
                  setSelectedFavouriteGameId(0);
                  setSelectedFavouriteGameName("");
                }} >{t("NO")}</button>
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  !stringIsNullOrEmpty(memberFavGame.filter(x => x.gameId == selectedFavouriteGameId)) ? toggleLike(selectedFavouriteGameId, false) : toggleLike(selectedFavouriteGameId, true)
                }} >{t("CONFIRM")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {showNonSeamlessTransferConfirmation && <NonSeamlessGameWalletModal />}
    </div>
  )
}

export default ProviderGameLobby;