import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import DynamicLoadTable2 from '../../components/custom/DynamicLoadTable2';
import Header from '../../components/custom/Header';
import { WebUrl, ApiUrl, ApiKey, SessionKey, LanguageOption, AlertTypes, _MAIN_WALLET_ID } from '../../util/Constant';
import { debounce, map } from "lodash";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import ApiEngine from "../../util/ApiEngine.js";
import { setBusy, setIdle, showMessage, showResponseMessage, setThemeName, setBannerPopupImage, setBannerPopupModal } from "../../redux/AppAction.js";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../../util/Util.js";
import {
  Alert,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import StyledSelect from "../../components/custom/StyledSelect";
import moment from "moment";
import Numpad2 from "../../components/custom/Numpad2";
import { startGame } from "../../redux/GameAction.js";
import NonSeamlessGameWalletModal from "../../components/custom/NonSeamlessGameWalletModal";

const FavouriteGame = props => {
  const { t, i18n } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [activeLanguageId, setActiveLanguageId] = useState(5);
  const [categoryActive, setCategoryActive] = useState("");
  var _userData = useSelector((state) => state.authState.userData);
  const [activeProviderName, setActiveProviderName] = useState("");
  var { isLoggedIn } = useSelector((state) => state.authState);
  const { isSpecialRollover, setWalletList } = useSelector((state) => state.gameState);
  var _history = useHistory();
  var _dispatch = useDispatch();
  const [productCategory, setProductCategory] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const providerName = params.get('name');
  const [memberFavGame, setMemberFavGame] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [selectedFavouriteGameId, setSelectedFavouriteGameId] = useState(0);
  const [selectedFavouriteGameName, setSelectedFavouriteGameName] = useState("");
  const [isCreateMessage, setIsCreateMessage] = useState(false);
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  const [activeCategory, setActiveCategory] = useState("All");
  const [availableFilteredMasterProducts, setAvailableFilteredMasterProducts] = useState([]);
  const [availableSlotsProviders, setAvailableSlotsProviders] = useState([]);
  const [availableSlotsTotal, setAvailableSlotsTotal] = useState(0);
  const [selectedProviders, setSelectedProviders] = useState("");
  const [selectedProviderList, setSelectedProviderList] = useState([]);
  const [productGameList, setProductGameList] = useState([]);
  const [favoriteGameCategory, setFavoriteGameCategory] = useState([]);
  const [favoriteGameCategoryTotal, setFavoriteGameCategoryTotle] = useState(0);
  const { handleSubmit, register, control, watch, setValue, errors, reset } =
    useForm();
  const [selectedSlotsProviderName, setSelectedSlotsProviderName] = useState("");
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [availableSlotsProviderList, setAvailableSlotsProviderList] = useState([]);
  const [game93ConnectData, setGame93ConnectData] = useState({});
  //const [providerWalletData, setProviderWalletData] = useState({});
  const [mpData, setMpData] = useState([]);
  const [gameIsApp, setGameIsApp] = useState(false);
  const [gameHasLobby, setGameHasLobby] = useState(false);
  const [gameAppUrl, setGameAppUrl] = useState("");
  const [gameAppDeepLink, setGameAppDeepLink] = useState("");
  const [gameAppPackageNameKeyword, setGameAppPackageNameKeyword] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [allWalletData, setAllWalletData] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [activeSoloGame, setActiveSoloGame] = useState({});
  let usernameRef = useRef();
  let passwordRef = useRef();
  var timeoutRef;

  const {
    register: registerPopupTransfer,
    handleSubmit: handleSubmitPopupTransfer,
    setValue: setValuePopupTransfer,
    getValues: getValuesPopupTransfer,
    watch: watchPopupTransfer
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != "undefined") {
      let tempLangId = LanguageOption.filter(x => x.code === localStorage.getItem(SessionKey._LANGUAGE))[0].languageId;
      setActiveLanguageId(tempLangId);
    }
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  useEffect(() => {
    if (isLoggedIn) {
      //getProductCategory();
      //getMemberSpecialRollover();
      getMemberFavouriteGame();
    } else {
      //_dispatch(setPlayableCategory([]));
      //_dispatch(setRolloverTarget(0));
    }
  }, [isLoggedIn])

  //useEffect(() => {
  //  if (isLoggedIn && isSpecialRollover && productCategory.length > 0) {
  //    getBonusDepositProductCategory();
  //  }
  //}, [isSpecialRollover, productCategory])

  const onChangeKeyword = useCallback(
    debounce(async (keyword) => {
      setSearchKeyword(keyword);
      setLoadingSearchData(false);
    }, 1000), []
  );

  //const startGame = async (productGame) => {
  //  if (isLoggedIn) {
  //    const { gameId, isSeamless, isNewWindow, originalTitle, productId } = productGame;
  //    if (isSpecialRollover) {
  //      let productCategoryListArr = await getBonusDepositProductCategory();
  //      if (productCategoryListArr.includes(originalTitle)) {
  //        if (isSeamless == false) {
  //        }
  //        else {
  //          if (!window.ReactNativeWebView) {
  //            window.open(
  //              `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
  //              }?gameId=${gameId}&isApp=0&device=m`
  //            );
  //          } else {
  //            if (isNewWindow === true) {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                    }${ApiUrl._API_START_GAME}?gameId=${gameId}&device=m`,
  //                })
  //              );
  //            } else {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                    }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
  //                })
  //              );
  //            }
  //          }
  //        }
  //      }
  //      else {
  //        _dispatch(
  //          showMessage({
  //            type: AlertTypes._INFO,
  //            content: t("ROLLOVER_EXIST"),
  //            onConfirm: () => {
  //            },
  //          })
  //        );
  //      }
  //    }
  //    else {
  //      if (isSeamless == false) {
  //        _dispatch(setBusy());
  //        let walletJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?masterProductId=" + productId);
  //        if (walletJson[ApiKey._API_SUCCESS_KEY]) {
  //          let walletData = walletJson[ApiKey._API_DATA_KEY];
  //          let tempProviderWalletData = walletData.filter(x => x.productId === productId)[0];
  //          setProviderWalletData(tempProviderWalletData);
  //          let mainWalletData = walletData.filter(x => x.productId === _MAIN_WALLET_ID)[0];
  //          setCurrentWalletBalance(parseFloat(mainWalletData.balance));
  //          let mpJson = await ApiEngine(ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS + "?masterProductId=" + productId);
  //          if (mpJson[ApiKey._API_SUCCESS_KEY]) {
  //            let data = mpJson[ApiKey._API_DATA_KEY];
  //            setMpData(data);

  //            if (productGame.gameName !== "LionKing") {
  //              if (window.ReactNativeWebView && productGame.isApp) {
  //                window.ReactNativeWebView.postMessage(
  //                  JSON.stringify({
  //                    action: "deeplink",
  //                    url: productGame.appUrl,
  //                    deeplink: productGame.appDeepLink
  //                      .replace("[username]", data["username"])
  //                      .replace("[password]", data["password"]),
  //                    packageKeyword: productGame.packageNameKeyword,
  //                  })
  //                );
  //              }
  //            }
  //            else {
  //              const gameArr = [
  //                {
  //                  masterProductId: productId,
  //                  gameId: gameId,
  //                  appDeepLink: productGame?.appDeepLink,
  //                  appUrl: productGame?.appUrl,
  //                  packageKeyword: productGame?.packageNameKeyword
  //                }
  //              ];
  //              if (window.ReactNativeWebView && productGame.isApp) {
  //                startGameLionKing(gameArr);
  //              }
  //            }
  //          }
  //        }
  //        setGameIsApp(productGame.isApp);
  //        setGameHasLobby(productGame.masterProductHasOwnLobby);
  //        setGameAppUrl(productGame?.appUrl)
  //        setGameAppDeepLink(productGame?.appDeepLink);
  //        setGameAppPackageNameKeyword(productGame?.packageNameKeyword);
  //        setMasterProductId(productId);
  //        setWalletModalVisible(true);
  //        _dispatch(setIdle());
  //      }
  //      else {
  //        if (!window.ReactNativeWebView) {
  //          window.open(
  //            `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
  //            }?gameId=${gameId}&isApp=0&device=m`
  //          );
  //        } else {
  //          if (isNewWindow === true) {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                  }${ApiUrl._API_START_GAME}?gameId=${gameId}&device=m`,
  //              })
  //            );
  //          } else {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
  //                  }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
  //              })
  //            );
  //          }
  //        }

  //      }
  //    }

  //  }
  //  else {
  //    _dispatch(
  //      showMessage({
  //        type: AlertTypes._INFO,
  //        content: t("PLEASE_LOGIN_FIRST"),
  //        onConfirm: () => {
  //        },
  //      })
  //    );
  //  }
  //}

  //const getMemberSpecialRollover = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
  //  let data = "";

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    data = responseJson[ApiKey._API_DATA_KEY];
  //    _dispatch(setIsSpecialRollover(data));

  //    if (data === false) {
  //      _dispatch(setPlayableCategory([]));
  //      _dispatch(setRolloverTarget(0));
  //    }
  //  }
  //  return data;
  //}

  //const getBonusDepositProductCategory = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    let productCategoryArr = [];

  //    if (data.includes(",")) {
  //      productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
  //    }
  //    else {
  //      productCategoryArr.push(data);
  //    }

  //    let tempArr = [];
  //    [...productCategory].forEach((x) => {
  //      if (productCategoryArr.includes(x.value)) {
  //        tempArr.push(x.originalTitle);
  //      }
  //    })
  //    _dispatch(setPlayableCategory(tempArr));
  //  }
  //}

  //const getProductCategory = async () => {
  //  let tempArr = [];
  //  var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
  //  if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = productCategoryJson[ApiKey._API_DATA_KEY];
  //    data.forEach(x => {
  //      if (x.id > 0) {
  //        tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
  //      }
  //    });
  //    setProductCategory(tempArr);
  //  }
  //}

  const getMemberFavouriteGame = async () => {
    _dispatch(setBusy());
    //const availableSlotsProviderList = [];
    var favGamesJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FAVOURITE_GAMES +
      "?selectedLanguage=" +
      selectedLanguage + ("&start=0&count=30"));
    var favCategoryJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FAVOURITE_CATEGORY +
      "?selectedLanguage=" +
      selectedLanguage)
    if (favGamesJson[ApiKey._API_SUCCESS_KEY] && favCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = favGamesJson[ApiKey._API_DATA_KEY]["data"];
      let dat2 = favCategoryJson[ApiKey._API_DATA_KEY];

      let favoriteGame = data;
      let dataCategory = dat2["favoriteCategory"];
      let availableSlotsProvider = dat2["availableSlotsProviders"];
      
      setAvailableSlotsProviderList(availableSlotsProvider.map((bd) => { return { label: bd.productName, value: bd.productId } }));
      setAvailableFilteredMasterProducts(favoriteGame);
      setFavoriteGameCategory(dataCategory);
      setAvailableSlotsProviders(availableSlotsProvider);
      setAvailableSlotsTotal(availableSlotsProvider.length);
      setFavoriteGameCategoryTotle(dataCategory.length);
      setMemberFavGame(favoriteGame);
      setGame93ConnectData(favoriteGame.filter(x => x.productGame.productCategory.originalTitle === "Connect")[0]);

      if (!stringIsNullOrEmpty(selectedProviderList)) {

        setProductGameList(favoriteGame.filter(x => x.productId == selectedProviderList.productId && x.favStatus));
      }

      if (selectedProviderList.categoryName == "Slots" && favoriteGame.filter(x => x.productId == selectedProviderList.productId && x.favStatus).length == 0) {
        handleProviderClick(availableSlotsProvider[0])
      }

      if (dataCategory.filter(x => x.title == activeCategory).length == 0) {
        setActiveCategory("All");
      }
    }
    _dispatch(setIdle());
  }

  async function toggleLike(gameId, isCreate) {
    _dispatch(setBusy());
    let params = {
      gameId: gameId
    };
    var responseJson = "";
    if (isCreate) {
      responseJson = await ApiEngine.post(ApiUrl._API_CREATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    } else {
      responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
      getMemberFavouriteGame();
      setConfirmMessage(false);
      setSelectedFavouriteGameId(0);
      setSelectedFavouriteGameName("");
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  //handle catergory click
  const handleCategoryClick = async (category) => {
      setActiveCategory(category);

      if (category === "Connect") {
        if (isLoggedIn) {
          let gameData = game93ConnectData.game;
          //startGame(gameData);
        }
      }
      else if (category !== activeCategory) {
        if (category === "Slots") {
          handleProviderClick(availableSlotsProviders.filter(x => x.categoryName === category)[0]);
        } else {
          filteredAvailableMasterProducts(category);
          setSelectedProviders("");
        }
      }
  }

  // check provider has lobby or redirect start game
  async function handleProviderClick(provider) {
    setSelectedSlotsProviderName(provider.productName);
    if (isLoggedIn) {
      setPopupBannerImage(provider["popupBannerImage"]);
      if (provider.categoryName === "Slots") {
        setSelectedProviders(provider.productName);
        if (provider.categoryName === "Slots") {
          setActiveCategory(provider.categoryName);
          handleSlotsProviderClick(provider);
        } else {
          _history.push({
            pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
          });
        }
      } else {
        if (provider.game != null && provider.game["gameUm"] !== true) {
          //startGame(
          //  provider.game["id"],
          //  provider.game["gameName"],
          //  provider.game["isApp"],
          //  provider.game["appUrl"],
          //  provider.game["appDeepLink"],
          //  provider.game["packageNameKeyword"],
          //  provider.productId,
          //  provider.isSeamless,
          //  provider.categoryName,
          //);
          
        } else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("GAME_UM"),
              onConfirm: () => {
              },
            })
          );
        }
      }

    }
    else {
      if (provider.hasOwnLobby) {
        setSelectedProviders(provider.productName);
        if (provider.categoryName === "Slots") {
          setActiveCategory(provider.categoryName);
          handleSlotsProviderClick(provider);
        } else {
          _history.push({
            pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
          });
        }
      } else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("PLEASE_LOGIN_FIRST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
  }

  // retrieve product game list by provider name
  const handleSlotsProviderClick = async (product) => {
      setSelectedProviderList(product);
      setSelectedProviders(product.productName);
      setProductGameList(memberFavGame.filter(x => x.productGame.productId == product.productId && x.favStatus));
  }

  //filtered available master products
  const filteredAvailableMasterProducts = async (category) => {
    if (category !== "All") {
      setAvailableFilteredMasterProducts(memberFavGame.filter(x => x.productGame.productCategory.originalTitle === category))
    } else {
      setAvailableFilteredMasterProducts(memberFavGame);
    }

  }

  //async function startGameLionKing(gameArr) {
  //  const mpJsonLionKing = await ApiEngine(
  //    ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_LION_KING +
  //    "?masterProductId=" +
  //    gameArr[0].masterProductId + "&gameId=" + gameArr[0].gameId + "&device=m"
  //  );

  //  if (mpJsonLionKing[ApiKey._API_SUCCESS_KEY]) {
  //    const data = mpJsonLionKing[ApiKey._API_DATA_KEY];
  //    const deeplink = data.split("jqk://");

  //    window.ReactNativeWebView.postMessage(
  //      JSON.stringify({
  //        action: "deeplink",
  //        url: gameArr[0].appUrl,
  //        deeplink: gameArr[0].appDeepLink
  //          .replace("[signature]", deeplink[1]),
  //        packageKeyword: gameArr[0].packageKeyword,
  //      })
  //    );
  //  }
  //}

  //const submitTransferForm = async (data, e) => {
  //  let conversionRateAmount = numberWithCurrencyFormat(
  //    parseFloat(watchPopupTransfer("amount")) * providerWalletData.productConversionRate,
  //    2,
  //    true
  //  );

  //  _dispatch(setBusy());
  //  let params = {
  //    fromWallet: _MAIN_WALLET_ID,
  //    toWallet: providerWalletData.productId,
  //    amount: data.amount,
  //    conversionRateAmount:
  //      activeProviderName == '918Kiss' || activeProviderName == "D2 Poker" || activeProviderName == "Pussy888" || activeProviderName == "Mega888" ?
  //        conversionRateAmount : 0,
  //  };

  //  let responseJson = await ApiEngine.post(
  //    ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
  //    createFormBody(params)
  //  );

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    await updateWalletBalance(
  //      parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2),
  //      providerWalletData.productId
  //    );
  //    setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
  //    setTransferDate(moment().format("YYYY-MM-DD HH:mm:ss"));
  //    setIsTransferSuccess(true);
  //    setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
  //    e.target.reset();

  //    _dispatch(
  //      showResponseMessage(
  //        responseJson[ApiKey._API_SUCCESS_KEY],
  //        responseJson[ApiKey._API_MESSAGE_KEY]
  //      )
  //    );
  //  } else {
  //    _dispatch(
  //      showResponseMessage(
  //        responseJson[ApiKey._API_SUCCESS_KEY],
  //        responseJson[ApiKey._API_MESSAGE_KEY]
  //      )
  //    );
  //  }
  //  _dispatch(setIdle());
  //}

  //async function updateWalletBalance() {
  //  var responseJson = await ApiEngine.get(
  //    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?masterProductId=" +
  //    providerWalletData.productId
  //  );
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let walletData = responseJson[ApiKey._API_DATA_KEY];
  //    _dispatch(setWalletList(walletData));
  //    //setAllWalletData(walletData);
  //    let mainWalletData = walletData.filter(x => x.productId === _MAIN_WALLET_ID)[0];
  //    setCurrentWalletBalance(parseFloat(mainWalletData.balance));
  //    let tempProviderWalletData = walletData.filter(x => x.productId === providerWalletData.productId)[0];
  //    _dispatch(setProviderWalletData(tempProviderWalletData));
  //    //setProviderWalletData(tempProviderWalletData);
  //  }
  //}

  //function copyText(textType) {
  //  setShowCopyAlert(false);
  //  clearTimeout(timeoutRef);
  //  if (textType === "username") {
  //    usernameRef.current.select();
  //  } else if (textType === "password") {
  //    passwordRef.current.select();
  //  }
  //  document.execCommand("copy");
  //  setAlertMessage(t(textType.toUpperCase()) + " " + t("COPY_SUCCESS"));
  //  setShowCopyAlert(true);
  //  timeoutRef = setTimeout(() => setShowCopyAlert(false), 3000);
  //}

  //async function resetProductPassword() {
  //  let apiUrl =
  //    ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
  //    "?masterProductId=" +
  //    providerWalletData.productId;
  //  let responseJson = await ApiEngine.get(apiUrl);
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    let tempProviderWalletData = providerWalletData;
  //    tempProviderWalletData.password = data.password;
  //    setProviderWalletData({ ...tempProviderWalletData });
  //  }
  //}

  return (
    <div id={"provider-lobby"} className={providerName + "-lobby "}>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("FAVORITE_GAME")}
        hasBackButton={true}
      />
      <div>
        {memberFavGame && memberFavGame.length == 0 && 
          <div>
            {t("NO_DATA_FOUND")}
          </div>
        }
        <div className="date-filter-container">
          {favoriteGameCategory &&
            favoriteGameCategory.length > 0 &&
            map(favoriteGameCategory.filter(x => x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "V.Sports" && x.originalTitle !== "Fish Games" && x.originalTitle !== "RNG"), (item, index) =>
            {
              return (
                <div className="date-filter-item" style={{ minWidth: '20%', minHeight:'30px' }}>
                  <div className={activeCategory === item.originalTitle ? "favorite-game-category-active" : "favorite-game-category"} onClick={() => handleCategoryClick(item.originalTitle)}>
                    <div style={{ minWidth: '20px' }}>
                      <img
                        src={`https://cdn.i8global.com/i8app/product-category/icon-${item.originalTitle}.svg`}
                        className='img-responsive'
                        alt={item.title}
                      />
                    </div>
                    <div className={'cat-details' + (item.originalTitle === "Connect" ? " connect-font" : "")} style={{ overflow: 'hidden' }}>{t(item.originalTitle)}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div>
        {activeCategory === "Slots" && !stringIsNullOrEmpty(selectedProviders) ?
          <>
            <div className="hr-right mb-3 mt-3"></div>
            <div className="form-group">
              <div className="custom-select-box">
                <Controller
                  control={control}
                  name="productId"
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={availableSlotsProviderList}
                        value={value}
                        placeholder={selectedSlotsProviderName}
                        onChange={(e) => {
                          onChange(e.value);
                          handleSlotsProviderClick(availableSlotsProviders.filter(x => x.productId == e.value)[0]);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="hr-left mb-3 mt-3"></div>
          </>
          :
          <>
          </>
        }
      </div>
      <div>
        <div className="load-table-wrapper-favourite">
          {activeCategory === "Slots" && !stringIsNullOrEmpty(selectedProviders) &&
            productGameList && productGameList.length > 0 ?
            <>
              {productGameList.map((game, i) => {
                return (
                  <div className="load-table-wrapper-section">
                    <div className="hot-games-tile" key={i}>
                      <a
                        key={i}
                        onClick={() => {
                          _dispatch(
                            startGame(game["productGame"])
                          );
                        }}
                        className={
                          isLoggedIn && game["productGame"]["gameUm"] == true ? "gameum" : ""
                        }
                        target="_blank"
                      >


                        <div
                          className="lobby-games-img"
                          style={{
                            backgroundImage: `url(${game["productGame"].image})`
                          }}
                        >
                          {game["productGame"]["isNewGame"] || game["productGame"]["isRecommended"] ? (
                            <div className="tag">
                              <img
                                src={
                                  game["productGame"]["isRecommended"]
                                    ? require("../../assets/img/assets/hot.png")
                                    : require("../../assets/img/assets/new.png")
                                }
                                className="img-responsive"
                                alt={game["productGame"]["isRecommended"] ? "hot" : "new"}
                              />
                            </div>
                          ) : null}
                          {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                          {isLoggedIn && game["productGame"]["gameUm"] == true && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                className="v2-um-tag game-category-label"
                              ></div>
                              <div
                                style={{
                                  backgroundImage: `url(${UMIcon})`,
                                }}
                                className="v2-um-tag-icon"
                              ></div>
                            </>
                          )}
                        </div>
                        <div className="hot-games-name">
                          <div className="hot-games-text">
                            {game["productGame"].gameName}
                          </div>
                        </div>
                      </a>
                    </div>
                    {isLoggedIn ?
                      <>
                        {!stringIsNullOrEmpty(memberFavGame.filter(x => x.favStatus)) ?
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(false) }}>
                              <img
                                src={require("../../assets/img/assets//yellow-star.svg")}
                                className="heart-icon"
                                alt="yellow-star"
                              />
                            </div>
                          </>
                          :
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(true) }}>
                              <img
                                src={require("../../assets/img/assets/grey-star.svg")}
                                className="heart-icon"
                                alt="grey-star"
                              />
                            </div>
                          </>
                        }
                      </>
                      :
                      <></>
                    }
                  </div>
                )
              })}
            </>
            :
            <>
              {activeCategory !== "All" ? memberFavGame.filter(x => x.productGame.productCategory.originalTitle == activeCategory).map((game, i) => {
                return (
                  <div className="load-table-wrapper-section">
                    <div className="hot-games-tile" key={i}>
                      <a
                        key={i}
                        onClick={() => {
                          _dispatch(
                            startGame(game["productGame"])
                          );
                        }}
                        className={
                          isLoggedIn && game["productGame"]["gameUm"] == true ? "gameum" : ""
                        }
                        target="_blank"
                      >


                        <div
                          className="lobby-games-img"
                          style={{
                            backgroundImage: `url(${game["productGame"].image})`
                          }}
                        >
                          {game["productGame"]["isNewGame"] || game["productGame"]["isRecommended"] ? (
                            <div className="tag">
                              <img
                                src={
                                  game["productGame"]["isRecommended"]
                                    ? require("../../assets/img/assets/hot.png")
                                    : require("../../assets/img/assets/new.png")
                                }
                                className="img-responsive"
                                alt={game["productGame"]["isRecommended"] ? "hot" : "new"}
                              />
                            </div>
                          ) : null}
                          {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                          {isLoggedIn && game["productGame"]["gameUm"] == true && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                className="v2-um-tag game-category-label"
                              ></div>
                              <div
                                style={{
                                  backgroundImage: `url(${UMIcon})`,
                                }}
                                className="v2-um-tag-icon"
                              ></div>
                            </>
                          )}
                        </div>
                        <div className="hot-games-name">
                          <div className="hot-games-text">
                            {game["productGame"].gameName}
                          </div>
                        </div>
                      </a>
                    </div>
                    {isLoggedIn ?
                      <>
                        {!stringIsNullOrEmpty(memberFavGame.filter(x => x.favStatus)) ?
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(false) }}>
                              <img
                                src={require("../../assets/img/assets//yellow-star.svg")}
                                className="heart-icon"
                                alt="yellow-star"
                              />
                            </div>
                          </>
                          :
                          <>
                            <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(true) }}>
                              <img
                                src={require("../../assets/img/assets/grey-star.svg")}
                                className="heart-icon"
                                alt="grey-star"
                              />
                            </div>
                          </>
                        }
                      </>
                      :
                      <></>
                    }
                  </div>
                )
              })
                :
                <>
                  {memberFavGame.map((game, i) => {
                    return (
                      <div className="load-table-wrapper-section">
                        <div className="hot-games-tile" key={i}>
                          <a
                            key={i}
                            onClick={() => {
                              _dispatch(
                                startGame(game["productGame"])
                              );
                            }}
                            className={
                              isLoggedIn && game["productGame"]["gameUm"] == true ? "gameum" : ""
                            }
                            target="_blank"
                          >


                            <div
                              className="lobby-games-img"
                              style={{
                                backgroundImage: `url(${game["productGame"].image})`
                              }}
                            >
                              {game["productGame"]["isNewGame"] || game["productGame"]["isRecommended"] ? (
                                <div className="tag">
                                  <img
                                    src={
                                      game["productGame"]["isRecommended"]
                                        ? require("../../assets/img/assets/hot.png")
                                        : require("../../assets/img/assets/new.png")
                                    }
                                    className="img-responsive"
                                    alt={game["productGame"]["isRecommended"] ? "hot" : "new"}
                                  />
                                </div>
                              ) : null}
                              {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                              {isLoggedIn && game["productGame"]["gameUm"] == true && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    className="v2-um-tag game-category-label"
                                  ></div>
                                  <div
                                    style={{
                                      backgroundImage: `url(${UMIcon})`,
                                    }}
                                    className="v2-um-tag-icon"
                                  ></div>
                                </>
                              )}
                            </div>
                            <div className="hot-games-name">
                              <div className="hot-games-text">
                                {game["productGame"].gameName}
                              </div>
                            </div>
                          </a>
                        </div>
                        {isLoggedIn ?
                          <>
                            {!stringIsNullOrEmpty(memberFavGame.filter(x => x.favStatus)) ?
                              <>
                                <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(false) }}>
                                  <img
                                    src={require("../../assets/img/assets//yellow-star.svg")}
                                    className="heart-icon"
                                    alt="yellow-star"
                                  />
                                </div>
                              </>
                              :
                              <>
                                <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["productGame"]["id"]); setSelectedFavouriteGameName(game["productGame"]["gameName"]); setIsCreateMessage(true) }}>
                                  <img
                                    src={require("../../assets/img/assets/grey-star.svg")}
                                    className="heart-icon"
                                    alt="grey-star"
                                  />
                                </div>
                              </>
                            }
                          </>
                          :
                          <></>
                        }
                      </div>
                    )
                  })}
                </>
              }
            </>
          }
        </div>
      </div>
      <Modal
        id="success-modal"
        fade={true}
        isOpen={confirmMessage}
        toggle={() => {
          setConfirmMessage(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">
            <h4 className="modal-success-title mt-4">
              {isCreateMessage ? t("CONFIRM_CREATE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName }) : t("CONFIRM_REMOVE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName })}
            </h4>
            <div className="fav-game-model">
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  setConfirmMessage(false);
                  setSelectedFavouriteGameId(0);
                  setSelectedFavouriteGameName("");
                }} >{t("NO")}</button>
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  !stringIsNullOrEmpty(memberFavGame.filter(x => x.gameId == selectedFavouriteGameId)) ? toggleLike(selectedFavouriteGameId, false) : toggleLike(selectedFavouriteGameId, true)
                }} >{t("CONFIRM")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      
      {currentInput &&
        <Numpad2
          currentBalance={_userData.walletBalance}
          currentInput={currentInput}
          setCurrentInput={(e) => {
            setCurrentInput(e);
          }}
          contentClassName="popup-transfer-numpad"
        />
      }
      <NonSeamlessGameWalletModal />
    </div>
  )
}

export default FavouriteGame;