import React from "react";
import { performLogout } from "../../redux/AuthAction";
import { useDispatch } from "react-redux";
const CamoHomePage = (props) => {
  const _dispatch = useDispatch();
  return (
    <>
      {/* <div style={{ position: "fixed", top: "0", left: "0", width: "fit-content", height: "fit-content", zIndex: "999", backgroundColor: "transparent", cursor: "pointer", margin: "15px" }}>
            <i className="fas fa-sign-out-alt" style={{ color: "#8a99b5" }} onClick={() => _dispatch(performLogout())}/>
        </div> */}
      <div>
        <iframe
          className="home_page"
          src={
            "https://crypto-admin-templates.multipurposethemes.com/sass/classic-dark/index.html"
          }
        />
      </div>
    </>
  );
};

export default CamoHomePage;
