import React from "react";
import { stringIsNullOrEmpty } from "../../util/Util";
import { WebUrl } from "../../util/Constant";

/// <summary>
/// Author :
/// Used to create the navigation button
/// It will form different button based on the existence of url
/// </summary>
const NavigationButton = (props) => {
  if (!stringIsNullOrEmpty(props.url)) {
    return (
      <button
        type="button"
        className={props.class ? props.class : "btn btn-primary"}
        style={props.style ? props.style : {}}
        onClick={() => {
          /**
           * If detected is mobile app, ignore history push
           */
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "back",
                history: props.history,
              })
            );
            if (
              props.url === WebUrl._URL_DASHBOARD_V2 ||
              props.url === WebUrl._URL_DASHBOARD
            ) {
              props.history.go(-(props.history.length - 1));
              return;
            }

            props.history.goBack();
          } else {
            props.history.push(props.url, props.state);
          }
        }}
      >
        <i className={props.icon ? props.icon : "fa fa-plus-circle m-r-10"}></i>
        {props.buttonText}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className={props.class ? props.class : "btn btn-inverse"}
        style={props.style ? props.style : {}}
        onClick={() => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "back",
                history: props.history,
              })
            );
          }

          props.history.goBack();
        }}
      >
        <i
          className={props.icon ? props.icon : "fas fa-long-arrow-alt-left"}
        ></i>
        {props.buttonText}
      </button>
    );
  }
};

export default NavigationButton;
