import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import Header from '../../components/custom/Header';
import { Modal, ModalBody } from "reactstrap";
import { ApiKey, ApiUrl, WebUrl, TransactionStatus } from "../../util/Constant.js";
import { useTranslation } from "react-i18next";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util.js";
import { Spinner } from "react-bootstrap";

/// <summary>
/// Author :
/// </summary>
const ReloadHistory = (props) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [reloadHistoryData, setReloadHistoryData] = useState([]);
  const [activeData, setActiveData] = useState({});
  const [rejectedModalVisible, setRejectedModalVisible] = useState(false);
  const [approvedModalVisible, setApprovedModalVisible] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getReloadHistory(startDate, endDate);
  }

  const getReloadHistory = async (start, end, selected = "") => {
    setIsLoadingData(true);
    let apiUrl =
      ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&type=" +
      "1";

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      setReloadHistoryData(data);
    }
    setIsLoadingData(false)
  }

  const handleTxnOnClick = (recordData) => {
    setActiveData(recordData);
    setApprovedModalVisible(true);
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_DEPOSIT}
        title={t("RELOAD_HISTORY")}
        hasBackButton={true}
      />
      <DateRangeFilter2
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onSave={(start, end) => {
          getReloadHistory(start, end);
          setSelectedDate("");
        }}
        options={true}
      />
      <hr className="custom-hr"/>
      <div className="">
        {!isLoadingData ?
          <>
            {reloadHistoryData &&
              reloadHistoryData.length > 0 &&
              reloadHistoryData.map((data, index) => {
                return (
                  <div className="reload-list-item" onClick={() => handleTxnOnClick(data)}>
                    <div className="first">
                      <div>
                        {data.approvalStatus === TransactionStatus._APPROVED ? (
                          <img
                            src={require("../../assets/img/v3/approved.png")}
                            alt="approved"
                          />
                        ) : (
                          <img
                            src={require("../../assets/img/v3/rejected.png")}
                            alt="rejected"
                          />
                        )}
                      </div>
                      <div>{data.approvalStatus === TransactionStatus._APPROVED ? t("APPROVED") : t("REJECTED")}</div>
                    </div>
                    <div className="second">{numberWithCurrencyFormat(data.amount, 2, true)}</div>
                  </div>
                )
              })
            }
            {(!reloadHistoryData || reloadHistoryData.length <= 0) &&
              <div style={{ textAlign: "center" }}>{t("NO_DATA_FOUND")}</div>
            }
          </>
          :
          <div style={{ textAlign: "center" }}><Spinner animation="border"></Spinner></div>
        }
      </div>
      <Modal
        id="custom-modal"
        contentClassName="modal-brand modal-bottom"
        fade={true}
        isOpen={approvedModalVisible}
        toggle={() => {
          setApprovedModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-custom-header justify-content-center">
            <h2 className="mb-0">{t("DETAILS")}</h2>
          </div>
          <div className="text-center mt-4">
            <img
              src={activeData.approvalStatus === TransactionStatus._APPROVED ? require("../../assets/img/v3/approved-modal.png") : require("../../assets/img/v3/rejected-modal.png")}
              alt={activeData.approvalStatus === TransactionStatus._APPROVED ? t("APPROVED") : t("REJECTED")}
            />
          </div>
          <div className={"reload-modal-" + (activeData.approvalStatus === TransactionStatus._APPROVED ? "approved" : "rejected") + "-text mt-2"}>
            {activeData.approvalStatus === TransactionStatus._APPROVED ? t("APPROVED") : t("REJECTED")}
          </div>
          <div className="reload-modal-amount mt-3">
            {numberWithCurrencyFormat(activeData.amount, 2, true)}
          </div>
          <div className="reload-modal-date-balance-container mt-4">
            <div className="first">
              <div>
                {t("DATE_AND_TIME")}
              </div>
              <div>
                {activeData.dateTime}
              </div>
            </div>
            {activeData.postMemberPrimaryBalance > 0 && <div className="second mt-2">
              <div>
                {t("BALANCE")}
              </div>
              <div>
                {numberWithCurrencyFormat(activeData.postMemberPrimaryBalance, 2, true)}
              </div>
            </div>}
            <div className="third mt-2">
              <div>
                {t("TRANSACTION_ID")}
              </div>
              <div>
                {activeData.id}
              </div>
            </div>
          </div>
          <div>
            <a className="btn custom-btn mt-5" onClick={() => setApprovedModalVisible(false)}>{t("CLOSE")}</a>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReloadHistory;
