import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ApiUrl,
  ApiKey,
  AlertTypes
} from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine';
import {
  showMessage
} from "../../redux/AppAction";
import CustomSpinWheel from "./CustomSpinWheel";

const CustomSpinWheelContainer = () => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.authState);
  const liveChat  = useSelector((state) => state.appState.liveChat);

  const [freeSpinCount, setFreeSpinCount] = useState(0);
  const [showFreeSpin, setShowFreeSpin] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
        checkMemberHasFreeSpin();
      }

      console.log(liveChat);
  }, [isLoggedIn]);


  const checkMemberHasFreeSpin = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_CHECK_FREE_SPIN_COUNT);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (data != null && data > 0) {
        setFreeSpinCount(data);
        setShowFreeSpin(true);
      }
      else {
        setFreeSpinCount(0);
        setShowFreeSpin(false);
      }
    }
    else {
      setFreeSpinCount(0);
      setShowFreeSpin(false);
    }
  }

  const onclickCheckMemberHasFreeSpin = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_CHECK_FREE_SPIN_COUNT);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (data != null && data > 0) {
        setFreeSpinCount(data);
        setShowFreeSpin(true);
      }
      else {
        setFreeSpinCount(0);
        setShowFreeSpin(false);
        _dispatch(
          showMessage({
            type: AlertTypes._ERROR,
            content: t("DEPOSIT_FOR_FREE_SPIN"),
          })
        );
      }
    }
    else {
      setFreeSpinCount(0);
      setShowFreeSpin(false);
    }
  }


  const deductMemberFreeSpin = async () => {
    if (freeSpinCount > 0) {
      setFreeSpinCount(freeSpinCount - 1)
    }
  }


  const memoizedComponents = useMemo(() => {
    return (
      <CustomSpinWheel freeSpinCount={freeSpinCount} setShowFreeSpin={setShowFreeSpin} deductMemberFreeSpin={deductMemberFreeSpin} />
    );
  }, [freeSpinCount])


    const miniMemoComponent = useMemo(() => {
        console.log(liveChat);
      return (
        
          <div className={liveChat ? "spin-container-open-upper" : "spin-container-open"} onClick={() => onclickCheckMemberHasFreeSpin()}></div>
    ); 
  }, [freeSpinCount,liveChat])


  return (
    <>
      {isLoggedIn && showFreeSpin && memoizedComponents}
      {isLoggedIn && !showFreeSpin && miniMemoComponent}
    </>
  )
}

export default CustomSpinWheelContainer;
