import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WebUrl } from "../../util/Constant";
import Header from "../../components/custom/Header";
import LiveChat from "react-livechat";
import querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { setLiveChat, setBusy, setIdle } from "../../redux/AppAction";

/// <summary>
/// Author: -
/// </summary>
const LiveChatPage = props => {
    const _history = useHistory();
    var _querystring = querystring;
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const _activeTheme = useSelector(state => state.authState.userData.selectedTheme);

    const _LOAD_STATUS = {
        LOAD_PENDING: 0,
        LOAD_SUCCESS: 1,
        LOAD_FAIL: -2
    }

    const [livechat, setLivechat] = useState();
    const [isLoaded, setIsLoaded] = useState(_LOAD_STATUS.LOAD_PENDING);

    useEffect(() => {
        _dispatch(setBusy());
        window.__lc = {};
        
        /// Validate the Username and password
        if (_location.search !== undefined) {
            const data = _querystring.parse(_location.search);
            window.__lc.license = parseInt(data.license);
        } else {
            setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
        }
    }, []);

    useEffect(() => {
        if (isLoaded != _LOAD_STATUS.LOAD_PENDING) {
            _dispatch(setIdle());
        }
    }, [isLoaded]);

    useEffect(() => {
        init();
    }, [livechat]);

    async function init() {
        if (livechat != undefined) {
            console.log(livechat);

            livechat.on_after_load = function () {
                livechat.open_chat_window();
                livechat.show_full_view();
                setIsLoaded(_LOAD_STATUS.LOAD_SUCCESS);
            }
            setTimeout(function () {
                if (!livechat.is_loaded()) {
                    setIsLoaded(_LOAD_STATUS.LOAD_FAIL);
                }
                else {
                    _dispatch(setLiveChat(true));
                }
            }, 5000);
        }
    }

    return (
        <div>
            <div style={{ paddingBottom: "10px" }}>
                <Header history={_history} url={WebUrl._URL_MAIN} title={t('LIVE_CHAT')}
                hasBackButton={!window.ReactNativeWebView} />
                {
                    isLoaded == _LOAD_STATUS.LOAD_FAIL &&
                    <div className={(_activeTheme === 0 ? "text-color-blue " : "text-color-white ") + "text-center"}>{t("LIVE_CHAT_NOT_LOADED")}</div>
                }
                {
                    isLoaded == _LOAD_STATUS.LOAD_SUCCESS &&
                    <div className={(_activeTheme === 0 ? "text-color-blue " : "text-color-white ") + "text-center"}>{t("LIVE_CHAT_LOADED")}</div>
                }
                <div className="testmodal">
                    <LiveChat license={0} onChatLoaded={ref => setLivechat(ref)} show />
                </div>
            </div>
        </div>
    )

}

export default LiveChatPage;