import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { useDispatch, useSelector } from "react-redux";
import {
    ApiKey,
    ApiUrl,
    WebUrl,
    TransactionType,
    TransactionStatus,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import moment from "moment";

const RolloverHistory = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
  const [transactionData, setTransactionData] = useState([]);
  const [notActiveRollOverCount, setNotActiveRollOverCount] = useState(0);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getBonusData();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getBonusData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          setTransactionData(data);

          let count = 0;
          data.forEach(item => {
            if (item.status !== "Active") {
              count++;
            }
          })
          setNotActiveRollOverCount(count);
        }
    }

    return (
        <>
            <Header
                history={_history}
                url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
                title={t("ROLLOVER_STATEMENT")}
            />
            <div
                id="rollover"
                style={{ marginBottom: "10px" }}
            >
                {transactionData.length === 0 || transactionData.length === notActiveRollOverCount ?
                  <div className="no-data-found-text">{t("NO_DATA_FOUND")}</div> :
                    transactionData.map((item, i) => {
                      if (item["status"] === "Active") {
                        var statusHtml = "";

                        statusHtml =
                          item["isLatestRecord"] == 1 ? (
                            <span
                              className={
                                item["RolloverProgress"] == item["RolloverTarget"]
                                  ? "green-label-box"
                                  : "red-label-box"
                              }
                            >
                              {t(item["status"])}
                            </span>
                          ) : (
                            <span className="red-label-box">
                              {t(item["status"])}
                            </span>
                          );

                        return (
                          <div className="card card-rollover mb-2" key={i}>
                            <div>
                              <table
                                className="table mb-0 table-rollover table-valign-middle"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ fontSize: "12px", color: '#898F97' }} className="date">{moment(item["auditDate"]).format('DD-MM-YYYY HH:mm:ss')}</td>
                                    <td>{statusHtml}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("AMOUNT")}</td>
                                    <th style={{ paddingRight: "4px" }}>{item["amount"]}</th>
                                  </tr>
                                  <tr>
                                    <td>{t("ROLLOVER_PROGRESS")}</td>
                                    <th style={{ paddingRight: "4px" }}>
                                      {item["isLatestRecord"] == 1
                                        ? item["rolloverProgress"]
                                        : item["rolloverTarget"]}{" "}
                                      / {item["rolloverTarget"]}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      }
                    })}
            </div>
        </>
    );
};

export default RolloverHistory;
