import moment from 'moment';
import { ApiKey, ApiUrl, WebUrl, AlertTypes, _MAIN_WALLET_ID } from "../util/Constant";
import { showMessage, setDisplayLogin } from './AppAction';
import { stringIsNullOrEmpty, numberWithCurrencyFormat, createFormBody } from "../util/Util";
import  store  from "../ApplicationStore";
import i18n from '../localization/i18n';
import { bool } from 'prop-types';

export const _PRODUCT_CATEGORY = "_PRODUCT_CATEGORY";
export const _ROLLOVER_TARGET = "_ROLLOVER_TARGET";
export const _ROLLOVER_PROCESS = "_ROLLOVER_PROCESS"
export const _PLAYABLE_CATEGORY = "_PLAYABLE_CATEGORY";
export const _PRODUCT_GAME_ID = '_PRODUCT_GAME_ID';
export const _MASTER_PRODUCT_ID = '_MASTER_PRODUCT_ID';
export const _MASTER_PRODUCT_NAME = '_MASTER_PRODUCT_NAME';
export const _GAME_IS_APP = '_GAME_IS_APP';
export const _GAME_APP_URL = '_GAME_APP_URL';
export const _GAME_APP_DEEP_LINK = '_GAME_APP_DEEP_LINK';
export const _GAME_APP_PACKAGE_NAME_KEYWORD = '_GAME_APP_PACKAGE_NAME_KEYWORD';
export const _WALLET_LIST = '_WALLET_LIST';
export const _PROVIDER_WALLET_DATA = '_PROVIDER_WALLET_DATA';
export const _MP_DATA = '_MP_DATA';
export const _SHOW_PREGAME_POPUP = '_SHOW_PREGAME_POPUP';
export const _SHOW_NON_SEAMLESS_TRANSFER_CONFIRMATION = '_SHOW_NON_SEAMLESS_TRANSFER_CONFIRMATION';
export const _GAME_HAVE_LOBBY = '_GAME_HAVE_LOBBY';
export const _ACTIVE_SOLO_GAME = '_ACTIVE_SOLO_GAME';
export const _CURRENT_ROLLOVER_BALANCE = "_CURRENT_ROLLOVER_BALANCE";
export const _MASTER_PRODUCT_CATEGORY = '_MASTER_PRODUCT_CATEGORY';

export const setProductCategory = (productCategory) => {
  return (dispatch) => {
    dispatch({
      type: _PRODUCT_CATEGORY,
      payload: productCategory,
    });
  };
};

export const setRolloverTarget = (rolloverTarget) => {
  return (dispatch) => {
    dispatch({
      type: _ROLLOVER_TARGET,
      payload: rolloverTarget,
    });
  };
};

export const setRolloverProcess = (rolloverProcess) => {
  return (dispatch) => {
    dispatch({
      type: _ROLLOVER_PROCESS,
      payload: rolloverProcess,
    });
  };

};
export const setCurrentRolloverBalance = (currentRolloverBalance) => {
  return (dispatch) => {
    dispatch({
      type: _CURRENT_ROLLOVER_BALANCE,
      payload: currentRolloverBalance,
    });
  };
};

export const setPlayableCategory = (playableCategory) => {
  return (dispatch) => {
    dispatch({
      type: _PLAYABLE_CATEGORY,
      payload: playableCategory,
    });
  };
};

export const setProductGameId = (id) => {
  return (dispatch) => {
    dispatch({
      type: _PRODUCT_GAME_ID,
      payload: id,
    });
  };
};

export const setMasterProductId = (id) => {
  return (dispatch) => {
    dispatch({
      type: _MASTER_PRODUCT_ID,
      payload: id,
    });
  };
};

export const setMasterProductName = (name) => {
  return (dispatch) => {
    dispatch({
      type: _MASTER_PRODUCT_NAME,
      payload: name,
    });
  };
};


export const setGameIsApp = (isApp) => {
  return (dispatch) => {
    dispatch({
      type: _GAME_IS_APP,
      payload: isApp,
    });
  };
};


export const setGameAppUrl = (url) => {
  return (dispatch) => {
    dispatch({
      type: _GAME_APP_URL,
      payload: url,
    });
  };
};



export const setGameAppDeepLink = (deepLink) => {
  return (dispatch) => {
    dispatch({
      type: _GAME_APP_DEEP_LINK,
      payload: deepLink,
    });
  };
};

export const setGameAppPackageNameKeyword = (keyword) => {
  return (dispatch) => {
    dispatch({
      type: _GAME_APP_PACKAGE_NAME_KEYWORD,
      payload: keyword,
    });
  };
};


export const setWalletList = (walletList) => {
  return (dispatch) => {
    dispatch({
      type: _WALLET_LIST,
      payload: walletList,
    });
  };
};

export const setProviderWalletData = (data) => {
  return (dispatch) => {
    dispatch({
      type: _PROVIDER_WALLET_DATA,
      payload: data,
    });
  };
};

export const setMpData = (data) => {
  return (dispatch) => {
    dispatch({
      type: _MP_DATA,
      payload: data,
    });
  };
};

export const setShowPregamePopup = (show) => {
  return (dispatch) => {
    dispatch({
      type: _SHOW_PREGAME_POPUP,
      payload: show,
    });
  };
};



export const setShowNonSeamlessTransferConfirmation = (show) => {
  return (dispatch) => {
    dispatch({
      type: _SHOW_NON_SEAMLESS_TRANSFER_CONFIRMATION,
      payload: show,
    });
  };
};

export const setGameHaveLobby = (show) => {
  return (dispatch) => {
    dispatch({
      type: _GAME_HAVE_LOBBY,
      payload: show,
    });
  };
};

export const setActiveSoloGame = (show) => {
  return (dispatch) => {
    dispatch({
      type: _ACTIVE_SOLO_GAME,
      payload: show,
    });
  };
};

export const setMasterProductCategory = (name) => {
  return (dispatch) => {
    dispatch({
      type: _MASTER_PRODUCT_CATEGORY,
      payload: name,
    });
  };
};

// Start game
export const startGame = (productGame) => {
  return async (dispatch) => {
    const { isLoggedIn, userData } = store.getState().authState;
    const { playableCategory } = store.getState().gameState;
    if (isLoggedIn) {
      if (productGame && productGame.gameUm !== true) {
        if (userData.isSpecialRollover) {
          let productCategoryListArr = [...playableCategory];
          if (productCategoryListArr.includes(productGame.productCategory.originalTitle))
          {
            dispatch(checkIsSeamlessBeforeStart(
              productGame
            ));
          }
          else {
            dispatch(showMessage({
              type: 'INFO',
              content: i18n.t('ROLLOVER_EXIST'),
              onConfirm: () => { },
            }));
          }
        }
        else
        {
          dispatch(checkIsSeamlessBeforeStart(
            productGame
          ));
        }
      } else {
        if (productGame.gameUm === true) {
          dispatch(showMessage({
            type: 'INFO',
            content: i18n.t('GAME_UM'),
            onConfirm: () => { },
          }));
        } else if (productGame) {
          dispatch(showMessage({
            type: 'INFO',
            content: i18n.t('COMING_SOON'),
          }));
        }
      }
    } 
    else {
      dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: i18n.t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  };
};

// check is seamless before start
const checkIsSeamlessBeforeStart = (productGame) => {
  return async (dispatch) => {
    if (productGame.product.isSeamless === false) {
      dispatch(setProductGameId(productGame.id));
      dispatch(setShowNonSeamlessTransferConfirmation(true));
      dispatch(setMasterProductId(productGame.productId));
      dispatch(setGameAppDeepLink(productGame.appDeepLink));
      dispatch(setGameAppPackageNameKeyword(productGame.packageNameKeyword));
      dispatch(setMasterProductName(productGame.gameName));
      dispatch(setMasterProductCategory(productGame.productCategory.originalTitle));
      dispatch(setGameIsApp(productGame.isApp));
      dispatch(setGameHaveLobby(productGame.product.hasOwnLobby));
      if (productGame.isApp && !stringIsNullOrEmpty(productGame.appUrl)) {
        dispatch(setGameAppUrl(productGame.appUrl));
      }
    } else {
      dispatch(enterGame(productGame.id, productGame.isApp, productGame.isNewWindow));
    }
  }
}

// Enter game
export const enterGame = (id, isApp = false , isNewWindow = false) => {
  return async (dispatch) => {
    if (!window.ReactNativeWebView) {
      window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
    } else {
      if (isNewWindow === true) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
              }${ApiUrl._API_START_GAME}?gameId=${id}&device=m`,
          })
        );
      } else {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
              }${WebUrl._URL_GAME_PAGE}?gameId=${id}`,
          })
        );
      }
    }
  };
}