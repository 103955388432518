import React, {useEffect} from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author : -
/// </summary>
const StyledSelect = props => {
    const themeName = useSelector((state) => state.appState.themeName);

    useEffect(() => {
        console.log(themeName);
    }, []);

    const { options, placeholder = "", value, onChange, ...otherProps } = props;
    const _SELECT_STYLES = {
        menu: (styles, state) => ({
            ...styles,
            position: 'absolute !important',
            zIndex: 2,
            background: '#0e1a3c !important;',
            fontSize: '12px !important',
            borderRadius: '8px'
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '8px',
            background: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%)',
            height: '40px',
            border: 'none !important',
            position: 'relative',
            boxShadow: 'none !important'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {

            if (isSelected) {
                return {
                    ...styles,
                    background: "#3369FF",
                    color: '#fff'
                };
            } else if (isFocused) {
                return {
                    ...styles,
                    background: "#FFF",
                    color: '#000'
                };
            } else {
                return {
                    ...styles,
                    color: '#fff',
                };
            }
        },
        singleValue: styles => ({
            ...styles,
            color: '#fff',
            padding: "2px 0px"
        }),
        valueContainer: styles => ({
            ...styles,
            color: '#fff',
            padding: "2px 10px",
            height: '35px'
        }),
        indicatorContainer: styles => ({
            ...styles,
            padding: "10px"
        }),
        indicatorSeparator: styles => ({
            ...styles,
            width: "0px"
        }),
        placeholder: styles => ({
            ...styles,
            color: '#A4A4A4'
        }),
    }

    const _SELECT_STYLES_LIGHT = {
        menu: (styles, state) => ({
            ...styles,
            background: '#fff',
        }),
        control: (styles, state) => ({
            ...styles,
            borderRadius: '8px',
            boxShadow: 'unset',
            height: '35px',
            fontSize: '.8rem',
            border: '0px',
            color: '#272727',
            background: "white",
            padding: "0px",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if (isSelected) {
                return {
                    ...styles,
                    background: "#3369FF",
                    color: '#fff'
                };
            
            } else if (isFocused) {
                return {
                    ...styles,
                    background: "#A4A4A4",
                    color: '#000'
                };
            }
            else {
                return {
                    ...styles,
                    background: '#fff',
                    color: '#A4A4A4',
                };
            }
        },

        singleValue: styles => ({
            ...styles,
            paddingLeft: '1.2rem',
            fontSize: '12px',
            color: '#272727',
            fontWeight: 'bold',
            padding: "0px",
        }),
        valueContainer: styles => ({
            ...styles,
            padding: "2px 10px",
            height: '35px',
            color: ' #272727'
        }),
        indicatorSeparator: styles => ({
            ...styles,
            display: "none",
            color: ' #272727'
        }),
        indicatorContainer: styles => ({
            ...styles,
            padding: "none",
            color: ' #272727'
        }),
        placeholder: styles => ({
            ...styles,
            fontSize: '14px',
            color: '#272727',
        })
    }

  return (
    <div className='custom-style-selection'>
        <Select
            options={options} isSearchable={false}
            styles={themeName === "light" ? _SELECT_STYLES_LIGHT : _SELECT_STYLES}
            placeholder={(options.filter(option => option.value == value)[0] !== undefined) ? (options.filter(option => option.value == value)[0].label) : placeholder}
            value={options.filter(option => option.value == value)}
            onChange={(e) => {
                onChange(e);
            }}
        {...otherProps} />
    </div>
    )
}

export default StyledSelect;