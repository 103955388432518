import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import { setBusy, setIdle, showResponseMessage, setDownlineSearchText, setDownlineQuery, setPageSize, setY } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { map } from "lodash";
import Accordion from "react-bootstrap/Accordion";
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import Header from "../../components/custom/Header";

/// <summary>
/// Author: -
/// </summary>
const Downline = props => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();

  const [searchDownlineKeyword, setSearchDownlineKeyword] = useState('');
  const [memberData, setMemberData] = useState({});
  const [downlines, setDownlines] = useState([]);
  const [displayDataIndex, setDisplayDataIndex] = useState(-1);
  const [isEditForm, setIsEditForm] = useState(false);
  const [activeOptionsList, setActiveOptionsList] = useState([]);
  const [member, setMember] = useState({});
  const [hideSummary, setHideSummary] = useState(false);
  const [share, setShare] = useState(0);
  const [shareholderIndex, setShareholderIndex] = useState("");
  const [displayData, setDisplayData] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAssignNewSH, setIsAssignNewSH] = useState(false);
  const [currentMemberHideSummary, setCurrentMemberHideSummary] = useState(false);
  let _userData = useSelector(state => state.authState.userData);
  let _savedData = useSelector(state => state.appState.savedText);
  let _savedQuery = useSelector(state => state.appState.savedQuery);
  let _savedPageSize = useSelector(state => state.appState.pageSize);
  const [page, setPage] = useState(0);
  const [tabActive, setTabActive] = useState(1);
  const [searchText, setSearchText] = useState("");

  const tabList = [
    {
      id: 1,
      title: 'SHAREHOLDERS',
    },
    {
      id: 2,
      title: 'NON_SHAREHOLDERS',
    },
  ];

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    }
    finally {
      _dispatch(setIdle());
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function getMemberDetails() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      let temp = null;
      if (_location && _location.state && _location.state.hideSummary) { temp = _location.state.hideSummary; }
      else if (_location && _location.search) { temp = _location.search.substring(_location.search.indexOf("=") + 1, _location.search.length); }
      else if (props && props.hideSummary) { temp = props.hideSummary; }
      setCurrentMemberHideSummary(temp === true);
      if (("" + responseJson[ApiKey._API_DATA_KEY]["hideSummary"]) !== temp) {
        _dispatch(showResponseMessage(false, "Error"));
        _history.push(WebUrl._URL_MAIN);
      }
    }
    else {
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }
  }

  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    setSearchText(e.target.value);
    _dispatch(setDownlineQuery(ApiUrl._API_GET_USER_MEMBER_LIST_SH + "?keyword=" + e.target.value + "&directUserOnly=true" + "&isShareHolder=" + (tabActive == 1 ? true : false)));
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  const handleEditShBtn = async (downline, index, e) => {
    _dispatch(setBusy());
    e.preventDefault();
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID + "?memberId=" + downline['id'] + "&shareholder=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];
      let tempOptionsList = [];
      for (var i = editingDownlineData["minShare"]; i <= editingDownlineData["maxShare"]; i += 5) {
        tempOptionsList.push(i.toFixed(2));
      }
      setActiveOptionsList(tempOptionsList);
      // setMember(editingDownlineData);
      // setHideSummary(editingDownlineData["hideSummary"]);
      // setShare(editingDownlineData["sharePercent"]);

      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setHideSummary(responseJson[ApiKey._API_DATA_KEY]["hideSummary"]);
      if (responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] !== undefined) {
        setShare(responseJson[ApiKey._API_DATA_KEY]['sharePercent']);
      }
      else {
        setShare(responseJson[ApiKey._API_DATA_KEY]['maxShare']);
      }

      setShareholderIndex(index);
      setIsAssignNewSH(responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] === undefined);
      setIsEditForm(true);
    }
    _dispatch(setIdle());
  }

  const handleSubmitForm = () => {
    if (isAssignNewSH) {
      setShowConfirmation(true);
    }
    else {
      submitForm();
    }
    setIsEditForm(false);
  }

  const submitForm = async () => {
    _dispatch(setBusy());
    let params =
    {
      "memberId": member.id,
      "share": share,
      "hideSummary": hideSummary
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_SHAREHOLDER,
      createMultiPartFormBody(params)
    );
    _dispatch(setY(0));
    setDisplayData(false);
    setDisplayDataIndex(-1);
    setTimeout(() => setDisplayData(true), 1);
    _dispatch(setIdle());
  }

  return (
    <>
      {_userData.hideSummary !== true && <Header
        history={_history}
        url={WebUrl._URL_SHAREHOLDER + "?GAzobE73Vse4nr8WpufA=" + _userData.hideSummary}
        title={t("SHAREHOLDERS")}
      />}
      {<div className="transfer-header-tab-container">
        {tabList &&
          tabList.length &&
          map(tabList, (item, index) => {
            return (
              <div key={index} className={item.id == tabActive ? "transfer-tab-list-item active" : "transfer-tab-list-item"} onClick={() => {
                setTabActive(item.id);
                _dispatch(setDownlineQuery(ApiUrl._API_GET_USER_MEMBER_LIST_SH + "?keyword=" + searchText + "&directUserOnly=true" + "&isShareHolder=" + (item.id == 1 ? true : false)))
              }
              }>
                {t(item.title)}
              </div>
            );
          })
        }
      </div>}
      <div className="hr-right mt-3 mb-3"></div>
      <div className="search-input-box">
        <Input placeholder={t("ALIAS_NAME_PHONE")} defaultValue={_savedData} onInput={(e) => { getText(e) }}
          inputGroupType="append" inputGroupContent={<img src={require("../../assets/img/v3/search-blue.png")} alt="search" />} />
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      {displayData &&
        <Accordion className="vip-event-accordion">
          <DynamicLoadTable pageSize={_savedPageSize} apiQuery={_savedQuery} searchEnabled={true} autoscrollEnabled={true} render={(downline, i) => {
            return (
              <>
                {tabActive == 1 && downline['isShareHolder'] == 1 &&
                  <>
                    <Accordion.Item
                      key={i}
                      eventKey={i}
                    >
                      <Accordion.Header onClick={() => {
                        if (displayDataIndex === i) {
                          setDisplayDataIndex(-1);
                        }
                        else {
                          setDisplayDataIndex(i);
                        }
                        setIsEditForm(false);
                      }} >
                        <div className="text-left">
                          {downline['username']} <br />
                          <div className="font11 mt-1">{downline['phoneNumber']}</div>
                        </div>
                        <div className="hide-summary-box">
                          {downline['hideSummary'] == true &&
                            <div><img src={require("../../assets/img/v3/hide-summary.png")} alt="hide" /></div>
                          }
                          <i className={"fas fa-chevron-" + (displayDataIndex === i ? "down" : "right")}></i>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="vip-event-downline-data">
                          <div className="shareholder-data shareholder-date">
                            <div className="data-left">{t("SHAREHOLDERDATE")}</div>
                            <div className="data-right">{moment(downline.shareHolderDate).format("YYYY-MM-DD")}</div>
                          </div>
                          <div className="shareholder-data shareholder-percent">
                            <div className="data-left">{t("SHAREPERCENT")}</div>
                            <div className="data-right">{downline.sharePercent}%</div>
                          </div>
                          <div className="edit-sh-btn" onClick={(e) => handleEditShBtn(downline, i, e)}>
                            {t("EDITSHAREHOLDER")}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                }
                {tabActive == 2 && downline['isShareHolder'] == false &&

                                    <Accordion.Item
                                    >
                                        <Accordion.Header>
                                            <div className="text-left">
                                                {downline['username']} <br />
                                                <div className="font11 mt-1">{downline['phoneNumber']}</div>
                                            </div>
                                            <div onClick={(e) => {
                                                handleEditShBtn(downline, i, e)
                                            }}>
                                                <i className={"fa fa-plus"}></i>
                                            </div>
                                        </Accordion.Header>
                                    </Accordion.Item>
                                }
                            </>
                        );
                    }} />
                </Accordion>}
            <Modal
                fade={true}
                contentClassName="modal-brand modal-bottom modal-edit-sh"
                id="custom-modal"
                isOpen={isEditForm}
                centered
                toggle={() => {
                    setIsEditForm(false);
                }}
            >
                <ModalBody>
                    <div className="edit-sh-data">
                        <div className="font20 text-white text-center" style={{ marginBottom: "25px" }}>{!stringIsNullOrEmpty(member.isShareHolder) ? t("EDIT_SHAREHOLDER_DETAIL") : t("ADD_SHAREHOLDER_DETAIL")}</div>
                        <div className="">
                            <div className="shareholder-data shareholder-date">
                                <div className="data-left">{t("SHAREHOLDER_DATE")}</div>
                                <div className="data-right">{moment(member.shareHolderDate).format("YYYY-MM-DD")}</div>
                            </div>
                            <div className="shareholder-data shareholder-percent">
                                <div className="data-left">
                                    <div>{t("SHAREHOLDER_PERCENT")}</div>
                                    <div className="sh-min-max">{t("MIN") + ":" + member.minShare + "%," + t("MAX") + ":" + member.maxShare + "%"}</div>
                                </div>
                                <div className="data-right sh-select-box">
                                    <select className="sh-select" onChange={(e) => { setShare(e.target.value); }}>
                                        <option value={share} disabled selected hidden>{numberWithCurrencyFormat(share, 2, true) + "%"}</option>
                                        {activeOptionsList &&
                                            activeOptionsList.length > 0 &&
                                            activeOptionsList.map((optionValue, index) => {
                                                return (
                                                    <option key={index} value={optionValue}>{optionValue + "%"}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {!currentMemberHideSummary && <div className="shareholder-data shareholder-hide-summary" onClick={() => setHideSummary(!hideSummary)}>
                                <div className="hide-summary-icon">
                                    {hideSummary ? (
                                        <img src={require("../../assets/img/v3/hide-summary.png")} alt="hide" />
                                    ) : (
                                        <img src={require("../../assets/img/v3/eye-blue.png")} alt="show" />
                                    )}
                                </div>
                                <div>
                                    {t("HIDE_SUMMARY")}
                                </div>
                            </div>}
                            <button className="btn custom-btn w-100 mt-4" onClick={() => handleSubmitForm()}>{t("SUBMIT")}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {showConfirmation && (
          <SweetAlert
            custom={true}
            customIcon={require("../../assets/img/sweetalert_warning.png")}
            showCancel
            confirmBtnText={t("CONFIRM")}
            cancelBtnText={t("CANCEL")}
            cancelBtnCssClass="custom-cancel-btn"
            confirmBtnCssClass="custom-btn"
            confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
            title={t(
              "CONFIRM_APPOINT_AS_SHAREHOLDER", { username: member["username"] }
            )}
            onConfirm={() => {
              setShowConfirmation(false);
              submitForm();
            }}
            onCancel={() => {
              setShowConfirmation(false);
            }}
          ></SweetAlert>
            )}
        </>
    );

}

export default Downline;