import React from "react";
/// <summary>
/// Author: -
/// </summary>
const UnderMaintenance = props => {

    return (
        <div>
            <img style={{ display: "block", margin: "100px auto 0 auto" }} src={require("../../assets/img/um.png")} />
                <div style = {{ color: "#002E6C", fontSize:"28px", fontWeight:"Bold",textAlign:"center", marginTop:"40px" , letterSpacing:"-1px"}}>UNDER<br/>MAINTENANCE</div>
        </div>
    )
}

export default UnderMaintenance;