import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import {
  AlertTypes,
  ApiUrl,
  ApiKey
} from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine';
import { createMultiPartFormBody } from '../../util/Util';
import {
  Modal,
  ModalBody
} from "reactstrap";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  showMessage,
} from "../../redux/AppAction.js";
import SweetAlert from "react-bootstrap-sweetalert";

const CustomSpinWheel = ({ freeSpinCount, setShowFreeSpin, deductMemberFreeSpin }) => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();

  //const [spinWheelContent, setSpinWheelContent] = useState({
  //  1: "888K",
  //  2: "Vespa",
  //  3: "500",
  //  4: "8.8K",
  //  5: "100",
  //  6: "8K",
  //  7: "Honda C-RV",
  //  8: "8.88M",
  //  9: "iPhone 15",
  //  10: "50",
  //  11: "1K",
  //  12: "200",
  //});

  const [spinWheelContent, setSpinWheelContent] = useState({
    1: "/img/spinwheel/268.8K.png",
    2: "/img/spinwheel/Vespa.png",
    3: "/img/spinwheel/150.png",
    4: "/img/spinwheel/2.28K.png",
     5: "/img/spinwheel/30.png",
    6: "/img/spinwheel/26.8K.png",
    7: "/img/spinwheel/CRV.png",
    8: "/img/spinwheel/2.6888M.png",
    9: "/img/spinwheel/Iphone.png",
    10: "/img/spinwheel/15.png",
    11: "/img/spinwheel/300.png",
    12: "/img/spinwheel/60.png",
  });

  const [spinWheelContent2, setSpinWheelContent2] = useState({
    268800: 1,
    9500000: 2,
    150: 3,
    2280: 4,
    30: 5,
    26800: 6,
    10000000: 7,
    2688800: 8,
    9000000: 9,
    15: 10,
    300: 11,
    60: 12,
  });

  const [spinningBool, setSpinningBool] = useState(false);
  const [showWinningText, setShowWinningText] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [currentRotation, setCurrentRotation] = useState(0);
  const _SECONDS_IN_MS = 1000;
  useEffect(() => {
  }, []);

  const handleConfirmOnClick = async () => {
    setShowWinningText(false);
    if (freeSpinCount === 0) {
      setShowFreeSpin(false);
      setCurrentRotation(0);
    }
  }

  const handleSpinOnClick = async () => {
    const box = document.getElementById("box");
    const element = document.getElementById("mainbox");

    if (spinningBool)
      return false;
    setSpinningBool(true);
    //box.style.setProperty("transition", "initial");
    //box.style.transform = "rotate(0deg)";

    let creditResponse = await ApiEngine.get(ApiUrl._API_GET_CHECK_FREE_SPIN_COUNT);
    if (creditResponse[ApiKey._API_SUCCESS_KEY]) {
      let data = creditResponse[ApiKey._API_DATA_KEY];
      if (data != null && data > 0) {
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_MEMBER_SPIN_WHEEL
        );


        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          deductMemberFreeSpin();
          var index = -1;
          setSelectedItem(responseJson[ApiKey._API_DATA_KEY]);
          Object.keys(spinWheelContent2).forEach((key, i) => {
            if (key == responseJson[ApiKey._API_DATA_KEY]) {
              index = spinWheelContent2[key] - 1; // Update the index when the key matches
            }
          });

          let value1 = shuffle([1890, 2250, 2610]);
          let value2 = shuffle([1850, 2230, 2580]);
          let value3 = shuffle([1820, 2180, 2545]);
          let value4 = shuffle([1790, 2160, 2510]);
          let value5 = shuffle([1770, 2120, 2490]);
          let value6 = shuffle([1740, 2110, 2455]);
          let value7 = shuffle([1710, 2080, 2435]);
          let value8 = shuffle([1670, 2040, 2410]);
          let value9 = shuffle([1280, 1285, 2380]);
          let value10 = shuffle([1250, 1255, 2350]);
          let value11 = shuffle([1220, 1225, 2320]);
          let value12 = shuffle([1190, 1195, 2290]);

          var result = "";
          if (index === 0) {
            result = value1[0];
          } else if (index === 1) {
            result = value2[0];
          } else if (index === 2) {
            result = value3[0];
          } else if (index === 3) {
            result = value4[0];
          } else if (index === 4) {
            result = value5[0];
          } else if (index === 5) {
            result = value6[0];
          } else if (index === 6) {
            result = value7[0];
          } else if (index === 7) {
            result = value8[0];
          } else if (index === 8) {
            result = value9[0];
          } else if (index === 9) {
            result = value10[0];
          } else if (index === 10) {
            result = value11[0];
          } else if (index === 11) {
            result = value12[0];
          }
          if (countdownPeriod === 0) {
            // spin
            box.style.setProperty("transition", "all ease 5s");
            const newRotation = currentRotation - (currentRotation % 360) + result;
            box.style.transform = "rotate(" + newRotation + "deg)";
            element.classList.remove("animate");
            setCurrentRotation(newRotation);
            setTimeout(function () {
              element.classList.add("animate");
            }, 5000);


            // alert
            setTimeout(function () {
              setSpinningBool(false);
              setShowWinningText(true)
            }, 6000);
            setCountdownPeriod(6);
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._ERROR,
              content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            })
          );
          setSpinningBool(false);
        }
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._ERROR,
            content: t("DEPOSIT_FOR_FREE_SPIN"),
          })
        );
        setSpinningBool(false);
      }
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._ERROR,
          content: t(creditResponse[ApiKey._API_MESSAGE_KEY]),
        })
      );
      setSpinningBool(false);
    }
  }

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  function shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  return (
    <>
      <div className="spin-container-bg-wrapper">
      </div>
      <div className="spin-container">
        <div className="spin-box not-spinning">
          <div className="spin-body">
            <div className="i8-spin-section" id="mainbox">
              <div className="i8-spin-arrow">
                <img loading="lazy" src={"/img/spinwheel/arrow.png"} alt="i8 lucky wheel arrow" className="img-responsive" />
              </div>
              <div className="i8-spin-ring">
                <img loading="lazy" src={"/img/spinwheel/ring.png"} alt="i8 lucky wheel ring" className="w-100" />
              </div>
              <div className="i8-spin-arrow">
                <img loading="lazy" src={"/img/spinwheel/arrow.png"} alt="i8 lucky wheel arrow" className="img-responsive" />
              </div>
              <div className="i8-spin-body" id="box">
                <img loading="lazy" src={"/img/spinwheel/body1.png"} alt="i8 lucky bottom" className="img-responsive" />
                {Object.keys(spinWheelContent).map((key, index) => {
                  return (
                    <div key={index} date className="rewards" style={{ transform: `rotate(${(index + 1) * (360 / Object.keys(spinWheelContent).length )}deg)` }}>
                      <img src={spinWheelContent[key]} className="img-responsive" alt="" />
                    </div>
                  )
                })}
              </div>
              <div className="i8-bottom">
                <img loading="lazy" src={"/img/spinwheel/bottom.png"} alt="i8 bottom" className="img-responsive" />
              </div>

              <div className="i8-bottom-left-coin">
                <img loading="lazy" src={"/img/spinwheel/bottom-left-coin.png"} alt="i8 bottom left coin" className="img-responsive" />
              </div>

              <div className="i8-bottom-right-coin">
                <img loading="lazy" src={"/img/spinwheel/bottom-right-coin.png"} alt="i8 bottom right coin" className="img-responsive" />
              </div>

              <div className="i8-bottom-button" onClick={() => !spinningBool ? handleSpinOnClick() : false}>
                <img loading="lazy" src={"/img/spinwheel/click-to-spin.png"} alt="i8 bottom" className="img-responsive" />
                <span className="i8-bottom-remainder">{freeSpinCount}</span>
              </div>

              <div className="spin-container-close" onClick={() => {
                setShowFreeSpin(false);
                setCurrentRotation(0);
              }}><img
                  draggable="false"
                  src={"/img/Close_Icon.svg"} style={{ width: "20px" }}
                  width="20"
                  height="20"
                  alt="close button" /></div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showWinningText}
        contentClassName="spinwheel-winning-popup"
        id="success-modal"
        fade={true}
        toggle={() => {
          handleConfirmOnClick();
        }}
        centered
      >
        <ModalBody>
          <div className="spinwheel-winning-popup-body">
            <div className="spinwheel-winning-text">
              <div>{t("SPIN_WHEEL_SUCCESS", { value: selectedItem }) + (typeof selectedItem === 'number' ? " " + t("POINTS") + "." : ".")}</div>
            </div>
            <button className="btn custom-btn w-100 mt-4" style={{ color: "#ffffff", background: "rgba(51, 105, 255, 1)" }} onClick={() => handleConfirmOnClick()}>{t("CONFIRM")}</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CustomSpinWheel;
