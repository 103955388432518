import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiEngine from '../../util/ApiEngine';
import { AlertTypes, ApiKey, ApiUrl, SessionKey, WebUrl } from '../../util/Constant';
import Header from "../../components/custom/Header";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  Modal,
  ModalBody
} from "reactstrap";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';

const Promotion = props => {
  let { t } = useTranslation();
  let _dispatch = useDispatch();
  let _history = useHistory();
  let _location = useLocation();
  const { isLoggedIn } = useSelector(state => state.authState);

  const { register, errors } = useForm();
  const [promotionData, setPromotionData] = useState([]);
  const [displayPromoData, setDisplayPromoData] = useState([]);
  const [vipFilterList, setVipFilterList] = useState([]);
  const [activeVipFilter, setActiveVipFilter] = useState("All");
  const [inputReferralCodeVisible, setInputReferralCodeVisible] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [loadingPromoData, setLoadingPromoData] = useState(true);

  useEffect(() => {
    init();
    setLoadingPromoData(false);
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_PROMOTION + "?languageCode=" + localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setPromotionData(data);
      setDisplayPromoData(data);
      let tempVipFilterList = [];
      data.map((promo, i) => {
        if (!tempVipFilterList.includes(promo.vipModel.name)) {
          if (promo.vipModel.name === "All") {
            tempVipFilterList.unshift(promo.vipModel.name);
          }
          else {
            tempVipFilterList.push(promo.vipModel.name);
          }
        }
      });
      //if (tempVipFilterList.length > 1) {
      //  tempVipFilterList.unshift("All");
      //}
      setVipFilterList(tempVipFilterList);
    }
  }

  const filterPromo = async (vipName) => {
    setLoadingPromoData(true);
    if (activeVipFilter !== vipName) {
      setActiveVipFilter(vipName);
      let tempPromoData = promotionData;
      if (vipName === "All") {
        setDisplayPromoData(tempPromoData);
      }
      else {
        let tempDisplayPromoData = tempPromoData.filter(x => x.vipModel.name === vipName);
        setDisplayPromoData(tempDisplayPromoData);
      }
      setTimeout(() => setLoadingPromoData(false), 100);
    }
  }

  async function getUpline(code) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code);
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage({
        type: AlertTypes._ERROR,
        content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        onConfirm: () => console.log(" ")
      }));
    }
    else {
      _history.push(WebUrl._URL_MAIN + "?code=" + code);
    }
    _dispatch(setIdle());
  }

  return (
    <>
      <Header
        history={_history}
        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_MAIN}
        title={t("LIVE_PROMOTION")}
        hasBackButton={true}
        state={{ from: window.location.pathname }}
      />
      <div id="promotion">
        {vipFilterList.length > 1 && <div className="vip-filter-container">
          {vipFilterList.map((vipName, index) => {
            return (
              <div key={index} className={"vip-level-name" + (activeVipFilter === vipName ? " active" : "")} onClick={() => filterPromo(vipName) }>
                {vipName}
              </div>
            )
          })}
        </div>}
        {displayPromoData.length > 0 ? <div className="promotion-wrapper">
        {!loadingPromoData ? displayPromoData.map((promotion, index) => {
            return (
              <div key={index} className={"promotion-row row-" + (index + 1)}>
                <div className="promo-img" onClick={() => _history.push({ pathname: WebUrl._URL_PROMOTION_DETAIL, state: {promotion: promotion, url: window.location.pathname} })}>
                  <img src={promotion.mobileImage} loading="lazy"/>
                </div>
                <div className="promo-content">
                  <div className="promo-title">
                    {promotion.title}
                  </div>
                  <div className="promo-text">
                    {promotion.description}
                  </div>
                  <div className="promo-btn">
                    <button className="btn-1 btn-block btn-primary" onClick={() => _history.push({ pathname: WebUrl._URL_PROMOTION_DETAIL, state: { promotion: promotion, url: window.location.pathname } })}>{t("MORE_INFO")}</button>
                    {!isLoggedIn && <button className="btn-2 custom-cancel-btn" onClick={() => setInputReferralCodeVisible(true)}>{t("JOIN_NOW")}</button>}
                  </div>
                </div>
              </div>
          )
        }) : <div className="loading-promo-spinner-wrapper"><Spinner className="loading-promo-spinner" animation="border" role="status"/></div>}
        </div> : <></>}
        <Modal
          id="custom-modal"
          fade={true}
          isOpen={inputReferralCodeVisible}
          contentClassName="modal-brand modal-bottom"
          toggle={() => {
            setInputReferralCodeVisible(false);
          }}
          centered
        >
          <ModalBody>
            <div className="flex-box-centered mb-1">
              <h2 className="custom-modal-title mb-0 mr-3">{t("JOIN_US_NOW")}</h2>
              <div>
                <img src={require("../../assets/img/v3/waving.png")} alt="waving" className="img-responsive" height="100%" width="100%" />
              </div>
            </div>
            <p className="pop-up-under-title-text mb-4 text-center">{t("REGISTER_BY_REFERRAL_CODE")}</p>
            <div className="input-container">
              <label>{t("REFERRAL_CODE")}</label>
              <div className="input-with-icon-container">
                <input
                  type='text'
                  name='referralCode'
                  className="custom-input"
                  placeholder={t("EG") + " AbCd3fGH +"}
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                  ref={register({
                    required: t("FIELD_REQUIRED"),
                  })}
                  errors={errors}
                />
                <div className="input-icon">
                  <img src={require("../../assets/img/v3/referral-code-icon.png")} alt="referral-code" className="img-responsive" />
                </div>
              </div>
            </div>
            <a className="btn custom-btn" style={{ marginTop: "24px" }} onClick={() => getUpline(referralCode)}>
              {t("REGISTER")}
            </a>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default Promotion;