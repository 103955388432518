import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-simple-keyboard/build/css/index.css";
import { ApiUrl, ApiKey, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setPageSize,
} from "../../redux/AppAction";
import { numberWithCurrencyFormat } from "../../util/Util";
import Header from "../../components/custom/Header";

const ProductCommRate = (props) => {
  var _history = useHistory();
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();

  const [products, setProducts] = useState([]);
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    if (_location["state"]) {
      init();
      _dispatch(setPageSize(_location["scrollIndex"]));
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  async function init() {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?memberId=${_location["state"]["id"]}&ignoreAccountProductStatus=true`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProducts(responseJson[ApiKey._API_DATA_KEY].products);
      setNickname(responseJson[ApiKey._API_DATA_KEY].nickname);
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_DOWNLINE}
        title={t("PRODUCT_COMM_RATE") + (_location["state"] && _location["state"]["username"] ? " - " + _location["state"]["username"] : "")}
      />
      <div className="pcr-list-wrapper">
        {products.map((product, index) => {
          if (product.bonus > 0 || product.commission > 0 || product.clubFee > 0) {
            return (
              <>
                <div className="pcr-list-card">
                  <div className="pcr-card-header">{product.productName}</div>
                  <div className="pcr-card-data">
                    {product.bonus > 0 && <div className="card-data-row product-bonus-rate">
                      <div className="left">{t("BONUS_RATE") + " (%) "}</div>
                      <div className="right">{numberWithCurrencyFormat(product.bonus, 2, true)}</div>
                    </div>}
                    {product.commission > 0 && <div className="card-data-row product-comm-rate">
                      <div className="left">{t("COMM_RATE") + " (%) "}</div>
                      <div className="right">{numberWithCurrencyFormat(product.commission, 2, true)}</div>
                    </div>}
                    {product.clubFee > 0 && <div className="card-data-row product-clubFee-rate">
                      <div className="left">{t("CLUBFEE_RATE") + " (%) "}</div>
                      <div className="right">{numberWithCurrencyFormat(product.clubFee, 2, true)}</div>
                    </div>}
                  </div>
                </div>
              </>
            )
          }
        })}
      </div>
    </>
  );
};
export default ProductCommRate;