import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/custom/Header";
import moment from "moment";
import { ApiUrl, WebUrl } from "../../util/Constant";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";

const WalletHistory = () => {
    const _history = useHistory();
    const { state } = useLocation();
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const [walletUrl, setWalletUrl] = useState("");
    const [startDate, setStartDate] = useState(
        moment().startOf("week").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("week").format("YYYY-MM-DD")
    );
    const [selectedDate, setSelectedDate] = useState("");

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getWalletStatement(startDate, endDate);
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getWalletStatement(start, end, selected = "") {
        // let apiUrl =
        //   ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
        //   "?StartDate=" +
        //   moment(start).format("YYYY-MM-DD") +
        //   "&EndDate=" +
        //   moment(end).format("YYYY-MM-DD") +
        //   "&v=" +
        //   Date.now();

        var apiUrl =
            ApiUrl._API_GET_MEMBER_WALLET_STATEMENT +
            "?StartDate=" +
            // moment(start).format("YYYY-MM-DD HH:mm:ss") +
            moment(start).startOf('day').format("YYYY-MM-DD") +
            "&EndDate=" +
            // moment(end).format("YYYY-MM-DD HH:mm:ss") +
            moment(end).endOf('day').format("YYYY-MM-DD") +
            "&v=" + moment(start).startOf('day').format("YYYY-MM-DD");

        if (!stringIsNullOrEmpty(selected)) {
            // apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
            apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
        }
        setWalletUrl(apiUrl);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getWalletStatement(startDate, endDate, selectedDate);
        }
    }, [selectedDate]);

    return (
        <>
            <Header
                history={_history}
                url={!window.ReactNativeWebView ? WebUrl._URL_REPORTS : null}
                hasBackButton={
                    state?.from == WebUrl._URL_REPORTS ? true : false
                }
                title={t("WALLET_STATEMENT")}
            />
            <div style={{ marginBottom: "10px" }}>
                <DateRangeFilter2
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onSave={(start, end) => {
                        getWalletStatement(start, end);
                        setSelectedDate("");
                    }}
                    options={true}
                />
                <div className="hr-right mb-3 mt-3"></div>
                <div className="card card-package">
                    <div className="card-body p-0">
                        <div className="card-table table-responsive">
                            <table className="table table-kpi-summary">
                                <thead>
                                    <tr>
                                        <th>{t("DATE")}</th>
                                        <th>{t("REMARK")}</th>
                                        <th style={{ textAlign: "right" }}>
                                            {t("PRE_MEMBER_CREDIT")}
                                        </th>
                                        <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                                        <th style={{ textAlign: "right" }}>
                                            {t("POST_MEMBER_CREDIT")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <DynamicLoadTable
                                        pageSize={20}
                                        apiQuery={walletUrl}
                                        searchEnabled={true}
                                        isTable={true}
                                        render={(item, i) => {
                                            const splitRemark = item.remark.split(" ");
                                            let isGrouped =
                                                moment(endDate, "YYYY-MM-DD").diff(
                                                    moment(startDate, "YYYY-MM-DD"),
                                                    "days"
                                                ) >= 1 && stringIsNullOrEmpty(selectedDate);
                                            return (
                                                <tr key={i}>
                                                    <td
                                                        className={isGrouped ? "hoverable" : ""}
                                                        onClick={() => {
                                                            if (isGrouped) {
                                                                // setSelectedDate(item["parsedTime"]);
                                                                setSelectedDate(moment(item["parsedTime"], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                                                            }
                                                        }}
                                                >
                                                  {moment(item["parsedTime"], "MM/DD/YYYY").format("YYYY-MM-DD")}
                                                    </td>
                                                    <td>
                                                        {item["remark"]}
                                                        {item["serviceCharge"] != "0"
                                                            ? " - Fees " +
                                                            (!isGrouped
                                                                ? "@" + item["serviceChargePercentage"] + "%"
                                                                : "") +
                                                            "= " +
                                                            numberWithCurrencyFormat(
                                                                parseFloat(item["serviceCharge"]),
                                                                2
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["preMemberCredit"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "right",
                                                            color:
                                                                item["postMemberCredit"] >
                                                                    item["preMemberCredit"]
                                                                    ? "green"
                                                                    : "red",
                                                        }}
                                                    >
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["amount"]),
                                                            2
                                                        )}
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        {numberWithCurrencyFormat(
                                                            parseFloat(item["postMemberCredit"]),
                                                            2
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletHistory;
