import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { useForm, Controller } from "react-hook-form";
import { Nav, NavItem, NavLink } from "reactstrap";

const Report = () => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    var _dispatch = useDispatch();

    const _MENU_OPTION = {
        "Summary": 1,
        "Shareholders": 2,
        "Claim": 3
    }
    const [activeMenu, setActiveMenu] = useState(_MENU_OPTION.Summary);

    const [renderComponent, setRenderComponent] = useState();

    const [monthOptions, setMonthOptions] = useState([]);
    const [kpiData, setKpiData] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState('');

    const { control, watch, setValue } = useForm();
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [selectedDate, setSelectedDate] = useState(moment());
    const [queryProduct, setQueryProduct] = useState('');
    const [queryStatus, setQueryStatus] = useState('');
    const [searchTrigger, setSearchTrigger] = useState(Date.now());


    /*    /// <summary>
        /// Author: -
        /// </summary>
        useEffect(() => {
            switch (activeMenu) {
                case _MENU_OPTION.Deposit:
                    setRenderComponent(<Deposit />);
                    break;
                case _MENU_OPTION.Withdrawal:
                    setRenderComponent(<Withdrawal />);
                    break;
                case _MENU_OPTION.Transfer:
                    setRenderComponent(<Transfer />);
                    break;
                case _MENU_OPTION.InternalTransfer:
                    setRenderComponent(<InternalTransfer />);
                    break;
                default:
                    setRenderComponent();
                    break;
            }
    
            init();
    
            /// used to persist the current tab when refresh
            if (_location.state && !stringIsNullOrEmpty(_location.state.menu) && activeMenu != _location.state.menu) {
                _history.replace({
                    pathname: _location.pathname,
                    state: {
                        menu: activeMenu
                    }
                })
            }
    
        }, [activeMenu])*/

    /*/// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init();
    }, []);


    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedMonth)) {
            setSelectedIndex(kpiData.bonusDetail.findIndex(bd => bd.month == selectedMonth));
        }
    }, [selectedMonth]);*/

    /// <summary>
    /// Author: -
    /// </summary>
    /*async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            let data = responseJson[ApiKey._API_DATA_KEY];

            setMonthOptions(data.bonusDetail.map((bd) => { return { label: bd.month, value: bd.month } }));
            setKpiData(data);
            setSelectedMonth(data["bonusDetail"][0]["month"]);
        }
        else {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    if (isObjectEmpty(kpiData)) {
        return (null);
    }
    else {
    }*/

    return (
        <>
            <span>Summary</span>
        </>
    );
};

export default Report;