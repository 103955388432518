import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import livechatIcon from "../../assets/img/Live_Chat_Icon/live-chat.png";
import {
    Alert,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane,
} from "reactstrap";
import { ApiKey, ApiUrl, WebUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";


const LiveChatModal = (props) => {
    const {
        title,
        history,
        url,
        slot,
        state,
        hasBackButton = true,
        hasRightMenu = false,
        rightMenuList = [],
        fromDashboard = false,
        setShowModal = false,
        ...rest
    } = props;
    const { t, i18n } = useTranslation();
    const [isModalShow, setIsModalShow] = useState(false);
    const activeTheme = useSelector(
        (state) => state.authState.userData.selectedTheme
    );

    const [communicationChannel, setCommunicationChannel] = useState([]);

    const [toggleModal, setToggleModal] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState();
    const liveChat = useSelector((state) => state.appState.liveChat);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true");
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setCommunicationChannel(data)
        }
    }

    useEffect(() => {
        if (selectedChannel) {
            if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
                setToggleModal(true);
            }
        }
    }, [selectedChannel]);


    return (
        <div className={ liveChat ? "live-chat-upper" : "live-chat" } >
            <div className="live-chat-box">
                <img src={livechatIcon} className="live-chat-img-button" onClick={() => { setIsModalShow(true) }}>
                </img>
            </div>
            <Modal
                id="custom-modal"
                contentClassName="modal-brand modal-bottom"
                fade={true}
                isOpen={isModalShow}
                toggle={() => {
                    setIsModalShow(false);
                }}
                centered
            >
                <ModalBody>
                    <form >
                        <div className="flex-box-centered mb-1">
                            <h2 className="custom-modal-title mb-0 mr-3" style={{ textAlign: 'center' }}>{t("WELCOME_TO_LIVECHAT")}</h2>
                        </div>
                        <div className="live-chat-container">
                            {communicationChannel && communicationChannel.length > 0 ? map(communicationChannel, (channel, index) => {
                                return (
                                    <div className="live-chat-icon" onClick={() => {
                                        if (channel.channelName === "Livechat") {
                                            // window.open(channel.actionUrl, "_blank")
                                            if (window.ReactNativeWebView) {
                                                window.ReactNativeWebView.postMessage(
                                                    JSON.stringify({
                                                        action: "chat",
                                                        url: `${window.location.origin}${channel.cta}`
                                                    })
                                                );
                                            }
                                            else {
                                                window.open(channel.cta, "_blank", 'live-chat');
                                            }
                                        }
                                        else if (!stringIsNullOrEmpty(channel.cta)) {
                                            if (window.ReactNativeWebView) {
                                                window.ReactNativeWebView.postMessage(
                                                    JSON.stringify({
                                                        action: "chat",
                                                        url: `${channel.cta}`
                                                    })
                                                );
                                            }
                                            else {
                                                window.location.href = channel.cta
                                            }
                                        }
                                        else if (channel.channelName.match("WeChat")) {
                                            setSelectedChannel(channel);
                                            setIsModalShow(false)
                                            setToggleModal(true);
                                        } else {
                                            setToggleModal(false)
                                        }
                                    }}>
                                        <div className="live-chat-gird">
                                            <img src={channel.channelImage} style={{ width: "32px", height: "32px" }}></img>
                                        </div>
                                        <a style={{ fontSize: "10px" }}>{channel.channelName}
                                        </a>
                                    </div>
                                )
                            }) : <Spinner className="dynamic-table-spinner" animation="border" role="status" size="sm" style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}>
                                <span className="sr-only">{t("LOADING")}...</span>
                            </Spinner>
                            }
                        </div>
                        <a className="btn custom-btn" style={{ marginTop: "95px" }} onClick={() => setIsModalShow(false)}>
                            {t("CLOSE")}
                        </a>
                    </form>
                </ModalBody>
            </Modal>

            <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
                <ModalHeader toggle={() => { setToggleModal(false) }} style={{ color: "black" }}>{selectedChannel?.channelDescription}</ModalHeader>
                <ModalBody>
                    <img src={selectedChannel?.attachment} className="w-100" />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default LiveChatModal;