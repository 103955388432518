import React, { useState, useEffect, useRef } from 'react';
import { numberWithCurrencyFormat2, stringIsNullOrEmpty } from '../../util/Util'
import { useTranslation } from "react-i18next";
import ApiEngine from '../../util/ApiEngine';
import { ApiUrl, ApiKey } from '../../util/Constant';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction";

const MegatotoQuickBet = () => {
  const timerRef = useRef(null);
  let _userData = useSelector((state) => state.authState.userData);

  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedOptionDetails, setSelectedOptionDetails] = useState({});
  const [rows, setRows] = useState([
    { id: 1, d3: '', zodiac: '', seasons: '', elements: '' },
  ]);
  const [options, setOptions] = useState([]);
  const [zodiac, setZodiac] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [elements, setElements] = useState([]);
  const [timeLeft, setTimeLeft] = useState([]);
  const [betInfo, setBetInfo] = useState([]);
  const [isBetButtonEnabled, setIsBetButtonEnabled] = useState(false);
  const [betInfoModal, setBetInfoModal] = useState(false)

  useEffect(() => {
    getQuickBetInfos()
  }, [])

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedOptionDetails)) {
      timerRef.current = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(selectedOptionDetails.closing_time));
      }, 1000);

      return () => clearTimeout(timerRef.current);
    }
  }, [timeLeft]);

  useEffect(() => {
    checkIfAllRowsAreFilled();
  }, [rows]);

  const getQuickBetInfos = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEGATOTO_QUICKBET_INFO);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempArr = [];

      Object.keys(data.game_info).map((key, index) => {
        let newObj = { ...data.game_info[key], name: formatText(key), id: index + 1 }

        tempArr.push(newObj);
      })

      setOptions(tempArr);
      setZodiac(data.bet_type["12_zodiac"]);
      setSeasons(data.bet_type["4_season"]);
      setElements(data.bet_type["5_element"]);
      setBetInfo(data.bet_info);
    }
  }

  const formatText = (text) => {
    const tempArr = text.split(" ");

    for (let i = 0; i < tempArr.length; i++) {
      tempArr[i] = tempArr[i][0].toUpperCase() + tempArr[i].substr(1).toLowerCase();
    }

    return tempArr.join(" ");
  }

  const handleOptionClick = (option) => {
    if (selectedOption !== option.id) {
      setSelectedOption(option.id);
      setSelectedOptionDetails(option);
      setTimeLeft(calculateTimeLeft(option.closing_time));
      resetRow();
    }
    else {
      setSelectedOption();
      setSelectedOptionDetails()
      setTimeLeft();
      clearTimeout(timerRef.current);
      resetRow();
    }
  }

  const calculateTimeLeft = (closingDateTime) => {
    const timeDifference = moment(closingDateTime) - moment.now();

    return moment(timeDifference).format("HH:mm:ss");
  };

  const resetRow = () => {
    setRows([{ id: 1, d3: '', zodiac: '', seasons: '', elements: '' }]);
  };

  const handleInputChange = (id, field, value) => {
    if (field === 'd3' && (value.length > 3 || isNaN(value))) return;
    setRows(rows.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    ));
  };

  const checkIfAllRowsAreFilled = () => {
    const allRowsFilled = rows.every(row =>
      row.d3 && row.zodiac && row.seasons && row.elements
    );
    setIsBetButtonEnabled(allRowsFilled);
  };

  const submitBet = async () => {
    _dispatch(setBusy());

    let payload = {
      gameId: selectedOptionDetails.game_id,
      data: rows
    }

    let responseJson = await ApiEngine.post(ApiUrl._API_PLACE_MEGATOTO_QUICKBET, payload)

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      setBetInfoModal(false);
      setRows([
        { id: 1, d3: '', zodiac: '', seasons: '', elements: '' },
      ]);
      setIsBetButtonEnabled(false);
    }

    _dispatch(setIdle())
  }

  return (
    <>
      <div className="mega3d-options-container" style={{ justifyContent: options.length >= 5 ? "space-between" : "start" }}>
        {options.map((option, index) => {
          return (
            <>
              <div className={`mega3d-option-selection ${selectedOption === option.id ? " active" : ""}`} onClick={() => { handleOptionClick(option) }}>
                <div className="mega3d-option-img">
                  <img
                    src={option.img}
                    alt={option.name}
                    width="50"
                    height="35"
                  />
                </div>
              </div>
            </>
          )
        })}
      </div>
      {selectedOption > 0 && !stringIsNullOrEmpty(selectedOptionDetails) && !stringIsNullOrEmpty(betInfo) &&
        <>
          <div className="mega3d-option-expansion">
            <div className="mega3d-expansion-header">
              <div className="mega3d-img-name">
                <div className="mega3d-option-img">
                  <img
                    src={!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.img : ""}
                    alt={!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.name : ""}
                    width="50"
                    height="35"
                  />
                </div>
                <div className="mega3d-option-name">
                  {!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.name : ""}
                </div>
              </div>

              <div className="mega3d-countdown">
                {t("TIME_LEFT")} {timeLeft}
              </div>
            </div>

            <div className="mega3d-expansion-body">
              {rows && rows.length > 0 && rows.map((row, index) => (
                <div className="mega3d-bet-form" key={row.id}>
                  <div className="mega3d-first-row">
                    <div className="mega3d-3d-column">
                      <div>{t("3D")}</div>
                      <input
                        type="text"
                        value={row.d3}
                        onChange={(e) => handleInputChange(row.id, 'd3', e.target.value)}
                        maxLength={3}
                        placeholder="3D"
                        pattern="\d*"
                      />
                    </div>
                    <div className="mega3d-12zodiac-column">
                      <div>{t("ZODIAC")}</div>
                      <div>
                        <select
                          value={row.zodiac}
                          onChange={(e) => handleInputChange(row.id, 'zodiac', e.target.value)}
                        >
                          <option value="">{t("ZODIAC")}</option>
                          {zodiac && zodiac.length > 0 && zodiac.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-arrow-wrapper">
                          <img
                            src={`/img/v3/mega3d_quickplay/DropDown_Arrow.svg`}
                            alt="reset"
                            width="50"
                            height="35"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mega3d-second-row">
                    <div className="mega3d-4seasons-column">
                      <div>{t("SEASONS")}</div>
                      <div>
                        <select
                          value={row.seasons}
                          onChange={(e) => handleInputChange(row.id, 'seasons', e.target.value)}
                        >
                          <option value="">{t("SEASONS")}</option>
                          {seasons && seasons.length > 0 && seasons.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-arrow-wrapper">
                          <img
                            src={`/img/v3/mega3d_quickplay/DropDown_Arrow.svg`}
                            alt="reset"
                            width="50"
                            height="35"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mega3d-5elements-column">
                      <div>{t("ELEMENTS")}</div>
                      <div>
                        <select
                          value={row.elements}
                          onChange={(e) => handleInputChange(row.id, 'elements', e.target.value)}
                        >
                          <option value="">{t("ELEMENTS")}</option>
                          {elements && elements.length > 0 && elements.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-arrow-wrapper">
                          <img
                            src={`/img/v3/mega3d_quickplay/DropDown_Arrow.svg`}
                            alt="reset"
                            width="50"
                            height="35"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mega3d-third-row">
                    <div className="mega3d-wallet-balance">{t("WALLET_BALANCE") + _userData.walletBalance}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mega3d-expansion-footer">
              <div className="mega3d-reset-btn-column">
                <div className="mega3d-reset-img">
                  <img
                    src={`/img/v3/mega3d_quickplay/Clear_icon.svg`}
                    alt="reset"
                    width="50"
                    height="35"
                    onClick={() => resetRow()}
                  />
                </div>
              </div>

              <div className="mega3d-footer-2nd-sec">
                <div className="mega3d-bet-amount">
                  {t("BET_AMOUNT") + numberWithCurrencyFormat2(betInfo.price, 2, true)}
                </div>
                <div className="mega3d-bet-button">
                <button disabled={!isBetButtonEnabled} onClick={() => { setBetInfoModal(true) }}>{t("BET_NOW_2")}</button>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      <Modal
        isOpen={betInfoModal}
        contentClassName="mega3d-betinfo-popup"
        id="mega3d-betinfo-modal"
        fade={true}
        toggle={() => {
          setBetInfoModal(false);
        }}
        centered
      >
        <ModalBody>
          <div className="mega3d-betinfo-content">
            <div className="mega3d-betinfo-header">
              {t("BET_CONFIRMATION")}
            </div>

            <div className="mega3d-beinfo-top-bar">
              <div className="mega3d-beinfo-name-img">
                <div className="mega3d-option-img">
                  <img
                    src={!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.img : ""}
                    alt={!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.name : ""}
                    width="50"
                    height="35"
                  />
                </div>
                <div className="mega3d-option-name">
                  {!stringIsNullOrEmpty(selectedOptionDetails) ? selectedOptionDetails.name : ""}
                </div>
              </div>
              <div className="mega3d-beinfo-type">
                {t("3D_BONUS")}
              </div>
            </div>

            <div className="mega3d-betinfo-list">
              <div className="mega3d-betinfo-header-wrapper">
                <div className="mega3d-betinfo-list-header">
                  <div>{t("NUMBER")}</div>
                </div>
              </div>

              {rows && rows.length > 0 && rows.map((row, index) => (
                <>
                  <div className="mega3d-betinfo-list-body">
                    <div className="mega3d-betinfo-number-column">
                      {row.d3 + " " + row.zodiac + " " + row.seasons + " " + row.elements}
                    </div>
                  </div>
                </>
              ))}

              <div className="mega3d-betinfo-wallet-bet">
                <div className="mega3d-betinfo-betrate-column">
                  {t("BET_RATE") + ": " + betInfo.bet_rate}
                </div>

                <div className="mega3d-betinfo-2nd-sec">
                  <div className="mega3d-betinfo-amount-total">
                    <input
                      type="text"
                      value={t("BET_TOTAL") + numberWithCurrencyFormat2(2, 2, true)}
                      disabled
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="mega3d-betinfo-wallet">
                <div className="mega3d-betinfo-wallet-balance">{t("WALLET_BALANCE") + numberWithCurrencyFormat2(_userData.walletBalance, 2, true)}</div>
              </div>
            </div>
          </div>

        </ModalBody>
        <ModalFooter className="mega3d-betinfo-footer">
          <div className="mega3d-betinfo-btn-container">
            <div className="mega3d-betinfo-cancel-btn">
              <button onClick={() => { setBetInfoModal(false) }}>{t("CANCEL")}</button>
            </div>
            <div className="mega3d-betinfo-accept-btn">
              <button onClick={() => { submitBet() }}>{t("ACCEPT")}</button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default MegatotoQuickBet;