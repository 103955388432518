import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody } from "reactstrap";

/// <summary>
/// Author :
/// </summary>
const Commission = (props) => {
  const referralGroupList = [
    {
      id: 1,
      name: "PXd8k9mi",
      members: 3,
    },
    {
      id: 2,
      name: "PXd8k9mi",
      members: 3,
    },
    {
      id: 3,
      name: "PXd8k9ui5",
      members: 11,
    },
    {
      id: 4,
      name: "OXd8k9mi",
      members: 106,
    },
    {
      id: 5,
      name: "PXd8k9ui5",
      members: 88,
    },

  ];
  return (
    <>
      <div className="page-custom-header">
        <div className="first">
            <div>
                <img src={require("../../assets/img/v3/back-icon.png")} alt="back" />
            </div>
            <div>
                Commission
            </div>
        </div>
        <div className="second">
            <img src={require("../../assets/img/v3/more-gold.png")} alt="more" />
        </div>
      </div>
      <div className="search-container">
        <input type='text'placeholder="Search" />
        <div className="search-btn">
          <img src={require("../../assets/img/v3/search-blue.png")} alt="search" />
        </div>
      </div>
      <div className="hr-right mt-3 mb-3"></div>
      <div className="referral-group-container mt-4">
        {referralGroupList &&
          referralGroupList.length &&
          map(referralGroupList, (item, index) => {
            return (
              <>
               <div className="box-list-with-shadow referral-group-item">
                    <div>
                        {item.name}
                        <div className="referral-group-count">
                            {item.members} members
                        </div>
                    </div>
                    <div className="second">
                        <img
                            src={require("../../assets/img/v3/arrow-right.png")}
                            alt="search"
                        />
                    </div>
               </div>
              </>
            );
          })}
      </div>

    </>
  );
};

export default Commission;
