import React from "react";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
    const { t } = useTranslation();

    return (
        <div className="maintenance-wrapper">
            <img src={require("../assets/img/icon/i8-logo.png")} alt="i8" className="logo"/>
            <img className="maintenance-img" src={require("../assets/img/icon/maintenance.svg")} />
            <p>{t("TOTAL_WINLOSE")}</p>
        </div>
    );
};

export default Maintenance;