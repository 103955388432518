import React, { useState, useEffect } from "react";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Header from "../../components/custom/Header";
import moment from "moment";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { numberWithCurrencyFormat } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";

const ClaimRecord = () => {
    let _history = useHistory();
    var _location = useLocation();
    const { t, i18n } = useTranslation();
    const [claimData, setClaimData] = useState([]);
    const [claimDataUrl, setClaimDataUrl] = useState("");
    const [startDate, setStartDate] = useState(
        moment().startOf("day").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("day").format("YYYY-MM-DD")
    );
    const [initPage, setInitPage] = useState(true);
    const [companyTimeZone, setCompanyTimeZone] = useState(0);
    const [loginHideSummary, setLoginHideSummary] = useState(false);

    useEffect(() => {
        if (_location["state"]) {
            setLoginHideSummary(_location.state.hideSummary);
        }
    }, []);

    async function getClaimData(start, end, selectedDateTime = "") {
        let apiUrl =
            ApiUrl._API_GET_CLAIM_HISTORY + "?startDate=" + start + "&endDate=" + end;
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setClaimData(data["data"]);
        }
        setClaimDataUrl(apiUrl);
        setInitPage(false);
    }

    return (
        <>
            <Header
                history={_history}
                //url={
                //  !window.ReactNativeWebView
                //    ? WebUrl._URL_SHAREHOLDER +
                //      "?GAzobE73Vse4nr8WpufA=" +
                //      loginHideSummary
                //    : null
                //}
                url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_SHAREHOLDER_CLAIM}
                title={t("CLAIM_HISTORY")}
            />
            <div style={{ marginBottom: "10px" }}>
                <DateRangeFilter2
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onSave={(start, end) => {
                        setStartDate(moment(start));
                        setEndDate(moment(end));
                        getClaimData(
                            moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                            moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
                        );
                    }}
                    options={true}
                />
                <div className="hr-right mt-3 mb-3"></div>
                <div>
                    {claimData.length > 0 ? (
                        <DynamicLoadTable
                            pageSize={20}
                            apiQuery={claimDataUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                                return (
                                    <div className="vip-claim-item box-list-with-shadow">
                                        <div>
                                            {moment(item["date"]).format("YYYY-MM-DD")}
                                        </div>
                                        <div
                                            className={
                                                item["netProfit"] < 0 ? "color-red" : "color-green"
                                            }
                                        >
                                            {numberWithCurrencyFormat(
                                                parseFloat(item["netProfit"]),
                                                2,
                                                true
                                            )}
                                        </div>
                                    </div>
                                    // <tr key={i}>
                                    //   <td></td>
                                    //   <td
                                    // className={
                                    //   item["netProfit"] < 0 ? "text-red" : ""
                                    // }
                                    //     style={{ textAlign: "right" }}
                                    //   >

                                    //   </td>
                                    // </tr>
                                );
                            }}
                        />
                    ) : (
                        <div className="no-data">
                            {initPage
                                ? t("CLICK_SEARCH_TO_CONTINUE")
                                : t("NO_DATA_FOUND")}
                        </div>
                    )}
                </div>
                <div className="card card-package d-none">
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table reportTable" style={{ color: "#002E6C" }}>
                                <thead>
                                    <tr>
                                        <th>{t("DATE")}</th>
                                        <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                                    </tr>
                                </thead>
                                {claimData.length > 0 ? (
                                    <tbody>
                                        <DynamicLoadTable
                                            pageSize={20}
                                            apiQuery={claimDataUrl}
                                            searchEnabled={true}
                                            isTable={true}
                                            render={(item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{moment(item["date"]).format("DD-MM-YYYY")}</td>
                                                        <td
                                                            className={
                                                                item["netProfit"] < 0 ? "text-red" : ""
                                                            }
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            {numberWithCurrencyFormat(
                                                                parseFloat(item["netProfit"]),
                                                                2,
                                                                true
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }}
                                        />
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                {initPage
                                                    ? t("CLICK_SEARCH_TO_CONTINUE")
                                                    : t("NO_DATA_FOUND")}
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClaimRecord;
