import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, SessionKey, WebUrl, LanguageOption } from "../../util/Constant";
import { useSelector } from 'react-redux';
import ApiEngine from '../../util/ApiEngine';
import parse from 'react-html-parser';

const About = (props) => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _userData = useSelector(state => state.authState.userData);
    const activeTheme = _userData.selectedTheme;
    const [title, setTitle] = useState("");
    const [html, setHtml] = useState("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
    var url_string = window.location;
    var url = new URL(url_string);
    var page = url.pathname.split("/")[2];
    let apiUrl =
      ApiUrl._API_GET_COMPANY_CONTENT +
      "?page=" +
      page;

      if (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
        if( LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
            apiUrl += "&languageId=" + LanguageOption.find(
              (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
            ).languageId;
          }
          else{
            apiUrl += "&languageId=1";
          }
      } else {
        apiUrl += "&languageId=1";
      }

      // LanguageOption.find(
      //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      // ).languageId;

    var responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY]){
        const title = responseJson[ApiKey._API_DATA_KEY][0]["title"];
        const content = responseJson[ApiKey._API_DATA_KEY][0]["content"];
        setHtml(content);
        setTitle(title);
      }
    }

    return (
        <>
            <Header history={_history} url={WebUrl._URL_MAIN} title={t(title)} />
            <div className={"about-container modern"} style={{ padding: "0 0 53px" }}>
                {/* <img className="tnc-logo" src={require('../../assets/img/icon/i8-logo.png')} /> */}
                {parse(html)}
            </div>
        </>
    )
}

export default About;