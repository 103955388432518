import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Language, SessionKey } from "../util/Constant";

import common_en from "./en/common.json";
import common_cn from "./zh/common.json";
import common_bm from "./ms/common.json";
//import common_bd from "./bn/common.json";
import common_th from "./th/common.json";
import common_vi from "./vi/common.json";
import common_id from "./id/common.json";

/// <summary>
/// Author: -
/// </summary>
const _resources = {};
_resources[Language._ENGLISH] = { common: common_en };
_resources[Language._CHINESE] = { common: common_cn };
_resources[Language._BAHASA_MELAYU] = { common: common_bm };
//_resources[Language._BANGLADESH] = { common: common_bd };
_resources[Language._THAI] = { common: common_th };
_resources[Language._VIETNAMESE] = { common: common_vi };
_resources[Language._INDON] = { common: common_id };

if (window.ReactNativeWebView) {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      action: "language",
      language: localStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH,
    })
  );
}

i18n.use(initReactI18next).init({
  defaultNS: "common",
  debug: false,
  resources: _resources,
  lng: localStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH,
  fallbackLng: [Language._ENGLISH, Language._CHINESE, Language._BAHASA_MELAYU],
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;