import {
  _PRODUCT_CATEGORY,
  _ROLLOVER_TARGET,
  _ROLLOVER_PROCESS,
  _IS_SPECIAL_ROLLOVER,
  _PLAYABLE_CATEGORY,
  _PRODUCT_GAME_ID,
  _MASTER_PRODUCT_ID,
  _MASTER_PRODUCT_NAME,
  _GAME_IS_APP,
  _GAME_APP_URL,
  _GAME_APP_DEEP_LINK,
  _GAME_APP_PACKAGE_NAME_KEYWORD,
  _WALLET_LIST,
  _PROVIDER_WALLET_DATA,
  _MP_DATA,
  _SHOW_PREGAME_POPUP,
  _SHOW_NON_SEAMLESS_TRANSFER_CONFIRMATION,
  _GAME_HAVE_LOBBY,
  _ACTIVE_SOLO_GAME,
  _CURRENT_ROLLOVER_BALANCE,
  _MASTER_PRODUCT_CATEGORY
} from "./GameAction";

const _INITIAL_STATE = {
  productCategory: [],
  rolloverTarget: 0,
  rolloverProcess: 0,
  currentRolloverBalance: 0,
  isSpecialRollover: false,
  playableCategory: [],
  productGameId: 0,
  masterProductId: 0,
  masterProductName: "",
  gameIsApp: false,
  gameAppUrl: "",
  gameAppDeepLink: "",
  gameAppPackageNameKeyword: "",
  walletList: [],
  providerWalletData: {},
  mpData: [],
  showPregamePopup: false,
  showNonSeamlessTransferConfirmation: false,
  gameHaveLobby: false,
  activeSoloGame: false,
  masterProductCategory: "",
};

/// <summary>
/// Author :
/// </summary>
export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload,
      };

    case _ROLLOVER_TARGET:
      return {
        ...state,
        rolloverTarget: action.payload,
      };
    case _ROLLOVER_PROCESS:
      return {
        ...state,
        rolloverProcess: action.payload,
      };
    case _CURRENT_ROLLOVER_BALANCE:
      return {
        ...state,
        currentRolloverBalance: action.payload,
      };

    case _PLAYABLE_CATEGORY:
      return {
        ...state,
        playableCategory: action.payload,
      };
    case _PRODUCT_GAME_ID:
      return {
        ...state,
        productGameId: action.payload,
      };
    case _MASTER_PRODUCT_ID:
      return {
        ...state,
        masterProductId: action.payload,
      };
    case _MASTER_PRODUCT_NAME:
      return {
        ...state,
        masterProductName: action.payload,
      };
    case _GAME_IS_APP:
      return {
        ...state,
        gameIsApp: action.payload,
      };
    case _GAME_APP_URL:
      return {
        ...state,
        gameAppUrl: action.payload,
      };
    case _GAME_APP_DEEP_LINK:
      return {
        ...state,
        gameAppDeepLink: action.payload,
      };
    case _GAME_APP_PACKAGE_NAME_KEYWORD:
      return {
        ...state,
        gameAppPackageNameKeyword: action.payload,
      };
    case _WALLET_LIST:
      return {
        ...state,
        walletList: action.payload,
      };
    case _PROVIDER_WALLET_DATA:
      return {
        ...state,
        providerWalletData: action.payload,
      };
    case _MP_DATA:
      return {
        ...state,
        mpData: action.payload,
      };
    case _SHOW_PREGAME_POPUP:
      return {
        ...state,
        showPregamePopup: action.payload,
      };
    case _SHOW_NON_SEAMLESS_TRANSFER_CONFIRMATION:
      return {
        ...state,
        showNonSeamlessTransferConfirmation: action.payload,
      };
    case _GAME_HAVE_LOBBY:
      return {
        ...state,
        gameHaveLobby: action.payload,
      };
    case _ACTIVE_SOLO_GAME:
      return {
        ...state,
        activeSoloGame: action.payload,
      };
    case _MASTER_PRODUCT_CATEGORY:
      return {
        ...state,
        masterProductCategory: action.payload,
      };
    default:
      return state;
  }
};
