import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine.js";
import { Spinner } from "react-bootstrap";

const MemberProductRateDetail = () => {
  let _history = useHistory();
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [memberProductList, setMemberProductList] = useState([]);
  const [displayMemberProductList, setDisplayMemberProductList] = useState([]);
  const [currCatList, setCurrCatList] = useState([]);
  const [activeCat, setActiveCat] = useState("All");

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
    setIsLoadingData(false);
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRODUCT_RATE);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMemberProductList(data);
      setDisplayMemberProductList(data);
      let tempCurrCatList = [];
      tempCurrCatList.push("All");
      data.map((info, index) => {
        if (!tempCurrCatList.includes(info.category)) {
          tempCurrCatList.push(info.category);
        }
      });
      setCurrCatList(tempCurrCatList);
    }
  }

  const filterProducts = async (categoryName) => {
    if (activeCat !== categoryName) {
      setActiveCat(categoryName);
      let tempDisplayMemberProductList = memberProductList;
      if (categoryName != "All") {
        tempDisplayMemberProductList = memberProductList.filter(x => x.category === categoryName);
      }
      setDisplayMemberProductList(tempDisplayMemberProductList);
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_UNCLAIM_COMMISSION_HISTORY}
        title={t("MEMBER_PRODUCT_RATE_%")}
      />
      {isLoadingData ? <Spinner animation="border" role="status"></Spinner>
        :
        <div id="product-rate-detail">
          {memberProductList.length > 0 && <>
            {currCatList.filter(x => x !== "All").length > 1 && <div className="category-slider">
              {currCatList.map((catName, i) => {
                return (
                  <div key={i} className={"category" + (activeCat == catName ? " active" : "")} onClick={() => filterProducts(catName)}>{catName}</div>
                )
              })}
            </div>}
          </>}
          <div className="hr-right"></div>
          {displayMemberProductList.map((product, index) => {
            return (
              <>
                <div key={index} className={"product-row row-" + index}>
                  <div className="top">
                    <div>{product.productName}</div><div className="cat-title-text">{" (" + product.category + ")"}</div>
                  </div>
                  <div className="bottom">
                    {product.bonus > 0 && <><div className="left">{t("BONUS")}</div><div className="right">{product.bonus + "%"}</div></>}
                    {product.commission > 0 && <><div className="left">{t("COMMISSION_SHORT")}</div><div className="right">{product.commission + "%"}</div></>}
                    {product.clubFee > 0 && <><div className="left">{t("CLUB_FEE")}</div><div className="right">{product.clubFee + "%"}</div></>}
                  </div>
                </div>
                {index != (displayMemberProductList.length - 1) && <hr className="custom-hr" />}
              </>
            )
          })}
        </div>
      }
    </>
  );
};

export default MemberProductRateDetail;