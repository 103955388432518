import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody } from "reactstrap";

/// <summary>
/// Author :
/// </summary>
const ReferralGroupDetails = (props) => {
  const [tabActive, setTabActive] = useState(2);
  const memberList = [
    {
      id: 1,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 2,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 3,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 4,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 5,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 6,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
  ];
  const addedMembers = [
    {
      id: 1,
      name: "PXd8k9mi",
      number: '+6012-125 8926',
    },
    {
      id: 2,
      name: "sample1",
      number: '+6012-125 8926',
    },
    {
        id: 3,
        name: "sample2",
        number: '+6012-125 8926',
    },
  ];
  const tabMenu = [
    {
      id: 1,
      title: 'All Members',
      members: '(5)'
    },
    {
      id: 2,
      title: 'Add Member',
      members: ''
    },
  ];
  return (
    <>
      <div className="page-custom-header">
        <div className="first">
          <div>
            <img
              src={require("../../assets/img/v3/back-icon.png")}
              alt="back"
            />
          </div>
          <div>PXd8k9ui5</div>
        </div>
        <div className="second">
          <img src={require("../../assets/img/v3/more-gold.png")} alt="more" />
        </div>
      </div>
      <div className="referral-details-qr-box">
        <div className="first">
          <div>
            <div className="font11 mb-1">Referral Code</div>
            <div className="referral-code-box">
              <div>barcove98</div>
              <div>
                <img src={require("../../assets/img/v3/copy.png")} alt="copy" />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="font11 mb-1 m">Remarks</div>
            <div className="referral-code-box">
              <div>Testing123</div>
            </div>
          </div>
        </div>
        <div className="second">
          <img src={require("../../assets/img/v3/deposit-qr.png")} alt="qr" />
        </div>
      </div>
      <div className="hr-right mt-3 mb-3"></div>
      <div className="transfer-header-tab-container">
        {tabMenu &&
          tabMenu.length &&
          map(tabMenu, (item, index) => {
            return (
              <div
                key={index}
                className={
                    tabActive == item.id
                    ? "transfer-tab-list-item active"
                    : "transfer-tab-list-item"
                }
                onClick={() => setTabActive(item.id)}
              >
                {item.title} {item.members}
              </div>
            );
          })}
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      {tabActive == 1 &&
        <div className="referral-group-details-container mt-4">
            {memberList &&
                memberList.length &&
                map(memberList, (item, index) => {
                    return (
                    <>
                        <div className="referral-group-details-item pb-3">
                            <div className="first">
                                <img
                                src={require("../../assets/img/v3/member.png")}
                                alt="search"
                                />
                            </div>
                            <div className="second">
                                {item.name}
                                <div className="referral-group-details-number">
                                {item.number}
                                </div>
                            </div>
                        </div>
                    </>
                    );
                })
            }
        </div>
      }
      {tabActive == 2 &&
        <>
            <div className="referral-members-added-container">
                {addedMembers &&
                    addedMembers.length &&
                    map(addedMembers, (item, index) => {
                        return (
                        <>
                            <div className={item.id == 1 ? 'referral-group-details-item new-added' : 'referral-group-details-item'}>
                                <div className="first">
                                    <img
                                    src={require("../../assets/img/v3/member.png")}
                                    alt="member"
                                    />
                                </div>
                                <div className="second">
                                    {item.name}
                                    <div className="referral-group-details-number">
                                    {item.number}
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src={require("../../assets/img/v3/tick.png")}
                                        alt="close"
                                    />
                                </div>
                            </div>
                        </>
                        );
                    })
                }
            </div>
            <div className="referral-group-details-container mt-3">
                {memberList &&
                    memberList.length &&
                    map(memberList, (item, index) => {
                        return (
                        <>
                            <label className="custom-checkbox pb-3">
                                <input type="checkbox" className="form-checkbox-label" name="membersList"  />
                                <span className="checkmark"></span>
                                <div className="referral-group-details-item">
                                    <div className="first">
                                        <img
                                        src={require("../../assets/img/v3/member.png")}
                                        alt="search"
                                        />
                                    </div>
                                    <div className="second">
                                        {item.name}
                                        <div className="referral-group-details-number">
                                        {item.number}
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </>
                        );
                    })
                }
            </div>
        </>
      }
      
    </>
  );
};

export default ReferralGroupDetails;
