
// library import
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Numpad2 from "../../components/custom/Numpad2";
import { useHistory, withRouter } from "react-router-dom";

// js import
import { ApiKey, ApiUrl, WebUrl, AlertTypes, _MAIN_WALLET_ID } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { formatNumber } from '../../util/Util';

// redux import
import { showMessage, setBusy, setIdle, showResponseMessage, setDisplayLogin } from "../../redux/AppAction.js";
import {
  setWalletList,
  setProviderWalletData,
  setMpData,
  setShowNonSeamlessTransferConfirmation,
  enterGame,
  setShowPregamePopup,
  setGameHaveLobby,
} from "../../redux/GameAction.js";

//component import
import BottomSuccessModal from '../custom/BottomSuccessModal';
import DepositNumpad from '../custom/DepositNumpad';



const NonSeamlessGameWalletModal = props => {
  const { title, amount, deductedAmount, bonus, date, isOpen, transactionNumber, setIsOpen, ...rest } = props;
  const { t } = useTranslation();
  const _dispatch = useDispatch();
  var timeoutRef;
  const usernameRef = useRef();
  const passwordRef = useRef();
  const _userData = useSelector((state) => state.authState.userData);
  var { isLoggedIn } = useSelector((state) => state.authState);
  const _history = useHistory();
  const {
    productGameId,
    masterProductId,
    masterProductName,
    gameIsApp,
    gameAppUrl,
    gameAppDeepLink,
    gameAppPackageNameKeyword,
    walletList,
    providerWalletData,
    mpData,
    showPregamePopup,
    showNonSeamlessTransferConfirmation,
    gameHaveLobby,
    isNewWindow,
    playableCategory,
    masterProductCategory
    }
    = useSelector((state) => state.gameState)

  const {
    register: registerPopupTransfer,
    handleSubmit: handleSubmitPopupTransfer,
    setValue: setValuePopupTransfer,
    getValues: getValuesPopupTransfer,
    errors: errorsPopupTransfer,
    setError: setErrorPopupTransfer,
    clearErrors: clearErrorsPopupTransfer,
    watch: watchPopupTransfer
  } = useForm();

  const [amountValue, setAmountValue] = useState("0");
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [currentInput, setCurrentInput] = useState();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    proceedNonSeamlessTransfer()
  }, [showNonSeamlessTransferConfirmation]);

  const proceedNonSeamlessTransfer = async () => {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
      "?masterProductId=" +
      masterProductId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let walletData = responseJson[ApiKey._API_DATA_KEY];
      _dispatch(setWalletList(walletData));

      let tempProviderWalletData = walletData.filter(x => x.productId === masterProductId)[0];
      _dispatch(setProviderWalletData(tempProviderWalletData));

      var mpJson = await ApiEngine(
        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
        "?masterProductId=" +
        masterProductId
      );
      if (mpJson[ApiKey._API_SUCCESS_KEY]) {
        let data = mpJson[ApiKey._API_DATA_KEY];
        _dispatch(setMpData(data));

        if (window.ReactNativeWebView && gameIsApp) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              action: "deeplink",
              url: gameAppUrl,
              deeplink: gameAppDeepLink
                .replace("[username]", data["username"])
                .replace("[password]", data["password"]),
              packageKeyword: gameAppPackageNameKeyword,
            })
          );
        }
      }
    }
    _dispatch(setShowPregamePopup(true));
    _dispatch(setIdle());
  }

  // Copy text
  function copyText(textType) {
    setShowCopyAlert(false);
    clearTimeout(timeoutRef);
    if (textType === "username") {
      usernameRef.current.select();
    } else if (textType === "password") {
      passwordRef.current.select();
    }
    document.execCommand("copy");
    setAlertMessage(t(textType.toUpperCase()) + " " + t("COPY_SUCCESS"));
    setShowCopyAlert(true);
    timeoutRef = setTimeout(() => setShowCopyAlert(false), 3000);
  }

  // Reset password
  async function resetProductPassword() {
    let apiUrl =
      ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
      "?masterProductId=" +
      providerWalletData.productId;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempProviderWalletData = providerWalletData;
      tempProviderWalletData.password = data.password;
      setProviderWalletData({ ...tempProviderWalletData });
    }
  }

  // Submit transfer
  const submitTransferForm = async (data, e) => {
    let conversionRateAmount = numberWithCurrencyFormat(
      parseFloat(watchPopupTransfer("amount")) * providerWalletData.productConversionRate,
      2,
      true
    );

    _dispatch(setBusy());
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: providerWalletData.productId,
      amount: amountValue,
      conversionRateAmount:
        masterProductName == '918Kiss' || masterProductName == "D2 Poker" || masterProductName == "Pussy888" || masterProductName == "Mega888" || masterProductName == "LionKing" ?
          conversionRateAmount : 0,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    let responseMsg = responseJson[ApiKey._API_MESSAGE_KEY];
    responseMsg = responseMsg.replace("Amount", t("AMOUNT"));
    responseMsg = responseMsg.replace("From", t("FROM"));
    responseMsg = responseMsg.replace("Wallet", t("MAIN_WALLET"));
    responseMsg = responseMsg.replace("to", t("TO"));
    if (responseMsg.includes("Success")) {
      responseMsg = responseMsg.replace("Success", t("SUCCESS"));
    }
    else {
      responseMsg = responseMsg.replace("Failed", t("FAILED"));
      if (responseMsg.includes("Transaction")) {
        responseMsg = responseMsg.replace("Transaction", t("TRANSACTION"));
      }
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(
        parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2),
        providerWalletData.productId
      );
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      setIsTransferSuccess(true);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
      setAmountValue("0");
      e.target.reset();

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        ));
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseMsg
        )
      );
    }
    _dispatch(setIdle());
  }

  //update wallet balance
  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?masterProductId=" +
      providerWalletData.productId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let walletData = responseJson[ApiKey._API_DATA_KEY];
      _dispatch(setWalletList(walletData));
      let tempProviderWalletData = walletData.filter(x => x.productId === providerWalletData.productId)[0];
      _dispatch(setProviderWalletData(tempProviderWalletData));
    }
  }

  const checkValueOnKeyDown = async (parsedAmtValue) => {
    clearErrorsPopupTransfer();
    let tempAmountValue = parsedAmtValue;
    setAmountValue(tempAmountValue);
    setValuePopupTransfer("amount", tempAmountValue);
    let min = 0;
    if (parseFloat(tempAmountValue) <= min) {
      setErrorPopupTransfer("amount", {
        message: t('PLEASE_ENTER_VALUE_MORE_THAN_ZERO'),
      });
    }
  }

  const onEnterGame = async (productGameId) => {
    if (isLoggedIn) {
      if (_userData.isSpecialRollover) {
        let productCategoryListArr = [...playableCategory];
        if (productCategoryListArr.includes(masterProductCategory)) {
          _dispatch(enterGame(productGameId,gameIsApp));
        } else {
          _dispatch(showMessage({
            type: 'INFO',
            content: t('ROLLOVER_EXIST'),
            onConfirm: () => { },
          }));
        }
      } else {
        _dispatch(enterGame(productGameId, gameIsApp));
      }
    } else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
            //console.log("hi");
          },
        })
      );
    }
  }


  return (
    <>
      {/*
      <Modal
        fade={false}
        id="success-modal"
        isOpen={showNonSeamlessTransferConfirmation}
        toggle={() => {
          _dispatch(setShowNonSeamlessTransferConfirmation(false));
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">{t("CONFIRM_TRANSFER_NON_SEAMLESS_CREDIT", { value: masterProductName })}</div>
          <div className="buttons">
            <button className="btn mt-3" style={{ width: "200px" }}
              onClick={() => {
                enterGame(productGameId);
                _dispatch(setShowNonSeamlessTransferConfirmation(false));
              }} >{t("NO") + "(" + t("START_GAME") + ")"}</button>
            <button className="btn mt-3" style={{ width: "200px" }}
              onClick={() => {
                _dispatch(setShowNonSeamlessTransferConfirmation(false));
                proceedNonSeamlessTransfer();
              }} >{t("CONFIRM")}</button>
          </div>
        </ModalBody>
      </Modal>
      */}

      <Modal
        id="custom-modal"
        contentClassName="modal-brand modal-bottom"
        fade={true}
        isOpen={showNonSeamlessTransferConfirmation}
        toggle={() => {
          _dispatch(setShowNonSeamlessTransferConfirmation(false));
        }}
        centered
      >
        <ModalBody>
          <div className="flex-box-centered mb-4">
            <h2 className="wallet-modal-title mb-0">{masterProductName}</h2>
          </div>
          <div className="game-wallet-container mb-4">
            <div className="first">
              <h6 className="mb-1">{t("MAIN_WALLET")}</h6>
              {/* <p className="mb-0">$ {numberWithCurrencyFormat2((isNaN(parseFloat(walletList.filter(x => x.productId === 0)[0].balance)) ? 0 : parseFloat(walletList.filter(x => x.productId === 0)[0].balance)), 2, true)}</p>*/}
              {walletList.length > 0 && <p className="mb-0">{numberWithCurrencyFormat2((isNaN(parseFloat(walletList.filter(x => x.productId === 0)[0].balance)) ? 0 : parseFloat(walletList.filter(x => x.productId === 0)[0].balance)), 2, true)}</p>}
            </div>
            <div className="second">
              <h6 className="mb-1">{masterProductName}</h6>
              <p className="mb-0">$ {numberWithCurrencyFormat2((isNaN(parseFloat(providerWalletData.balance)) ? 0 : parseFloat(providerWalletData.balance)), 2, true)}</p>
            </div>
          </div>
          <h5 className="game-wallet-amount-label mb-2">{t("AMOUNT")}</h5>
          <form onSubmit={handleSubmitPopupTransfer(submitTransferForm)}>
            <div className="game-wallet-amount-container mb-4">
              <div className="first">
                <input
                  type="number"
                  className="game-wallet-input"
                  name="amount"
                  readOnly
                  value={numberWithCurrencyFormat(amountValue, 2, true)}
                  onClick={(e) => setCurrentInput(e.target)}
                />
                
              </div>
              <button type="submit" className="game-wallet-btn second">
                {t("TRANSFER")}
              </button>
            </div>
          </form>
          <div className="game-wallet-new-balance-container">
            <h3 className="">{masterProductName} {t("NEW_BALANCE")}: 
              {amountValue > 0 ? numberWithCurrencyFormat2(parseFloat(providerWalletData.balance) + parseFloat(amountValue), 2, true) : "-"}
            </h3>
            {/*{providerWalletData.productConversionRate !== 0 && providerWalletData.productConversionRate !== 1 && <p>{t("CONVERSION_RATE") + " : 1"}&#3647; <i className="fas fa-arrow-right"></i> {numberWithCurrencyFormat(providerWalletData.productConversionRate, 2, true)}</p>}*/}
            {(providerWalletData.bonus > 0 || providerWalletData.commission > 0 || providerWalletData.clubFee > 0) &&
              <p className="mb-0">
                {providerWalletData.commission > 0 && t("COMMISSION") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.commission, 2, true) + ((providerWalletData.bonus > 0 || providerWalletData.clubFee > 0) ? " / " : "")}
                {providerWalletData.bonus > 0 && t("BONUS") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.bonus, 2, true) + (providerWalletData.clubFee > 0 ? " / " : "")}
                {providerWalletData.clubFee > 0 && t("CLUB_FEE") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.clubFee, 2, true)}
              </p>
            }
          </div>
          {gameIsApp && gameAppDeepLink === "" && (!stringIsNullOrEmpty(providerWalletData.username) && !stringIsNullOrEmpty(providerWalletData.password)) &&
            <>
              <div className="game-wallet-account-container mt-4 mb-4 pt-2 pb-2">
                <div className="first"></div>
              <div className="second">{masterProductName + " " + t("ACCOUNT")}</div>
                <div className="third"></div>
              </div>
              <div className="game-wallet-account-details">
                <div className="game-account-details-box">
                  <div className="flex-baseline">
                    <p className="mb-0" style={{ minWidth: "50px" }}>{t("USERNAME") + ":"} </p><p><input className="text-for-copy" ref={usernameRef} value={providerWalletData.username} readOnly /></p>
                  </div>
                  <div>
                    <img src={require("../../assets/img/v3/copy.png")} alt="copy" className="img-responsive" onClick={() => copyText("username")} />
                  </div>
                </div>
                <div className="game-account-details-box mt-3">
                  <div className="flex-baseline">
                    <p className="mb-0" style={{ minWidth: "50px" }}>{t("PASSWORD") + ":"} </p><p><input className="text-for-copy" ref={passwordRef} value={providerWalletData.password} readOnly /></p>
                  </div>
                  <div className="game-account-icon-box">
                    <div>
                      <img src={require("../../assets/img/v3/refresh.png")} alt="copy" className="img-responsive" onClick={() => resetProductPassword()} />
                    </div>
                    <div>
                      <img src={require("../../assets/img/v3/copy.png")} alt="copy" className="img-responsive" onClick={() => copyText("password")} />
                    </div>
                  </div>
                </div>
                <div className="game-account-details-box mt-3">
                  <span className="alert-text text-color-green">{showCopyAlert ? alertMessage : " "}</span>
                </div>
              </div>
            </>
          }
          {/*{gameHaveLobby ?*/}
          {/*<a*/}
          {/*    className="btn custom-btn mt-5"*/}
          {/*    onClick={() => {*/}
          {/*      _history.push({*/}
          {/*        pathname: WebUrl._URL_PROVIDER_GAME_LOBBY,*/}
          {/*        search: "?name=" + masterProductName*/}
          {/*      });*/}
          {/*      _dispatch(setShowNonSeamlessTransferConfirmation(false));*/}
          {/*    }*/}
               
          {/*    }*/}
          {/*>*/}
          {/*  {t("TO_LOBBY")}*/}
          {/*</a> :*/}
            <>
              {!gameIsApp || (gameIsApp && gameAppDeepLink != "") ? (
                <button className="btn custom-btn mt-5 w-100" onClick={() =>
                {
                  //_dispatch(enterGame(productGameId, gameIsApp))
                  onEnterGame(productGameId)
                }}>
                  {t("START_GAME")}
                </button>
              ) : (
                <button
                  className="btn custom-btn mt-5 w-100"
                  onClick={() => {
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                          url: gameAppUrl,
                          packageKeyword: gameAppPackageNameKeyword,
                        }));
                    } else {
                      window.open(gameAppUrl);
                    }
                  }}
                >
                  {t("DOWNLOAD_APP")}
                </button>
              )}
            </>
          {/*}*/}
        </ModalBody>
      </Modal>
      {currentInput &&
        <Numpad2
          currentBalance={_userData.walletBalance}
          currentInput={currentInput}
          setCurrentInput={(e) => {
            setCurrentInput(e);
            checkValueOnKeyDown(parseFloat(currentInput.value));
          }}
          contentClassName="popup-transfer-numpad"
        />
      }
    </>
  );
};

export default NonSeamlessGameWalletModal;