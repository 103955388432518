import React, { useState } from 'react'
import { getValidationMessage, stringIsNullOrEmpty } from '../../util/Util'
import { useTranslation } from 'react-i18next'
import { ApiKey } from '../../util/Constant'

/**
 * Author: -
 */
const Checkbox = React.forwardRef((props, ref) => {
  const {
    name,
    id,
    containerClassName = '',
    labelClassName = '',
    inputClassName = 'form-checkbox',
    type = 'checkbox',
    label,
    small,
    placeholder = '',
    errors,
    disabled,
    inputGroupType,
    inputGroupContent,
    ...rest
  } = props
    const { t, i18n } = useTranslation()

  return (
    <div className={'form-group' + containerClassName}>
      {inputGroupType ? (
        <div className="input-group mb-3">
          {inputGroupType == 'prepend' && (
            <div className={`input-group-${inputGroupType}`}>
              <span className="input-group-text">{inputGroupContent}</span>
            </div>
          )}
          <input
            name={name}
            ref={ref}
            type={type}
            className={'form-control ' + inputClassName}
            placeholder={placeholder}
            {...rest}
          />
          {label && (
            <label className={labelClassName}>
              <b>{label}</b>
            </label>
          )}
          {inputGroupType == 'append' && (
            <div className={`input-group-append`}>
              <span className="input-group-text">{inputGroupContent}</span>
            </div>
          )}
        </div>
      ) : !disabled ? (
        <div className="align-center">
          <input
            id={id}
            name={name}
            ref={ref}
            type={type}
            className={'form-control ' + inputClassName}
            placeholder={placeholder}
            {...rest}
          />{' '}
          {label && (
            <label htmlFor={id} className={labelClassName}>
              <b>{label}</b>
            </label>
          )}
        </div>
      ) : (
        <div className="form-disabled">{props.value}</div>
      )}
      {errors
        ? errors[name] && (
            <div className="invalid-feedback">
              {stringIsNullOrEmpty(errors[name][ApiKey._API_MESSAGE_KEY])
                ? t(getValidationMessage(errors[name]))
                : t(errors[name][ApiKey._API_MESSAGE_KEY])}
            </div>
          )
        : ''}
      {small && <div className="small">{small}</div>}
    </div>
  )
})

export default Checkbox
