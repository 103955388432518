import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { showResponseMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { Modal, ModalBody, ModalHeader, Alert, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Input from "../../components/custom/Input";
import { DropdownButton } from "react-bootstrap";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";

/// <summary>
/// Author: -
/// </summary>
const VideoTutorial = props => {
    var { t } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    var activeTheme = useSelector(state => state.authState.userData.selectedTheme);
    let _copyRef = useRef();
    const _ALERT_TIMEOUT = 2000;

    const [video, setVideo] = useState([]);
    const [showMenuList, setShowMenuList] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.screen.width);


    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init();
    }, []);
    const rightMenuList = [
         'Get Link'
    ];
    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VIDEO);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVideo(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    async function isClick(title, url) {
        setIsCopy(true);
        setTitle(title);
        setUrl(url);
    }

    async function copyUrl(url) {
      navigator.clipboard.writeText(url);
    document.execCommand('copy');
   _dispatch(showResponseMessage(true, t("COPY_SUCCESS")));
    }
    return (
        <>
            <Header history={_history} url={WebUrl._URL_MAIN} title={t('VIDEO_TUTORIAL')} />
            <div id={activeTheme === 0 ? "video-tutorial" : "video-tutorial-modern"} style={{ paddingBottom: '30px' }}>
                {video.map((video, index) => {
                    return (
                        <div className="video-item-box mb-3">
                            <iframe className="video"
                                src={video['content']}
                                allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"                            >
                            </iframe>
                            <div className="video-tutorial-content">
                                <div className="first">
                                    <div className="custom-icon-box"><img src={require("../../assets/img/v3/i8-logo-small.png")} alt="i8" /></div>
                                    <div>
                                        <div className="video-name">{video['title']}</div>
                                        <div className="video-provider">{t("i8Live_Official")}</div>
                                    </div>
                                </div>
                                {/*<div>*/}
                                {/*    <img src={require("../../assets/img/v3/more-gold.png")} alt="more" onClick={() => setShowMenuList(!showMenuList)} />*/}
                                
                                    
                                {/*    <div>*/}
                                {/*            {rightMenuList.map((item, index) => {*/}
                                {/*                return (*/}
                                {/*                    <div key={index} onClick={() => isClick(video['title'], video['content'] ) }>*/}
                                {/*                        <a>{t(item.title)}</a>*/}
                                {/*                    </div>*/}
                                {/*                )*/}
                                {/*            })}*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                {/*<div>*/}
                                {/*    <select >*/}
                                {/*        {rightMenuList.map((item) => {*/}
                                {/*            return (*/}
                                {/*                <div key={index} onClick={() => isClick(video['title'], video['content'])}>*/}
                                {/*                    <option>{t(item)}</option>*/}
                                {/*                </div>*/}
                                {/*            )*/}
                                {/*        })}*/}
                                {/*    </select>*/}
                                    
                                {/*</div>*/}

                                {/*<div className="transform-button">*/}
                                {/*    <UncontrolledButtonDropdown>*/}
                                {/*        <DropdownToggle className="transform-button">*/}
                                {/*            <img src={require("../../assets/img/v3/more-gold.png")} alt="more"/>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu className="transform-1">*/}
                                {/*            <DropdownItem*/}
                                {/*                onClick={() => isClick(video['title'], video['content'])}*/}
                                {/*            >*/}
                                {/*                <a style={{ color: 'hsla(0,0%,100%,.6)', fontSize:'12px' }}>{t("GET_LINK")}</a>*/}
                                {/*            </DropdownItem>*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledButtonDropdown>*/}
                                {/*</div>*/}

                                {/*<div className="transform-button" onClick={() => isClick(video['title'], video['content'])}>*/}
                                <div className="transform-button" onClick={() => copyUrl(video['content'])}>
                                    {/* <img src={require("../../assets/img/v3/more-gold.png")} alt="more"  /> */}
                                    {/*<VIcon*/}
                                    {/*    icon={themeIcons.moreVertical}*/}
                                    {/*    className="vicon"*/}
                                    {/*/>*/}
                                    <img src={require("../../assets/img/v3/copyIconNew.svg")} ></img>
                                </div>
                            </div>
                            <Modal
                                fade={true}
                                contentClassName="modal-brand modal-bottom modal-edit-sh"
                                id="custom-modal"
                                isOpen={isCopy}
                                centered
                                toggle={() => {
                                    setIsCopy(false);
                                }}
                            >
                                {showAlert && <div className="content content-full-width" style={{ position: "fixed", top: "10px", padding: 0, margin: 0, width: "100%", maxWidth: screenWidth - 40 }}>
                                    <div className="container" style={{ padding: 0, margin: 0 }}>
                                        <Alert color="green">{t("COPY_SUCCESS")}</Alert>
                                    </div>
                                </div>}
                                <ModalBody>
                                    
                                    <div className="edit-vt-data">
                                        <div className="text-white text-center" style={{ fontSize: "24px" }}>{title}</div>
                                        <div>
                                            <div className="video-data">
                                                <div style={{ fontSize: "24px" }}>{t("i8Live_Official")}</div>
                                            </div>
                                            <div className="video-data" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                                                <div><img src={require("../../assets/img/v3/copyIconNew.svg")} ></img></div>
                                            </div>
                                            <div className="video-data">
                                                <div style={{ fontSize: "12px" }}>{url}</div>
                                            </div>
                                            <button className="btn custom-btn w-100 mt-4" onClick={() => copyUrl(url) }>{t("COPY")}</button>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            
                         </div>
                    ) 
                })}
            </div>
        </>
    );
}

export default VideoTutorial;