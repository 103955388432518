import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import "react-owl-carousel2/src/owl.carousel.css";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { map } from "lodash";
import Result4D from "components/result-4d";
import Result4DTable from "components/result-4d/table";
import { ApiKey, ApiUrl, WebUrl, SessionKey } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import {
	setBusy,
	setIdle,
	showResponseMessage,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/el";
import Slider from "react-slick";
import DateRangePicker from "react-bootstrap-daterangepicker";

const Result = () => {
	let _history = useHistory();
	const { t } = useTranslation();
	const [scrollTop, setScrollTop] = useState(0);
	const [displayGameMenu, setDisplayGameMenu] = useState(true);
	const [poolDate, setPoolDate] = useState(null);
	const languageCode = localStorage.getItem(SessionKey._LANGUAGE);
	let _locale = "";

	switch (languageCode) {
		case "en":
			_locale = "en-gb";
			break;
		case "zh":
			_locale = "zh-cn";
			break;
		case "th":
			_locale = "th";
			break;
		case "id":
			_locale = "id";
			break;
		case "vi":
			_locale = "vi";
			break;
		case "ms":
			_locale = "ms";
			break;
		default:
			_locale = "en";
	}

	const DateCustomInput = ({ value, onClick }) => (
		<input
			className="form-control"
			onClick={onClick}
			value={value}
			type="text"
			readOnly={true}
			style={{ boxShadow: "unset", padding: "0 20px" }}
		/>
	);
	const ArrowButtonPrevious = ({ onClick }) => {
		return (
			<div
				style={{ position: "absolute", top: "-70px", left: 0, zIndex: "999" }}
			>
				<button
					type="button"
					className="btn btn-icon"
					style={{
						color: "#898F97",
						position: "absolute",
						top: "5px",
						left: "16px",
						zIndex: "1",
						fontSize: "12px",
					}}
					onClick={onClick}
				>
					<i className="fas fa-chevron-left"></i>
				</button>
			</div>
		);
	};

	const ArrowButtonNext = ({ onClick }) => {
		return (
			<div
				style={{ position: "absolute", top: "-70px", right: 0, zIndex: "999" }}
			>
				<button
					type="button"
					className="btn btn-icon"
					style={{
						color: "#898F97",
						position: "absolute",
						top: "5px",
						right: "16px",
						fontSize: "12px",
					}}
					onClick={onClick}
				>
					<i className="fas fa-chevron-right"></i>
				</button>
			</div>
		);
	};

	const result4DSlick = {
		slidesToShow: 1,
		dots: false,
		// arrows: true,
		swipe: true,
		// infinite: true,
		swipeToSlide: true,
		speed: 1200,
		autoplay: false,
		prevArrow: <ArrowButtonPrevious />,
		nextArrow: <ArrowButtonNext />,
	};

	//4D result//
	const _POOL_NAME = {
		mg: "Magnum",
		kd: "Da Ma Cai 1 + 3D",
		tt: "Sport TOTO",
		sg: "Singapore Pool ",
		sr: "Sarawak 88 ",
		sn: "SANDAKAN",
		sb: "Sabah 88",
		gd: "GRAND DRAGON",
	};

	const _TITLE_COLOR = {
		mg: "#000000",
		kd: "#FFFFFF",
		tt: "#FFFFFF",
		sg: "#FFFFFF",
		sr: "#FFFFFF",
		sn: "#000000",
		sb: "#FFFFFF",
		gd: "#FFFFFF",
	};

	const _COLOR = {
		mg: "#FFC100",
		kd: "#000080",
		tt: "#AD0006", //4D AND 5D AND 6D
		sg: "#0378BB",
		sr: "#008000",
		sn: "#F8C100",
		sb: "#33F", // currently no color
		gd: "#A1030E", //4D AND 5D AND 6D
	};

	const _POOL_IMAGE = {
		mg: require("../../assets/img/4dresult-icon/magnum_logo.svg"),
		kd: require("../../assets/img/4dresult-icon/damacai_logo.svg"),
		tt: require("../../assets/img/4dresult-icon/toto_logo.svg"),
		sg: require("../../assets/img/4dresult-icon/singapore_logo.svg"),
		sr: require("../../assets/img/4dresult-icon/bigcash_logo.svg"),
		sn: require("../../assets/img/4dresult-icon/stc_logo.svg"),
		sb: require("../../assets/img/4dresult-icon/sabah_logo.svg"),
		gd: require("../../assets/img/4dresult-icon/gd_logo.svg"),
	};

	const _SECTION_TYPE = {
		mg: "section-3",
		kd: "section-3",
		tt: "section-3", //4D AND 5D AND 6D
		sg: "section-3",
		sr: "section-3",
		sn: "section-3",
		sb: "section-3", // currently no color
		gd: "section-3", //4D AND 5D AND 6D
	};

	const _IS_5D_6D = {
		tt: "section-2",
		gd: "section-1",
	};

	useEffect(() => {
		const scrollEvent = () => {
			window.addEventListener("scroll", (e) => {
				setScrollTop(e.target.documentElement.scrollTop);
			});
		};

		scrollEvent();

		return () => {
			window.removeEventListener("scroll", () => {});
		};
	}, []);

	useEffect(() => {
		// console.log(scrollTop);
	}, [scrollTop]);

	useEffect(() => {
		setDisplayGameMenu(false);
		setTimeout(() => setDisplayGameMenu(true), 1);
	}, [
		localStorage.getItem(SessionKey._LANGUAGE) &&
		localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
			? localStorage.getItem(SessionKey._LANGUAGE)
			: "en",
	]);

	useEffect(() => {
		init();
	}, [poolDate]);

	async function init() {
		_dispatch(setBusy());
		try {
			moment.locale("en");
			await getPoolResult();
		}
		 finally {
			_dispatch(setIdle());
		}
		_dispatch(setIdle());
	}

	const [poolResult, setPoolResult] = useState([]);
	var _dispatch = useDispatch();

	async function getPoolResult() {
		var apiUrl = ApiUrl._API_GET_POOL_RESULT;

		if (poolDate != null) {
			apiUrl += "?date=" + moment(poolDate).format("YYYY-MM-DD");
		}

		var responseJson = await ApiEngine.get(apiUrl);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY];

			if (data.length > 0) {
				if (poolDate == null)
					setPoolDate(moment(data[0]["drawDateString"], "DD/MM/YYYY").toDate());
				setPoolResult(data);
			} else {
				setPoolResult([]);
			}
		}
	}

	const [resultData, setResultData] = useState([]);
	const [allResultData, setAllResultData] = useState([]);

	useEffect(() => {
		const tempArray = map(poolResult, (item) => {
			return {
				...item,
				name:
					item.poolKey != "kd"
						? item.p_4d_1st != ""
							? _POOL_NAME[item.poolKey] + " 4D"
							: item.p_5d_1st != ""
							? _POOL_NAME[item.poolKey] + " 5D"
							: _POOL_NAME[item.poolKey] + " 6D"
						: _POOL_NAME[item.poolKey],
				titleColor: _TITLE_COLOR[item.poolKey],
				color: _COLOR[item.poolKey],
				icon: _POOL_IMAGE[item.poolKey],
				type: item.is5D6D
					? _IS_5D_6D[item.poolKey]
					: _SECTION_TYPE[item.poolKey],
			};
		});

		setResultData(tempArray);
		setAllResultData(tempArray);
	}, [poolResult]);

	function getText(e) {
		const searchItemArray = allResultData.filter(function (item) {
			const regexName = item.name.replace(/ /g, "");
			const regexInputSearch = e.target.value.replace(/ /g, "");

			return regexName.toLowerCase().includes(regexInputSearch.toLowerCase());
		});
		setResultData(searchItemArray);
	}

	return (
		<>
			<Header
				history={_history}
				url={WebUrl._URL_DASHBOARD}
				title={t("RESULT")}
			/>
			<section
				id="result4d"
				className="result-section"
				style={{ margin: "unset" }}
			>
				<div className="sort-search-box align-items-end">
					<div>
						<div className="mb-3 title-4dresult">{t("LATEST_4D_RESULT")}</div>
						<div
							className="mb-0 title-4dresult form-white-search-input"
							style={{ position: "relative" }}
						>
							<div className="pool-calendar">
								<DateRangePicker
									locale={moment.locale(_locale)}
									id="datepicker"
									singleDatePicker={true}
									onApply={(event, picker) => {
										console.log(picker);
										setPoolDate(picker.startDate);
									}}
									showDropdowns={false}
									opens="center"
								>
									{/*<input*/}
									{/*	type="text"*/}
									{/*	className="form-control"*/}
									{/*	placeholder={t("SELECT_DATE")}*/}
									{/*	value={*/}
									{/*		poolDate == ""*/}
									{/*			? ""*/}
									{/*			: moment(poolDate).format("dddd, DD MMMM yyyy")*/}
									{/*	}*/}
									{/*	style={{*/}
									{/*		color: "#FFFFFF",*/}
									{/*		textAlign: "center",*/}
									{/*		fontSize: "12px",*/}
									{/*	}}*/}
									{/*	readOnly*/}
									{/*/>*/}
									<div className="display-date-container">{poolDate == "" ? "" : moment(poolDate).format("dddd, DD MMMM yyyy")}</div>
								</DateRangePicker>
							</div>
						</div>
					</div>
				</div>

				<div class="hr-right mt-3 mb-3"></div>

				{resultData && resultData.length > 0 && (
					<Slider {...result4DSlick} className="result-slick">
						{map(
							resultData.filter((x) => x.drawDateString !== "01/01/0001"),
							(item, index) => {
								return (
									<Result4D
										key={index}
										title={item.name}
										titleColor={item.titleColor}
										color={item.color}
										icon={item.icon}
									>
										<Result4DTable
											key={index}
											color={item.color}
											type={item.type}
											titleColor={item.titleColor}
											poolData={item}
										/>
									</Result4D>
								);
							}
						)}
					</Slider>
				)}

				{(resultData.filter((x) => x.drawDateString !== "01/01/0001").length ===
					0 ||
					resultData.length == 0) && (
					<div className="font-thick">{t("NO_DATA_FOUND")}</div>
				)}
			</section>
		</>
	);
};

export default Result;
