import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: -
/// </summary>
const GameDetails = () => {
    var _history = useHistory();
    var _location = useLocation();

    const [gameDetails, setGameDetails] = useState({});
    const { t, i18n } = useTranslation();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_location['state'] && !stringIsNullOrEmpty(_location['state']['data'])) {
            var data = JSON.parse(_location['state']['data']);
            setGameDetails(data);
        }
        else {
            _history.goBack();
        }
    }, [_location['state']]);

    async function getGameRecords(data) {
        
        var url = `${ApiUrl._API_GET_BET_DETAILS}`;
        url += '?betid=' + data.betId + '&memberProductId=' + data.memberProductId;
        var responseJson = await ApiEngine.get(url);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setGameDetails(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    return (
        <div className="container">
            <Header history={_history} url={WebUrl._URL_GAME_HISTORY} title={t('GAME_DETAILS')} />
            <h3 className="text-brand">{gameDetails['betId']}</h3>
            <p>{gameDetails['dateStr']}</p>
            <p>{t('STATUS')}: {gameDetails['amount']}</p>
            <p>{t('AMOUNT')}: {gameDetails['statusString']}</p>
            <p>{t('STRIKE')}: {gameDetails['winAmount']}</p>
            <div className="text-brand" style={{ whiteSpace: "pre-line" }}>{t('DESCRIPTION')}: <br />
                {gameDetails['remark']}
            </div>
        </div>
    );
};

export default GameDetails;