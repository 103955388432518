import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  setDownlineSearchText,
  setDownlineQuery,
  setPageSize,
  setY,
  setActiveTab,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../util/ApiEngine.js";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import StyledSelect from "../../components/custom/StyledSelect";
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody } from "reactstrap";
import classnames from "classnames";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleButton from "react-toggle-button";
import { Accordion } from "react-bootstrap";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import moment from "moment";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";


/// <summary>
/// Author: -
/// </summary>
const Downline = (props) => {
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();

  const [memberData, setMemberData] = useState({});
  let _userData = useSelector((state) => state.authState.userData);
  let _savedData = useSelector((state) => state.appState.savedText);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  const [initCheck, setInitCheck] = useState(false);
  const [categories, setCategories] = useState([]);
  const [productStatus, setProductStatus] = useState(false);
  const [products, setProducts] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [username, setUsername] = useState([]);
  const [downlineGroups, setDownlineGroups] = useState([]);
  const [downlineMemberData, setDownlineMemberData] = useState({});
  const [totalUngroupedMembers, setTotalUngroupedMembers] = useState(0);
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [member, setMember] = useState({});
  const [displayDataIndex, setDisplayDataIndex] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const activeTab = useSelector((state) => state.appState.activeTab);
  const _DOWNLINE_TAB = 0;
  const _DOWNLINE_GROUPS_TAB = 1;
  const _DOWNLINE_GAMES_TAB = 2;
  const [isCommissionEmpty, setIsCommissionEmpty] = useState(false);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function onSaveGroupSubmit(data, e) {
    _dispatch(setBusy());
    if (typeof selectedGroup === "undefined") {
      _dispatch(showResponseMessage(false, t("PLEASE_SELECT_GROUP")));
    } else {
      let params = {
        groupId: selectedGroup,
      };

      Object.keys(data).map((key, value) => {
        params[key] = data[key];
      });

      let formBody = createMultiPartFormBody(params);
      let responseJson = await ApiEngine.post(
        ApiUrl._API_UPDATE_DOWNLINE_PRODUCT_RATE,
        formBody
      );
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  }
  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    try {
      await getMemberDetails();
    } finally {
      _dispatch(setIdle());
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      _dispatch(setActiveTab(tab));
    }
  };

  async function getDownlineGames(product) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT + "?masterProductId=" + product
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername((responseJson[ApiKey._API_DATA_KEY]));
    }
    _dispatch(setIdle());
  }
  /// <summary>
  /// Author: -
  /// </summary>
  async function getMemberDetails() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    );

    let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      setCategories(categoryJson[ApiKey._API_DATA_KEY]);
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      getDownlineGroups(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }


  const getDownlineGroups = async (memberData) => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_GROUPS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let downlineGroupList = [];
      let totalMembersInEachGroup = [];
      downlineGroupList.push({ label: "Default", value: 0 });
      let totalMembersInDefault = memberData["totalDirectDownlines"];
      data["totalMemberInGroup"].map((total, index) => {
        totalMembersInDefault -= total;
        totalMembersInEachGroup.push(total);
      });
      setTotalUngroupedMembers(totalMembersInDefault);
      totalMembersInEachGroup.unshift(totalMembersInDefault);
      data["data"].map((group, index) => {
        downlineGroupList.push({ label: group["name"], value: group["id"] });
      });
      setDownlineGroups(downlineGroupList);
      setDownlineMemberData(totalMembersInEachGroup);
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
        "?keyword=" +
        e.target.value +
        "&directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  const deleteGroup = async (groupId) => {
    let params = {
      id: groupId,
    };
    let formBody = createFormBody(params);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_DOWNLINE_GROUP,
      formBody
    );

    if(responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      let afterRemoveList = downlineGroups.filter(x => x.value !== groupId);
      setDownlineGroups(afterRemoveList);
    }
  };

  async function toggleShowValue(value, index, downlineUsername, product, showStatus) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_DOWNLINE_MEMBER_GAME + "?username=" + downlineUsername + "&masterProductId=" + product + "&enable=" + !value);
    let tempProducts = username;
    let tempValue = !value;
    let newArr = tempProducts[index];
    if (tempValue === true) {
      newArr.show = true;
    }
    else {
      newArr.show = false;
    }
    tempProducts[index] = { ...newArr };
    _dispatch(setIdle());
  };

  const handleDownlineOnClick = async (downline, index, e) => {
    _dispatch(setBusy());
    e.preventDefault();
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID + "?memberId=" + downline['id']);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];

      setMember(editingDownlineData);
    }
    responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IS_MEMBER_COMM_EMPTY + "?memberId=" + downline['id']);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsCommissionEmpty(responseJson[ApiKey._API_DATA_KEY].isMemberCommEmpty);
    }
    if (displayDataIndex === index) {
      setDisplayDataIndex(-1);
    }
    else {
      setDisplayDataIndex(index);
    }
    _dispatch(setIdle());
  }

  const trailingActions = (value) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          let tempSelectedGroup = downlineGroups.filter(x => x.value === value)[0];
          setSelectedGroupToDelete(tempSelectedGroup);
          setConfirmDelete(true);
        }}
      >
        <div className="swipe-delete downline-groups">
          <img
            src={require("../../assets/img/v3/delete.png")}

            alt="icon"
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <div id={_userData.selectedTheme === 0 ? "downline" : "downline-modern"}>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("DOWNLINE")}
      />
      <Nav
        className={
          "downline-nav" +
          (" modern")
        }
        tabs
      >
        <NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_TAB);
          }}
        >
          <NavLink
            className={classnames({ active: activeTab === _DOWNLINE_TAB })}
          >
            <span>{t("DOWNLINES")}</span>
          </NavLink>
        </NavItem>
        <NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_GROUPS_TAB);
          }}
        >
          <NavLink
            className={classnames({
              active: activeTab === _DOWNLINE_GROUPS_TAB,
            })}
          >
            <span>{t("DOWNLINE_GROUPS")}</span>
          </NavLink>
        </NavItem>
        {/*<NavItem
          onClick={() => {
            toggleTab(_DOWNLINE_GAMES_TAB);
          }}
        >
          <NavLink
            className={classnames({
              active: activeTab === _DOWNLINE_GAMES_TAB,
            })}
          >
            <span>{t("DOWNLINE_GAMES")}</span>
          </NavLink>
        </NavItem>*/}
      </Nav>
      <div className="hr-left mt-3 mb-3"></div>
      <TabContent
        style={{ backgroundColor: "transparent" }}
        className="downline-tab-content"
        activeTab={activeTab}
      >
        <TabPane tabId={_DOWNLINE_TAB}>
          <div className="mt-3 pb-5">
            <div className="downline-stat-wrapper">
              <div>
                <div className="card-brand card-direct-downline text-center text-bold">
                  <h1 className="title">
                    {memberData["totalDirectDownlines"]}
                  </h1>
                  <div className="mt-2">{t("DIRECT_DOWNLINE")}</div>
                </div>
              </div>
              <div className="second">
                <div className="card-brand text-center text-bold">
                  <span className="color-yellow">{memberData["totalNewDownlineToday"]}</span>&nbsp; {t("NEW_MEMBER_TODAY")}
                </div>
                <div className="card-brand mb-0 text-center text-bold">
                  <div className="account-stat-wrapper">
                    <div>
                      {t("TOTAL")} &nbsp;{" "}
                      <span>{memberData["totalDownlines"]}</span>
                    </div>
                    <div>|</div>
                    <div>
                      {t("LEVEL")} &nbsp;{" "}
                      <span>{memberData["maxDownlineLevel"]}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-right mt-3 mb-3"></div>
            <div className="search-input-box">
              <Input
                placeholder={t("ALIAS_NAME_PHONE")}
                defaultValue={_savedData}
                onInput={(e) => {
                  getText(e);
                }}
                inputGroupType="append"
                inputGroupContent={
                  <img src={require("../../assets/img/v3/search-blue.png")} alt="search" />
                }
              />
            </div>
            <div className="hr-left mt-3 mb-3"></div>
            <p
              className={
                "f-w-800 text-brand" +
                (" modern")
              }
            >
              {t("DOWNLINES")}
            </p>
            <Accordion className="downline-accordion">
              <ul className="ul-news-list custom-dynamic-table-container" style={{ height: "unset" }}>
                <DynamicLoadTable
                  pageSize={_savedPageSize}
                  apiQuery={_savedQuery}
                  searchEnabled={true}
                  autoscrollEnabled={true}
                  render={(downline, i) => {
                    return (<>
                      <Accordion.Item
                        key={i}
                        eventKey={i}
                      >
                        <Accordion.Header
                          onClick={(e) => {
                            handleDownlineOnClick(downline, i, e);
                          }}
                        >
                          <div style={{ fontSize: "15px", textAlign: "left" }}>
                            <strong className="d-block">
                              {downline["username"]} {!stringIsNullOrEmpty(downline["fullname"]) ? " - " + downline["fullname"] : ""} {downline["nickname"] != "" && <small className="d-block">({downline["nickname"]})</small>}
                            </strong>
                            <span style={{ fontSize: "small" }}>
                              {downline["phoneNumber"]}
                            </span>
                          </div>
                          <i className={"fas fa-chevron-" + (displayDataIndex === i ? "down" : "right")}></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="downline-data-wrapper">
                            <div className="downline-data downline-date">
                              <div className="data-left">{t("CREATED_DATE")}</div>
                              <div className="data-right">{downline.createdDate}</div>
                            </div>
                            <div className="downline-data downline-comm-rate">
                              <div className="data-left">{t("COMM_RATE")}</div>
                              {isCommissionEmpty ?
                                (<div className="data-right"> - </div>)
                                :
                                (<div className="data-right color-red" onClick={() => _history.push({ pathname: WebUrl._URL_PRODUCT_COMM_RATE, state: { id: downline.id, username: downline.username } })}>{t("SEE_MORE")}</div>)
                              }                            </div>
                            <div className="downline-data downline-loyalty-points">
                              <div className="data-left">{t("POINTS")}</div>
                              <div className="data-right">{numberWithCurrencyFormat(downline.loyaltyPoints, 2, true)}</div>
                            </div>
                            <div className="downline-data downline-direct-downlines">
                              <div className="data-left">{t("DIRECT_DOWNLINES")}</div>
                              <div className="data-right">{member.totalDirectDownlines}</div>
                            </div>
                            <div className="downline-data downline-total-downlines">
                              <div className="data-left">{t("TOTAL_DOWNLINES")}</div>
                              <div className="data-right">{member.totalDownlines}</div>
                            </div>
                            <div className="downline-data downline-total-downlines">
                              <div className="data-left">{t("LAST_LOGIN")}</div>
                              <div className="data-right">{!stringIsNullOrEmpty(member.lastLogin) ? moment(member.lastLogin).format("YYYY-MM-DD HH:mm:ss") : t("NULL")}</div>
                            </div>
                            <div
                              className="edit-downline-btn"
                              onClick={() => _history.push(
                                {
                                  pathname: WebUrl._URL_EDIT_DOWNLINE,
                                  state: { id: downline.id }
                                }
                              )}
                            >
                              {t("EDIT_DOWNLINE_DETAILS")}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </>);
                  }}
                />
              </ul>
            </Accordion>
          </div>
        </TabPane>
        <TabPane tabId={_DOWNLINE_GROUPS_TAB}>
          <ul className="ul-news-list custom-dynamic-table-container">
            {downlineGroups.map((downlineGroup, index) => {
              return (
                downlineGroup.label !== "Default" ? (
                  <SwipeableList
                    fullSwipe={true}
                    type={ListType.IOS}>
                    <SwipeableListItem
                      trailingActions={trailingActions(downlineGroup.value)}
                      threshold={0.25}
                    >
                      <Link>
                        <button
                          style={{ whiteSpace: "nowrap" }}
                          className="btn-delete"
                          onClick={() => { 
                            let tempSelectedGroup = downlineGroups.filter(x => x.value === downlineGroup.value)[0];
                            setSelectedGroupToDelete(tempSelectedGroup);
                            setConfirmDelete(true);
                          }}
                        >
                          <VIcon
                            icon={themeIcons.delete}
                            className="theme-icon-size-15"
                          />
                        </button>
                      </Link>  
                      <div
                        className="align-items-center"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >

                        {downlineGroup.label}


                        <div className="font14">
                          {" (" +
                            t("TOTAL_MEMBER") +
                            ": " +
                            (downlineGroup.label !== "Default"
                              ? downlineMemberData[index]
                              : totalUngroupedMembers) +
                            ")"}
                        </div>

                      </div>
                      {downlineGroup.label !== "Default" &&
                        downlineMemberData[index] !== 0 ?
                        <Link
                          to={{
                            pathname: WebUrl._URL_DOWNLINE_PRODUCT_RATE_DETAIL,
                            state: { id: downlineGroup.value },
                          }}
                        >
                          <button
                            style={{ whiteSpace: "nowrap" }}
                            className="btn btn-block btn-brand"
                          >
                            {t("EDIT_%")}
                          </button>
                        </Link>
                        :
                        <Link>
                          <button
                            style={{ whiteSpace: "nowrap" }}
                            className="btn btn-block btn-brand"
                            onClick={() => _dispatch(showResponseMessage(false, t("NO_DOWNLINE_IN_GROUP")))}
                          >
                            {t("EDIT_%")}
                          </button>
                        </Link>     
                      }   
                      <Link
                        to={{
                          pathname: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                          state: { id: downlineGroup.value },
                        }}
                      >
                        <i
                        className="fas fa-chevron-right ml-4 downline-arrow-text"
                        ></i>
                      </Link>
                    </SwipeableListItem>
                  </SwipeableList>
                ) : (
                  <li key={index}>
                    <div
                      className="align-items-center"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >

                      {downlineGroup.label}


                      <div className="font14">
                        {" (" +
                          t("TOTAL_MEMBER") +
                          ": " +
                          (downlineGroup.label !== "Default"
                            ? downlineMemberData[index]
                            : totalUngroupedMembers) +
                          ")"}
                      </div>

                    </div>
                    {downlineGroup.label === "Default" &&
                      totalUngroupedMembers !== 0 ?
                      <Link
                        to={{
                          pathname: WebUrl._URL_DOWNLINE_PRODUCT_RATE_DETAIL,
                          state: { id: downlineGroup.value },
                        }}
                      >
                        <button
                          style={{ whiteSpace: "nowrap" }}
                          className="btn btn-block btn-brand"
                        >
                          {t("EDIT_%")}
                        </button>
                      </Link>
                      :
                      <Link>
                        <button
                          style={{ whiteSpace: "nowrap" }}
                          className="btn btn-block btn-brand"
                          onClick={() => _dispatch(showResponseMessage(false, t("NO_DOWNLINE_IN_GROUP")))}
                        >
                          {t("EDIT_%")}
                        </button>
                      </Link>
                    }
                    <Link
                      to={{
                        pathname: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                        state: { id: downlineGroup.value },
                      }}
                    >
                      <i
                      className="fas fa-chevron-right ml-4 downline-arrow-text"
                      ></i>
                      </Link>
                  </li>
                )
              );
            })}
          </ul>
          <div
            className="content content-full-width"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "0",
              width: "100%",
              zIndex: "999"
            }}
          >
            <div className="container">
              <button
                type="button"
                className="btn custom-btn w-100"
                onClick={() => {
                  _history.push({
                    pathname: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
                  });
                }}
              >
                {t("CREATE")}
              </button>
            </div>
          </div>
          <SweetAlert
            show={confirmDelete}
            custom={true}
            customIcon={require("../../assets/img/sweetalert_warning.png")}
            showCancel
            title={t("DELETE_GROUP") + selectedGroupToDelete.label + "?"}
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONFIRM")}
            cancelBtnCssClass="custom-cancel-btn"
            confirmBtnCssClass="custom-btn"
            confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
            onConfirm={() => {
              deleteGroup(selectedGroupToDelete.value);
              setConfirmDelete(false);
            }}
            onCancel={() => {
              setConfirmDelete(false);
            }}
          />
        </TabPane>
        <TabPane tabId={_DOWNLINE_GAMES_TAB}>
          <div
            className={
              "form-group m-b-0" +
              (_userData.selectedTheme === 0
                ? " downline-games-classic"
                : " downline-games-modern")
            }
          >
            <div className="form-group m-b-15">
              <label className="col-form-label">
                <b>{t("Games")}</b>
              </label>
              <div>
                <Controller
                  control={control}
                  name="productId"
                  defaultValue=""
                  render={({ onChange, value }) => {
                    return (
                      <StyledSelect
                        options={productOption}
                        placeholder={t("PLEASE_SELECT_GAME")}
                        value={value}
                        onChange={(e) => {
                          onChange(e.value);
                          getDownlineGames(e.value);
                        }}
                      />
                    );
                  }}
                  rules={{ required: "PLEASE_SELECT_BANK" }}
                />
                {errors.bankId && (
                  <div className="invalid-feedback">
                    {t(errors.bankId.message)}
                  </div>
                )}
              </div>
            </div>
            {username.map((u, index) => {
              return (
                <div style={{ color: "white" }}>

                  <table className="edit-downline-table">
                    <thead></thead>
                    <tbody>
                      <tr
                        className={
                          "data-row" +
                          ((index + 1) % 2 !== 0
                            ? " bg-color-1" +
                            (_userData.selectedTheme === 0
                              ? " classic"
                              : " modern")
                            : "")
                        }
                      >
                        <td
                          className="row-data-1"
                          style={{ color: "white" }}
                        >
                          <b>{u.username}</b>
                        </td>
                        <td className="row-data-2"></td>
                        <td className="row-data-3"></td>
                        <td className="row-data-4">
                          <ToggleButton
                            value={u.show === true ? true : false}
                            onToggle={(value) => {
                              toggleShowValue(value, index, u.username, u.product, u.show);
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            }
            )}
          </div>
        </TabPane>
      </TabContent>

      <Modal
        isOpen={isModalVisible}
        toggle={() => {
          setIsModalVisible(!isModalVisible);
        }}
        contentClassName="downline-detail modal-bottom"
        centered={true}
        fade={false}
      >
        <ModalHeader
          toggle={() => {
            setIsModalVisible(!isModalVisible);
          }}
        >
          {t("PRODUCT_COMM_RATE")}
        </ModalHeader>
        <ModalBody className="p-0">
          {products
            .filter((x) => !x["useGroupRate"])
            .map((product, index) => {
              return (
                <div key={index}>
                  <table className="table table-borderless table-description m-15 notGroupRate">
                    <tbody>
                      <tr>
                        <th width="50%">{t("PRODUCT_NAME")}</th>
                        <td>{product["productName"]}</td>
                      </tr>
                      <tr>
                        <th>{t("BONUS_RATE")} (%)</th>
                        <td>
                          {numberWithCurrencyFormat(product["bonus"], 2)}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("COMM_RATE")} (%)</th>
                        <td>
                          {numberWithCurrencyFormat(product["commission"], 2)}
                        </td>
                      </tr>
                      {product.productName === "D2 Poker" && (
                        <tr>
                          <th>{t("CLUBFEE_RATE")} (%)</th>
                          <td>
                            {numberWithCurrencyFormat(product["clubFee"], 2)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {index + 1 < products["length"] && <hr />}
                </div>
              );
            })}
          {products.filter((x) => x["useGroupRate"])["length"] > 0 && (
            <div className="row groupRates">
              <div style={{ width: "100%" }}>
                <div>
                  <label className="col-6 col-form-label">
                    {t("PRODUCT_NAME")}
                  </label>
                  <span>{t("SLOT_GAMES")}</span>
                </div>
                <div>
                  <label className="col-6 col-form-label">
                    {t("COMM_RATE")} (%)
                  </label>
                  <span>
                    {
                      products.filter((x) => x["useGroupRate"])[0][
                      "commission"
                      ]
                    }
                  </span>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Downline;
