import React, { useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { numberWithCurrencyFormat } from "../../util/Util";

/// <summary>
/// Author : Wong
/// </summary>
const Numpad = (p) => {

    const props = { ...p }
    const { t } = useTranslation();
    const [currentInput, setCurrentInput] = useState();
    const [inputValue, setInputValue] = useState('0.00');
    const keyboard = useRef();

    /// <summary>
    /// Author : Wong
    /// </summary>
    useEffect(() => {
        setCurrentInput(props.currentInput);
        if (props.currentInput) {
            renderValue(props.currentInput.value);
        }
    }, [props.currentInput]);

    useEffect(() => {
        if (currentInput) {
            renderValue(currentInput.value * 1);
        }
    }, [currentInput]);

    /// <summary>
    /// Author : Wong
    /// </summary>
    function renderValue(curValue) {
        if (currentInput) {
            let renderValue = ((Math.round(parseFloat(curValue) * 1) / 1) / 1).toFixed(2);
            if (isNaN(renderValue)) {
                renderValue = '0';
            }
            setInputValue(renderValue);
            currentInput.value = renderValue;
        }
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    function onKeyPress(key) {
        if (currentInput) {

            if (!currentInput.value) {
                currentInput.value = 0;
            }
            let curValue = Math.round(parseFloat(currentInput.value) * 1, 2);

            switch (key) {
                case "{bksp}":
                    currentInput.value = '';
                    curValue = '';
                    keyboard.current.setInput('');
                    break;
                default:
                    curValue += key;
                    break;
            }

            renderValue(curValue);
        }
    }

    return (
        <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad  popup-transfer-numpad" backdrop={true} toggle={() => { setCurrentInput(''); props.setCurrentInput(''); }} isOpen={currentInput} centered>
            <ModalBody>
                <div className="display-wrapper">
                    <button type="button" style={{
                        alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
                    }} onClick={() => { setCurrentInput(''); props.setCurrentInput(''); }} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
                    <strong className="text-brand display-value">{inputValue}</strong>

                    <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("CURRENT_BALANCE")} {isNaN(parseFloat(props.currentBalance)) ? "0.00" : numberWithCurrencyFormat(parseFloat(props.currentBalance), 2, true)/*parseFloat(props.currentBalance).toFixed(3)*/}</div>

                    {props.withdrawalLimit ?
                        <div className="text-brand text-center" style={{ color: "#A9A9A9", fontSize: "10px" }}>{t("WITHDRAWAL_LIMIT")} : {numberWithCurrencyFormat(parseFloat(props.withdrawalLimitBalance), 2, true) + "/" + numberWithCurrencyFormat(parseFloat(props.withdrawalLimit), 2, true)} </div>
                        :
                        ""
                    }

                </div>
                <Keyboard
                    layout={{
                        'default': [
                            "1 2 3 4 5 6 7 8 9 0 {bksp}"
                        ]
                    }}
                    display={{
                        '{bksp}': "<i class='fas fa-backspace'></i>",
                    }}
                    theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                    keyboardRef={r => (keyboard.current = r)}
                    onKeyPress={onKeyPress}
                    disableButtonHold
                    disableCaretPositioning
                />

                <button className="btn btn-block btn-brand-gradient btn-done" onClick={() => { setCurrentInput(''); props.setCurrentInput(''); }}>{t("DONE")}</button>
            </ModalBody>
        </Modal>
    );
};

export default Numpad;