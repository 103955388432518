import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from "react-redux";
import { SessionKey } from "../../util/Constant";
import "moment/locale/ms";

const DateRangeFilter2 = (props) => {
    let _history = useHistory();
    let activeTheme = useSelector(state => state.authState.userData.selectedTheme);
    const { t, i18n } = useTranslation();
    let _locale = "";
    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [openCalendar, setOpenCalendar] = useState(false);
    const [optionClicked, setOptionClicked] = useState(false);
    const [currMinDate, setCurrMinDate] = useState(moment());
    const [currMaxDate, setCurrMaxDate] = useState(moment());
    var _dateRef = useRef(null);
    const languageCode = localStorage.getItem(SessionKey._LANGUAGE)

    switch (languageCode) {
        case "en":
            _locale = "en-gb";
            break;
        case "zh":
            _locale = "zh-cn";
            break;
        case "th":
            _locale = "th";
            break;
        case "id":
            _locale = "id";
            break;
        case "vi":
            _locale = "vi";
            break;
        case "ms":
            _locale = "ms";
            break;
        default:
            _locale = "en";
    }

    const {
        startDate = sDate,
        endDate = eDate,
        setStartDate,
        setEndDate,
        minDate,
        maxDate,
        onStartDateChange,
        onEndDateChange,
        onSave,
        options = false,
        ...rest
    } = props;
    const _PREV_DAY = "PREV";
    const _NEXT_DAY = "NEXT";

    const _DATE_SELECTIONS = [
        {
            label: t("PREV_DAY"),
            from: _PREV_DAY,
        },
        {
            label: t("TODAY"),
            from: moment(),
        },
        {
            label: t("YESTERDAY"),
            from: moment().subtract(1, 'day'),
            to: moment().subtract(1, 'day')
        },
        {
            label: t("THIS_WEEK"),
            from: moment().startOf('isoWeek'),
        },
        {
            label: t("LAST_WEEK"),
            from: moment().subtract(1, 'week').startOf('isoWeek'),
            to: moment().subtract(1, 'week').endOf('isoWeek')
        },
        {
            label: t("THIS_MONTH"),
            from: moment().startOf('month'),
        },
        {
            label: t("LAST_MONTH"),
            from: moment().startOf('month').subtract(1, 'month'),
            to: moment().startOf('month').subtract(1, 'month').endOf('month')
        },
        {
            label: t("NEXT_DAY"),
            from: _NEXT_DAY
        },
    ];

    useEffect(() => {

        let tempMinDate = props.minDate;
        let tempMaxDate = props.maxDate;
        if (props.minDate === undefined) {
            tempMinDate = moment().subtract(2, 'months');
        }
        if (props.maxDate === undefined) {
            tempMaxDate = moment();
        }
        setCurrMinDate(tempMinDate);
        setCurrMaxDate(tempMaxDate);
        let startDateMoment;
        let endDateMoment;
        if (selectedOption.from == _PREV_DAY) {
            startDateMoment = moment(startDate).subtract(1, 'day');
            endDateMoment = moment(startDate).subtract(1, 'day');
        } else if (selectedOption.from == _NEXT_DAY) {
            startDateMoment = moment(startDate).add(1, 'day');
            endDateMoment = moment(startDate).add(1, 'day');
        } else {
            startDateMoment = selectedOption.from ? selectedOption.from : moment();
            endDateMoment = selectedOption.to ? selectedOption.to : moment();
        }

        if (startDate === undefined || optionClicked) {
            setSDate(startDateMoment);
            if (typeof (setStartDate) !== "undefined") {
                props.setStartDate(startDateMoment);
            }
        }
        else
            setSDate(startDate);
        if (endDate === undefined || optionClicked) {
            setEDate(endDateMoment);
            if (typeof (setEndDate) !== "undefined") {
                props.setEndDate(endDateMoment);
            }
        }
        else
            setEDate(endDate);

        setOptionClicked(false);
    }, [selectedOption]);

    useEffect(() => {
        if (openCalendar) {
            _dateRef.current.openCalendar();
        }
    }, [openCalendar])

    function handleStartDate(date) {
        setSDate(date);
        console.log(date)
        props.setStartDate(date);
        setOpenCalendar(true);
        setSelectedOption({});
        setEDate(moment(date).add(1, "day"));
        props.setEndDate(moment(date).add(1, "day"));
        if (onStartDateChange) {
            onStartDateChange(date);
        }
    }

    function handleEndDate(date) {
        setEDate(date);
        props.setEndDate(date);
        if (onEndDateChange) {
            onEndDateChange(date);
        }
    }

    function handleSave() {
        if (onSave) {
            onSave(sDate, eDate);
        }
    }
    var sDateValid = function (current) {
        return current.isSameOrAfter(currMinDate) && current.isSameOrBefore(currMaxDate);
    }

    var eDateValid = function (current) {
        return current.isSameOrAfter(moment(sDate).startOf('day')) && current.isSameOrBefore(currMaxDate);
    }
    return (
        <div id={activeTheme === 0 ? "date-range-filter" : "date-range-filter-modern"}>
            <div className="custom-date-filter-container">
                <div className="first">
                    <div>
                        <DateTime
                            inputProps={{ className: 'form-control leftAlignPicker', readOnly: true }}
                            name="startDate"
                            dateFormat="YYYY-MM-DD"
                            closeOnSelect={true}
                            value={sDate}
                            isValidDate={sDateValid}
                            timeFormat={false}
                            onChange={handleStartDate}
                            locale={_locale} />
                    </div>
                    <div className="cdf-divider"></div>
                    <div>
                        <DateTime
                            inputProps={{ className: 'form-control', readOnly: true }}
                            name="endDate"
                            dateFormat="YYYY-MM-DD"
                            closeOnSelect={true}
                            timeFormat={false}
                            value={eDate}
                            isValidDate={eDateValid}
                            ref={_dateRef}
                            onChange={handleEndDate}
                            locale={_locale} />
                    </div>
                </div>
                <div className="second" onClick={() => { handleSave() }}>
                    <img
                        src={require("../../assets/img/v3/search-blue.png")}
                        alt="search"
                    />
                </div>
            </div>
            {/* <div className="filter-grid-wrapper" style={{ "marginBottom": "0.5em" }}>
        <DateTime
          inputProps={{ className: 'form-control leftAlignPicker', readOnly: true }}
          name="startDate"
          dateFormat="YYYY-MM-DD"
          closeOnSelect={true}
          value={sDate}
          isValidDate={sDateValid}
          timeFormat={false}
          onChange={handleStartDate} />
        <span className="text">{t("TO")}</span>
        <DateTime
          inputProps={{ className: 'form-control', readOnly: true }}
          name="endDate"
          dateFormat="YYYY-MM-DD"
          closeOnSelect={true}
          timeFormat={false}
          value={eDate}
          isValidDate={eDateValid}
          ref={_dateRef}
          onChange={handleEndDate} />
        <button className="btn btn-brand text-nowrap" onClick={() => { handleSave() }}>{t("SEARCH")}</button>
      </div> */}

            {
                props.options &&
                <>
                    <div className="date-filter-container mt-4">
                        {_DATE_SELECTIONS.map((selection) => {
                            return (
                                <div
                                    key={selection.label}
                                    onClick={() => { setOptionClicked(true); setSelectedOption(selection) }}
                                    className={classNames("date-filter-item", { active: (selection.from !== _PREV_DAY && selection.from !== _NEXT_DAY && selectedOption.label == selection.label) })}
                                >
                                    <div>{selection.label}</div>
                                </div>
                            );
                        })
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default DateRangeFilter2;