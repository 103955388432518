import React, { useState, useEffect, useRef } from "react";
import PinInput from "react-pin-input";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  SessionKey,
  WebUrl,
  DisplayDevice,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showMessage,
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { isEmpty } from "lodash";
import { saveLanguageId } from "../../redux/LanguageAction";
import { performLogout } from "../../redux/AuthAction";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  Alert,
} from "reactstrap";
import { checkIsLoggedIn } from "../../redux/AuthAction";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
  createFormBody,
} from "../../util/Util";
import Spinner from "react-bootstrap/Spinner";
import classnames from "classnames";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useForm } from "react-hook-form";
import Numpad from "../../components/custom/Numpad";
import moment from "moment";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import i8Icon from "../../assets/img/icon/i8-logo_backup.png";
import vidTutorialIcon from "../../assets/img/icon/vid-tutorial-icon.svg";
import reloadPasswordIcon from "../../assets/img/icon/reset_password.svg";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import Draggable from 'react-draggable';

/// <summary>
/// Author: -
/// </summary>
const Dashboard = (props) => {
  var { t, i18n } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  const [memberData, setMemberData] = useState({});
  const [kpiData, setKpiData] = useState({});
  const [isLobbyModalVisible, setIsLobbyModalVisible] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [games, setGames] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [walletDetails, setWalletDetails] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [loadingWalletFlag, setLoadingWalletFlag] = useState(true);
  const [loadingMemberFlag, setLoadingMemberFlag] = useState(true);
  const [loadingLogoutFlag, setLoadingLogoutFlag] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showCreateShareholderPin, setShowCreateShareholderPin] =
    useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const [pinNumberFinal, setPinNumberFinal] = useState("");
  const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
  const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
  const [forgetPin, setForgetPin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [showProductPasswordAlert, setShowProductPasswordAlert] =
    useState(false);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [smsSent, setSmsSent] = useState(false);
  const [unclaimComm, setUnclaimComm] = useState("");
  const [imageTopRight, setImageTopRight] = useState();
  const [imageBottomLeft, setImageBottomLeft] = useState();
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  //const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [mpData, setMpData] = useState([]);
  const [isApp, setIsApp] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [clubFee, setClubFee] = useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [conversionRate, setConversionRate] = useState(0);

  let usernameRef = useRef();
  let passwordRef = useRef();

  const _COUNTDOWN_PERIOD = 120;
  const _SECONDS_IN_MS = 1000;
  const _ALERT_TIMEOUT = 3000;
  const [countdownPeriod, setCountdownPeriod] = useState(0);

  const elePin = useRef(null);
  const eleConfirm = useRef(null);
  const eleFinalPin = useRef(null);
  const keyboard = useRef();

  const [openMiniGameModal, setOpenMiniGameModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState("COINMINI");
  const [coinMiniGameUrl, setCoinMiniGameUrl] = useState("");
  const [kenoMiniGameUrl, setKenoMiniGameUrl] = useState("");
  const [taiXiuMiniGameUrl, setTaiXiuMiniGameUrl] = useState("");
  const [miniGamesFirstClicked, setMiniGamesFirstClicked] = useState(["COINMINI"]);
  const [showMiniGameTrigger, setShowMiniGameTrigger] = useState(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
  const [controlledPosition, setControlledPosition] = useState({});
  const [startGameTime, setStartGameTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [games93Connect, setGames93Connect] = useState({});
  const delayTime = 4000;

  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    setControlledPosition({ x, y });
  }

  const toggle = tab => {
    if (currentActiveTab !== tab) {
      if (startGameTime != "") {
        const withinSecond = new Date().getTime() - startGameTime < delayTime;
        if (miniGamesFirstClicked.indexOf(tab) == -1) {
          if (!withinSecond) {
            setCurrentActiveTab(tab);
            setIsLoading(true);
            startMiniGame(tab, 'd');
            setTimeout(function () {
              setMiniGamesFirstClicked([...miniGamesFirstClicked, tab]);
              setIsLoading(false);
            }, delayTime);
          }
        }
        else if (miniGamesFirstClicked.indexOf(currentActiveTab) != -1) {
          setCurrentActiveTab(tab);
        }
      }
    }
  }
  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const _CLOSE_TAB_KEY = "CLOSE_TAB";
  const _USER_DATA = useSelector((s) => s.authState.userData);

  const _MENU_SELECTIONS = [];

  !window.ReactNativeWebView &&
    _MENU_SELECTIONS.push({
      name: "RELOAD",
      imageUrl: require("../../assets/img/icon/reload-icon.svg"),
      redirectUrl: WebUrl._URL_RELOAD_TRANSACTION,
    });

  _MENU_SELECTIONS.push(
    {
      name: "TRANSFER",
      imageUrl: require("../../assets/img/icon/transfer-icon.svg"),
      redirectUrl: WebUrl._URL_TRANSACTION,
    },
    {
      name: "GAMES",
      imageUrl: require("../../assets/img/icon/game.svg"),
    },
    {
      name: "REPORTS",
      imageUrl: require("../../assets/img/icon/reports-icon.svg"),
      redirectUrl: WebUrl._URL_REPORTS,
    },
    {
      name: "4D_RESULT",
      imageUrl: require("../../assets/img/icon/4d-result-icon.svg"),
      redirectUrl: WebUrl._URL_GAME_RESULT,
      customStyle: { marginTop: "-0.3rem", marginRight: "-0.3rem" },
    },
    {
      name: "DOWNLINE",
      imageUrl: require("../../assets/img/icon/downlines-icon.svg"),
      redirectUrl: WebUrl._URL_DOWNLINE,
    },
    {
      name: "REFERRAL",
      imageUrl: require("../../assets/img/icon/referral-icon.svg"),
      redirectUrl: WebUrl._URL_MANAGE_REFERRAL_CODES,
    }
  );

  !window.ReactNativeWebView &&
    _MENU_SELECTIONS.push({
      name: "PROMOTION",
      imageUrl: require("../../assets/img/icon/Dashboard_Reward.svg"),
      redirectUrl: WebUrl._URL_REWARDS,
    });

  _MENU_SELECTIONS.push({
    name: "SETTINGS",
    imageUrl: require("../../assets/img/icon/settings-icon.svg"),
    redirectUrl: WebUrl._URL_SETTINGS,
  });

  memberData["isShareHolder"] &&
    _MENU_SELECTIONS.push({
      name: "VIP_EVENT",
      imageUrl: require("../../assets/img/icon/star.svg"),
      redirectUrl: WebUrl._URL_SHAREHOLDER,
    });

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function submitPin(pin, conf) {
    _dispatch(setBusy());
    let params = {
      pinNumber: pin,
      confirmPinNumber: conf,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowCreateShareholderPin(false);
      setInvalidPin(false);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    } else {
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      setInvalidPin(true);
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
      elePin["current"].focus();
    }
    _dispatch(setIdle());
  }

  const verifyPin = async (pin) => {
    let params = {
      pinNumber: pin,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setInvalidPin(false);
      _history.push(
        WebUrl._URL_SHAREHOLDER +
        "?GAzobE73Vse4nr8WpufA=" +
        memberData["hideSummary"]
      );
    } else {
      setInvalidPin(true);
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(0);

    }
  };

  /// <summary>
  /// Author: Wong
  /// </summary>
  useEffect(() => {
  }, [showMiniGameTrigger]);

  /// <summary>
  /// Author: Wong
  /// </summary>
  useEffect(() => {
    if (_USER_DATA.selectedTheme === 0) {
      init();
    } else if (_USER_DATA.selectedTheme === 1) {
      _dispatch(setBusy());
      _dispatch(showResponseMessage(false, t("REDIRECTING")));
      window.location.replace(WebUrl._URL_DASHBOARD_V2);
    }

    if (_USER_DATA.recentlyResetPassword) {
      setChangePasswordModal(true);
    }

    window.addEventListener("message", function (event) {
      if (event.data == _CLOSE_TAB_KEY) {
        event.source.close();
      }
    });
  }, []);

  useEffect(() => {
    ManageBanner();
    fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY].filter(
            (x) => x.originalTitle !== "All"
          );
          setGameCategories(data);
          setActiveTab(data[0]["id"]);
        }
      });
  }, []);

  function ManageBanner(isRead) {
    let apiUrl = ApiUrl._API_MANAGE_BANNER_NOTIFICATION;
    if (typeof isRead != "undefined") {
      apiUrl += "?isRead=" + isRead;
    }
    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          if (data.filter((x) => x["isDisplay"] === true).length > 0) {
            setShowBanner(true);
          } else {
            setShowBanner(false);
          }
        }
      });
  }

  useEffect(() => {
    fetch(ApiUrl._API_GET_USER_BANNER + "?page=" + window.location.pathname, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY].filter(x => x.displayDevice === DisplayDevice._MOBILE_POPOUT);
          setBannerData(data);
        }
      });
  }, []);

  useEffect(() => {
    if (walletDetails === true) {
      setLoadingWalletFlag(true);

      ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE).then(
        (responseJson) => {
          if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
              showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
          } else {
            setWallets(responseJson[ApiKey._API_DATA_KEY]);
            setLoadingWalletFlag(false);
          }
        }
      );
    }
  }, [walletDetails]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === true) {
      localStorage.setItem(SessionKey._ISONLOAD, false);
    }
    else if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === false) {
      localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
      setShowMiniGameTrigger(true);
    }
  }, []);

  window.onbeforeunload = function () {
    localStorage.setItem(SessionKey._ISONLOAD, true);
  };

  async function init() {
    let member = [];
    try {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
      );
      setLoadingMemberFlag(false);
      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      member = responseJson[ApiKey._API_DATA_KEY];
      if (member["selectedTheme"] === 1) {
        _dispatch(setBusy());
        _dispatch(showResponseMessage(false, t("REDIRECTING")));
        window.location.replace(WebUrl._URL_DASHBOARD_V2);
        return;
      }
      changeLanguage(member["languageCode"]);
      setMemberMainWalletBalance(
        isNaN(parseFloat(member["primaryCredit"]), 3)
          ? "0.00"
          : numberWithCurrencyFormat(
            parseFloat(member["primaryCredit"]),
            3,
            true
          )
      );

      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_CATEGORY_GROUP_GAME_DASHBOARD +
        "?languageId=" +
        member["languageSetting"]
      ); // + "?languageId=" + member["languageSetting"]
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setGames(responseJson[ApiKey._API_DATA_KEY]);

        /// preload
        responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SUMMARY_KPI);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setKpiData(responseJson[ApiKey._API_DATA_KEY]);
        setTimeout(() => {
          setLoadingLogoutFlag(true);
        }, 1500);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      let params = {};
      if (member["isShareHolder"] === true) {
        responseJson = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          setShowCreateShareholderPin(true);
          if (
            elePin["current"]["values"][0] == "" ||
            elePin["current"]["values"][0] == undefined
          ) {
            elePin["current"]["elements"][0].focus();
          }
        }
      }

      /*var responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTACT_US);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setContactUsData(responseJson[ApiKey._API_DATA_KEY]);
            }*/
      getUnclaimCommHistory();
      _dispatch(checkIsLoggedIn());
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    } finally {
      _dispatch(setIdle());
    }
  }

  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  function onCreatePinKeyPress(key) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (key !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        elePin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumber(pinStr);
        if (currentIndex >= 0 && currentIndex < 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        eleConfirm["current"]["elements"][currentIndex].state.value = key;
        pinConfirmStr += key;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex <= 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          submitPin(pinNumber, pinConfirmStr);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  function onVerifyPinKeyPress(key) {
    let currentIndex = 0;
    let pinStr = enteredPinNumberFinal;
    if (key !== "{bksp}") {
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        eleFinalPin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin["current"]["elements"][currentIndex].focus();
        } else {
          verifyPin(pinStr);
        }
      }
    } else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin["current"]["elements"][currentIndex].focus();
    }
  }

  /// <summary>
  /// Author:
  /// </summary>
  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "true",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(false);
          setSmsSent(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        }
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  //function setTransfertoToption(fromWallet) {
  //    if (isTransferBackToMainWallet) {
  //        let tempToOptions = [];
  //        if (fromWallet !== _MAIN_WALLET_ID) {
  //            setShowTaxWarning(true);
  //            fromOptions.map((fromOption, index) => {
  //                if (fromOption.value === _MAIN_WALLET_ID) {
  //                    tempToOptions.push(fromOption);
  //                }
  //            });
  //        }
  //        else {
  //            setShowTaxWarning(false);
  //            fromOptions.map((fromOption, index) => {
  //                if (fromOption.value !== fromWallet) {
  //                    tempToOptions.push(fromOption);
  //                }
  //            });
  //        }
  //        setToOptions(tempToOptions);
  //    }
  //}

  //async function checkIfNonSeamless(gameId, masterProductId) {
  //    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_NON_SEAMLESS + "?id=" + gameId);
  //    if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY] !== null) {
  //        let responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
  //        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //            setPopupBannerImage(responseJson[ApiKey._API_DATA_KEY]["bannerImage"]);
  //            setBonus(responseJson[ApiKey._API_DATA_KEY]["bonus"]);
  //            setCommission(responseJson[ApiKey._API_DATA_KEY]["commission"]);
  //            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

  //            let tempFromOptions = [];
  //            let tempToOptions = [];

  //            responseJson[ApiKey._API_DATA_KEY].map((product) => {
  //                let option = { label: product.productName + "   " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
  //                if (product.productId === _MAIN_WALLET_ID) {
  //                    tempFromOptions.push(option);
  //                } else if (product.productId === masterProductId) {
  //                    setGameWalletBalanceBeforeTransfer(product.balance);
  //                    tempToOptions.push(option);
  //                }
  //            })
  //            setFromOptions(tempFromOptions);
  //            setToOptions(tempToOptions);
  //        }
  //        setShowPregamePopup(true);
  //    }
  //    else {
  //        window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId);
  //    }
  //}

  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          setMemberMainWalletBalance(
            isNaN(parseFloat(product.balance), 3)
              ? "0.00"
              : numberWithCurrencyFormat(parseFloat(product.balance), 3, true)
          );
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = parseFloat(product.balance);
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          if (product.masterProductContent !== null) {
            setMasterProductContent(product.masterProductContent);
          } else {
            setMasterProductContent("");
          }
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

          var mpJson = await ApiEngine(
            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
            "?masterProductId=" +
            masterProductId
          );
          if (mpJson[ApiKey._API_SUCCESS_KEY]) {
            let data = mpJson[ApiKey._API_DATA_KEY];
            setMpData(data);

            let productName = gameName.replaceAll(' ', '');
            if (data.hasOwnProperty('conversionRate')) {
              data['conversionRate'].map((item) => {
                if (item.key.includes(productName + "ConversionRate")) {
                  setConversionRate(item.value);
                }
              })
            }

            if (window.ReactNativeWebView && isApp) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  action: "deeplink",
                  url: appUrl,
                  deeplink: appDeepLink
                    .replace("[username]", data["username"])
                    .replace("[password]", data["password"]),
                  packageKeyword: packageNameKeyword,
                })
              );
            }
          }
        }
        setProductGameId(gameId);
        var isNewWindowJson = await ApiEngine(
          ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
        );
        setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
        setShowPregamePopup(true);
        _dispatch(setIdle());
      } else {
        if (!window.ReactNativeWebView) {
          window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp ? "1" : "0") + "&device=m");
        } else {
          if (isNewWindow) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                  }${ApiUrl._API_START_GAME}?gameId=${gameId}`,
              })
            );
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                  }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
                selectedTheme: _USER_DATA.selectedTheme,
              })
            );
          }
        }
      }
    } else {
      if (isSeamless == false) {
        _dispatch(setBusy());
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

          var mpJson = await ApiEngine(
            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
            "?masterProductId=" +
            masterProductId
          );
          if (mpJson[ApiKey._API_SUCCESS_KEY]) {
            let data = mpJson[ApiKey._API_DATA_KEY];
            setMpData(data);

            let productName = gameName.replaceAll(' ', '');
            if (data.hasOwnProperty('conversionRate')) {
              data['conversionRate'].map((item) => {
                if (item.key.includes(productName + "ConversionRate")) {
                  setConversionRate(item.value);
                }
              })
            }
          }
        }
        setProductGameId(gameId);
        setShowPregamePopup(true);
        SetToLobbyBtn(true);
        _dispatch(setIdle());
      } else {
        _history.push(
          WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"]
        );
      }
    }
  }

  const submitPopupForm = async (data, e) => {
    _dispatch(setBusy());
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: masterProductId,
      amount: data.amount,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(responseJson[ApiKey._API_DATA_KEY].amount);
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function resetFormField() {
    setValuePopup("fromWallet", null);
    setValuePopup("toWallet", null);
    setCurrentInput();
  }

  /// <summary>
  /// Author: -
  /// </summary>
  function copyText(textType) {
    if (textType === "username") {
      console.log(usernameRef);
      usernameRef.current.select();
    } else if (textType === "password") {
      console.log(passwordRef);
      passwordRef.current.select();
    }
    document.execCommand("copy");
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function resetProductPassword() {
    let apiUrl = ""; //replace this apiUrl
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setMpData(data);
      setShowProductPasswordAlert(true);
      setTimeout(() => setShowProductPasswordAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  const changeLanguage = async (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);

    _dispatch(saveLanguageId(selectedLanguage));
  };

  async function startMiniGame(miniGameCode, device) {
    setStartGameTime("");
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_GAME_URL +
      "?miniGameCode=" + miniGameCode +
      "&device=" + device
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    else {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (miniGameCode == "COINMINI") {
        setCoinMiniGameUrl(data);
        setStartGameTime(moment());
        setTimeout(function () {
          setIsLoading(false);
        }, 3000);
      }
      else if (miniGameCode == "KENOMINI") {
        setKenoMiniGameUrl(data);
        setStartGameTime(moment());
      }
      else {
        setTaiXiuMiniGameUrl(data);
        setStartGameTime(moment());
      }
    }
  }

  return (
    <>
      <div className="content-header home-header">
        {showBanner && bannerData.length > 0 && (
          <div>
            <div
              onClick={() => ManageBanner()}
              className="banner-popup-wrapper"
            ></div>
            <i
              className="close-popup fa fa-times"
              onClick={() => ManageBanner(true)}
            />
            <div className="dashboard-carousel-wrapper">
              <ReactCarousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                indicator={true}
              >
                <CarouselIndicators
                  items={bannerData}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {bannerData.map((banner, index) => (
                  <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={index}
                  >
                    {/*{item.url = item.url.toLowerCase().indexOf("http://") == 0 || item.url.toLowerCase().indexOf("https://") == 0 ? item.url : "http://" + item.url}*/}
                    <a
                      href="#"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(banner.url);
                      }}
                    >
                      <img
                        className="carousel-img"
                        src={banner.mobileImage}
                        alt={banner.content}
                      />
                    </a>
                  </CarouselItem>
                ))}
              </ReactCarousel>
            </div>
          </div>
        )}
        <div className="top-header">
          <div style={{ display: "flex" }}>
            {memberData["newNofitication"] === true ? (
              <img
                onClick={() => {
                  _history.push(WebUrl._URL_NEWS);
                }}
                src={require("../../assets/img/icon/notification-icon-unread.svg")}
                style={{ width: "22px", marginBottom: "18px" }}
              />
            ) : (
              <img
                onClick={() => {
                  _history.push(WebUrl._URL_NEWS);
                }}
                src={require("../../assets/img/icon/notification-icon.svg")}
                style={{ width: "22px", marginBottom: "18px" }}
              />
            )}
            <img
              src={require("../../assets/img/icon/tnc-icon.svg")}
              style={{ width: "22px", margin: "0px 0px 18px 25px" }}
              onClick={() => {
                _history.push(WebUrl._URL_TNC);
              }}
            />
            <div
              className="vid-tutorial-top"
              style={{ backgroundImage: `url(${vidTutorialIcon})` }}
              onClick={() => _history.push(WebUrl._URL_VIDEO_TUTORIAL)}
            ></div>
          </div>
          <div style={{ display: "flex" }}>
            <Link to={WebUrl._URL_CONTACT_US} className="mr-3">
              <img
                src={require("../../assets/img/icon/contact-us-icon.png")}
                style={{ width: "22px" }}
              />
              <div className="dashboard-top-right">{t("HELP")}</div>
            </Link>
            {loadingLogoutFlag == false ? (
              <div style={{ opacity: "0.3" }}>
                <img
                  src={require("../../assets/img/icon/dashboard-logout.svg")}
                />
                <div className="dashboard-top-right">{t("LOGOUT")}</div>
              </div>
            ) : (
              <div
                onClick={() => {
                  _dispatch(performLogout());
                }}
              >
                <img
                  src={require("../../assets/img/icon/dashboard-logout.svg")}
                />
                <div className="dashboard-top-right">{t("LOGOUT")}</div>
              </div>
            )}
          </div>
        </div>
        <div className="member-card" style={{
          paddingTop: "30px"

        }}>
          <div>{t("TOTAL_AVAILABLE_BALANCE")}</div>
          <div
            className="balance-wrapper"
            onClick={() => {
              setWalletDetails(true);
            }}
          >
            <i
              className="fas fa-wallet text-yellow"
              style={{ fontSize: "1rem" }}
            ></i>
            {loadingMemberFlag == true ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ verticalAlign: "middle", marginLeft: "10px" }}
              >
                <span className="sr-only">{t("LOADING")}...</span>
              </Spinner>
            ) : (
              <strong className="member-title ml-2">
                {memberMainWalletBalance}
              </strong>
            )}
            <i
              className="fas fa-info-circle ml-2 text-yellow"
              style={{ fontSize: "1rem" }}
            ></i>
          </div>

          {loadingMemberFlag != true ? (
            <div
              className="text-yellow"
              onClick={() =>
                _history.push(WebUrl._URL_UNCLAIM_COMMISSION_HISTORY)
              }
            >
              <div>
                <img
                  src={require("../../assets/img/icon/money-bag.svg")}
                  style={{ margin: "0 10px 3px 0" }}
                />
                <strong className="mr-1">
                  {unclaimComm == ""
                    ? t("LOADING") + "... "
                    : " " +
                    numberWithCurrencyFormat(
                      parseFloat(unclaimComm.totalClaimable),
                      3
                    ) +
                    " "}
                  <i className="fa fa-chevron-right" />
                </strong>
              </div>
            </div>
          ) : (
            <div className="text-yellow">
              <strong className="mr-1">&nbsp;</strong>
            </div>
          )}
        </div>
      </div>
      {/* <div className="container">
                <div className="text-center timer-background card card-gradient" style={{ backgroundImage: `url(${Timer})`, boxShadow: "none"}} >
                    <Count />
                </div>
            </div> */}
      <div className="temp-space">
      </div>
      <div className="container" style={{ marginBottom: "90px", bottom: "35px", position: "relative", top: "20px" }}>
        <div
          className="card card-gradient card-kpi-widget"
          style={{ marginBottom: "20px" }}
        >
          <Link
            to={WebUrl._URL_KPI}
            className="d-inline-block"
            style={{ textDecoration: "none" }}
          >
            <div className="card-body p-0">
              <div className="kpi-widget-wrapper" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
                <div>
                  <div>{t("KPI")}</div>
                  <div>
                    {isObjectEmpty(kpiData) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{ verticalAlign: "middle", marginLeft: "10px" }}
                      >
                        <span className="sr-only">{t("LOADING")}...</span>
                      </Spinner>
                    ) : (
                      kpiData["month"]
                    )}
                  </div>
                </div>
                <div>
                  <div>{t("SALES")}</div>
                  <div>
                    {isObjectEmpty(kpiData) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{ verticalAlign: "middle", marginLeft: "10px" }}
                      >
                        <span className="sr-only">{t("LOADING")}...</span>
                      </Spinner>
                    ) : (
                      numberWithCurrencyFormat(
                        parseFloat(kpiData["ownSales"]),
                        3,
                        true
                      )
                    )}
                  </div>
                </div>
                <div>
                  <div>{t("BONUS")}</div>
                  <div>
                    {isObjectEmpty(kpiData) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{ verticalAlign: "middle", marginLeft: "10px" }}
                      >
                        <span className="sr-only">{t("LOADING")}...</span>
                      </Spinner>
                    ) : (
                      numberWithCurrencyFormat(
                        parseFloat(kpiData["ownBonus"]),
                        3,
                        true
                      )
                    )}
                  </div>
                </div>
                <div>
                  <div>{t("COMMISSION_SHORT")}</div>
                  <div>
                    {isObjectEmpty(kpiData) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        style={{ verticalAlign: "middle", marginLeft: "10px" }}
                      >
                        <span className="sr-only">{t("LOADING")}...</span>
                      </Spinner>
                    ) : (
                      numberWithCurrencyFormat(
                        parseFloat(kpiData["ownCommission"]),
                        3,
                        true
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {imageTopRight != null ? (
            <div
              style={{ backgroundImage: `url(${imageTopRight})` }}
              className="top-right-icon"
            >
              {" "}
            </div>
          ) : (
            ""
          )}
          {imageBottomLeft != null ? (
            <div
              style={{ backgroundImage: `url(${imageBottomLeft})` }}
              className="bottom-left-icon"
            >
              {" "}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="action-row main-menu-row" onClick={() => localStorage.setItem(SessionKey._ISONLOAD, true)}>
          {_MENU_SELECTIONS.map((menu, index) => {
            if (!stringIsNullOrEmpty(menu["redirectUrl"])) {
              if (menu["redirectUrl"] !== "/shareholder") {
                return (
                  <Link
                    key={index}
                    to={{
                      pathname: menu["redirectUrl"],
                      state: { fromDashboard: true },
                    }}
                  >
                    <div className="action-icon-container">
                      <img
                        style={{ ...menu["customStyle"] }}
                        src={menu["imageUrl"]}
                      />
                    </div>
                    <p>{t(menu["name"])}</p>
                  </Link>
                );
              } else {
                return (
                  <div onClick={() => setShowPinEntry(true)}>
                    <div className="action-icon-container">
                      <img
                        style={{ ...menu["customStyle"] }}
                        src={menu["imageUrl"]}
                      />
                    </div>
                    <p>{t(menu["name"])}</p>
                  </div>
                );
              }
            } else {
              return (
                <div key={index} onClick={() => setIsLobbyModalVisible(true)}>
                  <div className="action-icon-container">
                    <img
                      style={{ width: "30px", objectFit: "contain" }}
                      src={menu["imageUrl"]}
                    />
                  </div>
                  <p>{t(menu["name"])}</p>
                </div>
              );
            }
          })}
        </div>
        <Modal
          fade={false}
          contentClassName="modal-brand modal-bottom wallet-info"
          isOpen={walletDetails}
          toggle={() => {
            setWalletDetails(false);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setWalletDetails(false);
            }}
          >
            {t("WALLET_INFO")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "unset" }}>
            <div className="row">
              <label
                className="col-6 col-form-label"
                style={{
                  padding: "0 15px 15px 15px",
                  color: "#002e6c",
                  fontSize: "16px",
                }}
              >
                {t("CATEGORY")}
              </label>
              <label
                className="col-6 col-form-label text-right"
                style={{
                  padding: "0 15px 15px 15px",
                  color: "#002e6c",
                  fontSize: "16px",
                }}
              >
                {t("BALANCE")}
              </label>
            </div>
            <hr style={{ margin: "0" }} />
            {loadingWalletFlag && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    verticalAlign: "middle",
                    width: "4rem",
                    height: "4rem",
                  }}
                >
                  <span className="sr-only">{t("LOADING")}...</span>
                </Spinner>
              </div>
            )}

            {!loadingWalletFlag &&
              wallets
                .filter((i) => i.productId == 0)
                .map((wallet, index) => {
                  return (
                    <span key={index}>
                      <div className="row">
                        <label
                          className="text-brand col-6 col-form-label"
                          style={{ fontSize: "16px" }}
                        >
                          {wallet["productName"]}
                        </label>
                        <label
                          className="text-brand col-6 col-form-label text-right"
                          style={{ fontSize: "16px" }}
                        >
                          {numberWithCurrencyFormat(
                            parseFloat(
                              wallet["balance"] != "N/A" ? wallet["balance"] : 0
                            ),
                            3
                          )}
                        </label>
                      </div>
                    </span>
                  );
                })}
            {!loadingWalletFlag &&
              wallets
                .filter((i) => i.productId != 0)
                .map((wallet, index) => {
                  return (
                    <span key={index}>
                      <div className="row">
                        <label
                          className="text-brand col-6 col-form-label"
                          style={{ fontSize: "16px" }}
                        >
                          {wallet["productName"]}
                        </label>
                        <label
                          className="text-brand col-6 col-form-label text-right"
                          style={{ fontSize: "16px" }}
                        >
                          {numberWithCurrencyFormat(
                            parseFloat(
                              wallet["balance"] != "N/A" ? wallet["balance"] : 0
                            ),
                            3
                          )}
                        </label>
                      </div>
                    </span>
                  );
                })}
          </ModalBody>
        </Modal>
        <Modal
          fade={false}
          contentClassName="game-lobby-modal modal-brand modal-bottom"
          isOpen={isLobbyModalVisible}
          toggle={() => {
            setIsLobbyModalVisible(false);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setIsLobbyModalVisible(false);
            }}
          >
            {t("GAMES")}
          </ModalHeader>
          <ModalBody>
            <div>
              <Nav className="games-lobby">
                {gameCategories.map((category, index) => {
                  if (
                    games.filter((n) => n.categoryTitle === category.title)
                      .length > 0 /*/*||
                    category.title === "All"*/
                  ) {

                    return (
                      <NavItem
                        className={classnames({
                          active: activeTab === category.id,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === category.id,
                          })}
                          onClick={() => {
                            toggleTab(category.id);
                          }}
                        >
                          <span className="d-sm-none">{t(category.originalTitle == "Connect" ? "93 Connect" : category.title)}</span>
                          <span className="d-sm-block d-none">
                            {t(category.originalTitle == "Connect" ? "93 Connect" : category.title)}
                          </span>
                        </NavLink>
                      </NavItem>
                    );
                  }
                })}
              </Nav>
              <TabContent activeTab={activeTab}>
                {gameCategories.map((category, index) => {
                  if (
                    games.filter((n) => n.categoryTitle === category.title)
                      .length > 0 /*||
                    category.title === "All"*/
                  ) {
                    return (
                      <TabPane tabId={category.id}>
                        <div className="grid-wrapper">
                          {games.map((game, index) => {
                            if (
                              game["categoryTitle"] === category.title /*||
                              category.title === "All"*/
                            ) {
                              return (
                                <div
                                  className="game-container"
                                  key={"container-" + index}
                                >
                                  <div
                                    className={
                                      game["gameUm"] == true ? "um-wrapper" : ""
                                    }
                                  >
                                    <a
                                      key={index}
                                      onClick={() => {
                                        setPopupBannerImage(
                                          game["popupBannerImage"]
                                        );
                                        startGame(
                                          game["id"],
                                          game["hasOwnLobby"],
                                          game["masterProductId"],
                                          game["gameName"],
                                          game["masterProductIsSeamless"],
                                          game["isApp"],
                                          game["appUrl"],
                                          game["appDeepLink"],
                                          game["isNewWindow"]
                                        );
                                      }}
                                      className={
                                        game["gameUm"] == true ? "gameum" : ""
                                      }
                                    >
                                      <div
                                        className="game-info"
                                        style={{
                                          backgroundImage: !stringIsNullOrEmpty(
                                            game["image"]
                                          )
                                            ? `url('${game["image"]}')`
                                            : `url('${i8Icon}')`,
                                        }}
                                      >
                                        {game["gameUm"] == true ? (
                                          <span
                                            style={{
                                              backgroundColor: "red",
                                              width: "100%",
                                            }}
                                            className="game-category-label"
                                          >
                                            UM
                                          </span>
                                        ) : (
                                          <span className="game-category-label">
                                            {game["categoryTitle"]}
                                          </span>
                                        )}
                                      </div>
                                      <div className="game-name">
                                        {game["gameName"]}
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </TabPane>
                    );
                  }
                })}
              </TabContent>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          fade={false}
          contentClassName="modal modal-brand"
          isOpen={changePasswordModal}
          toggle={() => {
            setChangePasswordModal(false);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setChangePasswordModal(false);
            }}
          >
            {t("CHANGE_PASSWORD")}
          </ModalHeader>
          <ModalBody>
            <p>{t("CHANGE_PASSWORD_REMINDER_DESC")}</p>
            <div className="text-center">
              <a
                className="btn btn-brand-gradient mr-2"
                href={WebUrl._URL_SETTINGS}
              >
                {t("CONTINUE")}
              </a>
              <div
                className="text-brand d-inline"
                onClick={() => setChangePasswordModal(false)}
              >
                {t("DISMISS")}
              </div>
            </div>
          </ModalBody>
        </Modal>
        {showPinEntry && (
          <div>
            <div className="pin-wrapper"></div>
            <div className="pin-number">
              <button
                type="button"
                style={{
                  alignSelf: "flex-end",
                  backgroundColor: "transparent",
                  border: "none",
                  marginRight: "15px",
                  fontSize: "2rem",
                  color: "#002e6c",
                  marginTop: "-15px",
                  float: "right",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  clearInterval(false);
                  setShowPinEntry(false);
                }}
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "20px", marginLeft: "40px" }}
              >
                {t("ENTER_YOUR_PIN")}
              </strong>
              <PinInput
                style={{ marginBottom: "35px" }}
                ref={eleFinalPin}
                length={4}
                initialValue=""
                secret={true}
                focus={showPinEntry}
                autoSelect={false}
                disabled={showPinEntry}
                onChange={(value, index) => {
                  setPinNumberFinalFocused(index);
                }}
                type="numeric"
                inputMode="number"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

              <strong
                className="text-brand display-value"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setForgetPin(true);
                  sendPinCode();
                }}
              >
                {t("RESET_PIN")}
              </strong>
              {smsSent && (
                <p
                  className="text-brand"
                  style={{ textAlign: "center", marginTop: "5px" }}
                >
                  {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "}
                  {t("SECOND")}
                </p>
              )}
              {showAlert && (
                <div
                  className="content content-full-width"
                  style={{
                    position: "fixed",
                    top: "10px",
                    left: "0",
                    width: "100%",
                  }}
                >
                  <div className="container">
                    <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                  </div>
                </div>
              )}
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t(errorMessage)}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
              <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class ='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onVerifyPinKeyPress}
                disableButtonHold
                disableCaretPositioning
              />
            </div>
          </div>
        )}

        <Modal
          fade={false}
          contentClassName="modal-brand modal-bottom modal-numpad"
          isOpen={showCreateShareholderPin}
          centered
        >
          <ModalBody>
            <form>
              <div className="display-wrapper">
                <strong
                  className="reload-title text-yellow mb-3 d-block"
                  style={{ fontSize: "20px" }}
                >
                  {t("NO_SHAREHOLDER_PIN_FOUND")}
                </strong>
                <strong
                  className="reload-title mb-3 d-block"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#002e6c",
                  }}
                >
                  {t("ENTER_YOUR_PIN")}
                </strong>
                <PinInput
                  ref={elePin}
                  length={4}
                  initialValue=""
                  secret={true}
                  focus={true}
                  autoSelect={false}
                  disabled={showCreateShareholderPin}
                  type="numeric"
                  onChange={(value, index) => {
                    setPinNumberFocused(index);
                  }}
                  onComplete={(value) => adjustFocus()}
                  inputMode="numeric"
                  inputStyle={{
                    border: "0",
                    margin: "0px 10px",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "transparent",
                    borderBottom: "2px solid #002e6c",
                  }}
                  inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                />
                <strong
                  className="reload-title mb-3 d-block"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#002e6c",
                    marginTop: "30px",
                  }}
                >
                  {t("CONFIRM_YOUR_PIN")}
                </strong>
                <PinInput
                  ref={eleConfirm}
                  length={4}
                  initialValue=""
                  secret={true}
                  focus={false}
                  autoSelect={false}
                  disabled={showCreateShareholderPin}
                  type="numeric"
                  onChange={(value, index) => {
                    setPinNumberConfirmFocused(index);
                  }}
                  inputMode="numeric"
                  inputStyle={{
                    border: "0",
                    margin: "0px 10px",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "transparent",
                    borderBottom: "2px solid #002e6c",
                  }}
                  inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                />
                <div
                  className="invalid-feedback"
                  style={{ fontSize: "12px", marginTop: "1rem" }}
                >
                  {invalidPin && t(errorMessage)}
                  {!invalidPin && <span>&nbsp;</span>}
                </div>
                <Keyboard
                  layout={{
                    default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                  }}
                  display={{
                    "{bksp}": "<i class ='fas fa-backspace'></i>",
                  }}
                  theme={`keyboard keyboard-numpad`}
                  keyboardRef={(r) => (keyboard.current = r)}
                  onKeyPress={onCreatePinKeyPress}
                  disableButtonHold
                  disableCaretPositioning
                />
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          id="dashboard-pregame-popup"
          fade={false}
          contentClassName="modal-pregame-popup modal-brand modal-center"
          isOpen={showPregamePopup}
          toggle={() => {
            setShowPregamePopup(false);
            SetToLobbyBtn(false);
          }}
          centered
        >
          <div className="pregame-popup-header">
            <i
              className="fa fa-arrow-left"
              style={{ position: "absolute", left: "20px" }}
              onClick={() => {
                setShowPregamePopup(false);
                SetToLobbyBtn(false);
              }}
            />
            {selectedGameName}
          </div>
          <div
            className="pregame-popup-banner"
            style={{ backgroundImage: `url(${popupBannerImage})` }}
          ></div>
          <ModalBody className="pregame-modal-body">
            <form onSubmit={handleSubmitPopup(submitPopupForm)}>
              <div className="display-wrapper">
                <div className="wallet-info">
                  <div>
                    {fromOptions.length > 0
                      ? fromOptions[0].label +
                      " " +
                      numberWithCurrencyFormat(fromOptions[0].amount, 3)
                      : ""}
                  </div>
                  <div>
                    <hr className="vertical-line" />
                  </div>
                  <div>
                    {toOptions.length > 0
                      ? toOptions[0].label +
                      " " +
                      numberWithCurrencyFormat(toOptions[0].amount, 3)
                      : ""}
                  </div>
                </div>
                <div className="transfer-amount-wrapper">
                  <div className="transfer-input-label">
                    {t("TOTAL_TRANSFER_AMOUNT")}
                  </div>
                  <div className="transfer-input">
                    {/*<div className="currency">{"MYR"}</div>*/}
                    <input
                      type="number"
                      className="form-control transfer-amount"
                      readOnly
                      placeholder={"0.00"}
                      name="amount"
                      onClick={(e) => {
                        setCurrentInput(e.target);
                      }}
                      ref={registerPopup({
                        required: "PLEASE_ENTER_AMOUNT",
                        validate: {
                          minValue: (value) =>
                            parseFloat(value) > 0 ||
                            "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                        },
                      })}
                    />
                    {errorsPopup.amount && (
                      <div className="invalid-feedback">
                        {t(errorsPopup.amount.message)}
                      </div>
                    )}
                  </div>
                  <div className="transfer-balance">
                    <div>{selectedGameName + " " + t("NEW_BALANCE")}
                      {selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888" ?
                        <div className="reminder text-white">
                          1 THB = {conversionRate} point
                        </div>
                        : ""
                      }
                    </div>
                    <div>
                      {
                        selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888"
                          ?
                          watchPopup("amount") > 0 &&
                          numberWithCurrencyFormat(
                            parseFloat(toOptions[0].amount) +
                            parseFloat(watchPopup("amount")) * conversionRate,
                            2,
                            true
                          )
                          :
                          watchPopup("amount") > 0 &&
                          numberWithCurrencyFormat(
                            parseFloat(toOptions[0].amount) +
                            parseFloat(watchPopup("amount")),
                            2,
                            true
                          )
                      }
                      {" "}
                      {watchPopup("amount") == "" && "-"}
                    </div>
                  </div>
                </div>
                <div className="transfer-btn">
                  <button type="submit" className="btn btn-brand-gradient">
                    {t("TRANSFER")}
                  </button>
                </div>
              </div>
            </form>
            {masterProductContent.length > 1 && !stringIsNullOrEmpty(masterProductContent) && (
              <div className="masterProductContent">{masterProductContent}</div>
            )}
            {(bonus > 0 || commission > 0 || clubFee > 0) && (
              <div className="bonus-and-commission">
                {bonus > 0 && (
                  <div className="bonus">
                    {t("BONUS") +
                      " (%) : " +
                      numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                      "%"}
                  </div>
                )}
                {commission > 0 && (
                  <div className="commission">
                    {t("COMMISSION") +
                      " (%) : " +
                      numberWithCurrencyFormat(parseFloat(commission), 2, true) +
                      "%"}
                  </div>
                )}
                {clubFee > 0 && (
                  <div className="clubFee">
                    {t("CLUB_FEE") +
                      " (%) : " +
                      numberWithCurrencyFormat(parseFloat(clubFee), 2, true) +
                      "%"}
                  </div>
                )}
              </div>
            )}
            {isApp && appDeepLink === "" && (
              <>
                <div style={{ paddingBottom: "20px" }}>
                  <hr
                    style={{
                      backgroundColor: "#ffc159",
                      width: "90%",
                      padding: "0.5px 0",
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "-25px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#02285A",
                        color: "#ffc159",
                        padding: "0 25px",
                      }}
                    >
                      {selectedGameName + " " + t("ACCOUNT")}
                    </span>
                  </div>
                </div>
                <div className="username-and-password">
                  <div className="details username">
                    <div className="details-label">{t("USERNAME") + " : "}</div>
                    <input
                      type="text"
                      className="username-input"
                      value={mpData["username"]}
                      ref={usernameRef}
                      readOnly
                    />
                    <div
                      style={{ marginLeft: "10px" }}
                      onClick={() => copyText("username")}
                    >
                      <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                    </div>
                  </div>
                  <div className="details password">
                    <div className="details-label">{t("PASSWORD") + " : "}</div>
                    <input
                      type="text"
                      className="password-input"
                      value={mpData["password"]}
                      ref={passwordRef}
                      readOnly
                    />
                    <div
                      style={{ marginLeft: "10px" }}
                      onClick={() => copyText("password")}
                    >
                      <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                    </div>
                    {selectedGameName != "Mega888" && (
                      <div
                        className="refresh-password-btn"
                        style={{
                          backgroundImage: `url(${reloadPasswordIcon})`,
                        }}
                        onClick={() => resetProductPassword(masterProductId)}
                      >
                        <div className="tooltip-text">
                          <span>{t("REFRESH_PASSWORD")}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="start-btn-wrapper" style={{ width: "100%" }}>
              <div className="container">
                {!isApp || (isApp && appDeepLink != "") ? (
                  <>
                    {!toLobbyBtn ? (
                      <button
                        type="button"
                        onClick={() => {
                          if (!window.ReactNativeWebView) {
                            //if (isNewWindow === true || isApp) {
                            window.open(
                              ApiUrl._API_START_GAME +
                              "?gameId=" +
                              productGameId +
                              "&isApp=0" +
                              "&device=m"
                            );
                            /*} else {
                              _history.push({
                                pathname: WebUrl._URL_GAME_PAGE,
                                search: "?gameId=" + productGameId,
                                state: { url: window.location.pathname },
                              });
                            }*/
                          } else {
                            if (isApp) {
                              window.ReactNativeWebView.postMessage(
                                JSON.stringify({
                                  action: "deeplink",
                                  url: appUrl,
                                  deeplink: appDeepLink
                                    .replace(
                                      "[username]",
                                      mpData["username"]
                                    )
                                    .replace(
                                      "[password]",
                                      mpData["password"]
                                    ),
                                })
                              );
                            } else {
                              if (isNewWindow === true) {
                                window.ReactNativeWebView.postMessage(
                                  JSON.stringify({
                                    url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                      window.location.origin
                                      }${ApiUrl._API_START_GAME
                                      }?gameId=${productGameId}&isApp=0&device=m`,
                                  })
                                );
                              } else {
                                window.ReactNativeWebView.postMessage(
                                  JSON.stringify({
                                    url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                      window.location.origin
                                      }${WebUrl._URL_GAME_PAGE
                                      }?gameId=${productGameId}&isApp=0&device=m`,
                                    selectedTheme: _USER_DATA.selectedTheme,
                                  })
                                );
                              }
                            }
                          }
                        }}
                        className="btn pregame-popup-btn btn-block btn-brand"
                      >
                        {t("START_GAME")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          _history.push(
                            WebUrl._URL_GAME_LOBBY +
                            "?name=" +
                            selectedGameName +
                            "&id=" +
                            productGameId +
                            "&languageId=" +
                            memberData["languageSetting"]
                          );
                        }}
                        className="btn pregame-popup-btn btn-block btn-brand"
                      >
                        {t("TO_LOBBY", { productName: selectedGameName })}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({ url: appUrl })
                        );
                      } else {
                        window.open(appUrl);
                      }
                    }}
                    className="btn pregame-popup-btn btn-block btn-brand"
                  >
                    {t("DOWNLOAD_APP")}
                  </button>
                )}
              </div>
            </div>
          </ModalBody>

        </Modal>
        <BottomSuccessModal
          isOpen={isTransferSuccess}
          setIsOpen={setIsTransferSuccess}
          title={"TRANSFER_SUCCESS"}
          amount={paidAmount}
          date={transferDate}
          transactionNumber={transcactionNumber}
        />
        {(getValuesPopup("toWallet") > 0 || currentInput) && (
          <Numpad
            currentBalance={parseFloat(memberMainWalletBalance)}
            currentInput={currentInput}
            setCurrentInput={(e) => {
              setCurrentInput(e);
            }}
          />
        )}
        {showCopyAlert && (
          <div
            className="content content-full-width"
            style={{
              zIndex: "9999",
              position: "absolute",
              top: "5px",
              right: "5px",
              width: "200px",
            }}
          >
            <Alert color="green" style={{ textAlign: "center" }}>
              {t("COPY_SUCCESS")}
            </Alert>
          </div>
        )}
        {showProductPasswordAlert && (
          <div
            className="content content-full-width"
            style={{
              zIndex: "9999",
              position: "absolute",
              top: "5px",
              right: "5px",
              width: "200px",
            }}
          >
            <Alert color="green" style={{ textAlign: "center" }}>
              {t("PASSWORD_RESET_SUCCESS")}
            </Alert>
          </div>
        )}

        {openMiniGameModal && (
          <div className="cny-video">
            <div className="cny-video-box">
              <div className="mini-games-modal">
                <div className="mini-games-dialog">
                  <div className="mini-games-header">
                    <img src={require("../../assets/img/mini-games/MiniGamesHeader.png")} />
                    <button className="mini-close-button"
                      onClick={() => {
                        setOpenMiniGameModal(false);
                        setCoinMiniGameUrl("");
                        setKenoMiniGameUrl("");
                        setTaiXiuMiniGameUrl("");
                        setCurrentActiveTab("COINMINI");
                        setMiniGamesFirstClicked(["COINMINI"]);
                        setIsLoading(true);
                      }}
                    ></button>
                  </div>
                  <div className="mini-games-body">
                    <Nav tabs className="mini-games-tab">
                      <NavItem>
                        <NavLink
                          className={
                            "mini-games-items " +
                            classnames({
                              selected:
                                currentActiveTab === "KENOMINI"
                            })}
                          onClick={() => {
                            toggle("KENOMINI");
                          }}
                        >
                          <img
                            src={require("../../assets/img/mini-games/gameIcon/KenoMini-Icon.png")}
                            loading="lazy" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            "mini-games-items " +
                            classnames({
                              selected:
                                currentActiveTab === "COINMINI"
                            })}
                          onClick={() => {
                            toggle("COINMINI");
                          }}
                        >
                          <img
                            src={require("../../assets/img/mini-games/gameIcon/CoinMini.png")}
                            loading="lazy" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            "mini-games-items " +
                            classnames({
                              selected:
                                currentActiveTab === 'TAIXIUMINI'
                            })}
                          onClick={() => {
                            toggle('TAIXIUMINI');
                          }}
                        >
                          <img
                            src={require("../../assets/img/mini-games/gameIcon/TaiXiumini.png")}
                            loading="lazy" />
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={currentActiveTab}
                      className="mini-games-section"
                    >
                      <TabPane
                        tabId="KENOMINI"
                        className="mini-games-content"
                        style={currentActiveTab === 'KENOMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }
                        }>
                        <div id="KENOMINI" className="mini-games-content">
                          {
                            isLoading &&
                            <div className="loading">
                              <Spinner animation="border" role="status"></Spinner>
                            </div>
                          }
                          <iframe
                            className="iframe"
                            loading="lazy"
                            frameBorder="0"
                            width="248"
                            height="227"
                            title="Keno Mini"
                            src={kenoMiniGameUrl}
                          >
                          </iframe>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="COINMINI"
                        className="mini-games-content"
                        style={currentActiveTab === 'COINMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                        <div id="COINMINI" className="mini-games-content">
                          {
                            isLoading &&
                            <div className="loading">
                              <Spinner animation="border" role="status"></Spinner>
                            </div>
                          }
                          <iframe
                            className="iframe"
                            loading="lazy"
                            width="248"
                            height="227"
                            title="Coin Mini"
                            src={coinMiniGameUrl}
                          >
                          </iframe>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="TAIXIUMINI"
                        className="mini-games-content"
                        style={currentActiveTab === 'TAIXIUMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                        <div id="TAIXIUMINI" className="mini-games-content">
                          {
                            isLoading &&
                            <div className="loading">
                              <Spinner animation="border" role="status"></Spinner>
                            </div>
                          }
                          <iframe
                            className="iframe"
                            loading="lazy"
                            frameBorder="0"
                            width="248"
                            height="227"
                            title="TaiXiu Mini"
                            src={taiXiuMiniGameUrl}
                          >
                          </iframe>
                        </div>
                      </TabPane>
                    </TabContent>
                    <div className="more-game-img"
                      onClick={() => {
                        if (!isEmpty(games93Connect)) {
                          startGame(
                            games93Connect["id"],
                            games93Connect["hasOwnLobby"],
                            games93Connect["masterProductId"],
                            games93Connect["gameName"],
                            games93Connect["masterProductIsSeamless"],
                            games93Connect["isApp"],
                            games93Connect["appUrl"],
                            games93Connect["appDeepLink"],
                            games93Connect["isNewWindow"]
                          );
                        }
                      }}
                    >
                      <img src={require("../../assets/img/mini-games/moreGames/moreGames.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {
          showMiniGameTrigger &&
          <>
            <Draggable
              bounds={{ left: 150 - window.screen.width, top: -375, right: 0, bottom: 0 }}
              onDrag={(e, position) => {
                onControlledDrag(e, position);
                setIsDragging(true);
              }}
              onStop={() => {
                if (!isDragging) {
                  setOpenMiniGameModal(true);
                  startMiniGame(currentActiveTab, "m");
                  setGames93Connect(games.filter(x => x.gameName === "93Connect")[0]);
                }
                setIsDragging(false)
              }}
            >

              <div className="cny-pop-trigger">
                <img src={require("../../assets/img/mini-games/app_"
                  + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                    ? localStorage.getItem(SessionKey._LANGUAGE) == 'en' || localStorage.getItem(SessionKey._LANGUAGE) == 'zh' || localStorage.getItem(SessionKey._LANGUAGE) == 'ms'
                      ? localStorage.getItem(SessionKey._LANGUAGE)
                      : 'en'
                    : 'en')
                  + ".png")}
                />
              </div>
            </Draggable>

            <Draggable
              bounds="body"
              position={controlledPosition}
              onStop={() => {
                setShowMiniGameTrigger(false);
                localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
              }}
            >
              <div className="cny-pop-trigger">
                <div className={'cny-trigger-close'}>
                  <img src={require("../../assets/img/cny/Close_Icon.svg")} style={{ width: "20px" }} />
                </div>
              </div>
            </Draggable>
          </>
        }

      </div>
    </>
  );
};

export default Dashboard;
