import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl, Role, PaymentType, Status, AlertTypes, Language, LanguageOption, LanguageSelectStyle, SessionKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import Input from "../../components/custom/Input";
import PhoneNumber from "../../components/custom/PhoneNumber";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine";
import { setBusy, setIdle, showMessage, showResponseMessage } from "../../redux/AppAction";
import { performLogin } from "../../redux/AuthAction.js";
import { saveLanguageId } from "../../redux/LanguageAction";
import Icon from '@mdi/react';
import { mdiAccount, mdiLock, mdiLockCheck } from '@mdi/js';
import i8Icon from './../../assets/img/icon/i8-logo.png';

/// <summary>
/// Author :
/// </summary>
const Register = props => {
    const _DEFAULT_THRESHOLD = 5;
    const _DEFAULT_CREDIT = 0;
    const _DEFAULT_PAYMENT_MODE_ID = PaymentType._POSTPAID;
    const _MIN_PASSWORD_LENGTH = 6;
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, clearErrors, setError, setValue, getValues, watch, control, trigger, phoneNumberChk } = useForm({
        mode: "onBlur"
    });
    const [referralCode, setReferralCode] = useState("");
    const [uplineDetail, setUplineDetail] = useState({});
    const [formattedPhone, setFormattedPhone] = useState();
    const [_username, _setUsername] = useState("");
    const [agreedTnC, setAgreedTnC] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [showTnC, setShowTnC] = useState(false);
    const [showTacEntry, setShowTacEntry] = useState(false);
    const [TACBtnAble, setTACBtnAble] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [TACsent, setTACsent] = useState(false);
    const [TACtimedOut, setTACtimedOut] = useState(true);
    const [activeLanguage, setActiveLanguage] = useState("EN");
    const _TAC_VALID_TIMER = 300000;
    const _SECONDS_IN_MS = 1000;

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
    }, []);

    async function logSession(code) {
        let params = {
            "referralCode": code
        }
        let logJson = await ApiEngine.post(ApiUrl._API_ADD_OPERATION_LOG, createFormBody(params));
        if (logJson[ApiKey._API_SUCCESS_KEY]) {
            console.log("done");
        }
    }

    /// <summary>
    /// Author :
    /// </summary>
    async function checkUsername(username) {
        let avail = true;
        let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_USERNAME + "?username=" + username + "&code=" + referralCode);
        avail = responseJson[ApiKey._API_SUCCESS_KEY];
        return avail;
    }

    const validateUsername = (e) => {
        var regexp = /^[\S]*^[^ \[\]`~\/!@#$%^&*()"':;?|{}=+_<>,.\\-]*$/g;
        if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
            _setUsername(e.target.value);
        }
    }

    /// <summary>
    /// Author: 
    /// </summary>
    useEffect(() => {
        if (countdownPeriod > 0) {
            setTimeout(() => {
                let tempPeriod = countdownPeriod - 1;
                setCountdownPeriod(tempPeriod);
            }, _SECONDS_IN_MS);
        }
    }, [countdownPeriod]);

    /// <summary>
    /// Author :
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(setBusy());
        let TACparams = {
            "TAC": data.tacInput,
            "phoneNumber": formattedPhone,
            "referralCode": referralCode
        };
        let TACjson = await ApiEngine.post(ApiUrl._API_VERIFY_TAC_CODE, createFormBody(TACparams));
        if (TACjson[ApiKey._API_SUCCESS_KEY]) {
            let params = {
                "username": data.username,
                "password": data.password,
                //"userFullName": data.fullName,
                "phoneNumber": formattedPhone,
                //"email": data.email,
                "referralCode": referralCode,
                "isMemberOperate": true,
                "languageSetting": props.languageState.languageId
            };
            let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, createFormBody(params));
            _dispatch(showMessage({
                type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                onConfirm: async () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setTACBtnAble(false);
                        clearTimeout();
                        await _dispatch(performLogin(data.username, data.password));
                        if (!window.ReactNativeWebView) {
                            _history.replace(WebUrl._URL_DASHBOARD_V2)
                        }
                    }
                    else {
                        _dispatch(setIdle())
                    }
                }
            }));
        }
        else {
            _dispatch(showResponseMessage(false, TACjson[ApiKey._API_MESSAGE_KEY]));
        }
        _dispatch(setIdle());
    }

    /// <summary>
    /// Author: Wong
    /// </summary>
    const onChangeLanguage = async (selectedLanguage) => {
        setActiveLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);

        _dispatch(saveLanguageId(selectedLanguage, true));
    }

    async function sendTACCode() {
        setPhoneNumberError(false);
        if ((TACtimedOut || countdownPeriod > 0) && typeof (formattedPhone) !== "undefined" && formattedPhone.length >= 11) {
            if (!errors.phoneNumber) {
                let params = {
                    "phoneNumber": formattedPhone,
                    "referralCode": referralCode,
                    "languageCode": activeLanguage,
                    "countdown": countdownPeriod,
                    "TACExist": !TACtimedOut
                };

                let responseJson = await ApiEngine.post(ApiUrl._API_SEND_TAC_CODE, createFormBody(params));
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTACsent(true);
                    setTACtimedOut(false);
                    setCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
                    setShowTacEntry(true);
                    setTACBtnAble(false);
                    setTimeout(() => { setTACBtnAble(true); setTACtimedOut(true); }, _TAC_VALID_TIMER);
                }
                else {
                    setPhoneNumberError(true);
                }
            }
        }
        else {
            setError("phoneNumber", { message: "PHONE_NUMBER_INVALID" })
        }
        /*uncomment when want to TAC input without sending SMS*/
        //console.log("sendTACCode");
        //setTACsent(true);
        //setTACtimedOut(false);
        //setCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
        //setShowTacEntry(true);
        //setTACBtnAble(false);
        //setTimeout(() => { setTACBtnAble(true); setTACtimedOut(true); }, _TAC_VALID_TIMER);
    }

    //async function checkPhoneNumber(formattedPhoneNumber) {
    //    clearErrors("phoneNumber");
    //    if (formattedPhoneNumber.length >= 11) {
    //        let params =
    //        {
    //            "phoneNumber": formattedPhoneNumber
    //        };
    //        let responseJson = await ApiEngine.post(ApiUrl._API_CHECK_PHONE_NUMBER, createFormBody(params));
    //        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    //            setPhoneNumberError(true);
    //        }
    //        else {
    //            setPhoneNumberError(false);
    //        }
    //    }
    //}

    /// <summary>
    /// Author :
    /// </summary>
    return (
        <div className="login register">
            <Select
                className="lang-selector"
                menuPlacement="auto"
                menuPosition="fixed"
                options={LanguageOption.map(x => { return { label: x['prefix'], value: x['value'] } })}
                isSearchable={false}
                styles={LanguageSelectStyle}
                placeholder={(LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].prefix) : "EN"}
                value={(LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].prefix) : Language._ENGLISH}
                onChange={(e) => { onChangeLanguage(e.value) }} />
            <div className="container">
                <div className="brand-header" style={{ position: "relative" }}>
                    <div className="register-i8-icon" style={{ backgroundImage: `url(${i8Icon})` }}></div>
                    <div style={{ color: "white" }}>{t('YOU_ARE_INVITED')}!</div>
                </div>
                <form className="margin-bottom-0">
                    <div className="input-card">
                        <Icon
                            className="register-icon"
                            path={mdiAccount}
                            size={1}
                            color="#ffc159"
                        />
                        <hr className="vertical-line" style={{ backgroundColor: "#ffc159", height: "30px", margin: "0 35px" }} />
                        <Input id="username" name="username"
                            ref={register({
                                required: true,
                                validate: async (value) => await checkUsername(value) || (console.log('test'), "USERNAME_TAKEN"),
                                pattern: {
                                    value: /^[a-zA-Z0-9]*$/i,
                                    message: "Please remove spaces and special characters."
                                }
                            })}
                            autoFocus
                            value={_username}
                            onInput={(e) => validateUsername(e)}
                            errors={errors}
                            placeholder={t("USERNAME")} /
                        >
                    </div>
                    <div className="input-card">
                        <Icon
                            className="register-icon"
                            path={mdiLock}
                            size={1}
                            color="#ffc159"
                        />
                        <hr className="vertical-line" style={{ backgroundColor: "#ffc159", height: "30px", margin: "0 35px" }} />
                        <Input name="password"
                            ref={register({
                                required: true,
                                minLength: {
                                    value: _MIN_PASSWORD_LENGTH,
                                    message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                                },
                                validate: value => (watch("confirmPassword") == '' || value == watch("confirmPassword")) || "PASSWORD_MISMATCH"
                            })}
                            errors={errors}
                            placeholder={t("PASSWORD")} />
                    </div>
                    <div className="input-card">
                        <Icon
                            className="register-icon"
                            path={mdiLockCheck}
                            size={1}
                            color="#ffc159"
                        />
                        <hr className="vertical-line" style={{ backgroundColor: "#ffc159", height: "30px", margin: "0 35px" }} />
                        <Input name="confirmPassword"
                            ref={register({
                                required: true,
                                minLength: {
                                    value: _MIN_PASSWORD_LENGTH,
                                    message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                                },
                                validate: value => (watch("password") == '' || value == watch("password")) || "PASSWORD_MISMATCH"
                            })}
                            errors={errors}
                            placeholder={t("CONFIRM_PASSWORD")} />
                    </div>
                    {/*<Input name="fullName"
                        ref={register({ required: true })}
                        errors={errors}
                        placeholder={t("FULL_NAME")} />*/}
                    <div className="input-card">
                        <div className="phoneNum-input-wrapper">
                            <PhoneNumber
                                register={register}
                                name="phoneNumber"
                                value={watch('phoneNumber')}
                                errors={errors}
                                onChange={(value, formattedValue) => {
                                    if (!TACsent || (countdownPeriod === 0)) {
                                        setValue('phoneNumber', value);
                                        setFormattedPhone(formattedValue);
                                        //checkPhoneNumber(formattedValue);
                                    }
                                }}
                            />
                            {<button type="button" className="send-tac-btn" onClick={() => handleSubmit(sendTACCode)()} disabled={TACsent && !TACBtnAble} >{TACsent ? t("RESEND_TAC_CODE") : t("SEND_TAC_CODE")}</button>}
                            {phoneNumberError == true ? <div className="invalid-feedback phonenumber-error">{t("NUMBER_NOT_AVAILABLE")}</div> : ""}
                        </div>
                    </div>

                    {showTacEntry && <div className="tac-input-wrapper">
                        <Input
                            name="tacInput"
                            ref={register({
                                minLength: {
                                    value: 6,
                                    message: t("MIN_CHARACTERS", { length: 6 })
                                },
                                maxLength: {
                                    value: 6,
                                    message: t("MAX_CHARACTERS", { length: 6 })
                                }
                            })}
                            errors={errors}
                            placeholder={t("TAC")}
                        />
                    </div>}
                    {TACsent && countdownPeriod != 0 && <p className="tac-countdown">{t("TAC_SENT")} {t("VALID_FOR")} {countdownPeriod}s</p>}

                    {/*<Input name="email"
                        ref={register({
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "INVALID_EMAIL"
                            }
                        })}
                        errors={errors}
                        placeholder={t("EMAIL")} />*/}
                    <div className="form-group" style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="checkbox"
                            id="terms-and-conditions"
                            style={{ marginBottom: "5px" }}
                            ref={register}
                            onChange={(e) => setAgreedTnC(!agreedTnC)}
                        />
                        <div className="tnc-agree-label"><label style={{ color: "white", fontSize: "11px" }} htmlFor="terms-and-conditions">{t("I_AGREE_TO")}<div style={{ display: "contents" }}>{t("TNC_i8")}</div></label><div style={{ textDecoration: "underline", fontSize: "11px", color: "#ffc159" }} onClick={() => setShowTnC(true)}>{t("TNC")}</div></div>
                    </div>
                    <button type="button" disabled={!agreedTnC || countdownPeriod == 0 || !showTacEntry || !getValues('tacInput') || (getValues('tacInput') && getValues('tacInput').length != 6)} onClick={() => handleSubmit(submitForm)()} className="btn btn-brand-gradient btn-block m-t-35 m-b-35">{t('SIGNUP')}</button>
                </form>
                {showTnC && <div>
                    <div className="tnc-popup-wrapper"></div>
                    <div className="tnc-popup">
                        <div className="tnc-popup-header">{t("TNC")}</div>
                        <div className="tnc-popup-body">
                            <label className="tnc-popup-label" htmlFor="terms-of-use">{t("TERMS_OF_USE")}</label>
                            <div id="terms-of-use">
                                <div>{t("TERMS_OF_USE_1")}</div>
                                <div>{t("TERMS_OF_USE_2")}</div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-general">{t("GENERAL")}</label>
                            <div id="tnc-general">
                                <div className="tnc-list"> 1.<div className="col-12">{t("GENERAL_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("GENERAL_2")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-memberAcc">{t("MEMBER_ACCOUNT")}</label>
                            <div id="tnc-memberAcc">
                                <div className="tnc-list"> 1.<div className="col-12">{t("MEMBER_ACCOUNT_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("MEMBER_ACCOUNT_2")}</div></div>
                                <div className="tnc-list"> 3.<div className="col-12">{t("MEMBER_ACCOUNT_3")}</div></div>
                                <div className="tnc-list"> 4.<div className="col-12">{t("MEMBER_ACCOUNT_4")}</div></div>
                                <div className="tnc-list"> 5.<div className="col-12">{t("MEMBER_ACCOUNT_5")}</div></div>
                                <div className="tnc-list"> 6.<div className="col-12">{t("MEMBER_ACCOUNT_6")}</div></div>
                                <div className="tnc-list"> 7.<div className="col-12">{t("MEMBER_ACCOUNT_7")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-depositNwithdrawal">{t("DEPOSIT_AND_WITHDRAWAL")}</label>
                            <div id="tnc-depositNwithdrawal">
                                <div className="tnc-list"> 1.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_2")}</div></div>
                                <div className="tnc-list"> 3.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_3")}</div></div>
                                <div className="tnc-list"> 4.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_4")}</div></div>
                                <div className="tnc-list"> 5.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_5")}</div></div>
                                <div className="tnc-list"> 6.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_6")}</div></div>
                                <div className="tnc-list"> 7.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_7")}</div></div>
                                <div className="tnc-list"> 8.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_8")}</div></div>
                                <div className="tnc-list"> 9.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_9")}</div></div>
                                <div className="tnc-list">10.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_10")}</div></div>
                                <div className="tnc-list">11.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_11")}</div></div>
                                <div className="tnc-list">12.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_12")}</div></div>
                                <div className="tnc-list">13.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_13")}</div></div>
                                <div className="tnc-list">14.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_14")}</div></div>
                                <div className="tnc-bullets-wrapper">
                                    <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_1")}</div>
                                    <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_2")}</div>
                                    <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_3")}</div>
                                    <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_4")}</div>
                                    <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_5")}</div>
                                </div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-bettingTransactions" style={{ fontSize: "13px" }}>{t("MANAGEMENT_OF_BETTING_TRANSACTIONS")}</label>
                            <div id="tnc-bettingTransactions">
                                <div className="tnc-list"> 1.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_2")}</div></div>
                                <div className="tnc-list"> 3.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_3")}</div></div>
                                <div className="tnc-list"> 4.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_4")}</div></div>
                                <div className="tnc-list"> 5.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_5")}</div></div>
                                <div className="tnc-list"> 6.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_6")}</div></div>
                                <div className="tnc-list"> 7.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_7")}</div></div>
                                <div className="tnc-list"> 8.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_8")}</div></div>
                                <div className="tnc-list"> 9.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_9")}</div></div>
                                <div className="tnc-list">10.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_10")}</div></div>
                                <div className="tnc-list">11.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_11")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-collusion">{t("COLLUSION_AND_ABUSE_ACTIVITIES")}</label>
                            <div id="tnc-collusion">
                                <div className="tnc-list"> 1.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_2")}</div></div>
                                <div className="tnc-list"> 3.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_3")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-interruptions" style={{ fontSize: "13px" }}>{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE")}</label>
                            <div id="tnc-interruptions">
                                <div className="tnc-list"> 1.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_2")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-administrations">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE")}</label>
                            <div id="tnc-administrations">
                                <div className="tnc-list"> 1.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_1")}</div></div>
                                <div className="tnc-list"> 2.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_2")}</div></div>
                            </div>
                            <label className="tnc-popup-label" htmlFor="tnc-customerService">{t("CUSTOMER_SERVICE")}</label>
                            <div id="tnc-customerService">
                                <div className="tnc-list"> 1.<div className="col-12">{t("CUSTOMER_SERVICE_1")}</div></div>
                            </div>
                            <div>
                                <div className="tnc-list" style={{ marginTop: "20px" }}><div>{t("ADDITIONAL_TNC_1")}</div></div>
                            </div>
                        </div>
                        <div style={{ paddingRight: "8px" }}><button type="button" onClick={() => setShowTnC(false)} className="btn btn-brand-gradient btn-block">{t('OK')}</button></div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps
)(Register);