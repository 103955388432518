import { templateSettings, template } from "lodash";
///font variable structure:///
// font size will start with -> themeFontSize
// font family will start with -> themeFontFamily

///colors (background, border, text color) variable structure:///
// text color will start with -> themeColorText
// background color will start with -> themeColorBg
// border color will start with -> themeColorBorder

///shadow variable structure:///
// shadow will start with -> themeBoxShadow

const defaultTheme = {
  themeColorBgWhole: "#0D1A3B",
  themeFontFamilyBbase: 'Montserrat',
  themeColorBgBase: "#0D1A3B",
  themeColorTextBase: "#fff",
  themeColorBgCustomSelectField: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgHomeWalletContainer: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgQrBoxWrapper: 'linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%)',
  themeColorBgHomeWalletIcon: 'linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%)',
  themeColorTextIconColor: '#FFD928',
  themeColorBgHomePointsItems: '#1B2A54',
  themeColorBgHomeButtonList: '#141A50',
  themeColorTextUnderTitleText: '#fff',
  themeColorBgFooter:'#00002080',
  themeColorBgWalletMethodContainer: 'linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%)',
  themeColorBgCustomIconBox: 'linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%)',
  themeColorBgCustomIconBoxTransparent: 'linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%)',
  themeColorBgMainWalletListItem: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgMainWalletListItemActive: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorTextYellow: '#dbc44c',
  themeColorBgTopRightMenuDropdown: '#3c3D6f',
  themeColorBgTopRightMenuDropdown2: 'unset',
  themeColorTextTopRightMenuDropdown: '#ffffff99',
  themeColorBgPaymentListItem:'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBorderCheckmarkBorder: '2px solid #898F97',
  themeColorBgFormGroupInput: '#132144',
  themeColorTextFormGroupInputPlaceholder: '#fff',
  themeColorBgPopupTransferNumpad: 'rgba(22, 26, 47)',
  themeColorBgKeyboardNumpadV3: 'rgba(22, 26, 47, 0.8)',
  themeColorBgAmountRow: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgCurrencyPrefix:'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgDepositTutorialControl: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%)',
  themeColorTextTutorialRow:'#fff',
  themeColorTextTutorialRowSpan:'#fff',
  themeColorBgCardPanel: '#112349',
  themeColorBgReportListIcons: 'unset',
  themeColorBorderCustomDateFilter: '1px solid #3369FF',
  themeColorTextCustomDateFilterInput: '#CBD5E1',
  themeColorBgCardTable: 'linear-gradient(260deg,rgba(20,36,77,1) 0%,rgba(19,33,66,1) 100%)',
  themeColorThBorderCarpackage: '#212C49',
  themeColorBorderCarpackage: '#212C49',
  themeColorTextReloadModalDateBalance: '#A4A4A4',
  themeColorBgTransferHeaderTab:'linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%)',
  themeColorBgTransferHeaderTabActive:'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgCustomInputSelect: '#132144',
  themeColorBgImageCustomInputSelect: 'url("../../assets/img/v3/arrow-down.png")',
  themeColorBgComponentSelectGradiant: '#15295f',
  themeColorBorderComponentSelectGradiant: '1px solid #ffffff2f',
  themeColorBgTfoot: '#1b2961',
  themeColorBgKpiTotal: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgCardRollover: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgGameSelect: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 200%)',
  themeColorBgVideoItem: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgCustomModal: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%)',
  themeColorTextModalText: '#A4A4A4',
  themeColorTextGray2: '#6c757d',
  themeColorBgCustomHr: 'rgba(255, 255, 255, 0.1)',
  themeColorTextGrayToBlue:'#A4A4A4',
  themeColorBgRewardCalendar: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorTextWhiteToBlue: '#fff',
  themeColorTextLightBlueToBlue: '#6c92fc',
  themeColorBgTwoCols: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgReferralCodeBox: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgReferralNav: 'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgReferralNavActive: 'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgAccordionItem:'#142655',
  themeColorBgDownlineDefault:'#112349',
  themeColorTextDarkgrayToQhite: '#898F97',
  themeColorTextSlectArrow:'#9096AA',
  themeColorBgProviderLogo: 'unset',
  themeBoxShadowCustomIcon: '0px 4px 7px rgba(0, 0, 0, 0.3)',
  themeColorBgDepositNormalBankDetails:'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgDateFilterContainer:'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorTextDateFilterContainerActive:'#3369FF',
  themeColorBgCustomDateFilterContainer: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%)',
  themeColorBgPopupDataSection: '#252a5229',
  themeColorTextYellowToMainBlue: '#FFD928',
  themeColorBgMaxBtn: 'linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgReferralUserIcon: 'linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%)',
  themeColorBgNotifIcon:' linear-gradient(135deg, #1B2A5400, #3369FF99)',
  themeColorBgResult4dWhiteBackground: '#0D1A3B',
  themeColorBgResult4dSearch: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(51,105,255,.2) 0,hsla(0,0%,100%,.0447917) 77.08%,hsla(0,0%,100%,0) 100%)',
  themeColorBgCustomIconBoxDark: 'linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%)',
  themeColorBorderPin: '2px solid #fff',
  themeColorBgVipNav: 'linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%)',
  themeColorTextCustomModalTitle: '#EFEFEF',
  themeColorBgForgotOtpPin: 'linear-gradient(0deg, rgba(119, 135, 154, 0.2), rgba(119, 135, 154, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.5) 0%, rgba(254, 94, 171, 0.05) 100%)',
  themeColorBgSuccessModal: 'linear-gradient(0deg, rgba(36, 80, 202, 0.5) 0%, rgb(248 230 255 / 10%) 100%)',
  themeColorBgSuccessTransfer:'linear-gradient(0deg, rgb(16 62 183 / 70%) 0%, rgb(181 181 181 / 80%) 100%)',
  themeColorBgTerms:'#0D1939',
  themeColorBgHelpCenterDefault:'#112349',
  themeColorTextI8Logo:'#fff',
  themeColorBgNavMenu: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%)',
  themeColorTextLang: '#EBEBF599',
  themeColorCustomHr: 'rgba(255, 255, 255, 0.1)',
  themeColorHomeReferralIcon: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(210deg) brightness(101%) contrast(103%)',
  themeColorI8liveLogo: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(210deg) brightness(101%) contrast(103%)',
  themeColorHomeRefreshIcon: 'brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(2%) hue-rotate(272deg) brightness(102%) contrast(102%)',
  themeColorBgCheckIn: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
  themeColorBgCheckInDesc: 'linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%)',
  themeColorBgCheckInToday: 'rgb(58,110,255,0.3)',
  themeColorBgCheckInTodayDesc: 'linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%)',
  themeColorBgSpecialRollover: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgCategoryPlayable: 'linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%)',
  themeColorBgRolloverProgressBar: '#404881',
  themeLoyaltyTitleImg: '#fff',
  themeDropdownBorder: 'radial-gradient(61.69% 212.78% at 108.28% 168.58%, #243B7B 40.77%, #3954A2 100%), radial-gradient(79.38% 146.5% at -21.75% -8.36%, #243B7B  40.77%, #3954A2 100%)',
  themeColorTextPromotionDetailContent: "#CBCBCB",
  themeColorDailyCheckedInColor: "#BACCFF",
  themeColorTextVideoProviderText: '#898F97',
  themeColorDepositCustomHr: '#2B3654',
  themeColorCommNextArrow: "unset",
  themeMainWallFilter: "brightness(0) saturate(100%) invert(86%) sepia(88%) saturate(2087%) hue-rotate(329deg) brightness(99%) contrast(104%)",
  themeColorTextBlackToWhite: "#fff",
  themeColorTextBlackBlue: "#fff",
  themeColorTextBlueToWhite: "#fff",
  themeColorBigWinningBgBase: "#1C1C58",
  themeColorBigWinningBgArrow: "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg) brightness(102%) contrast(102%)",
  themeColorBigWinningBgArrowPagination: "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(9deg) brightness(100%) contrast(101%)",
  themeColorBigWinningBgArrowPaginationHover: "#1C1C58",
  themeColorBigWinningBgIcon: "unset",
  themeColorBigWinningBgContainer: "linear-gradient(to bottom right, rgba(255, 255, 255, 0.4), rgba(28, 28, 88, 1))",
  themeColorBigWinningSeperator: "rgba(255,255,255,0.4)",
  themeColorProviderCategoryBgContainer: "rgba(16, 33, 86, 1)"
};


const lightTheme = {
    themeColorBgWhole: "#fff",
    themeFontFamilyBbase: 'Montserrat',
    themeColorBgBase: "#f6f7ff",
    themeColorTextBase: "#272727",
    themeColorBgCustomSelectField: '#fff',
    themeColorBgHomeWalletContainer: '#FFFFFF99',
    themeColorBgQrBoxWrapper: 'linear-gradient(180deg, #2E57C9 0%, #04105C 100%)',
    themeColorBgHomeWalletIcon: '#F2F4FF',
    themeColorTextIconColor: '#3369FF',
    themeColorBgHomePointsItems: '#F2F4FF',
    themeColorBgHomeButtonList: '#fff',
    themeColorTextUnderTitleText: '#272727',
    themeColorBgFooter: '#FFFFFF',
    themeColorBgWalletMethodContainer: '#fff',
    themeColorBgCustomIconBox: 'transparent',
    themeColorBgCustomIconBoxTransparent: 'transparent',
    themeColorBgMainWalletListItem: '#fffff',
    themeColorBgMainWalletListItemActive: '#E4E7FF',
    themeColorTextYellow: '#4273ff',
    themeColorBgTopRightMenuDropdown: '#F6F7FF',
    themeColorBgTopRightMenuDropdown2: '#D0DDFF',
    themeColorTextTopRightMenuDropdown: '#272727',
    themeColorBgPaymentListItem: '#fff',
    themeColorBorderCheckmarkBorder: '2px solid #272727',
    themeColorBgFormGroupInput: '#fff',
    themeColorTextFormGroupInputPlaceholder: '#272727',
    themeColorBgPopupTransferNumpad: '#586587',
    themeColorBgKeyboardNumpadV3: 'transparent',
    themeColorBgAmountRow: '#fff',
    themeColorBgCurrencyPrefix: '#fff',
    themeColorBgDepositTutorialControl: '#272727',
    themeColorTextTutorialRow: '#272727',
    themeColorTextTutorialRowSpan: '#1b32ef',
    themeColorBgCardPanel: '#fff',
    themeColorBgReportListIcons: '#fff',
    themeColorBorderCustomDateFilter: '1px solid #c4c5c9',
    themeColorTextCustomDateFilterInput: '#272727',
    themeColorBgCardTable: '#fff',
    themeColorThBorderCarpackage: '#272727',
    themeColorBorderCarpackage: '#d4d4d4',
    themeColorTextReloadModalDateBalance: '#fff',
    themeColorBgTransferHeaderTab: '#F2F4FF',
    themeColorBgTransferHeaderTabActive: '#3369FF',
    themeColorBgCustomInputSelect: '#fff',
    themeColorBgImageCustomInputSelect: 'url("../../assets/img/v3/light-theme/arrow-down.png")',
    themeColorBgComponentSelectGradiant: '#fff',
    themeColorBorderComponentSelectGradiant: '1px solid #3369FF',
    themeColorBgTfoot: '#F0F4FF',
    themeColorBgKpiTotal: '#fff',
    themeColorBgCardRollover: '#fff',
    themeColorBgGameSelect: '#F2F4FF',
    themeColorBgVideoItem: '#fff',
    themeColorBgCustomModal: '#F6F7FF',
    themeColorTextModalText: '#272727',
    themeColorTextGray2: '#272727',
    themeColorBgCustomHr: '#272727',
    themeColorTextGrayToBlue: '#3369FF',
    themeColorBgRewardCalendar: '#fff',
    themeColorTextWhiteToBlue: '#3369FF',
    themeColorTextLightBlueToBlue: '#3369FF',
    themeColorBgTwoCols: '#E4E7FF',
    themeColorBgReferralCodeBox: '#fff',
    themeColorBgReferralNav: '#fff',
    themeColorBgReferralNavActive: '#3369FF',
    themeColorBgAccordionItem: '#fff',
    themeColorBgDownlineDefault: '#FFFFFF',
    themeColorTextDarkgrayToWhite: '#272727',
    themeColorTextSlectArrow: '#272727',
    themeColorBgProviderLogo: '#fff',
    themeBoxShadowCustomIcon: 'unset',
    themeColorBgDepositNormalBankDetails: '#F2F4FF',
    themeColorBgDateFilterContainer: '#3369FF',
    themeColorTextDateFilterContainerActive: '#fff',
    themeColorBgCustomDateFilterContainer: '#fff',
    themeColorBgPopupDataSection: '#F6F7FF',
    themeColorTextYellowToMainBlue: '#3369FF',
    themeColorBgMaxBtn: 'linear-gradient(180deg, #2E57C9 0%, #04105C 100%)',
    themeColorBgReferralUserIcon: 'linear-gradient(180deg, #2E57C9 0%, #04105C 100%)',
    themeColorBgNotifIcon: 'transparent',
    themeColorBgResult4dWhiteBackground: '#F6F7FF',
    themeColorBgResult4dSearch: '#fff',
    themeColorBgCustomIconBoxDark: 'linear-gradient(180deg, #2E57C9 0%, #04105C 100%)',
    themeColorBorderPin: '2px solid #272727',
    themeColorBgVipNav: '#E4E7FF',
    themeColorTextCustomModalTitle: '#272727',
    themeColorBgForgotOtpPin: '#F2F4FF',
    themeColorBgSuccessModal: '#F6F7FF',
    themeColorBgSuccessTransfer: 'F6F7FF',
    themeColorBgTerms: '#fff',
    themeColorBgHelpCenterDefault: '#fff',
    themeColorTextI8Logo: '#002E6C',
    themeColorBgNavMenu: '#fff',
    themeColorTextLang: '#272727',
    themeColorCustomHr: '#272727',
    themeColorHomeReferralIcon: 'brightness(0) saturate(100%) invert(32%) sepia(47%) saturate(3379%) hue-rotate(217deg) brightness(100%) contrast(102%)',
    themeColorI8liveLogo: 'brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(1663%) hue-rotate(196deg) brightness(94%) contrast(108%)',
    themeColorHomeRefreshIcon: 'brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(2814%) hue-rotate(233deg) brightness(96%) contrast(97%)',
    //themeColorCustomHr: '#3369ff52',
    themeColorBgCheckIn: "#ffffff",
    themeColorBgCheckInDesc: "#D0DDFF",
    themeColorBgCheckInToday: "rgba(58, 110, 255, 0.3)",
    themeColorBgCheckInTodayDesc: "rgba(58, 110, 255, 0.3)",
    themeColorBgSpecialRollover: '#fff',
    themeColorBgCategoryPlayable: '#E4E7FF',
    themeColorBgRolloverProgressBar: 'rgba(78, 75, 200, 0.3)',
    themeLoyaltyTitleImg: 'filter: brightness(0) saturate(100%) invert(35%) sepia(59%) saturate(4256%) hue-rotate(217deg) brightness(100%) contrast(101%)',
    themeDropdownBorder: 'radial-gradient(61.69% 212.78% at 108.28% 168.58%, #3369FF 40.77%, rgba(255,255,255,0) 100%), radial-gradient(79.38% 146.5% at -21.75% -8.36%, #3369FF 40.77%, rgba(255,255,255,0) 100%)',
    themeColorTextPromotionDetailContent: "#272727",
    themeColorDailyCheckedInColor: "#2C3C67",
    themeColorTextVideoProviderText: '#272727',
    themeColorDepositCustomHr: '#272727',
    themeColorCommNextArrow: "brightness(0) saturate(100%) invert(13%) sepia(0%) saturate(1732%) hue-rotate(242deg) brightness(100%) contrast(93%)",
    themeMainWallFilter: "brightness(0) saturate(100%) invert(28%) sepia(99%) saturate(2415%) hue-rotate(220deg) brightness(105%) contrast(101%)",
    themeColorTextBlackToWhite: "#000",
    themeColorTextBlackBlue: "#3369FF",
    themeColorTextBlueToWhite: "#3369FF",
    themeColorBigWinningBgBase: "#fff",
    themeColorBigWinningBgArrow: "brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(0%) hue-rotate(22deg) brightness(86%) contrast(106%)",
    themeColorBigWinningBgArrowPagination: "brightness(0) saturate(100%) invert(0%) sepia(99%) saturate(7454%) hue-rotate(294deg) brightness(109%) contrast(100%)",
    themeColorBigWinningBgArrowPaginationHover: "#fff",
    themeColorBigWinningBgIcon: "brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(102%) contrast(102%)",
    themeColorBigWinningBgContainer: "#fff",
    themeColorBigWinningSeperator: "#000",
    themeColorProviderCategoryBgContainer: "#fff"
};



const themeGenerator = (activeTheme, replaceValue = {}) => {
    let selectedThemeString = JSON.stringify(defaultTheme);

    if (activeTheme === "default") {
        selectedThemeString = JSON.stringify(defaultTheme);
    } else if (activeTheme === "light") {
        selectedThemeString = JSON.stringify(lightTheme);
    }

    templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const compiled = template(selectedThemeString);

    const result = compiled(replaceValue);

    return JSON.parse(result);
};

const themeIcons = {
  ///general
  logo: "i8live_logo",
  menu: "hamburger_menu",
  menu3: "menu3",
  angleRight: "chevron-right",
  angleLeft: "chevron-left",
  angleDown: "chevron-down",
  angleUp: "chevron-up",
  search: "search2",
  wallet: "wallet2",
  moreVertical: "more-vertical",
  home: "home",
  activity: "news-paper",
  account: "user-solid-circle",
  downlines: "share-2",
  referral: "users2",
  sales: "chart1",
  home1: "home1",
  star: "vip-event",
  close: "close",
  qrcode: "qrcode",
  user: "username",
  delete: "trash2",
  upload: "upload4",
  card: "credit-card",
  refresh: "spinner11",
  claim: "download5",
  okup_withdraw: "okup_withdraw",
  eye: "eye",
  eyeblocked: "eye-blocked",
  image: "image",
  bank: "bank",
  info: "info",
  cog: "cog",
  alertCircle: "alert-circle",
  edit: "edit-pencil",
  statistic: "stats-bars2",
  copy: "copy1",
  checkmark: "checkmark",
  huione: "huione",
  usdt: "usdt",
  bubbles: "bubbles",
  profile: "profile",
  usercheck: "user-check",
  giftpace: "giftpace",
  huione: "huione",
  usdt: "usdt",
  topkash: "topkash",
  sliderArrowRight: "slider-arrow-right",
  sliderArrowLeft: "slider-arrow-left",
  key: "key1",
  send: "send",
  commission: "commission_1",
  reward: "gift1",
  loyaltyPoints: "loyalty-points",
  withdraw: "withdraw",
  transfer1: "transfer1",
  kpi: "kpi_1",
  downline: "downline",
  report: "report_1",
  deposit: "okup_deposit",
  bonus: "currency-dollar",
  reportsDepositRecord: "deposit",
  reportsWithdrawalRecord: "reports_withdrawal-record",
  walletTransfer: "walletTransfer",
  bonusReport: "Bonus",
  gameReport: "dice",
  rolloverReport: "rollover",
  walletStatement: "wallet-statement",
  notifClose: "uil_envelope",
  notifOpen: "ph_envelope-open-bold",
  code: "register-code",
  play: "play-circle",
  livechat: "message-square",
  settings: "settings",
  promotion: "promotion",
  about: "exclamation-outline",
  result4d: "d",
  tc: "tc",
  ugroup: "user-group",
  bell: "bell1",
  dailyCheckIn: "daily_check_in",
  lock1: "lock1",
  mobile: "mobile",
  favorite: "star",


    ///dashboard
    dashboardAnnouncement: "bullhorn",
    dashboardNotification: "bell",
    dashboardBalanceDeposit: "plus",
    dashboardBalanceWithdraw: "okup_withdraw",
    dashboardBalanceRestore: "undo",
    dashboardBalanceTransfer: "okup_transfer",
    dashboardBalanceWallet: "okup_wallet",
    dashboardCommission: "currency-dollar",
    dashboardCommissionClaim: "arrow-down3",
    dashboardCommissionDownlines: "share-2",
    dashboardCommissionReferral: "users2",
    dashboardCommissionSales: "chart1",
    dashboardPoints: "star-full",
    dashboardPointsCheckin: "calendar1",
    dashboardPointsShop: "shopping-bag",
    dashboardPointsHistory: "history",
    dashboardPointsRecord: "gift",
    lockClosed: "lock-closed",

    ///settings
    settingsLanguage: "conversation",
    settingsBank: "library",
    settingsPassword: "lock1",
    settingsPin: "calculator1",
    settingsLivechat: "help-circle",
    settingsTerms: "file-text2",
    settingsLogout: "log-out",

    //profile
    profilePhone: 'phone',
    profileMail: 'mail1',

};

export { defaultTheme, lightTheme, themeGenerator, themeIcons };
