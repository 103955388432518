import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
  WebUrl,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import DynamicLoadTable from "../../../components/custom/DynamicLoadTable";
import Numpad2 from "../../../components/custom/Numpad2";
import moment from "moment";
import BottomSuccessModal from "../../../components/custom/BottomSuccessModal";
import { numberWithCurrencyFormat, createFormBody } from "../../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setGameSearchText,
  setGameQuery,
  setPageSize,
  setY,
} from "../../../redux/AppAction.js";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import backbutton from "../../../assets/img/icon/backbutton.png";
import Input from "../../../components/custom/Input";
import UMIcon from "../../../assets/img/icon/dashboardv2_um.png";

/// <summary>
/// Author: -
/// </summary>
const DefaultLobby = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  let _history = useHistory();
  let _savedQuery = useSelector((state) => state.appState.gameQuery);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  let _savedData = useSelector((state) => state.appState.gameSavedText);

  const [masterProductId, setMasterProductId] = useState(0);
  const [games, setGames] = useState([]);
  const [recommendedGamesList, setRecommendedGamesList] = useState([]);
  const [recordUrl, setRecordUrl] = useState("");
  const [gameCategories, setGameCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [initCheck, setInitCheck] = useState(false);
  const [providerLogo, setProviderLogo] = useState("");
  const [getTextFlag, setGetTextFlag] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [lobbyName, setLobbyName] = useState("");
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  //const [isTransferBackToMainWallet, setIsTransferBackToMainWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [showTaxWarning, setShowTaxWarning] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [deductedAmount, setDeductedAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      getGames(tab);
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    setLobbyName(props.name);
    setMasterProductId(parseInt(props.id));
    _dispatch(setGameQuery(ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME));
    init(props.id, props.languageId);
  }, []);

  async function getGames(categoryId) {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME +
      "?id=" +
      masterProductId +
      "&category=" +
      gameCategories.find((x) => x.id === categoryId).originalTitle +
      "&languageId=" +
      props.languageId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var availableGames = responseJson[ApiKey._API_DATA_KEY].data;
      setGames(availableGames);
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function init(id, languageId) {
    var apiUrl = ApiUrl._API_GET_MASTER_PRODUCT_BY_ID;
    apiUrl += "?id=" + id;
    var responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannerImage(responseJson[ApiKey._API_DATA_KEY]["bannerImage"]);
      setIconImage(responseJson[ApiKey._API_DATA_KEY]["iconImage"]);
      setProviderLogo(responseJson[ApiKey._API_DATA_KEY]["productImage"]);
      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME +
        "?id=" +
        id +
        "&languageId=" +
        languageId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var availableGames = responseJson[ApiKey._API_DATA_KEY].data;
        var tempList = [];
        setGames(availableGames);
        /*availableGames.map((game, index) => {
                    if (game['isRecommended'] === true) { tempList.push(game) }
                })*/
        responseJson = await ApiEngine.get(
          ApiUrl._API_GET_RECOMMENDED_MASTER_PRODUCT_GROUP_GAME +
          "?id=" +
          id +
          "&languageId=" +
          languageId
        );
        setRecommendedGamesList(responseJson[ApiKey._API_DATA_KEY]);

        responseJson = await ApiEngine.get(
          ApiUrl._API_GET_MASTER_PRODUCT_CATEGORY + "?id=" + id
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          setGameCategories(data);
          setActiveTab(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
        } else {
          _dispatch(
            showResponseMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              responseJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    setInitCheck(true);
  }

  async function startGame(gameId, gameName, isSeamless, isNewWindow) {
    setSelectedGameName(gameName);
    setProductGameId(gameId);
    if (isSeamless == false) {
      _dispatch(setBusy());
      let member = [];
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
      );
      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
        return;
      }
      member = responseJson[ApiKey._API_DATA_KEY];
      setMemberMainWalletBalance(
        isNaN(parseFloat(member["primaryCredit"]), 3)
          ? "0.00"
          : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2)
      );
      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setWalletList(responseJson[ApiKey._API_DATA_KEY]);

        let tempFromOptions = [];
        let tempToOptions = [];

        responseJson[ApiKey._API_DATA_KEY].map((product) => {
          let option = {
            label:
              product.productName +
              "   " +
              numberWithCurrencyFormat(parseFloat(product.balance), 2),
            value: product.productId,
          };
          if (product.productId === _MAIN_WALLET_ID) {
            tempFromOptions.push(option);
          } else if (product.productId === masterProductId) {
            setBonus(product.bonus);
            setCommission(product.commission);
            setGameWalletBalanceBeforeTransfer(product.balance);
            tempToOptions.push(option);
          }
        });
        setFromOptions(tempFromOptions);
        setToOptions(tempToOptions);
      }

      /*var isNewWindowJson = await ApiEngine(
        ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
      );
      setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);*/
      setShowPregamePopup(true);
      _dispatch(setIdle());
    } else {
      if (!window.ReactNativeWebView) {
        window.open(
          `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
          }?gameId=${gameId}&isApp=0&device=m`
        );
      } else {
        if (isNewWindow === true) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                }${ApiUrl._API_START_GAME}?gameId=${gameId}&device=m`,
            })
          );
        } else {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              url: `${process.env.REACT_APP_ENDPOINT_URL || window.location.origin
                }${WebUrl._URL_GAME_PAGE}?gameId=${gameId}`,
            })
          );
        }
      }
    }
  }

  //async function checkIfNonSeamless(gameId) {
  //    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IF_NON_SEAMLESS + "?id=" + gameId);
  //    if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY] !== null) {
  //        let member = [];
  //        responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
  //        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
  //            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
  //            return;
  //        }
  //        member = responseJson[ApiKey._API_DATA_KEY];
  //        setMemberMainWalletBalance(isNaN(parseFloat(member['primaryCredit']), 3) ? "0.00" : numberWithCurrencyFormat(parseFloat(member['primaryCredit']), 3));
  //        setPopupBannerImage(responseJson[ApiKey._API_DATA_KEY]["bannerImage"]);
  //        setBonus(responseJson[ApiKey._API_DATA_KEY]["bonus"]);
  //        setCommission(responseJson[ApiKey._API_DATA_KEY]["commission"]);
  //        responseJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE);
  //        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

  //            let tempFromOptions = [];
  //            let tempToOptions = [];

  //            responseJson[ApiKey._API_DATA_KEY].map((product) => {
  //                let option = { label: product.productName + " - " + numberWithCurrencyFormat(parseFloat(product.balance), 3), value: product.productId };
  //                if (product.productId === _MAIN_WALLET_ID) {
  //                    tempFromOptions.push(option);
  //                } else if (product.productId === masterProductId) {
  //                    setGameWalletBalanceBeforeTransfer(product.balance);
  //                    tempToOptions.push(option);
  //                }
  //            })
  //            setFromOptions(tempFromOptions);
  //            setToOptions(tempToOptions);
  //        }
  //        setShowPregamePopup(true);
  //    }
  //    else {
  //        window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId);
  //    }
  //}

  //function setTransfertoToption(fromWallet) {
  //    if (isTransferBackToMainWallet) {
  //        let tempToOptions = [];
  //        if (fromWallet !== _MAIN_WALLET_ID) {
  //            setShowTaxWarning(true);
  //            fromOptions.map((fromOption, index) => {
  //                if (fromOption.value === _MAIN_WALLET_ID) {
  //                    tempToOptions.push(fromOption);
  //                }
  //            });
  //        }
  //        else {
  //            setShowTaxWarning(false);
  //            fromOptions.map((fromOption, index) => {
  //                if (fromOption.value !== fromWallet) {
  //                    tempToOptions.push(fromOption);
  //                }
  //            });
  //        }
  //        setToOptions(tempToOptions);
  //    }
  //}

  const submitPopupForm = async (data, e) => {
    _dispatch(setBusy());
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: masterProductId,
      amount: data.amount,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(responseJson[ApiKey._API_DATA_KEY].amount);

      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setDeductedAmount(responseJson[ApiKey._API_DATA_KEY].deductedAmount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  function getText(e) {
    _dispatch(setGameSearchText(e.target.value));
    _dispatch(setGameQuery(""));
    setGetTextFlag(true);
    _dispatch(
      setGameQuery(
        ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME +
        "?keyword=" +
        e.target.value +
        "&id=" +
        props.id
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }
  async function updateWalletBalance(amount) {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label:
            product.productName +
            "   " +
            numberWithCurrencyFormat(parseFloat(product.balance), 2),
          value: product.productId,
        };
        if (product.productId === _MAIN_WALLET_ID) {
          setMemberMainWalletBalance(
            isNaN(parseFloat(product.balance), 3)
              ? 0.0
              : numberWithCurrencyFormat(parseFloat(product.balance), 2)
          );
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = product.balance;
          gameWalletBalance += amount;
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }
  /// <summary>
  /// Author : -
  /// </summary>
  function resetFormField() {
    setValuePopup("fromWallet", null);
    setValuePopup("toWallet", null);
    setCurrentInput();
  }

  return (
    <div id={"custom-lobby" + (activeTheme === 0 ? "" : "-modern")}>
      {initCheck && (
        <div className="content-wrapper">
          <div
            className="top-banner"
            style={{ backgroundColor: "black", position: "relative" }}
          >
            <div
              className="banner-background"
              style={{ backgroundImage: `url(${bannerImage})` }}
            ></div>
            <div
              className="lobby-back-button"
              onClick={() =>
                _history.push(
                  activeTheme === 0
                    ? WebUrl._URL_DASHBOARD
                    : WebUrl._URL_DASHBOARD_V2
                )
              }
              style={{ backgroundImage: `url(${backbutton})` }}
            ></div>
            <div className="banner-icon-wrapper">
              <div
                className="banner-icon"
                style={{ backgroundImage: `url(${iconImage})` }}
              ></div>
            </div>
            <div className="game-search-box">
              <Input
                placeholder={t("SEARCH")}
                defaultValue={_savedData}
                onInput={(e) => {
                  getText(e);
                }}
                inputGroupType="append"
                inputGroupContent={
                  <i
                    className="fas fa-search"
                    style={{ color: "#FFC159", fontSize: "18px" }}
                  ></i>
                }
              />
            </div>
            {/*<img className="banner-icon" src={require('./../../../assets/img/icon/playtech-icon.png')} style={{ position: "absolute", width: "30px", top: "0", left: "0", margin: "10px" }} />
                    <div className="text-and-searchbar">
                        <div className="banner-text">{lobbyName}</div>
                        <div className="banner-searchbar">
                            <input className="search-box" type="text" placeholder="SEARCH" />
                            <div><i className="search-icon fas fa-search"></i>
                            </div>
                        </div>
                    </div>*/}
          </div>
          {recommendedGamesList.length > 0 && (
            <>
              <div className="hot-games">
                <div className="hot-games-label">
                  <label htmlFor="hot-games-list">{t("HOT_GAMES")}</label>
                  {/*<div>{t("SEE_MORE")}</div>*/}
                </div>
                <div id="hot-games-list" className="hot-games-list game-row">
                  {recommendedGamesList.map((game, index) => {
                    return (
                      <div className="hot-games-tile" key={index}>
                        <a
                          key={index}
                          onClick={() => {
                            setPopupBannerImage(game["popupBannerImage"]);
                            startGame(
                              game["id"],
                              game["gameName"],
                              game["isSeamless"],
                              game["isNewWindow"]
                            );
                          }}
                          className={game["gameUm"] == true ? "gameum" : ""}
                          target="_blank"
                        >
                          <div className="lobby-games-img-section">
                            {game["gameUm"] == true && (
                              <>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    className="v2-um-tag game-category-label"
                                ></div>
                                <div
                                    style={{
                                        backgroundImage: `url(${UMIcon})`,
                                    }}
                                    className="v2-um-tag-icon"
                                ></div>
                              </>
                            )}
                            <img
                              src={game["image"] !== null ? game["image"] : providerLogo}
                              alt="Game provider"
                              className="lobby-games-img"
                              style={{ 
                              visibility: game["gameUm"] == true ? "hidden" : "" }}
                            />
                          </div>
                          <div className="hot-games-name">
                            <div className="hot-games-text">
                              {game.gameName}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          <div className="all-games">
            <Nav className="games-lobby">
              {gameCategories.map((category, index) => {
                return (
                  <NavItem
                    className={classnames({
                      active: activeTab === category.id,
                    })}
                    key={index}
                  >
                    <NavLink
                      className={classnames({
                        active: activeTab === category.id,
                      })}
                      onClick={() => {
                        toggleTab(category.id);
                      }}
                    >
                      <span className="d-sm-none">{t(category.title)}</span>
                      <span className="d-sm-block d-none">
                        {t(category.title)}
                      </span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {gameCategories.map((category, index) => {
                return (
                  <TabPane tabId={category.id} key={index}>
                    <div className="game-row" style={{ marginTop: "20px" }}>
                      <DynamicLoadTable
                        pageSize={24}
                        apiQuery={
                          getTextFlag === false
                            ? _savedQuery +
                            "?id=" +
                            props.id +
                            "&category=" +
                            category.originalTitle
                            : _savedQuery
                        }
                        searchEnabled={true}
                        autoscrollEnabled={true}
                        render={(game, i) => {
                          return (
                            <div className="hot-games-tile" key={i}>
                              <a
                                key={index}
                                onClick={() => {
                                  startGame(game["id"],
                                    game["gameName"],
                                    game["isSeamless"],
                                    game["isNewWindow"]);
                                }}
                                className={
                                  game["gameUm"] == true ? "gameum" : ""
                                }
                                target="_blank"
                              >
                                <div className="lobby-games-img-section">
                                  {game["gameUm"] == true && (
                                    <>
                                      <div
                                          style={{
                                              width: "100%",
                                              height: "100%",
                                          }}
                                          className="v2-um-tag game-category-label"
                                      ></div>
                                      <div
                                          style={{
                                              backgroundImage: `url(${UMIcon})`,
                                          }}
                                          className="v2-um-tag-icon"
                                      ></div>
                                    </>
                                  )}
                                  <img
                                    src={game["image"] !== null ? game["image"] : providerLogo}
                                    alt="Game provider"
                                    className="lobby-games-img"
                                    style={{ 
                                    visibility: game["gameUm"] == true ? "hidden" : "" }}
                                  />
                                </div>
                                <div className="hot-games-name">
                                  <div className="hot-games-text">
                                    {game.gameName}
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
          <Modal
            id="dashboard-pregame-popup"
            fade={false}
            contentClassName="modal-pregame-popup modal-brand modal-center"
            isOpen={showPregamePopup}
            toggle={() => {
              setShowPregamePopup(false);
            }}
            centered
          >
            <div className="pregame-popup-header">
              <i
                className="fa fa-arrow-left"
                style={{ position: "absolute", left: "20px" }}
                onClick={() => setShowPregamePopup(false)}
              />
              {selectedGameName}
            </div>
            <div
              className="pregame-popup-banner"
              style={{ backgroundImage: `url(${popupBannerImage})` }}
            ></div>
            <ModalBody>
              <form onSubmit={handleSubmitPopup(submitPopupForm)}>
                <div className="display-wrapper">
                  <div className="wallet-info">
                    <div>
                      {fromOptions.length > 0
                        ? fromOptions[0].label +
                        " " +
                        numberWithCurrencyFormat(fromOptions[0].amount, 2)
                        : ""}
                    </div>
                    <div>
                      <hr className="vertical-line" />
                    </div>
                    <div>
                      {toOptions.length > 0
                        ? toOptions[0].label +
                        " " +
                        numberWithCurrencyFormat(toOptions[0].amount, 2)
                        : ""}
                    </div>
                  </div>
                  <div className="transfer-amount-wrapper">
                    <div className="transfer-input-label">
                      {t("TOTAL_TRANSFER_AMOUNT")}
                    </div>
                    <div className="transfer-input">
                      <input
                        type="text"
                        className="form-control transfer-amount"
                        readOnly
                        placeholder={"0.00"}
                        name="amount"
                        onClick={(e) => {
                          setCurrentInput(e.target);
                        }}
                        ref={registerPopup({
                          required: "PLEASE_ENTER_AMOUNT",
                          validate: {
                            minValue: (value) =>
                              parseFloat(value) > 0 ||
                              "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                          },
                        })}
                      />
                      {errorsPopup.amount && (
                        <div className="invalid-feedback">
                          {t(errorsPopup.amount.message)}
                        </div>
                      )}
                    </div>
                    <div className="transfer-balance">
                      <div>{selectedGameName + " New " + t("BALANCE")}</div>
                      <div>
                        {watchPopup("amount") > 0 &&
                          numberWithCurrencyFormat(
                            parseFloat(toOptions[0].amount) +
                            parseFloat(watchPopup("amount")),
                            2,
                            true
                          )}{" "}
                        {watchPopup("amount") == "" && "-"}
                      </div>
                    </div>
                  </div>
                  <div className="transfer-btn">
                    <button type="submit" className="btn btn-brand-gradient">
                      {t("TRANSFER")}
                    </button>
                  </div>
                  <div className="bonus-and-commission">
                    {bonus > 0 && (
                      <div className="bonus">
                        {t("BONUS") +
                          " (%) : " +
                          numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                          "%"}
                      </div>
                    )}
                    {commission > 0 && (
                      <div className="commission">
                        {t("COMMISSION") +
                          " (%) : " +
                          numberWithCurrencyFormat(
                            parseFloat(commission),
                            2,
                            true
                          ) +
                          "%"}
                      </div>
                    )}
                  </div>
                  <div className="start-btn-wrapper">
                    <button
                      type="button"
                      onClick={() => {
                        if (!window.ReactNativeWebView) {
                          //if (isNewWindow === true) {
                          window.open(
                            ApiUrl._API_START_GAME +
                            "?gameId=" +
                            productGameId +
                            "&isApp=0" +
                            "&device=m"
                          );
                          /*} else {
                            _history.push({
                              pathname: WebUrl._URL_GAME_PAGE,
                              search: "?gameId=" + productGameId,
                              state: { url: window.location.pathname },
                            });
                          }*/
                        } else {
                          if (isNewWindow === true) {
                            window.ReactNativeWebView.postMessage(
                              JSON.stringify({
                                url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                  window.location.origin
                                  }${ApiUrl._API_START_GAME
                                  }?gameId=${productGameId}&isApp=0&device=m`,
                              })
                            );
                          } else {
                            window.ReactNativeWebView.postMessage(
                              JSON.stringify({
                                url: `${process.env.REACT_APP_ENDPOINT_URL ||
                                  window.location.origin
                                  }${WebUrl._URL_GAME_PAGE
                                  }?gameId=${productGameId}&isApp=0&device=m`,
                              })
                            );
                          }
                        }
                      }}
                      className="btn pregame-popup-btn btn-block btn-brand"
                    >
                      {t("START_GAME")}
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
          <BottomSuccessModal
            isOpen={isTransferSuccess}
            setIsOpen={setIsTransferSuccess}
            title={"TRANSFER_SUCCESS"}
            amount={paidAmount}
            date={transferDate}
            transactionNumber={transcactionNumber}
          />
          {(getValuesPopup("toWallet") > 0 || currentInput) && (
            <Numpad2
              currentBalance={
                typeof walletList?.find(
                  (op) => op.productId == getValuesPopup("toWallet")
                )?.balance !== "undefined"
                  ? walletList?.find(
                    (op) => op.productId == getValuesPopup("toWallet")
                  )?.balance
                  : 0
              }
              currentInput={currentInput}
              setCurrentInput={(e) => {
                setCurrentInput(e);
              }}
              contentClassName="popup-transfer-numpad"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DefaultLobby;
