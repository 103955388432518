import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import StyledSelect from "../../components/custom/StyledSelect";
import Claim from "./Claim";
import Report from "./Report";
import Summary from "./Summary";
import Downline from "./Downline";

const Shareholder = () => {
  const { t, i18n } = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hideSummary = params.get("GAzobE73Vse4nr8WpufA");
  const _userData = useSelector(state => state.authState.userData);
  let _history = useHistory();
  let _location = useLocation();
  var _dispatch = useDispatch();
  var activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const _MENU_OPTION = {
    Summary: 1,
    Shareholders: 2,
    Claim: 3,
  };
  const [activeMenu, setActiveMenu] = useState(
    hideSummary === "true" ? _MENU_OPTION.Shareholders : _MENU_OPTION.Summary
  );

  const [renderComponent, setRenderComponent] = useState();
  const [headerMsg, setHeaderMsg] = useState("");

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (_userData.isVip) {
      checkComponentToRender();
    }
    else {
      _history.push(WebUrl._URL_MAIN);
    }
    let temp = localStorage.getItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF");
    if (temp !== "wdAvBAPKCPB1TvXPsfPSda8PUUdEGaHg") {
      _history.push(WebUrl._URL_MAIN);
    }
  }, [activeMenu]);

  /// <summary>
  /// Author: -
  /// </summary>
  function checkComponentToRender() {
    switch (activeMenu) {
      case _MENU_OPTION.Summary:
        setRenderComponent(<Summary hideSummary={hideSummary} />);
        break;
      case _MENU_OPTION.Shareholders:
        setRenderComponent(<Downline hideSummary={hideSummary} />);
        break;
      case _MENU_OPTION.Claim:
        setRenderComponent(<Claim hideSummary={hideSummary} />);
        break;
      default:
        setRenderComponent();
        break;
    }

    init();

    /// used to persist the current tab when refresh
    if (
      _location.state &&
      !stringIsNullOrEmpty(_location.state.menu) &&
      activeMenu != _location.state.menu
    ) {
      _history.replace({
        pathname: _location.pathname,
        state: {
          menu: activeMenu,
        },
      });
    }
  };

  async function init() {
    setHeaderMsg("");
    if (
      activeMenu == _MENU_OPTION.Withdrawal ||
      activeMenu == _MENU_OPTION.Deposit
    ) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PENDING_TRANSACTION
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] == true) {
          setHeaderMsg(t("NEW_TXN_PROHIBITED"));
        }
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    }
  }

    return (
        <>
            {<Header
                history={_history}
                url={WebUrl._URL_MAIN}
                title={hideSummary === "true" ? t("SHAREHOLDERS") : t("VIP_EVENT")}
            />}
            <div className="vip-main-nav">
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        _history.push({ pathname: WebUrl._URL_SHAREHOLDER_DOWNLINE, state: { hideSummary: hideSummary } });
                    }}
                >
                    {t("SHAREHOLDERS")}
                </div>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        _history.push({ pathname: WebUrl._URL_SHAREHOLDER_CLAIM, state: { hideSummary: hideSummary } });
                    }}
                >
                    {t("CLAIM")}
                </div>
            </div>
            <div >
                {!stringIsNullOrEmpty(headerMsg) && (
                    <div className="alert alert-warning">{headerMsg}</div>
                )}
                {renderComponent}
            </div>
        </>
    );
};

export default Shareholder;
