import React, { useState, useEffect } from 'react';
import { ApiKey } from '../../util/Constant';
import * as Util from '../../util/Util';
import { useDispatch, useSelector } from "react-redux";
import { showMessage, showResponseMessage } from "../../redux/AppAction";
import { setPageSize, setY } from '../../redux/AppAction.js';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import Spinner from 'react-bootstrap/Spinner';

/// <summary>
/// Author: -
/// </summary>
const DynamicLoadTable2 = props => {
  const { t, i18n } = useTranslation();
  var _supportInfiniteLoading = props["infiniteLoadEnable"] ?? true;
  const _dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [totalRecords, setTotaRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [autoscrollEnabled, setAutoscrollEnabled] = useState(false);

  const [apiQuery, setApiQuery] = useState(props["apiQuery"]);
  const [title, setTitle] = useState("TOTAL_RECORD");
  const [isTable, setIsTable] = useState(false);
  const y = useSelector((state) => state.appState.y);

  const _DEFAULT_PAGE_SIZE = 20;

  const [pageSize, setPageSize] = useState(_DEFAULT_PAGE_SIZE);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!Util.stringIsNullOrEmpty(props["apiQuery"])) {
      setDataList([]);
      setTitle(props["title"]);
      setApiQuery(props["apiQuery"]);
    }
  }, [props["apiQuery"]]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    setSearchEnabled(props["searchEnabled"]);
    if (!props["searchEnabled"]) {

      setDataList([]);
    }

    if (props["autoscrollEnabled"]) {
      setAutoscrollEnabled(props["autoscrollEnabled"]);
    }

    if (props["isTable"]) {
      setIsTable(props["isTable"]);
    }

  }, [props["searchEnabled"]]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (props["returnRecord"]) {
      props.returnRecord(dataList);
    }
  }, [dataList]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    setPageSize(props['pageSize'] ? props['pageSize'] : _DEFAULT_PAGE_SIZE);
  }, [props["pageSize"]]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!Util.stringIsNullOrEmpty(apiQuery)) {
      getDataList(dataList);
    }
  }, [apiQuery, searchEnabled, pageSize]);

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    try {
      if (_supportInfiniteLoading) {
        window.addEventListener("scroll", onScrollHandler);
        return () => {
          window.removeEventListener("scroll", onScrollHandler);
        }
      }
    }
    catch {

    }
  }, [dataList, totalRecords, isLoading, pageSize]);

  /// <summary>
  /// Author: -
  /// </summary>
  function onScrollHandler() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + Math.ceil(window.pageYOffset);
    if (autoscrollEnabled && window.scrollY > 0) {
      _dispatch(setY(window.scrollY));
    }
    if (windowBottom >= docHeight && !isLoading) {
      if (dataList.length < totalRecords) {
        //getDataList(dataList);
      }
    }
  };

  /// <summary>
  /// Author: -
  /// </summary>
  async function getDataList(dataList) {
    if (searchEnabled) {
      setIsLoading(true);

      var pageNumber = Math.ceil(dataList.length / pageSize);
      var isQueryStringAppended = apiQuery.includes('?');
      var apiUrl = apiQuery + (isQueryStringAppended ? "&" : "?") + "count=" + pageSize + "&start=" + (pageNumber * pageSize);

      let responseJson = await ApiEngine.get(apiUrl);
      setIsLoading(false);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setDataList([...dataList, ...responseJson[ApiKey._API_DATA_KEY].data]);
        setTotaRecords(responseJson[ApiKey._API_DATA_KEY].totalCount);

        if (props.onComplete) {
          props.onComplete({
            totalDataLoaded: [...dataList, ...responseJson[ApiKey._API_DATA_KEY].data]['length']
          });
        }

        if (autoscrollEnabled && window.scrollY == 0 && y > 0) {
          window.scrollTo(0, y);
        }

      }
      else {
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      }
    }
    else {
      setDataList([]);
      setTotaRecords('0');
    }

    //window.scrollTo(0, 200);
  }

  return (
    <>
      {dataList.length == 0 && !isLoading && < tr > <td colSpan="20" className="font-thick">{t("NO_DATA_FOUND")}</td></tr>}
      <>
        {
          dataList.length != 0 &&
          <>

            {
              dataList.map((data, i) => {
                data.dataLength = dataList.length;
                return props.render(data, i);
              })
            }
            <div></div>
            {isLoading && !isTable ?
              <tr className="game-loading" style={{ display: "flex", justifyContent: "center" }}>
                <td colSpan="20" style={{ textAlign: "center", paddingTop: "25px", paddingBottom: "20px" }}>
                  <Spinner className="dynamic-table-spinner" animation="border" role="status" size="sm" style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}>
                    <span className="sr-only">{t("LOADING")}...</span>
                  </Spinner>
                </td>
              </tr>
              : <>
                {dataList.length < totalRecords && <div className="see-more-text" onClick={() => getDataList(dataList)}>{t("SEE_MORE")}</div>}
                </>}
          </>
        }

        {isLoading && isTable &&
          <tr style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
            <td colSpan="20" style={{ paddingTop: "25px", paddingBottom: "20px" }}>
              <Spinner className="dynamic-table-spinner" animation="border" role="status" size="sm" style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}>
                <span className="sr-only">{t("LOADING")}...</span>
              </Spinner>
            </td>
          </tr>
        }
        {/*isLoading && !isTable &&
          <tr className="game-loading" style={{ display: "flex", justifyContent: "center" }}>
            <td colSpan="20" style={{ textAlign: "center", paddingTop: "25px", paddingBottom: "20px" }}>
              <Spinner className="dynamic-table-spinner" animation="border" role="status" size="sm" style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}>
                <span className="sr-only">{t("LOADING")}...</span>
              </Spinner>
            </td>
          </tr>
        */}

      </>
    </>
  );
}

export default DynamicLoadTable2;