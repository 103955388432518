import { createStore, applyMiddleware, combineReducers } from "redux";
import appReducer from "./redux/AppReducer";
import authReducer from "./redux/AuthReducer";
import languageReducer from "./redux/LanguageReducer";
import gameReducer from "./redux/GameReducer";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
/// <summary>
/// Author :
/// </summary>

const store = createStore(combineReducers({
  authState: authReducer,
  appState: appReducer,
  languageState: languageReducer,
  gameState: gameReducer,
}), composeWithDevTools(applyMiddleware(thunk)));

export default store;