import React from 'react';
import i18n from '../../localization/i18n';
import PacgorLogo from '../../assets/img/pagcorlogo2.svg';
import CertIcons from '../../assets/img/footer/certificate.svg';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        var date = new Date();
        this.state = {
            year: date.getFullYear(),
            paymentMethodList: [
                {
                    className: "img-payment",
                    alt: "TrueMoney",
                    image: require("../../assets/img/assets/truemoney_desktop.png"),
                },
                {
                    className: "img-payment",
                    alt: "ThaiQr",
                    image: require("../../assets/img/assets/thaiqr_desktop.png"),
                },
                {
                    className: "img-payment",
                    alt: "PromptPay",
                    image: require("../../assets/img/assets/promptpay_desktop.png"),
                },
                {
                    className: "img-payment-fatty-pay",
                    style: "margin-left: 10px",
                    alt: "FattyPay",
                    image: require("../../assets/img/payment-method/FattyPay.svg"),
                },
                {
                    className: "img-payment",
                    alt: "VaderPay",
                    image: require("../../assets/img/payment-method/VaderPay.svg"),
                },
                {
                    className: "img-payment",
                    alt: "JSTPay",
                    image: require("../../assets/img/JST_Web.svg"),
                }
            ],
            securityList: [
                {
                    alt: "Iovation",
                    image: require("../../assets/img/footer/security1.svg"),
                },
                {
                    alt: "ThreatMetrix",
                    image: require("../../assets/img/footer/security2.svg"),
                },
            ],
            recommendedBrowserList: [
                {
                    alt: "Crome",
                    image: require("../../assets/img/footer/browser1.svg"),
                },
                {
                    alt: "Firefox",
                    image: require("../../assets/img/footer/browser2.svg"),
                },
            ],
            socialMediaList: [
                {
                    alt: "facebook",
                    image: require("../../assets/img/social-media/fb.svg"),
                    link: "https://www.facebook.com/i8OfficialTeam",
                },
                {
                    alt: "youtube",
                    image: require("../../assets/img/social-media/yt.svg"),
                    link: "https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ",
                },
                {
                    alt: "instagram",
                    image: require("../../assets/img/social-media/ig.svg"),
                    link: "https://instagram.com/i8.live",
                },
            ]
        }
    }

    render() {
        {/*<span style={{ color: "black" }}>Footer</span>)*/ }
        return (
            <div id="footer" className="footer">
            </div>
        )
    }
}

export default Footer;