import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { showResponseMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  DEFAULT_PAGE_SIZE,
  SessionKey,
} from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";

/// <summary>
/// Author: -
/// </summary>
const News = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  const [announcements, setAnnouncements] = useState([]);
  const [downlineUrl, setDownlineUrl] = useState(
    ApiUrl._API_GET_NOTIFICATION_LIST +
      "?languageCode=" +
      localStorage.getItem(SessionKey._LANGUAGE)
  );

  const _SELECTIONS = {
    "Deposit SMS": "DEPOSIT",
    "Withdrawal SMS": "WITHDRAWAL",
    "Reset Password SMS": "RESET_PASSWORD",
    "Phone Verfication SMS": "PHONE_VERIFICATION",
    "PIN Reset": "RESET_PIN",
  };
  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    var responseJson = await ApiEngine.get(downlineUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setAnnouncements(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_DASHBOARD}
        title={t("NEWS_BONUS")}
      />
      <div className="container" style={{ paddingBottom: "30px" }}>
        <ul className="ul-news-list">
          <DynamicLoadTable
            pageSize={DEFAULT_PAGE_SIZE}
            apiQuery={downlineUrl}
            searchEnabled={true}
            render={(announcement, index) => {
              return (
                <li
                  className={`news-item ${
                    announcement["isRead"]
                      ? "announcementRead"
                      : "announcementUnread bg-primary-light"
                  }`}
                  key={index}
                >
                  <Link
                    to={{
                      pathname: WebUrl._URL_NEWS_DETAILS,
                      state: { data: JSON.stringify(announcement) },
                    }}
                  >
                    <i
                      className={`news-item ${
                        announcement["isRead"]
                          ? "icon-news fas fa-envelope-open"
                          : "icon-news fas fa-envelope"
                      }`}
                    ></i>
                    <div>
                      <div>
                        <strong className="d-block" style={{ margin: "5px 0" }}>
                          {announcement["title"] == "Deposit SMS" ||
                          announcement["title"] == "Withdrawal SMS"
                            ? t(_SELECTIONS[announcement["title"]])
                            : announcement["title"]}
                        </strong>
                        <span style={{ fontSize: "12px" }}>
                          {moment(announcement["date"]).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </span>
                      </div>
                      <div
                        className="brief-annoucement"
                        style={{ fontSize: "12px", margin: "5px 0" }}
                        dangerouslySetInnerHTML={{
                          __html: announcement["description"],
                        }}
                      ></div>
                    </div>
                  </Link>
                </li>
              );
            }}
          />
        </ul>
      </div>
    </>
  );
};

export default News;
