import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, WebUrl, Role } from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  createFormBody,
  imagePathToFileObject,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import Input from "../../components/custom/Input";
import Header from "../../components/custom/Header";
import ApiEngine from "../../util/ApiEngine";
import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Keyboard from "react-simple-keyboard";
import { showResponseMessage } from "../../redux/AppAction";
import QRCode from "qrcode.react";
import { InlineShareButtons } from "sharethis-reactjs";
import Select from "react-select";
import moment from "moment";
import classnames from "classnames";
import DateTime from "react-datetime";
import PlusIcon from "../../assets/img/icon/ionic-ios-add-circle-outline.svg";
import MinusIcon from "../../assets/img/icon/ionic-ios-minus-circle-outline.svg";
import BluePlusIcon from "../../assets/img/icon/Add_Icon.svg";
import BlueMinusIcon from "../../assets/img/icon/Minus_Icon.svg";
import GoldPlusIcon from "../../assets/img/icon/goldPlus.svg";
import GoldMinusIcon from "../../assets/img/icon/goldMinus.svg";
import copyIcon from "../../assets/img/v3/copyIcon2.svg";
import completeIcon from "../../assets/img/v3/complete_Icon.gif";
import { setBusy, setIdle } from "../../redux/AppAction";
import { Spinner } from "react-bootstrap";
import { map } from "lodash";

/// <summary>
/// Author :
/// </summary>
const ReferralCodeDetail = (props) => {
  const { t, i18n } = useTranslation();
  const _APPEND_REGISTER_URL = "?code=";
  const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";
  const _ALERT_TIMEOUT = 2000;
  let _history = useHistory();
  let _location = useLocation();
  const _dispatch = useDispatch();
  let _userData = useSelector((state) => state.authState.userData);
  let _copyRef = useRef();
  let _copyRef1 = useRef();
  const [referralCodeDetail, setReferralCodeDetail] = useState({});
  const [referralCodeId, setReferralCodeId] = useState();
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [initCheck, setInitCheck] = useState(false);
  const [registerUrl, setRegisterUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [remark, setRemark] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showCategoryKeyboard, setShowCategoryKeyboard] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0000");
  const [currentIndexOfValue, setCurrentIndexOfValue] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");
  const [selectedBonusOrComm, setSelectedBonusOrComm] = useState("");
  const [selectedProductsIndex, setSelectedProductsIndex] = useState(0);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [valueList, setValueList] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [memberRegisterList, setMemberRegisterList] = useState([]);
  const keyboard = useRef();
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [deviceType, setDeviceType] = useState("");
  const [pinTabActive, setPinTabActive] = useState(1);
  const [registerWhatsAppUrl, setRegisterWhatsAppUrl] = useState("");
  const [copyAlert, setCopyAlert] = useState(false);
  const [copyAlert2, setCopyAlert2] = useState(false);
  const [copyAlert3, setCopyAlert3] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();

  const topRightMenuList = [
    {
      title: "EDIT",
      url: WebUrl._URL_EDIT_REFERRAL,
      stateId: _location.state && _location.state.id ? _location.state.id : 0,
    },
    {
      title: "SHARE",
    },
  ];

  const backgroundLightMaxButton =
    "linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";
  const backgroundDarkMaxButton =
    "linear-gradient(0deg, rgba(51, 105, 255, 0.5), rgba(51, 105, 255, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)";

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    init();
  }, [_location.state]);

  useEffect(() => {
    setDeviceType(window.navigator.platform);
  }, []);
  
  const pinList = [
    {
      id: 1,
      title: 'Referral Details',
    },
    {
      id: 2,
      title: 'All Member',
    },
  ];

  /// <summary>
  /// Author: -
  /// </summary>
  useEffect(() => {
    if (!_location.state) {
      products.map((product, index) => {
        setValue("bonus-" + product.productId, 0.0);
        setValue("commission-" + product.productId, 0.0);
        setValue("clubFee-" + product.productId, 0.0);
      });
      categories.map((category, index) => {
        setValue("category-bonus-" + category.id, 0.0);
        setValue("category-commission-" + category.id, 0.0);
        setValue("category-clubFee-" + category.id, 0.0);
      });
    } else {
      if (!isObjectEmpty(referralCodeDetail)) {
        referralCodeDetail.memberReferralInfos.map((info) => {
          setValue("bonus-" + info.productId, info.bonus);
          setValue("commission-" + info.productId, info.commission);
          setValue("clubFee-" + info.productId, info.clubFee);
        });
      }
    }
  }, [initCheck]);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.screen.width);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  /// <summary>
  /// Author: -
  /// </summary>
  async function getCodeDetail() {
    let apiUrl = ApiUrl._API_GET_MEMBER_REFERRAL_CODES;

    if (!stringIsNullOrEmpty(_location.state.id)) {
      apiUrl += "?id=" + _location.state.id;
    }
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY][0];
        setReferralCodeDetail(data);
        console.log(data);
      setExpiryDate(moment(data["expiryDate"]).format("YYYY-MM-DD"));
      setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + data.code);
      let apiUrl1 = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=ChatSpherePhoneNumber";
      let settingJson = await ApiEngine.get(apiUrl1);

      if (settingJson[ApiKey._API_SUCCESS_KEY]) {
        let data1 = settingJson[ApiKey._API_DATA_KEY];
        setRegisterWhatsAppUrl("https://wa.me/" + data1 + _APPEND_REGISTER_WHATSAPP_URL + data.code);
      }
      setValue("remark", data.remark);

      let memberListJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_REGISTERED_UNDER_CODE +
          "?id=" +
          _location.state.id
      );
      if (memberListJson[ApiKey._API_SUCCESS_KEY]) {
        let memberListData = memberListJson[ApiKey._API_DATA_KEY];
        setMemberRegisterList(memberListData);
      }
    }
    return data;
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function onModalOpened() {
    if (!isObjectEmpty(referralCodeDetail)) {
      referralCodeDetail.memberReferralInfos.map((info) => {
        setValue("bonus-" + info.productId, info.bonus);
        setValue("commission-" + info.productId, info.commission);
        setValue("clubFee-" + info.productId, info.clubFee);
      });
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    let tempReferralCodeDetail = [];
    if (_location.state) {
      setReferralCodeId(_location.state.id);
      tempReferralCodeDetail = await getCodeDetail();

      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_WITH_MAX_RATE
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let productData = responseJson[ApiKey._API_DATA_KEY];
        setProducts(productData);

        responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let categoryData = responseJson[ApiKey._API_DATA_KEY];
          setCategories(categoryData);
          setActiveTab(categoryData[0]["id"]);
          let tempObj = {};
          let tempHighestMaxBonus = 0.0;
          let tempHighestMaxComm = 0.0;
          let tempHighestMaxClubFee = 0.0;
          let tempAvailableCategories = [];
          categoryData.map((category, index) => {
            //if (tempReferralCodeDetail.length > 0 && tempReferralCodeDetail.memberReferralInfos.filter(x => x.productCategory === category.originalTitle && (x.commission > 0 || x.bonus > 0 || x.clubFee > 0)).length > 0 || category.originalTitle === "All") {
            //  tempAvailableCategories.push(category);
            //}
            if (!tempObj[category.originalTitle]) {
              tempObj[category.originalTitle] = {
                bonusValues: [],
                commValues: [],
                clubFeeValues: [],
                highestMaxBonus: 0,
                highestMaxComm: 0,
                highestMaxClubFee: 0,
              };
            }
            productData
              .filter((x) => x.categoryName === category.originalTitle)
              .map((product, i) => {
                if (i === 0) {
                  tempHighestMaxBonus = product.maxBonus;
                  tempHighestMaxComm = product.maxCommission;
                  tempHighestMaxClubFee = product.maxClubFee;
                }
                if (!tempObj[category.originalTitle][product.productName])
                  tempObj[category.originalTitle][product.productName] = {
                    bonusValues: [],
                    commValues: [],
                    clubFeeValues: [],
                  };
                if (product.maxBonus > tempHighestMaxBonus) {
                  tempHighestMaxBonus = product.maxBonus;
                }
                if (product.maxCommission > tempHighestMaxComm) {
                  tempHighestMaxComm = product.maxCommission;
                }
                if (product.maxClubFee > tempHighestMaxClubFee) {
                  tempHighestMaxClubFee = product.maxClubFee;
                }
                if (product.maxBonus > 0) {
                  for (
                    var i = 0.0;
                    parseFloat(i.toFixed(2)) <=
                    parseFloat(product.maxBonus.toFixed(2));
                    i += 0.1
                  ) {
                    i = i * 100;
                    i = Math.round(i);
                    i = i / 100;
                    i = parseFloat(i.toFixed(2));
                    if (i === 0.0) {
                      tempObj[category.originalTitle][
                        product.productName
                      ].bonusValues.push("-");
                    }
                    tempObj[category.originalTitle][
                      product.productName
                    ].bonusValues.push(i);
                  }
                }
                if (product.maxCommission > 0) {
                  for (
                    var i = 0.0;
                    parseFloat(i.toFixed(2)) <=
                    parseFloat(product.maxCommission.toFixed(2));
                    i += 0.1
                  ) {
                    i = i * 100;
                    i = Math.round(i);
                    i = i / 100;
                    i = parseFloat(i.toFixed(2));
                    if (i === 0.0) {
                      tempObj[category.originalTitle][
                        product.productName
                      ].commValues.push("-");
                    }
                    tempObj[category.originalTitle][
                      product.productName
                    ].commValues.push(i);
                  }
                }
                if (product.maxClubFee > 0) {
                  for (
                    var i = 0.0;
                    parseFloat(i.toFixed(2)) <=
                    parseFloat(product.maxClubFee.toFixed(2));
                    i += 5.0
                  ) {
                    i = i * 100;
                    i = Math.round(i);
                    i = i / 100;
                    i = parseFloat(i.toFixed(2));
                    if (i === 0.0) {
                      tempObj[category.originalTitle][
                        product.productName
                      ].clubFeeValues.push("-");
                    }
                    tempObj[category.originalTitle][
                      product.productName
                    ].clubFeeValues.push(i);
                  }
                }
              });

            tempObj[category.originalTitle]["highestMaxBonus"] =
              tempHighestMaxBonus;
            tempObj[category.originalTitle]["highestMaxComm"] =
              tempHighestMaxComm;
            tempObj[category.originalTitle]["highestMaxClubFee"] =
              tempHighestMaxClubFee;

            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(tempHighestMaxBonus.toFixed(2));
              i += 0.1
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.0) {
                tempObj[category.originalTitle].bonusValues.push("-");
              }
              tempObj[category.originalTitle].bonusValues.push(i);
            }
            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(tempHighestMaxComm.toFixed(2));
              i += 0.1
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.0) {
                tempObj[category.originalTitle].commValues.push("-");
              }
              tempObj[category.originalTitle].commValues.push(i);
            }
            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(tempHighestMaxClubFee.toFixed(2));
              i += 5.0
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));
              if (i === 0.0) {
                tempObj[category.originalTitle].clubFeeValues.push("-");
              }
              tempObj[category.originalTitle].clubFeeValues.push(i);
            }
          });
          setValueList(tempObj);
          //setCategories(tempAvailableCategories);
        }
      }
    } else {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_PRODUCT_RATES
      );
      let uplineProductRates = [];
      let processedProducts = [];
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }

      var productResponseJson = await ApiEngine.get(
        ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT
      );
      if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
        productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
          let uplineProduct = uplineProductRates.filter(
            (i) => i.accountProductId == product.accountProductId
          )[0];
          processedProducts.push({
            id: product.id,
            productId: product.productId,
            accountProductId: product.accountProductId,
            productName:
              product.productName + (product.useGroupRate ? " *" : ""),
            assigned: product.assigned,
            bonus: product.bonus,
            commission: product.commission,
            clubFee: product.clubFee,
            categoryName: product.categoryName,
            maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
            maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
            maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
            useGroupRate: product.useGroupRate,
          });
        });
      } else {
        throw productResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProduct(
        products["length"] == 0
          ? processedProducts[0]
          : processedProducts.find((x) => x["id"] == product["id"])
      );
      setProducts(processedProducts);

      let categoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
      if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
        let categoryData = categoryJson[ApiKey._API_DATA_KEY];
        setActiveTab(categoryData[0]["id"]);
        setCategories(categoryData);
        let tempObj = {};
        let tempHighestMaxBonus = 0.0;
        let tempHighestMaxComm = 0.0;
        let tempHighestMaxClubFee = 0.0;
        categoryData.map((category, index) => {
          if (!tempObj[category.originalTitle]) {
            tempObj[category.originalTitle] = {
              bonusValues: [],
              commValues: [],
              clubFeeValues: [],
              highestMaxBonus: 0,
              highestMaxComm: 0,
              highestMaxClubFee: 0,
            };
          }
          processedProducts
            .filter((x) => x.categoryName === category.originalTitle)
            .map((product, i) => {
              if (i === 0) {
                tempHighestMaxBonus = product.maxBonus;
                tempHighestMaxComm = product.maxCommission;
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (!tempObj[category.originalTitle][product.productName])
                tempObj[category.originalTitle][product.productName] = {
                  bonusValues: [],
                  commValues: [],
                  clubFeeValues: [],
                };
              if (product.maxBonus > tempHighestMaxBonus) {
                tempHighestMaxBonus = product.maxBonus;
              }
              if (product.maxCommission > tempHighestMaxComm) {
                tempHighestMaxComm = product.maxCommission;
              }
              if (product.maxClubFee > tempHighestMaxClubFee) {
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (product.maxBonus > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxBonus.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));
                  if (i === 0.0) {
                    tempObj[category.originalTitle][
                      product.productName
                    ].bonusValues.push("-");
                  }
                  tempObj[category.originalTitle][
                    product.productName
                  ].bonusValues.push(i);
                }
              }
              if (product.maxCommission > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxCommission.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  if (i === 0.0) {
                    tempObj[category.originalTitle][
                      product.productName
                    ].commValues.push("-");
                  }
                  tempObj[category.originalTitle][
                    product.productName
                  ].commValues.push(i);
                }
              }
              if (product.maxClubFee > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxClubFee.toFixed(2));
                  i += 5.0
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  if (i === 0.0) {
                    tempObj[category.originalTitle][
                      product.productName
                    ].clubFeeValues.push("-");
                  }
                  tempObj[category.originalTitle][
                    product.productName
                  ].clubFeeValues.push(i);
                }
              }
            });

          tempObj[category.originalTitle]["highestMaxBonus"] =
            tempHighestMaxBonus;
          tempObj[category.originalTitle]["highestMaxComm"] =
            tempHighestMaxComm;
          tempObj[category.originalTitle]["highestMaxClubFee"] =
            tempHighestMaxClubFee;
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxBonus.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.0) {
              tempObj[category.originalTitle].bonusValues.push("-");
            }
            tempObj[category.originalTitle].bonusValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxComm.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.0) {
              tempObj[category.originalTitle].commValues.push("-");
            }
            tempObj[category.originalTitle].commValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxClubFee.toFixed(2));
            i += 5.0
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            if (i === 0.0) {
              tempObj[category.originalTitle].clubFeeValues.push("-");
            }
            tempObj[category.originalTitle].clubFeeValues.push(i);
          }
        });
        setValueList(tempObj);
      }
    }

    setInitCheck(true);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data) => {
    _dispatch(setBusy());
    //if (expiryDate == '' || expiryDate == null) {
    //    _dispatch(showResponseMessage(false, t("EXPIRY_DATE_INVALID")));
    //}
    let params = {
      //expiryDate: expiryDate,
      remark: remark,
    };

    if (!stringIsNullOrEmpty(referralCodeId)) {
      params["id"] = referralCodeId;
    }

    Object.keys(data).map((key, value) => {
      if (data[key] !== "-") {
        params[key] = data[key];
      } else {
        params[key] = 0.0;
      }
    });

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_GENERATE_MEMBER_REFERRAL_CODE,
      formBody
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setModalVisible(false);

      /// used to show the latest detail after created and edit
      if (isObjectEmpty(referralCodeDetail)) {
        _history.push(WebUrl._URL_MANAGE_REFERRAL_CODES)
      } else {
        init();
      }
    }
    _dispatch(setIdle());
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const ReferralCodeDetailForm = () => {
    return (
      <div id={"referral-detail-modern"}>
        {/*<div className="row" style={{ marginBottom: "1em" }}>
                    <div className="col-lg-12 ">
                        <label className="col-md-4 col-form-label p-0">{t("EXPIRY_DATE")}</label>
                        <div className="col-md-7 p-0">
                            <DateTime
                                inputProps={{ className: 'form-control form-control-date', readOnly: true }}
                                name="expiryDate"
                                dateFormat="YYYY-MM-DD"
                                closeOnSelect={true}
                                timeFormat={false}
                                value={expiryDate}
                                onChange={(e) => {
                                    setExpiryDate(e.format("YYYY-MM-DD"));
                                }} />
                        </div>
                    </div>
                </div>*/}
        <div>
          <h4 className="mb-3">{t("REMARK")}:</h4>
          <div className="custom-input-container">
            <Input
              name="remark"
              defaultValue={
                _location.state && _location.state.id
                  ? !stringIsNullOrEmpty(referralCodeDetail.remark)
                    ? referralCodeDetail.remark
                    : "-"
                  : ""
              }
              ref={register()}
              placeholder={t("ENTER_OWN_REMARK")}
            />
          </div>
        </div>
        {/* <div className="row" style={{ marginBottom: "1em" }}>
          <div className="col-lg-12">
            <label className="col-md-4 col-form-label p-0">{t("REMARK")}</label>
            <div className="col-md-7 p-0">
              <Input name="remark"
                defaultValue={referralCodeDetail.remark}
                ref={register()}
                placeholder={t("REMARK")} />
            </div>
          </div>
        </div> */}
        {/* <div className="referral-commission-container mt-4 pb-2">
        {categories.map((category, index) => {
          if (products.filter(x => x.categoryName === category.originalTitle && (x.maxBonus > 0 || x.maxCommission > 0 || x.maxClubFee > 0)).length > 0) {
            return (
              <>
                {products.filter(x => x.categoryName === category.originalTitle && (x.maxCommission > 0)).length > 0 &&
                  <div className="referral-commission-item">
                    <div className="first">
                      {t(category.title)}
                    </div>
                    <div className="second">
                      <div className="title">
                      {t("COMMISSION_SHORT")}<br/>{t("MAX") + ":" + (valueList[category.originalTitle].highestMaxComm).toFixed(2) + "%"}
                      </div>
                      <div className="add-minus-box">
                        <div>
                          <img src={require("../../assets/img/v3/minus.svg")} alt="minus" />
                        </div>
                        <div>
                          <input type='text'/>
                        </div>
                        <div>
                          <img src={require("../../assets/img/v3/add.svg")} alt="minus" />
                        </div>
                      </div>
                      <div className="btn-max">
                        MAX
                      </div>
                    </div>
                  </div>
                }
              </>
            );
          }
        })}
        </div> */}
        <div>
          <table className="edit-referral-table">
            <thead></thead>
            <tbody>
              {categories.map((category, index) => {
                if (
                  products.filter(
                    (x) =>
                      x.categoryName === category.originalTitle &&
                      (x.maxBonus > 0 ||
                        x.maxCommission > 0 ||
                        x.maxClubFee > 0)
                  ).length > 0
                ) {
                  return (
                    <>
                      {products.filter(
                        (x) =>
                          x.categoryName === category.originalTitle &&
                          x.maxBonus > 0
                      ).length > 0 ? (
                        <>
                          {!products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxBonus > 0
                          )[0] && <hr className="edit-referral-hr" />}
                          {index % 2 == 0 ? (
                            <div className="hr-right mt-3 mb-3"></div>
                          ) : (
                            <div className="hr-left mt-3 mb-3"></div>
                          )}
                          <tr
                            className="data-row category"
                            style={{
                              borderBottomLeftRadius:
                                valueList[
                                  category.originalTitle
                                ].highestMaxBonus.toFixed(2) > 0
                                  ? "0"
                                  : "8px",
                              borderBottomRightRadius:
                                valueList[
                                  category.originalTitle
                                ].highestMaxBonus.toFixed(2) > 0
                                  ? "0"
                                  : "8px",
                              boxShadow:
                                valueList[
                                  category.originalTitle
                                ].highestMaxBonus.toFixed(2) > 0
                                  ? "0px 0px 0px rgba(0, 0, 0, 0)"
                                  : "0px 4px 7px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <td className="row-data-1">
                              <b className="category-title">
                                {t(category.title)}
                              </b>
                            </td>
                            <td className="row-data-2">
                              <div className="txt-1">{t("BONUS")}</div>
                              <div className="txt-2">
                                {t("MAX") +
                                  ":" +
                                  valueList[
                                    category.originalTitle
                                  ].highestMaxBonus.toFixed(2) +
                                  "%"}
                              </div>
                            </td>
                            <td className="row-data-3">
                              <Controller
                                control={control}
                                name={"category-bonus-" + category.id}
                                defaultValue={0}
                                render={({ onChange, value }) => {
                                  let valueNotLowest =
                                    checkValueForLowest(value);
                                  let valueNotHighest = checkValueForHighest(
                                    value,
                                    category,
                                    "bonus"
                                  );

                                  if (
                                    document.getElementById(
                                      "category-" + category.id + "-bonus"
                                    ) !== null
                                  ) {
                                    if (!valueNotHighest) {
                                      document.getElementById(
                                        "category-" + category.id + "-bonus"
                                      ).style.background =
                                        backgroundDarkMaxButton;
                                    } else {
                                      document.getElementById(
                                        "category-" + category.id + "-bonus"
                                      ).style.background =
                                        backgroundLightMaxButton;
                                    }
                                  }

                                  return (
                                    <>
                                      <div className="add-minus-box">
                                        <div
                                          onClick={(e) => {
                                            if (valueNotLowest === true) {
                                              if (value !== "-") {
                                                value -= 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              } else {
                                                value = 0.0;
                                              }

                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.id,
                                                category.originalTitle,
                                                value
                                              );
                                            }
                                          }}
                                        >
                                          <img
                                            style={{
                                              opacity: valueNotLowest
                                                ? "1"
                                                : "0.4",
                                            }}
                                            src={require("../../assets/img/v3/minus.svg")}
                                            alt="minus"
                                          />
                                        </div>

                                        <select
                                          className="bonusComm-option-select"
                                          value={value}
                                          placeholder={value}
                                          onChange={(e) => {
                                            handleSelectCatValue(
                                              "bonus",
                                              category.id,
                                              category.originalTitle,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {valueList[
                                            category.originalTitle
                                          ].bonusValues.map((value, index) => {
                                            return (
                                              <option
                                                key={index}
                                                className="bonusComm-option-value"
                                                value={
                                                  value !== "-"
                                                    ? parseFloat(
                                                        value.toFixed(2)
                                                      )
                                                    : "-"
                                                }
                                              >
                                                {value !== "-"
                                                  ? value.toFixed(2)
                                                  : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                    ? '\u00A0\u00A0\u00A0\u00A0-'
                                                    : "-"
                                                }
                                              </option>
                                            );
                                          })}
                                        </select>
                                        <div
                                          onClick={(e) => {
                                            if (valueNotHighest === true) {
                                              if (value !== "-") {
                                                value += 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                              } else {
                                                value = 0.0;
                                              }
                                              setValue(
                                                "category-bonus-" + category.id,
                                                value
                                              );
                                              handleCatBtn(
                                                "bonus",
                                                category.id,
                                                category.originalTitle,
                                                value
                                              );
                                            }
                                          }}
                                        >
                                          <img
                                            style={{
                                              opacity: valueNotHighest
                                                ? "1"
                                                : "0.4",
                                            }}
                                            src={require("../../assets/img/v3/add.svg")}
                                            alt="add"
                                          />
                                        </div>
                                      </div>
                                      {errors.editPercent && (
                                        <div className="invalid-feedback">
                                          {t(errors.editPercent.message)}
                                        </div>
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </td>
                            <td className="row-data-4">
                              <div
                                className="row-data-max"
                                id={"category-" + category.id + "-bonus"}
                                onClick={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "bonus"
                                  )
                                }
                              >
                                {t("MAX")}
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {category.originalTitle == "Lottery" ? (
                            <div className="hr-right mt-3 mb-3"></div>
                          ) : null}
                        </>
                      )}

                      {category.originalTitle != "Lottery" ? (
                        index % 2 == 0 ? (
                          <div className="hr-right mt-3 mb-3"></div>
                        ) : (
                          <div className="hr-left mt-3 mb-3"></div>
                        )
                      ) : null}

                      {products.filter(
                        (x) =>
                          x.categoryName === category.originalTitle &&
                          x.maxCommission > 0
                      ).length > 0 && (
                        <tr
                          className="data-row category"
                          style={{
                            marginTop:
                              products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxBonus > 0
                              ).length > 0
                                ? "0"
                                : "25px",
                            borderTopLeftRadius:
                              valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0
                                ? "0"
                                : "8px",
                            borderTopRightRadius:
                              valueList[
                                category.originalTitle
                              ].highestMaxBonus.toFixed(2) > 0
                                ? "0"
                                : "8px",
                          }}
                        >
                          <td className="row-data-1">
                            {products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxBonus > 0
                            ).length > 0 ? (
                              ""
                            ) : (
                              <b className="category-title">
                                {t(category.title)}
                              </b>
                            )}
                          </td>
                          <td className="row-data-2">
                            <div className="txt-1">{t("COMMISSION_SHORT")}</div>
                            <div className="txt-2">
                              {t("MAX") +
                                ":" +
                                valueList[
                                  category.originalTitle
                                ].highestMaxComm.toFixed(2) +
                                "%"}
                            </div>
                          </td>
                          <td className="row-data-3">
                            {products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxCommission > 0
                            ).length > 0 && (
                              <>
                                <Controller
                                  control={control}
                                  name={"category-commission-" + category.id}
                                  defaultValue={0}
                                  render={({ onChange, value }) => {
                                    let valueNotLowest =
                                      checkValueForLowest(value);
                                    let valueNotHighest = checkValueForHighest(
                                      value,
                                      category,
                                      "commission"
                                    );

                                    if (
                                      document.getElementById(
                                        "category-" +
                                          category.id +
                                          "-commission"
                                      ) !== null
                                    ) {
                                      if (!valueNotHighest) {
                                        document.getElementById(
                                          "category-" +
                                            category.id +
                                            "-commission"
                                        ).style.background =
                                          backgroundDarkMaxButton;
                                      } else {
                                        document.getElementById(
                                          "category-" +
                                            category.id +
                                            "-commission"
                                        ).style.background =
                                          backgroundLightMaxButton;
                                      }
                                    }
                                    return (
                                      <>
                                        <div className="add-minus-box">
                                          <div
                                            onClick={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== "-") {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                // handleCatBtn("commission", category.originalTitle, value);
                                                handleCatBtn(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              style={{
                                                opacity: valueNotLowest
                                                  ? "1"
                                                  : "0.4",
                                              }}
                                              src={require("../../assets/img/v3/minus.svg")}
                                              alt="minus"
                                            />
                                          </div>
                                          <select
                                            className="bonusComm-option-select"
                                            value={value}
                                            placeholder={value}
                                            onChange={(e) => {
                                              handleSelectCatValue(
                                                "commission",
                                                category.id,
                                                category.originalTitle,
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {valueList[
                                              category.originalTitle
                                            ].commValues.map(
                                              (commValue, index) => {
                                                return (
                                                  <option
                                                    className="bonusComm-option-value"
                                                    value={
                                                      commValue !== "-"
                                                        ? parseFloat(
                                                            commValue.toFixed(2)
                                                          )
                                                        : "-"
                                                    }
                                                  >
                                                    {commValue !== "-"
                                                      ? commValue.toFixed(2)
                                                      : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                        ? '\u00A0\u00A0\u00A0\u00A0-'
                                                        : "-"
                                                    }
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                          <div
                                            onClick={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== "-") {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                // handleCatBtn("commission", category.originalTitle, value);
                                                handleCatBtn(
                                                  "commission",
                                                  category.id,
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              style={{
                                                opacity: valueNotHighest
                                                  ? "1"
                                                  : "0.4",
                                              }}
                                              src={require("../../assets/img/v3/add.svg")}
                                              alt="add"
                                            />
                                          </div>
                                        </div>
                                        {errors.editPercent && (
                                          <div className="invalid-feedback">
                                            {t(errors.editPercent.message)}
                                          </div>
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td className="row-data-4">
                            <div
                              id={"category-" + category.id + "-commission"}
                              className="row-data-max"
                              onClick={() =>
                                handleMaxCatBtn(
                                  category.id,
                                  category.originalTitle,
                                  "commission"
                                )
                              }
                            >
                              {t("MAX")}
                            </div>
                          </td>
                        </tr>
                      )}
                      {category.originalTitle === "Poker" &&
                        products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxClubFee > 0
                        ).length > 0 && (
                          <tr
                            className="data-row category"
                            style={{
                              marginTop:
                                products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxCommission > 0
                                ).length > 0
                                  ? "0"
                                  : "25px",
                            }}
                          >
                            <td className="row-data-1">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxCommission > 0
                              ).length > 0 ? (
                                ""
                              ) : (
                                <b className="category-title">
                                  {t(category.title)}
                                </b>
                              )}
                            </td>
                            <td className="row-data-2">
                              <div className="txt-1">{t("CLUB_FEE")}</div>
                              <div className="txt-2">
                                {t("MAX") +
                                  ":" +
                                  valueList[
                                    category.originalTitle
                                  ].highestMaxClubFee.toFixed(2) +
                                  "%"}
                              </div>
                            </td>
                            <td className="row-data-3">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxClubFee > 0
                              ).length > 0 && (
                                <>
                                  <Controller
                                    control={control}
                                    name={"category-clubFee-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "clubFee"
                                        );

                                      if (
                                        document.getElementById(
                                          "category-" + category.id + "-clubFee"
                                        ) !== null
                                      ) {
                                        if (!valueNotHighest) {
                                          document.getElementById(
                                            "category-" +
                                              category.id +
                                              "-clubFee"
                                          ).style.background =
                                            backgroundDarkMaxButton;
                                        } else {
                                          document.getElementById(
                                            "category-" +
                                              category.id +
                                              "-clubFee"
                                          ).style.background =
                                            backgroundLightMaxButton;
                                        }
                                      }

                                      return (
                                        <>
                                          <div className="add-minus-box">
                                            <div
                                              onClick={(e) => {
                                                if (valueNotLowest === true) {
                                                  if (value !== "-") {
                                                    value -= 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  // handleCatBtn("clubFee", category.originalTitle, value);
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                style={{
                                                  opacity: valueNotLowest
                                                    ? "1"
                                                    : "0.4",
                                                }}
                                                src={require("../../assets/img/v3/minus.svg")}
                                                alt="minus"
                                              />
                                            </div>
                                            <select
                                              className="bonusComm-option-select"
                                              value={value}
                                              placeholder={value}
                                              onChange={(e) => {
                                                handleSelectCatValue(
                                                  "clubFee",
                                                  category.id,
                                                  category.originalTitle,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {valueList[
                                                category.originalTitle
                                              ].clubFeeValues.map(
                                                (value, index) => {
                                                  return (
                                                    <option
                                                      className="bonusComm-option-value"
                                                      value={
                                                        value !== "-"
                                                          ? parseFloat(
                                                              value.toFixed(2)
                                                            )
                                                          : "-"
                                                      }
                                                    >
                                                      {value !== "-"
                                                        ? value.toFixed(2)
                                                        : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                          ? '\u00A0\u00A0\u00A0\u00A0-'
                                                          : "-"
                                                      }
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                            <div
                                              onClick={(e) => {
                                                if (valueNotHighest === true) {
                                                  if (value !== "-") {
                                                    value += 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.id,
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                style={{
                                                  opacity: valueNotHighest
                                                    ? "1"
                                                    : "0.4",
                                                }}
                                                src={require("../../assets/img/v3/add.svg")}
                                                alt="add"
                                              />
                                            </div>
                                          </div>
                                          {errors.editPercent && (
                                            <div className="invalid-feedback">
                                              {t(errors.editPercent.message)}
                                            </div>
                                          )}
                                        </>
                                      );
                                    }}
                                  />
                                </>
                              )}
                            </td>
                            <td className="row-data-4">
                              <div
                                id={"category-" + category.id + "-clubFee"}
                                className="row-data-max"
                                onClick={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "clubFee"
                                  )
                                }
                              >
                                {t("MAX")}
                              </div>
                            </td>
                          </tr>
                        )}

                      {products.filter(
                        (x) => x.categoryName === category.originalTitle
                      ).length > 0 &&
                        products
                          .filter(
                            (product) =>
                              product.categoryName === category.originalTitle &&
                              (product.maxBonus > 0 ||
                                product.maxCommission > 0 ||
                                product.maxClubFee > 0)
                          )
                          .map((product, i) => {
                            return (
                              <Fragment key={i}>
                                {product.maxBonus > 0 && (
                                  <tr
                                    className={
                                      "data-row" +
                                      ((i + 1) % 2 !== 0 ? " bg-color-1" : "")
                                    }
                                  >
                                    <td className="row-data-1">
                                      <div className="row-data-title">
                                        {product.productName}
                                      </div>
                                    </td>
                                    <td className="row-data-2">
                                      <div className="txt-1">{t("BONUS")}</div>
                                      <div className="txt-2">
                                        {t("MAX") +
                                          ":" +
                                          product.maxBonus.toFixed(2) +
                                          "%"}
                                      </div>
                                    </td>
                                    <td className="row-data-3">
                                      <Controller
                                        control={control}
                                        name={"bonus-" + product.productId}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {
                                          let valueNotLowest =
                                            checkValueForLowest(value);
                                          let valueNotHighest =
                                            checkValueForHighest(
                                              value,
                                              category,
                                              "bonus"
                                            );
                                          return (
                                            <>
                                              <div className="add-minus-box">
                                                <div
                                                  onClick={(e) => {
                                                    if (
                                                      product.maxBonus > 0 &&
                                                      parseFloat(value) > 0
                                                    ) {
                                                      value -= 0.1;

                                                      document.getElementById(
                                                        "product-" +
                                                          product.productId +
                                                          "-bonus"
                                                      ).style.background =
                                                        backgroundLightMaxButton;
                                                      document
                                                        .getElementById(
                                                          "div-product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        )
                                                        .getElementsByTagName(
                                                          "img"
                                                        )[0].style.opacity =
                                                        "1";

                                                      if (
                                                        value ==
                                                        product.maxBonus
                                                      ) {
                                                        document.getElementById(
                                                          "product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        ).style.background =
                                                          backgroundDarkMaxButton;
                                                        document
                                                          .getElementById(
                                                            "div-product-" +
                                                              product.productId +
                                                              "-bonus"
                                                          )
                                                          .getElementsByTagName(
                                                            "img"
                                                          )[0].style.opacity =
                                                          "0.4";
                                                      } else {
                                                        document.getElementById(
                                                          "product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        ).style.background =
                                                          backgroundLightMaxButton;
                                                        document
                                                          .getElementById(
                                                            "div-product-" +
                                                              product.productId +
                                                              "-bonus"
                                                          )
                                                          .getElementsByTagName(
                                                            "img"
                                                          )[0].style.opacity =
                                                          "1";
                                                      }

                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      opacity: valueNotLowest
                                                        ? "1"
                                                        : "0.4",
                                                    }}
                                                    src={require("../../assets/img/v3/minus.svg")}
                                                    alt="minus"
                                                  />
                                                </div>
                                                <select
                                                  className="bonusComm-option-select"
                                                  value={value}
                                                  placeholder={value}
                                                  onChange={(e) => {
                                                    handleSelectValue(
                                                      "bonus",
                                                      product.productId,
                                                      e.target.value,
                                                      product.maxBonus
                                                    );
                                                  }}
                                                >
                                                  {valueList[
                                                    category.originalTitle
                                                  ][
                                                    product.productName
                                                  ].bonusValues.map(
                                                    (value, index) => {
                                                      return (
                                                        <option
                                                          className="bonusComm-option-value"
                                                          value={
                                                            value !== "-"
                                                              ? parseFloat(
                                                                  value.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                              : "-"
                                                          }
                                                        >
                                                          {value !== "-"
                                                            ? value.toFixed(2)
                                                            : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                              ? '\u00A0\u00A0\u00A0\u00A0-'
                                                              : "-"
                                                          }
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                                <div
                                                  id={
                                                    "div-product-" +
                                                    product.productId +
                                                    "-bonus"
                                                  }
                                                  onClick={(e) => {
                                                    if (
                                                      product.maxBonus > 0 &&
                                                      parseFloat(value) <
                                                        product.maxBonus
                                                    ) {
                                                      value += 0.1;

                                                      document.getElementById(
                                                        "product-" +
                                                          product.productId +
                                                          "-bonus"
                                                      ).style.background =
                                                        backgroundLightMaxButton;
                                                      document
                                                        .getElementById(
                                                          "div-product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        )
                                                        .getElementsByTagName(
                                                          "img"
                                                        )[0].style.opacity =
                                                        "1";

                                                      if (
                                                        value ==
                                                        product.maxBonus
                                                      ) {
                                                        document.getElementById(
                                                          "product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        ).style.background =
                                                          backgroundDarkMaxButton;
                                                        document
                                                          .getElementById(
                                                            "div-product-" +
                                                              product.productId +
                                                              "-bonus"
                                                          )
                                                          .getElementsByTagName(
                                                            "img"
                                                          )[0].style.opacity =
                                                          "0.4";
                                                      } else {
                                                        document.getElementById(
                                                          "product-" +
                                                            product.productId +
                                                            "-bonus"
                                                        ).style.background =
                                                          backgroundLightMaxButton;
                                                        document
                                                          .getElementById(
                                                            "div-product-" +
                                                              product.productId +
                                                              "-bonus"
                                                          )
                                                          .getElementsByTagName(
                                                            "img"
                                                          )[0].style.opacity =
                                                          "1";
                                                      }

                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      opacity: valueNotHighest
                                                        ? "1"
                                                        : "0.4",
                                                    }}
                                                    src={require("../../assets/img/v3/add.svg")}
                                                    alt="add"
                                                  />
                                                </div>
                                              </div>

                                              {errors.editPercent && (
                                                <div className="invalid-feedback">
                                                  {t(
                                                    errors.editPercent.message
                                                  )}
                                                </div>
                                              )}
                                            </>
                                          );
                                        }}
                                      />
                                    </td>
                                    <td className="row-data-4">
                                      <div
                                        className="row-data-max"
                                        id={
                                          "product-" +
                                          product.productId +
                                          "-bonus"
                                        }
                                        onClick={() =>
                                          handleMaxBtn(
                                            product.productId,
                                            product.maxBonus,
                                            product.maxCommission,
                                            product.maxClubFee,
                                            "bonus"
                                          )
                                        }
                                      >
                                        {t("MAX")}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {product.maxCommission > 0 && (
                                  <tr
                                    className={
                                      "data-row" +
                                      ((i + 1) % 2 !== 0
                                        ? product.maxBonus > 0
                                          ? product.maxClubFee > 0
                                            ? " bg-color-2"
                                            : " bg-color-3"
                                          : product.maxClubFee > 0
                                          ? " bg-color-1"
                                          : ""
                                        : "")
                                    }
                                  >
                                    <td className="row-data-1">
                                      {product.maxBonus > 0 ? (
                                        ""
                                      ) : (
                                        <div className="row-data-title">
                                          {product.productName}
                                        </div>
                                      )}
                                    </td>
                                    <td className="row-data-2">
                                      <div className="txt-1">
                                        {t("COMMISSION_SHORT")}
                                      </div>
                                      <div className="txt-2">
                                        {t("MAX") +
                                          ":" +
                                          product.maxCommission.toFixed(2) +
                                          "%"}
                                      </div>
                                    </td>
                                    <td className="row-data-3">
                                      {product.maxCommission > 0 && (
                                        <>
                                          <Controller
                                            control={control}
                                            name={
                                              "commission-" + product.productId
                                            }
                                            defaultValue={0}
                                            render={({ onChange, value }) => {
                                              let valueNotLowest =
                                                checkValueForLowest(value);
                                              let valueNotHighest =
                                                checkValueForHighest(
                                                  value,
                                                  category,
                                                  "commission"
                                                );
                                              return (
                                                <>
                                                  <div className="add-minus-box">
                                                    <div
                                                      className=""
                                                      onClick={(e) => {
                                                        if (value !== "-") {
                                                          if (
                                                            product.maxCommission >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 0.1;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;

                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }

                                                          if (
                                                            value ==
                                                            product.maxCommission
                                                          ) {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundDarkMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "0.4";
                                                          } else {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }
                                                        } else {
                                                          value = 0.0;
                                                        }
                                                        setValue(
                                                          "commission-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          opacity:
                                                            valueNotLowest
                                                              ? "1"
                                                              : "0.4",
                                                        }}
                                                        src={require("../../assets/img/v3/minus.svg")}
                                                        alt="minus"
                                                      />
                                                    </div>
                                                    <select
                                                      className="bonusComm-option-select"
                                                      value={value}
                                                      placeholder={value}
                                                      onChange={(e) => {
                                                        handleSelectValue(
                                                          "commission",
                                                          product.productId,
                                                          e.target.value,
                                                          product.maxCommission
                                                        );
                                                      }}
                                                    >
                                                      {valueList[
                                                        category.originalTitle
                                                      ][
                                                        product.productName
                                                      ].commValues.map(
                                                        (commValue, index) => {
                                                          return (
                                                            <option
                                                              className="bonusComm-option-value"
                                                              value={
                                                                commValue !==
                                                                "-"
                                                                  ? parseFloat(
                                                                      commValue.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  : "-"
                                                              }
                                                            >
                                                              {commValue !== "-"
                                                                ? commValue.toFixed(2)
                                                                : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                                  ? '\u00A0\u00A0\u00A0\u00A0-'
                                                                  : "-"
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                    <div
                                                      id={
                                                        "div-product-" +
                                                        product.productId +
                                                        "-commission"
                                                      }
                                                      onClick={(e) => {
                                                        if (value !== "-") {
                                                          if (
                                                            product.maxCommission >
                                                              0 &&
                                                            parseFloat(value) <
                                                              product.maxCommission
                                                          ) {
                                                            value += 0.1;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;

                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }

                                                          if (
                                                            value ==
                                                            product.maxCommission
                                                          ) {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundDarkMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "0.4";
                                                          } else {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-commission"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-commission"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }
                                                        } else {
                                                          value = 0.0;
                                                        }
                                                        setValue(
                                                          "commission-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          opacity:
                                                            valueNotHighest
                                                              ? "1"
                                                              : "0.4",
                                                        }}
                                                        src={require("../../assets/img/v3/add.svg")}
                                                        alt="add"
                                                      />
                                                    </div>
                                                  </div>
                                                  {errors.editPercent && (
                                                    <div className="invalid-feedback">
                                                      {t(
                                                        errors.editPercent
                                                          .message
                                                      )}
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                    </td>
                                    <td className="row-data-4">
                                      <div
                                        className="row-data-max"
                                        id={
                                          "product-" +
                                          product.productId +
                                          "-commission"
                                        }
                                        onClick={() =>
                                          handleMaxBtn(
                                            product.productId,
                                            product.maxBonus,
                                            product.maxCommission,
                                            product.maxClubFee,
                                            "commission"
                                          )
                                        }
                                      >
                                        {t("MAX")}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {product.productName === "D2 Poker" &&
                                  product.maxClubFee > 0 && (
                                    <tr
                                      className={
                                        "data-row" +
                                        ((i + 1) % 2 !== 0
                                          ? product.maxClubFee > 0 &&
                                            (product.maxBonus > 0 ||
                                              product.maxCommission > 0)
                                            ? " bg-color-3"
                                            : " bg-color"
                                          : " bg-color")
                                      }
                                    >
                                      <td className="row-data-1">
                                        {product.maxCommission > 0 ? (
                                          ""
                                        ) : (
                                          <div className="row-data-title">
                                            {product.productName}
                                          </div>
                                        )}
                                      </td>
                                      <td className="row-data-2">
                                        <div className="txt-1">
                                          {t("CLUB_FEE")}
                                        </div>
                                        <div className="txt-2">
                                          {t("MAX") +
                                            ":" +
                                            product.maxClubFee.toFixed(2) +
                                            "%"}
                                        </div>
                                      </td>
                                      <td className="row-data-3">
                                        {product.maxClubFee > 0 && (
                                          <>
                                            <Controller
                                              control={control}
                                              name={
                                                "clubFee-" + product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                let valueNotLowest =
                                                  checkValueForLowest(value);
                                                let valueNotHighest =
                                                  checkValueForHighest(
                                                    value,
                                                    category,
                                                    "clubFee"
                                                  );

                                                return (
                                                  <>
                                                    <div className="add-minus-box">
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 5.0;

                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-clubFee"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-clubFee"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }

                                                          if (
                                                            value ==
                                                            product.maxClubFee
                                                          ) {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-clubFee"
                                                            ).style.background =
                                                              backgroundDarkMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-clubFee"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "0.4";
                                                          } else {
                                                            document.getElementById(
                                                              "product-" +
                                                                product.productId +
                                                                "-clubFee"
                                                            ).style.background =
                                                              backgroundLightMaxButton;
                                                            document
                                                              .getElementById(
                                                                "div-product-" +
                                                                  product.productId +
                                                                  "-clubFee"
                                                              )
                                                              .getElementsByTagName(
                                                                "img"
                                                              )[0].style.opacity =
                                                              "1";
                                                          }

                                                          setValue(
                                                            "clubFee-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          style={{
                                                            opacity:
                                                              valueNotLowest ||
                                                              value == "-"
                                                                ? "1"
                                                                : "0.4",
                                                          }}
                                                          src={require("../../assets/img/v3/minus.svg")}
                                                          alt="minus"
                                                        />
                                                      </div>
                                                      <select
                                                        className="bonusComm-option-select"
                                                        value={value}
                                                        placeholder={value}
                                                        onChange={(e) => {
                                                          handleSelectValue(
                                                            "clubFee",
                                                            product.productId,
                                                            e.target.value,
                                                            product.maxClubFee
                                                          );
                                                        }}
                                                      >
                                                        {valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].clubFeeValues.map(
                                                          (
                                                            clubFeeValues,
                                                            index
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={index}
                                                                className="bonusComm-option-value"
                                                                value={
                                                                  clubFeeValues !==
                                                                  "-"
                                                                    ? parseFloat(
                                                                        clubFeeValues.toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                    : "-"
                                                                }
                                                              >
                                                                {clubFeeValues !== "-"
                                                                  ? clubFeeValues.toFixed(2)
                                                                  : (deviceType == "MacIntel" || deviceType == "MacPPC" || deviceType == "iPhone" || deviceType == "iPad" || deviceType == "iPod")
                                                                    ? '\u00A0\u00A0\u00A0\u00A0-'
                                                                    : "-"
                                                                }
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                      <div
                                                        id={
                                                          "div-product-" +
                                                          product.productId +
                                                          "-clubFee"
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) <
                                                              product.maxClubFee
                                                          ) {
                                                            value += 5.0;
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <img
                                                          style={{
                                                            opacity:
                                                              valueNotHighest
                                                                ? "1"
                                                                : "0.4",
                                                          }}
                                                          src={require("../../assets/img/v3/add.svg")}
                                                          alt="add"
                                                        />
                                                      </div>
                                                    </div>
                                                    {errors.editPercent && (
                                                      <div className="invalid-feedback">
                                                        {t(
                                                          errors.editPercent
                                                            .message
                                                        )}
                                                      </div>
                                                    )}
                                                  </>
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      </td>
                                      <td className="row-data-4">
                                        <div
                                          className="row-data-max"
                                          id={
                                            "product-" +
                                            product.productId +
                                            "-clubFee"
                                          }
                                          onClick={() =>
                                            handleMaxBtn(
                                              product.productId,
                                              product.maxBonus,
                                              product.maxCommission,
                                              product.maxClubFee,
                                              "clubFee"
                                            )
                                          }
                                        >
                                          {t("MAX")}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                              </Fragment>
                            );
                          })}
                    </>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  function onEditReferralKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    } else {
      tempSlicedValueStr = valueStr.substring(
        tempCurrentIndex + 1,
        valueStr.length
      );
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      } else {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          } else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              } else {
                valueStr = "" + (parseInt(valueStr) + 1);
              }
            } else {
              valueStr =
                valueStr.substring(0, 2) +
                (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
                "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(
            valueStr.substring(0, 3) +
              "." +
              valueStr.substring(3, valueStr.length)
          );
        } else {
          tempValue = parseFloat(
            valueStr.substring(0, 2) +
              "." +
              valueStr.substring(2, valueStr.length)
          );
        }
        if (selectedBonusOrComm === "bonus") {
          if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
            tempValue = products[selectedProductsIndex]["maxBonus"];
          }
        } else if (selectedBonusOrComm === "commission") {
          if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
            tempValue = products[selectedProductsIndex]["maxCommission"];
          }
        } else if (selectedBonusOrComm === "clubFee") {
          if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
            tempValue = products[selectedProductsIndex]["maxClubFee"];
          }
        } else {
          //error
        }
        setValue("" + selectedBonusOrComm + "-" + selectedProductId, tempValue);
        setTimeout(() => setShowKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    } else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        } else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            } else {
              valueStr = "" + (parseInt(valueStr) + 1);
            }
          } else {
            valueStr =
              valueStr.substring(0, 2) +
              (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
              "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(
          valueStr.substring(0, 3) +
            "." +
            valueStr.substring(3, valueStr.length)
        );
      } else {
        tempValue = parseFloat(
          valueStr.substring(0, 2) +
            "." +
            valueStr.substring(2, valueStr.length)
        );
      }
      if (selectedBonusOrComm === "bonus") {
        if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
          tempValue = products[selectedProductsIndex]["maxBonus"];
        }
      } else if (selectedBonusOrComm === "commission") {
        if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
          tempValue = products[selectedProductsIndex]["maxCommission"];
        }
      } else if (selectedBonusOrComm === "clubFee") {
        if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
          tempValue = products[selectedProductsIndex]["maxClubFee"];
        }
      } else {
        //error
      }
      setValue("" + selectedBonusOrComm + "-" + selectedProductId, tempValue);
      setTimeout(() => setShowKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    } else {
      setSelectedValue("0000");
    }
  }

  function onCategoryKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    } else {
      tempSlicedValueStr = valueStr.substring(
        tempCurrentIndex + 1,
        valueStr.length
      );
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      } else {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          } else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              } else {
                valueStr = "" + (parseInt(valueStr) + 1);
              }
            } else {
              valueStr =
                valueStr.substring(0, 2) +
                (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
                "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(
            valueStr.substring(0, 3) +
              "." +
              valueStr.substring(3, valueStr.length)
          );
        } else {
          tempValue = parseFloat(
            valueStr.substring(0, 2) +
              "." +
              valueStr.substring(2, valueStr.length)
          );
        }
        if (selectedBonusOrComm === "bonus") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxBonus"]) {
                setValue("bonus-" + product.productId, product["maxBonus"]);
              } else {
                setValue("bonus-" + product.productId, tempValue);
              }
            });
        } else if (selectedBonusOrComm === "commission") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxCommission"]) {
                setValue(
                  "commission-" + product.productId,
                  product["maxCommission"]
                );
              } else {
                setValue("commission-" + product.productId, tempValue);
              }
            });
        } else if (selectedBonusOrComm === "clubFee") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxClubFee"]) {
                setValue("clubFee-" + product.productId, product["maxClubFee"]);
              } else {
                setValue("clubFee-" + product.productId, tempValue);
              }
            });
        } else {
          //error
        }
        setValue(
          "category-" + selectedBonusOrComm + "-" + selectedCategoryId,
          tempValue
        );
        setTimeout(() => setShowCategoryKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    } else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        } else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            } else {
              valueStr = "" + (parseInt(valueStr) + 1);
            }
          } else {
            valueStr =
              valueStr.substring(0, 2) +
              (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
              "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(
          valueStr.substring(0, 3) +
            "." +
            valueStr.substring(3, valueStr.length)
        );
      } else {
        tempValue = parseFloat(
          valueStr.substring(0, 2) +
            "." +
            valueStr.substring(2, valueStr.length)
        );
      }
      if (selectedBonusOrComm === "bonus") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxBonus"]) {
              setValue("bonus-" + product.productId, product["maxBonus"]);
            } else {
              setValue("bonus-" + product.productId, tempValue);
            }
          });
      } else if (selectedBonusOrComm === "commission") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxCommission"]) {
              setValue(
                "commission-" + product.productId,
                product["maxCommission"]
              );
            } else {
              setValue("commission-" + product.productId, tempValue);
            }
          });
      } else if (selectedBonusOrComm === "clubFee") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxClubFee"]) {
              setValue("clubFee-" + product.productId, product["maxClubFee"]);
            } else {
              setValue("clubFee-" + product.productId, tempValue);
            }
          });
      } else {
        //error
      }
      setValue(
        "category-" + selectedBonusOrComm + "-" + selectedCategoryId,
        tempValue
      );
      setTimeout(() => setShowCategoryKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    } else {
      setSelectedValue("0000");
    }
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );

            if (
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ) !== null
            ) {
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ).style.background = backgroundDarkMaxButton;
              document
                .getElementById(
                  "div-product-" + product.productId + "-" + bonusOrComm
                )
                .getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          } else if (value == product["max" + tempBonusOrComm]) {
            setValue(bonusOrComm + "-" + product.productId, value);

            if (
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ) !== null
            ) {
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ).style.background = backgroundDarkMaxButton;
              document
                .getElementById(
                  "div-product-" + product.productId + "-" + bonusOrComm
                )
                .getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          } else {
            setValue(bonusOrComm + "-" + product.productId, value);

            if (
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ) !== null
            ) {
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ).style.background = backgroundLightMaxButton;
              document
                .getElementById(
                  "div-product-" + product.productId + "-" + bonusOrComm
                )
                .getElementsByTagName("img")[0].style.opacity = "1";
            }
          }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          setValue(bonusOrComm + "-" + product.productId, "-");

          if (
            document.getElementById(
              "product-" + product.productId + "-" + bonusOrComm
            ) !== null
          ) {
            document.getElementById(
              "product-" + product.productId + "-" + bonusOrComm
            ).style.background = backgroundLightMaxButton;
            document
              .getElementById(
                "div-product-" + product.productId + "-" + bonusOrComm
              )
              .getElementsByTagName("img")[0].style.opacity = "1";
          }
        });
    }
    setValue("category-" + bonusOrComm + "-" + categoryId, value);
  }

  function handleSelectValue(bonusOrComm, productId, value, maxValue) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      if (value == maxValue) {
        document.getElementById(
          "product-" + productId + "-" + bonusOrComm
        ).style.background = backgroundDarkMaxButton;
        document
          .getElementById("div-product-" + productId + "-" + bonusOrComm)
          .getElementsByTagName("img")[0].style.opacity = "0.4";
      } else {
        document.getElementById(
          "product-" + productId + "-" + bonusOrComm
        ).style.background = backgroundLightMaxButton;
        document
          .getElementById("div-product-" + productId + "-" + bonusOrComm)
          .getElementsByTagName("img")[0].style.opacity = "1";
      }
    } else {
      document.getElementById(
        "product-" + productId + "-" + bonusOrComm
      ).style.background = backgroundLightMaxButton;
      document
        .getElementById("div-product-" + productId + "-" + bonusOrComm)
        .getElementsByTagName("img")[0].style.opacity = "1";
    }

    setValue(bonusOrComm + "-" + productId, value);
  }

  //function setValueOptionList(productsIndex, bonusOrComm, productId) {
  //    setSelectedProductsIndex(productsIndex);
  //    setSelectedProductId(productId);
  //    setSelectedBonusOrComm(bonusOrComm);
  //}

  //function setCatValueOptionList(categoryIndex, bonusOrComm, categoryTitle, categoryId) {
  //    setSelectedCategoryIndex(categoryIndex);
  //    setSelectedCategoryTitle(categoryTitle);
  //    setSelectedCategoryId(categoryId);
  //    setSelectedBonusOrComm(bonusOrComm);

  //    let categoryProductList = products.filter(x => x.categoryName === categoryTitle);
  //    let tempList = [];
  //    categoryProductList.map((product, index) => {
  //        if (bonusOrComm === "bonus") {
  //            tempList.push(product.maxBonus);
  //        }
  //        else if (bonusOrComm === "commission") {
  //            tempList.push(product.maxCommission);
  //        }
  //    });

  //    let tempOptionsList = [];
  //    let tempValue = 0.0;
  //    categoryProductList.map((product, index) => {
  //        tempOptionsList.push(tempValue);
  //        if (Math.max(tempList) > tempValue) {
  //            tempValue += 0.1;
  //        }
  //    });
  //    setValueList(tempOptionsList);
  //}

  function handleCatBtn(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );
          } else if (value == product["max" + tempBonusOrComm]) {
            setValue(bonusOrComm + "-" + product.productId, value);

            if (
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ) !== null
            ) {
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ).style.background = backgroundDarkMaxButton;
              document
                .getElementById(
                  "div-product-" + product.productId + "-" + bonusOrComm
                )
                .getElementsByTagName("img")[0].style.opacity = "0.4";
            }
          } else {
            setValue(bonusOrComm + "-" + product.productId, value);

            if (
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ) !== null &&
              document.getElementById(
                "category-" + categoryId + "-" + bonusOrComm
              ) !== null
            ) {
              document.getElementById(
                "product-" + product.productId + "-" + bonusOrComm
              ).style.background = backgroundLightMaxButton;
              document
                .getElementById(
                  "div-product-" + product.productId + "-" + bonusOrComm
                )
                .getElementsByTagName("img")[0].style.opacity = "1";
            }
          }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          setValue(bonusOrComm + "-" + product.productId, "-");

          if (
            document.getElementById(
              "product-" + product.productId + "-" + bonusOrComm
            ) !== null
          ) {
            document.getElementById(
              "product-" + product.productId + "-" + bonusOrComm
            ).style.background = backgroundLightMaxButton;
            document
              .getElementById(
                "div-product-" + product.productId + "-" + bonusOrComm
              )
              .getElementsByTagName("img")[0].style.opacity = "1";
          }
        });
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {
    document.getElementById(
      "product-" + productId + "-" + bonusOrComm
    ).style.background = backgroundDarkMaxButton;
    document
      .getElementById("div-product-" + productId + "-" + bonusOrComm)
      .getElementsByTagName("img")[0].style.opacity = "0.4";

    if (bonusOrComm === "bonus") {
      setValue("bonus-" + productId, maxBonus);
    } else if (bonusOrComm === "commission") {
      setValue("commission-" + productId, maxComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("clubFee-" + productId, maxClubFee);
    }
  }

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {
        if (
          document.getElementById(
            "product-" + product.productId + "-" + bonusOrComm
          ) !== null &&
          document.getElementById(
            "category-" + categoryId + "-" + bonusOrComm
          ) !== null
        ) {
          document.getElementById(
            "product-" + product.productId + "-" + bonusOrComm
          ).style.background = backgroundDarkMaxButton;
          document
            .getElementById(
              "div-product-" + product.productId + "-" + bonusOrComm
            )
            .getElementsByTagName("img")[0].style.opacity = "0.4";
        }

        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function checkValueForLowest(value) {
    if (value !== "-") {
      if (value === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (value !== "-") {
      if (bonusOrComm === "bonus") {
        if (value === valueList[category.originalTitle].highestMaxBonus) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "commission") {
        if (value === valueList[category.originalTitle].highestMaxComm) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "clubFee") {
        if (value === valueList[category.originalTitle].highestMaxClubFee) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  /// <summary>
  /// Author: -
  /// </summary>
  function copyUrl() {
    //_copyRef.current.select();
    //console.log("copyUrl", registerUrl);
    navigator.clipboard.writeText(registerUrl);
    document.execCommand("copy");
      if (!copyAlert3) {
          setCopyAlert(false);
          setCopyAlert2(false);
          setCopyAlert3(true);
          setTimeout(() => setCopyAlert3(false), [5000]);
      }
     //_dispatch(showResponseMessage(true, t("COPY_SUCCESS")));


    //setShowAlert(true);
    //setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
  }

  function copyUrl1() {
    //_copyRef1.current.select();
    //console.log("copyUrl1", registerWhatsAppUrl);
    navigator.clipboard.writeText(registerWhatsAppUrl);
    document.execCommand("copy");
      if (!copyAlert2) {
          setCopyAlert(false);
          setCopyAlert2(true);
          setCopyAlert3(false);
          setTimeout(() => setCopyAlert2(false), [5000]);
      }
    //_dispatch(showResponseMessage(true, t("COPY_SUCCESS")));
  }

  function copyCode() {
    navigator.clipboard.writeText(referralCodeDetail.code);
      document.execCommand('copy');
      if (!copyAlert) {
          setCopyAlert(true);
          setCopyAlert2(false);
          setCopyAlert3(false);
          setTimeout(() => setCopyAlert(false), [5000]);
      }
      
    //_dispatch(showResponseMessage(true, t("COPY_SUCCESS")));
  }

  // function handleClick(registerUrl) {
  //   if (window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         action: "share",
  //         url: `${registerUrl}`,
  //       })
  //     );
  //   }
  // }

  /// <summary>
  /// Author: -
  /// </summary>
  const ShareButton = useMemo(() => {
    if (!stringIsNullOrEmpty(registerUrl)) {
      return (
        <InlineShareButtons
          config={{
            alignment: 'center',
            color: 'social',
            enabled: true,
            language: "en",
            networks: [
              "whatsapp",
              "facebook",
              "twitter",
              "wechat",
              "instagram",
              "telegram",
            ],
            padding: 10,
            radius: 40,
            size: 40,

            url: registerUrl,
          }}
        />
      );
    } else {
      return null;
    }
  }, [registerUrl]);

  /// <summary>
  /// Author :
  /// </summary>
  return (
    <div>
      <Header
        history={_history}
        url={WebUrl._URL_MANAGE_REFERRAL_CODES}
        title={
          _location.state && _location.state.id
            ? t("EDIT_REFERRAL_DETAIL")
            : t("NEW_REFERRAL_DETAIL")
        }
        hasRightMenu={_location.state && _location.state.id ? true : false}
        rightMenuList={topRightMenuList}
        setShowModal={setShowShareModal}
      />
      <div id="referral-code-detail" style={{ paddingBottom: "30px" }}>
        <form>
          {isObjectEmpty(referralCodeDetail) ? (
            <>
              {initCheck ? (
                <>
                  {ReferralCodeDetailForm()}
                  <div style={{ margin: "1rem 0px" }}>
                    <button
                      type="button"
                      className="btn custom-btn mt-4 mb-5 w-100"
                      onClick={() => handleSubmit(submitForm)()}
                    >
                      {t("SUBMIT")}
                    </button>
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </>
          ) : (
            <>
              {initCheck ? (
                <>
                  {!isObjectEmpty(referralCodeDetail) && initCheck ? (
                    <>
                      <div>
                        <div>
                          <div className="referral-details-qr-box">
                            <div className="first">
                              <div>
                                <div className="font11 mb-10">
                                  {t("REFERRAL_CODE")}
                                </div>
                                <div className="referral-code-box">
                                  <div>
                                    {!isObjectEmpty(referralCodeDetail)
                                      ? referralCodeDetail.code
                                      : ""}{" "}
                                    +
                                  </div>
                                    <div>
                                      <img
                                        onClick={() => copyCode()}
                                        src={`${copyAlert ? completeIcon : copyIcon}?t=${new Date().getTime()}`}
                                        alt="copy"
                                      />
                                    </div> 
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="font11 mb-10">
                                  {t("REMARK")}
                                </div>
                                <div className="referral-code-box">
                                  <div className="remark">
                                    {referralCodeDetail["remark"] == ""
                                      ? "-"
                                      : referralCodeDetail["remark"]}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="second">
                              <QRCode value={registerUrl} />
                            </div>
                          </div>
                          <div className="referral-link-wrapper">
                            <div style={{ marginBottom: "4px" }}>
                              {t("REFERRAL_LINK")}
                            </div>
                            <Input
                              ref={_copyRef}
                              value={registerUrl}
                              readOnly={true}
                              containerClassName={" mb-0"}
                            />
                            <img
                              className="icon-white referral-copy-btn"
                              onClick={() => copyUrl()}
                              src={`${copyAlert3 ? completeIcon : copyIcon}?t=${new Date().getTime()}`}
                              alt=""
                            />
                          </div>
                          <div className="referral-link-wrapper">
                            <div style={{ marginBottom: "4px" }}>
                              {t("WHATSAPP_REFERRAL_LINK")}
                            </div>
                            <Input
                              ref={_copyRef1}
                              value={registerWhatsAppUrl}
                              readOnly={true}
                              containerClassName={" mb-0"}
                            />
                            <img
                              className="icon-white referral-copy-btn"
                              onClick={() => copyUrl1()}
                              src={`${copyAlert2 ? completeIcon : copyIcon}?t=${new Date().getTime()}`}
                              alt=""
                            />
                          </div>
                          <div className="hr-right mb-3 mt-3"></div>
                          <div className="transfer-header-tab-container">
                          {
                                (pinList &&
                                pinList.length &&
                                map(pinList, (item, index) => {
                                    return (
                                        <div key={index} className={item.id == pinTabActive ? "transfer-tab-list-item active" : "transfer-tab-list-item"} onClick={() => setPinTabActive(item.id)}>
                                        {t(((item.title).replace(' ', '_')).toLocaleUpperCase(), { value: memberRegisterList.length })}
                                        </div>
                                    );
                                }))
                          }
                            </div>
                            <div className="hr-left mb-3 mt-3"></div>
                               {pinTabActive == 1 && (
                              <>
                                <div
                                  className="referral-nav"
                                  style={{
                                    display: "flex",
                                    padding: "16px 0px",
                                    justifyContent: "start",
                                    overflowX: "scroll",
                                    columnGap: "10px",
                                  }}
                                >
                              {categories.filter((x) => x.originalTitle !== "All")
                                    .length > 2 &&
                                     categories.map((category, i) => {
                                      if (
                                        referralCodeDetail.memberReferralInfos.filter(
                                          (x) =>
                                            x.productCategory ===
                                            category.originalTitle &&
                                            (x.commission > 0 ||
                                              x.bonus > 0 ||
                                              x.clubFee > 0)
                                        ).length > 0 ||
                                          (category.originalTitle === "All" && referralCodeDetail.memberReferralInfos.filter(
                                              (x) => (x.commission > 0 ||
                                                      x.bonus > 0 ||
                                                      x.clubFee > 0)
                                          ).length > 0 )
                                      ) {
                                        return (
                                          <Nav tabs>
                                            <NavItem
                                              className={classnames({
                                                active: activeTab === category.id,
                                              })}
                                            >
                                              <NavLink
                                                className={classnames({
                                                  active: activeTab === category.id,
                                                })}
                                                onClick={() => {
                                                  toggleTab(category.id);
                                                }}
                                              >
                                                <span>{t(category.title)}</span>
                                              </NavLink>
                                            </NavItem>
                                          </Nav>
                                        );
                                      }
                                    })}
                                </div>
                                <hr className="custom-hr" />
                                <TabContent activeTab={activeTab}>
                                  {categories.map((category, i) => {
                                    if (
                                      referralCodeDetail.memberReferralInfos.filter(
                                        (x) =>
                                          x.productCategory === category.originalTitle
                                      ).length > 0 ||
                                      category.originalTitle === "All"
                                    ) {
                                      return (
                                        <TabPane tabId={category.id}>
                                          {referralCodeDetail.memberReferralInfos.map(
                                            (info, index) => {
                                              if (
                                                (info.commission > 0 ||
                                                  info.bonus > 0 ||
                                                  info.clubFee > 0) &&
                                                (info.productCategory ===
                                                  category.originalTitle ||
                                                  category.originalTitle === "All")
                                              ) {
                                                return (
                                                  <div key={"info-" + index}>
                                                    <div style={{ display: "flex" }}>
                                                      <div
                                                        className="mb-0 text-white"
                                                        style={{ padding: "4px" }}
                                                      >
                                                        {info.productName}
                                                      </div>
                                                      <div className="referral-category">
                                                        {"(" +
                                                          t(info.productCategory) +
                                                          ")"}
                                                      </div>
                                                    </div>
                                                    <table
                                                      className="table table-description mb-0 table-rollover table-valign-middle"
                                                      style={{
                                                        borderCollapse: "collapse",
                                                      }}
                                                    >
                                                      <tbody className="referral-table-body">
                                                        {info.commission > 0 && (
                                                          <tr
                                                            className="col-6 referral-table-row"
                                                            style={{
                                                              paddingLeft: "0",
                                                            }}
                                                          >
                                                            <td>
                                                              {t("COMMISSION_SHORT")}
                                                            </td>
                                                            <td className="text-right">
                                                              {info.commission}%
                                                            </td>
                                                          </tr>
                                                        )}
                                                        {/* {info.commission > 0 &&
                                                          info.bonus > 0 && (
                                                            <hr className="referral-table-hr" />
                                                          )} */}
                                                        {info.bonus > 0 && (
                                                          <tr
                                                            className="col-6 referral-table-row"
                                                            style={{
                                                              paddingRight: "0",
                                                            }}
                                                          >
                                                            <td>{t("BONUS")}</td>
                                                            <td className="text-right">
                                                              {info.bonus}%
                                                            </td>
                                                          </tr>
                                                        )}
                                                        {/* {info.bonus > 0 &&
                                                          info.clubFee > 0 && (
                                                            <hr className="referral-table-hr" />
                                                          )} */}
                                                        {info.productName ===
                                                          "D2 Poker" &&
                                                          info.clubFee > 0 && (
                                                            <tr
                                                              className="col-6 referral-table-row"
                                                              style={{
                                                                paddingRight: "0",
                                                              }}
                                                            >
                                                              <td>{t("CLUB_FEE")}</td>
                                                              <td className="text-right">
                                                                {info.clubFee}%
                                                              </td>
                                                            </tr>
                                                          )}
                                                      </tbody>
                                                    </table>
                                                    <hr className="custom-hr" />
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </TabPane>
                                      );
                                    }
                                  })}
                                </TabContent>
                              </>)}
                            {
                              pinTabActive == 2 && (
                                <>
                                  {memberRegisterList.length > 0 && 
                                    memberRegisterList.map((data, index) => {
                                      return (
                                        <div style={{ paddingBottom: '10px' }}>
                                          <div className="referralAllMember">
                                            <div style={{ width: '13%', alignSelf: 'center',paddingTop:'10px' }}>
                                              {/* <img style={{ width: '100%' }} src={require("../../assets/img/v3/userProfile.svg")}></img> */}
                                              <div className="member-icon">
                                                <img
                                                src={require("../../assets/img/v3/member.png")}
                                                alt="search"
                                                />
                                            </div>
                                            </div>
                                            <div className="referralMemberContainer">
                                              <div>{data.memberUsername}</div>
                                              <div style={{ color: "rgba(137, 143, 151, 1)" }}>{data.phoneNumber}</div>
                                            </div>
                                          </div>
                                          </div>
                                          
                                      )
                                    })
                                  }
                                 
                                </>
                              )
                            }
                        </div>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  )}
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              )}
            </>
          )}
        </form>
      </div>
      {/* Modal for manually keying % */}
      <Modal
        isOpen={showKeyboard}
        contentClassName="modal-brand modal-bottom modal-numpad"
        centered
      >
        <ModalBody>
          <div className="display-wrapper">
            <button
              type="button"
              style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
                fontSize: "2rem",
                color: "#002e6c",
                marginTop: "-15px",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowKeyboard(false);
              }}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
            <strong className="text-brand display-value">
              {selectedValue.substring(0, 2) +
                "." +
                selectedValue.substring(2, selectedValue.length)}
            </strong>
          </div>
          <Keyboard
            layout={{
              default: ["1 2 3 4 5 6 7 8 9 . {enter} 0 {bksp}"],
            }}
            display={{
              "{bksp}": "<i class ='fas fa-backspace'></i>",
              "{enter}":
                "<span className='referral-keyboard-enter'>Enter</span>",
            }}
            theme={`keyboard keyboard-numpad`}
            keyboardRef={(r) => (keyboard.current = r)}
            onKeyPress={onEditReferralKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
        </ModalBody>
      </Modal>
      {/* Modal for manually keying category % */}
      <Modal
        isOpen={showCategoryKeyboard}
        contentClassName="modal-brand modal-bottom modal-numpad"
        centered
      >
        <ModalBody>
          <div className="display-wrapper">
            <button
              type="button"
              style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
                fontSize: "2rem",
                color: "#002e6c",
                marginTop: "-15px",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowCategoryKeyboard(false);
              }}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
            <strong className="text-brand display-value">
              {selectedValue.substring(0, 2) +
                "." +
                selectedValue.substring(2, selectedValue.length)}
            </strong>
          </div>
          <Keyboard
            layout={{
              default: ["1 2 3 4 5 6 7 8 9 . {enter} 0 {bksp}"],
            }}
            display={{
              "{bksp}": "<i class ='fas fa-backspace'></i>",
              "{enter}":
                "<span className='referral-keyboard-enter'>Enter</span>",
            }}
            theme={`keyboard keyboard-numpad`}
            keyboardRef={(r) => (keyboard.current = r)}
            onKeyPress={onCategoryKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
        </ModalBody>
      </Modal>
      {showAlert && (
        <div
          className="content content-full-width"
          style={{
            position: "fixed",
            top: "10px",
            padding: 0,
            margin: 0,
            width: "100%",
            maxWidth: screenWidth - 40,
          }}
        >
          <div className="container" style={{ padding: 0, margin: 0 }}>
            <Alert color="green">{t("COPY_SUCCESS")}</Alert>
          </div>
        </div>
      )}
      {showShareModal && (
        <>
          <div
            className="referral-share-bg-wrapper"
            onClick={() => setShowShareModal(false)}
          ></div>
          <div
            className="referral-icons-wrapper"
            style={{ paddingBottom: "1.5rem" }}
          >
            <div className="font24 text-center mb-4">
              {t("SHARE_REFERRAL_CODE")}
            </div>
            <div className="ref-share">
              {ShareButton}
            </div>
            <div>
              <span
                className="btn custom-btn mt-5 w-100 mb-4"
                onClick={() => setShowShareModal(false)}
              >
                {t("DONE")}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferralCodeDetail;
