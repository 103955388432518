import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody } from "reactstrap";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import Header from '../../components/custom/Header';
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant.js";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2.js";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage, showResponseMessage } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author :
/// </summary>
const Referral = (props) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const _dispatch = useDispatch();
  const [referralCodes, setReferralCodes] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author: -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodes(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  async function deleteReferral(id) {
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_DEL_REFERRAL + "?id=" + id
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        init();
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showMessage(false, t(errorMessage)));
    }
  }

  const trailingActions = (codeId) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          let tempSelectedReferral = referralCodes.filter(x => x.id === codeId)[0];
          setSelectedReferral(tempSelectedReferral);
          setShowConfirmDelete(true);
        }}
      >
        <div className="swipe-delete">
          <img
            src={require("../../assets/img/v3/delete.png")}

            alt="icon"
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("REFERRAL")}
        hasBackButton={true}
      />
      <div className="custom-box-two-col-container referral-buttons">
        <div className="first" onClick={() => _history.push(WebUrl._URL_CREATE_REFERRAL)}>
          {t("CREATE") + " " + t("REFERRAL_CODE")}
        </div>
        <div className="second">
          {t("Referral Group")}
        </div>
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      <div className="pb-5">
        {referralCodes &&
          referralCodes.length > 0 ?
          map(referralCodes, (item, index) => {
            return (
              <SwipeableList
                fullSwipe={false}
                type={ListType.IOS}
              >
                <SwipeableListItem
                  trailingActions={trailingActions(item.id)}
                >
                  <div className="referral-item-list">
                    <div className="first">
                      <div>{item.code}</div>
                    </div>
                    <div className="second">
                      <img
                        src={require("../../assets/img/v3/arrow-right.png")}
                        alt="search"
                      />
                    </div>
                  </div>
                </SwipeableListItem>
              </SwipeableList>
            );
          })
          :
          <div style={{ textAlign: "center" }}>{t("NO_DATA_FOUND")}</div>}
      </div>
      <SweetAlert
        show={showConfirmDelete}
        custom={true}
        customIcon={require("../../assets/img/sweetalert_warning.png")}
        title={t("DELETE_REFERRAL", selectedReferral.code) + "?"}
        showCancel
        cancelBtnText={t("CANCEL")}
        confirmBtnText={t("CONFIRM")}
        cancelBtnCssClass="custom-cancel-btn"
        confirmBtnCssClass="custom-btn"
        confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
        onConfirm={() => {
          deleteReferral(selectedReferral.id);
          setShowConfirmDelete(false);
        }}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
      />
    </>
  );
};

export default Referral;
