import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DynamicLoadTable2 from '../../components/custom/DynamicLoadTable2';
import Header from '../../components/custom/Header';
import { WebUrl, ApiUrl, ApiKey, SessionKey, LanguageOption, AlertTypes } from '../../util/Constant';
import { debounce } from "lodash";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import ApiEngine from "../../util/ApiEngine.js";
import { setBusy, setIdle, showMessage, showResponseMessage, setThemeName, setBannerPopupImage, setBannerPopupModal } from "../../redux/AppAction.js";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../../util/Util.js";
import {
  Alert,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { startGame, setProductCategory } from "../../redux/GameAction.js";
import NonSeamlessGameWalletModal from "../../components/custom/NonSeamlessGameWalletModal";
import moment from "moment";
import ProviderUMIcon from '../../assets/img/icon/under_maintenance.svg';
import ProviderNEWIcon from '../../assets/img/icon/New-top-right.svg';
import ProviderHOTIcon from '../../assets/img/icon/hot-top-right.svg';

const ProviderCategory = props => {
  const { t, i18n } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [activeLanguageId, setActiveLanguageId] = useState(5);
  const [categoryActive, setCategoryActive] = useState("");
  const [activeProviderName, setActiveProviderName] = useState("");
  const [allAvailableProvidersList, setAllAvailableProvidersList] = useState([]);
  const [searchProvider, setSearchProvider] = useState("");
  var { isLoggedIn } = useSelector((state) => state.authState);
  var _history = useHistory();
  var _dispatch = useDispatch();
  //const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  //const [productCategory, setProductCategory] = useState([]);
  let { category, provider } = useParams();
  const themeName = useSelector((state) => state.appState.themeName);
  const [selectedProvider, setSelectedProvider] = useState(provider);
  const [allSelectedProviderGame, setAllSelectedProviderGame] = useState([]);
  
  const search = window.location.search;
  var location = useLocation();
  const params = new URLSearchParams(search);
  const providerName = params.get('name');
  const [masterProviderList, setMasterProviderList] = useState({});
  const [memberFavGame, setMemberFavGame] = useState([]);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [selectedFavouriteGameId, setSelectedFavouriteGameId] = useState(0);
  const [selectedFavouriteGameName, setSelectedFavouriteGameName] = useState("");
  const [isCreateMessage, setIsCreateMessage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const _DEFAULT_PAGE_SIZE = 24;
  const [pageSize, setPageSize] = useState(_DEFAULT_PAGE_SIZE);
  const [gameProviderList, setGameProviderList] = useState({});
  const [searchGameCount, setSearchGameCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const { productCategory, rolloverTarget, isSpecialRollover, playableCategory, showNonSeamlessTransferConfirmation } = useSelector((state) => state.gameState);
  let containerRef = useRef();
  const LONG_PRESS_DURATION = 300;

  const [availableCategoryProviders, setAvailableCategoryProviders] = useState([]);
  const [isSlotProviderSelected, setIsSlotProviderSelected] = useState(false);
  const [isCasinoProviderSelected, setIsCasinoProviderSelected] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isLoadingProvidersList, setIsLoadingProvidersList] = useState(false);
  const providerList = _history.location.state?.providerList;
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  useEffect(() => {
    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != "undefined") {
      let tempLangId = LanguageOption.filter(x => x.code === localStorage.getItem(SessionKey._LANGUAGE))[0].languageId;
      setActiveLanguageId(tempLangId);
    }
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  useEffect(() => {
    if (provider) {
      getProductGameByProvideName(provider, 0);
      setSelectedProvider(provider);
    }
    getProductByCategory();
  }, []);

  const getProductByCategory = async () => {
    _dispatch(setBusy());
    try {
      if (providerList === undefined) {
        let apiUrl = ApiUrl._API_GET_AVAILABLE_MASTER_PRODUCT_BY_CATEGORY + "?category=" + category;
        var newResponseJson = await ApiEngine.get(apiUrl);
        if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
          const data = newResponseJson[ApiKey._API_DATA_KEY];
          setAllAvailableProvidersList(data);
          setMasterProviderList(data);
        }
      } else {
        let availableProductList = providerList.filter((x) => x.categoryName == category);
        setAllAvailableProvidersList(availableProductList);
        setMasterProviderList(availableProductList);
      }
      
    }
    catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    }

    _dispatch(setIdle());
  }

  const getProductGameByProvideName = async (providerName, start) => {
    _dispatch(setBusy());
    setIsLoadingProvidersList(true);
    try {
      setCurrentCount(pageSize + start);
      let apiUrl = ApiUrl._API_GET_PRODUCT_GAMES_LIST_DASHBOARD + "?languageId=" + activeLanguageId + (category === "" || category === "All" ? "" : "&category=" + category) + ("&providerName=" + providerName) + "&start=" + start + "&count=" + pageSize + (searchProvider === "" ? "" : "&keyword=" + searchProvider);

      var newResponseJson = await ApiEngine.get(apiUrl);
      if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
        
        const data = newResponseJson[ApiKey._API_DATA_KEY]["data"];
        var newList = [];
        if (start === 0) {
          newList = newResponseJson[ApiKey._API_DATA_KEY]["data"];
        } else {
          newList = allSelectedProviderGame.concat(newResponseJson[ApiKey._API_DATA_KEY]["data"]);
        }
        setAllSelectedProviderGame(newList);
        setGameProviderList(newList);
        setTotalCount(newResponseJson[ApiKey._API_DATA_KEY]["totalCount"]);
        setSelectedProvider(providerName);
        if (category === "Slots") {
          setIsSlotProviderSelected(true);
        } else if (category === "Casino") {
          setIsCasinoProviderSelected(true);
        }
      } else {
        setIsLoadingProvidersList(false);
        throw newResponseJson[ApiKey._API_MESSAGE_KEY];
      }
    }
    catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    }
    setIsLoadingProvidersList(false);
    _dispatch(setIdle());
  }

  const handleChange = (e) => {
    setSearchProvider(e.target.value);
    if (provider) {
      const newList = allSelectedProviderGame.filter((item) => item.gameName.toLowerCase().includes(e.target.value.toLowerCase()));
      setGameProviderList(newList);
      setSearchGameCount(newList.length);
      if (e.target.value.length == 0) {
        setIsSearch(false);
      } else {
        setIsSearch(true);
      }
        
      } else {
        const list = allAvailableProvidersList.filter((item) => item.productName.toLowerCase().includes(e.target.value.toLowerCase()));
        setMasterProviderList(list);
      }
    //}
  };

  const handleProviderClick = async (provider) => {
    if (!provider.isUM) {
      if (provider != null) {
        let tempProviderName = provider.productName;
        setActiveProviderName(tempProviderName);
      }

      if (!provider.isSeamless) {
        if (!provider.hasOwnLobby) {
          if (isLoggedIn) {
            _dispatch(setBusy());
            _dispatch(startGame(provider.productGame));
            _dispatch(setIdle());
          } else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
        } else {
          _history.push(WebUrl._URL_PROVIDER_LOBBY
            .replace(":category", category)
            .replace("/:provider?", provider.productName ? "/" + provider.productName : ""));
          
          setSelectedProvider(provider.productName);
          
          getProductGameByProvideName(provider.productName, 0);
        }
      }
      else {
        if (!provider.hasOwnLobby) {
          if (isLoggedIn) {
            //startGameProvider(provider.game);
            _dispatch(startGame(provider.productGame));
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
        }
        else 
        {
          setSelectedProvider(provider.productName);
          _history.push(WebUrl._URL_PROVIDER_LOBBY
            .replace(":category", category)
            .replace("/:provider?", provider.productName ? "/" + provider.productName : ""));
          
          getProductGameByProvideName(provider.productName, 0);
        }
      }
    }
  }

  const getMemberFavouriteGame = async () => {
    _dispatch(setBusy());
    var favGamesJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FAVOURITE_GAMES +
      "?selectedLanguage=" +
      selectedLanguage + ("&start=0&count=30"));
    if (favGamesJson[ApiKey._API_SUCCESS_KEY]) {
      let data = favGamesJson[ApiKey._API_DATA_KEY]["data"];
      setMemberFavGame(data);
    }
    _dispatch(setIdle());
  }

  async function toggleLike(gameId, isCreate) {
    _dispatch(setBusy());
    let params = {
      gameId: gameId
    };
    var responseJson = "";
    if (isCreate) {
      responseJson = await ApiEngine.post(ApiUrl._API_CREATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    } else {
      responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_FAVOURITE_GAMES,
        createFormBody(params));
    }
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
      getMemberFavouriteGame();
      setConfirmMessage(false);
      setSelectedFavouriteGameId(0);
      setSelectedFavouriteGameName("");
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    _dispatch(setIdle());
  };

  const scrollToPosition = (isBtn, leftOrRight, productName) => {
    var position;
    let containerDiv = document.getElementById("providerList");
    let fullDivLength = containerDiv.scrollWidth;
    var totalper = fullDivLength / masterProviderList.length;
    if (isBtn) {
      if (leftOrRight === "left" && containerRef.current.scrollLeft > (totalper * 3)) {
        position = containerRef.current.scrollLeft - (totalper * 3);
      }
      else if (leftOrRight === "right" && containerRef.current.scrollLeft < (fullDivLength - (totalper * 3))) {
        position = containerRef.current.scrollLeft + (totalper * 3);
      }
    }
    else {
      const index = masterProviderList.findIndex(e => e.productName === productName);
      if (index !== -1) {
        if (containerRef.current) {
          position = totalper * (index);
        }
      }
    }
    containerRef.current.scrollLeft = position;
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    setMouseDownTime(new Date().getTime());
    setIsGrabbing(true);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging && isGrabbing) {
      const deltaX = e.clientX - startX;
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      if (
        e.clientX < containerRect.left ||
        e.clientX > containerRect.left + containerWidth ||
        e.clientY < containerRect.top ||
        e.clientY > containerRect.top + containerHeight
      ) {

        setIsDragging(false);
        setIsGrabbing(false);
        return;
      }

      containerRef.current.scrollLeft = scrollLeft - deltaX;
    }
  };

  const handleMouseUp = (e) => {
    setIsGrabbing(false);
    const currentTime = new Date().getTime();
    if (currentTime - mouseDownTime < LONG_PRESS_DURATION) {
      setIsDragging(false);
    }
  };

  const handleSelectStart = (e) => {
    if (isDragging) {
      e.preventDefault();
    }
  };


  return (
    <div id={"provider-lobby"} className={providerName + "-lobby "}>
      <Header
        history={_history}
        url={stringIsNullOrEmpty(provider) ?
          WebUrl._URL_MAIN :
          WebUrl._URL_PROVIDER_LOBBY
          .replace(":category", category)
          .replace("/:provider?", "/")}
        title={stringIsNullOrEmpty(provider) ? category : provider}
        hasBackButton={true}
      />
      <div>
        <div className="search-container">
          <input
            type="text"
            placeholder={stringIsNullOrEmpty(provider) ? t("SEARCH_PROVIDER") : t("SEARCH_GAME")}
            value={searchProvider}
            onChange={handleChange}
          />
        </div>
      </div>
      {stringIsNullOrEmpty(provider) ?
        <div className="mt-4">
          <div>
            {Object.keys(masterProviderList).length > 0 &&
              <>
                <div className="new-provider-parent-container">
                  {masterProviderList
                    .map((provider, index) => {
                      return (
                        <>
                          <div className="provider-image new-provider-logo-div" onClick={() => { handleProviderClick(provider); }}>
                            {!stringIsNullOrEmpty(provider.productGame) && provider.productGame["isRecommended"] ? <div className="new-recommended-icon-div" style={{ backgroundImage: `url(${ProviderHOTIcon})` }}></div> : <></>}
                            <img className={"provider-image" + (provider.isUM ? " um" : "")} id={provider.productName} src={provider.productImage} alt={provider.productName} />
                            {provider.isUM && <div className="new-um-icon-div" style={{ backgroundImage: `url(${ProviderUMIcon})` }}></div>}
                           {/* <img className={"provider-image" + (provider.isUM ? " um" : "")} id={provider.productName} src={provider.productImage} alt={provider.productName} />*/}

                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </>
            }
          </div>
        </div>
        :
        <>
          {
            (isSlotProviderSelected || isCasinoProviderSelected) &&
            <>
              {Object.keys(masterProviderList).length > 0 &&
                <div className="slot-provider-wrapper mt-4">
                  <div className="scroll-left-btn" onClick={() => scrollToPosition(true, "left")}>
                    <img src={"/img/v3/game-providers/prev.svg"} alt="prev-arow" />
                  </div>
                  <ul className={`game-section-v3-slots-provider-box list-inline ${isGrabbing && "dragging"}`} id="providerList" ref={containerRef}
                    onMouseDown={(e) => handleMouseDown(e)}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseUp={(e) => handleMouseUp(e)}
                    onSelectStart={(e) => handleSelectStart(e)}
                    onLoad={() => scrollToPosition(false, "", selectedProvider)}>
                    {masterProviderList.map((provider, index) => {
                      return (
                        <li
                          key={"game_slots_provider_" + index}
                          className={(selectedProvider === provider.productName ? "active" : "") + (provider.isUM ? " is-um um-display" : "")}
                          style={{ cursor: isGrabbing ? "grabbing" : provider.isUM ? "not-allowed" : "pointer" }}
                          onClick={() => {
                            if (!provider.isUM) {
                              handleProviderClick(provider);
                            }
                          }}>
                          <div className={`img-container ${(isLoggedIn && provider.isUM ? " um" : "")}`}>
                            {isLoggedIn && provider.isUM &&
                              <>
                                <div className="um-overlay"></div>
                                <img
                                  src={"/img/under_maintenance.svg"}
                                  className="v2-um-tag-icon"
                                  alt='maintenance-icon'
                                />
                              </>
                            }
                            {themeName === "default" ? 
                              <img
                                src={`/img/v3/game-providers/defaultTheme/${provider.productName}.png`}
                                className='img-responsive'
                                alt={provider.title}
                                draggable="false"
                              />
                              :
                              <img
                                src={`/img/v3/game-providers/whiteTheme/${provider.productName}.png`}
                                className='img-responsive'
                                alt={provider.title}
                                draggable="false"
                              />
                            }
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="scroll-right-btn" onClick={() => scrollToPosition(true, "right")}>
                    <img src={"/img/v3/game-providers/next.svg"} alt="next-arow" />
                  </div>
                </div>
              }
            </>
          }
          {gameProviderList && 
            gameProviderList.length > 0 &&
            <>
              <div className="load-table-wrapper">
                {gameProviderList.map((game, i) => {
                  let image = new Image();
                  image.src = game["image"];
                  window[game["image"]] = image;
                  if (game["masterProductHasOwnLobby"] === false) {
                    return (
                      <div className="load-table-wrapper-section">
                        <div className="hot-games-tile" key={i}>
                          <a
                            key={i}
                            onClick={() => {
                              //startGame(game);
                              _dispatch(startGame(game));
                            }}
                            className={
                              isLoggedIn && game["gameUm"] == true ? "gameum" : ""
                            }
                            target="_blank"
                          >


                            <div
                              className="lobby-games-img"
                              style={{
                                backgroundImage: `url(${game.image})`
                              }}
                            >
                              {game["isNewGame"] || game["isRecommended"] ? (
                                <div className="tag">
                                  <img
                                    src={
                                      game["isRecommended"]
                                        ? require("../../assets/img/assets/hot.png")
                                        : require("../../assets/img/assets/new.png")
                                    }
                                    className="img-responsive"
                                    alt={game["isRecommended"] ? "hot" : "new"}
                                  />
                                </div>
                              ) : null}
                              {isLoggedIn && game["gameUm"] == true && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    className="v2-um-tag game-category-label"
                                  ></div>
                                  <div
                                    style={{
                                      backgroundImage: `url(${UMIcon})`,
                                    }}
                                    className="v2-um-tag-icon"
                                  ></div>
                                </>
                              )}
                            </div>
                            <div className="hot-games-name">
                              <div className="hot-games-text">
                                {game.gameName}
                              </div>
                            </div>
                          </a>
                        </div>
                        {isLoggedIn ?
                          <>
                            {!stringIsNullOrEmpty(memberFavGame.filter(x => x.gameId == game["id"] && x.favStatus)) ?
                              <>
                                <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["id"]); setSelectedFavouriteGameName(game["gameName"]); setIsCreateMessage(false) }}>
                                  <img
                                    src={require("../../assets/img/assets//yellow-star.svg")}
                                    className="heart-icon"
                                    alt="yellow-star"
                                  />
                                </div>
                              </>
                              :
                              <>
                                <div className="fav-game-backgroud" onClick={() => { setConfirmMessage(true); setSelectedFavouriteGameId(game["id"]); setSelectedFavouriteGameName(game["gameName"]); setIsCreateMessage(true) }}>
                                  <img
                                    src={require("../../assets/img/assets/grey-star.svg")}
                                    className="heart-icon"
                                    alt="grey-star"
                                  />
                                </div>
                              </>
                            }
                          </>
                          :
                          <></>
                        }
                      </div>
                    );
                  }
                  else {
                    return (
                      <div className="hot-games-tile" key={i}>
                        <a
                          key={i}
                          onClick={() => {
                            //startGame(game);
                            _dispatch(startGame(game));
                          }}
                          className={
                            isLoggedIn && game["gameUm"] == true ? "gameum" : "hot-games-link"
                          }
                          target="_blank"
                        >
                          <div
                            className="lobby-games-img"
                            style={{
                              backgroundImage: `url(${game.image})`
                            }}
                          >
                            {game["isNewGame"] || game["isRecommended"] ? (
                              <div className="providerTag">
                                <img
                                  src={
                                    game["isRecommended"]
                                      ? require("../../assets/img/assets/hot.png")
                                      : require("../../assets/img/assets/new.png")
                                  }
                                  className="img-responsive"
                                  alt={game["isRecommended"] ? "hot" : "new"}
                                />
                              </div>
                            ) : null}

                            <div className="gamelist-img-inner-shadow"></div>
                            {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                            {isLoggedIn && game["gameUm"] == true && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className="v2-um-tag game-category-label"
                                ></div>
                                <div
                                  style={{
                                    backgroundImage: `url(${UMIcon})`,
                                  }}
                                  className="v2-um-tag-icon"
                                ></div>
                              </>
                            )}
                          </div>
                          <div className="hot-games-name">
                            <div className="hot-games-text">
                              {game.gameName}
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  }
                })}
            </div>
            {(!isSearch ? (totalCount > currentCount) : searchGameCount > 24) && <div className="see-more-text" style={{ textAlign: "center" }} onClick={() => getProductGameByProvideName(provider, currentCount)}>{t("SEE_MORE")}</div>}
            </>
          }
        </>
      }
      <Modal
        id="success-modal"
        fade={true}
        isOpen={confirmMessage}
        toggle={() => {
          setConfirmMessage(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">
            <h4 className="modal-success-title mt-4">
              {isCreateMessage ? t("CONFIRM_CREATE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName }) : t("CONFIRM_REMOVE_FAVOURITE_MESSAGE", { value: selectedFavouriteGameName })}
            </h4>
            <div className="fav-game-model">
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  setConfirmMessage(false);
                  setSelectedFavouriteGameId(0);
                  setSelectedFavouriteGameName("");
                }} >{t("NO")}</button>
              <button className="btn btn-block btn-brand-gradient-fav-game mt-3" style={{ width: "40% !important" }}
                onClick={() => {
                  !stringIsNullOrEmpty(memberFavGame.filter(x => x.gameId == selectedFavouriteGameId)) ? toggleLike(selectedFavouriteGameId, false) : toggleLike(selectedFavouriteGameId, true)
                }} >{t("CONFIRM")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {showNonSeamlessTransferConfirmation && <NonSeamlessGameWalletModal />}
    </div>
  )
}

export default ProviderCategory;