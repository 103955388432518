import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css'
import { formatPercent } from './../../util/Util';
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";

const RangeSlider = (props) => {
    
    const {
        min = 0,
        max = 100,
        orientation = 'horizontal',
        format = formatPercent,
        postfix = "%",
        label,
        value,
        step,
        ...rest
    } = props;

    return (
        <>
            {
                label ?
                    <div className="form-group">
                        <label className="text-primary">{label}</label>
                        <Slider
                            min={min}
                            max={max}
                            orientation={orientation}
                            handleLabel={`${numberWithCurrencyFormat(value)}${postfix}`}
                            value={numberWithCurrencyFormat(value)}
                            step={step}
                            {...rest}
                        />
                    </div>
                    : <Slider
                        min={min}
                        max={max}
                        orientation={orientation}
                        handleLabel={numberWithCurrencyFormat(value)}
                        value={numberWithCurrencyFormat(value)}
                        step={step}
                        {...rest}
                    />
            }
        </>
    )
}

export default RangeSlider;