import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Header from "../../components/custom/Header";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import tempBanner from "./../../assets/img/banner.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle } from "../../redux/AppAction";
import Spinner from "react-bootstrap/Spinner";
import Slider from "react-slick";
import "./../../../node_modules/slick-carousel/slick/slick.css";
import "./../../../node_modules/slick-carousel/slick/slick-theme.css";

const RewardsDetail = (props) => {
  var _history = useHistory();
  var _location = useLocation();
  var _dispatch = useDispatch();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableMasterProduct, setAvailableMasterProduct] = useState([]);
  const [categoryId, setCategoryId] = useState(parseInt(_location.state.categoryId));
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment(_location.state.selectedDate));
  const [rewardsData, setRewardsData] = useState({});
  const [productRewardsData, setProductRewardsData] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );

  const _DAY_OF_WEEK = [
    { label: "M", value: 1 },
    { label: "T", value: 2 },
    { label: "W", value: 3 },
    { label: "T", value: 4 },
    { label: "F", value: 5 },
    { label: "S", value: 6 },
    { label: "S", value: 0 },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    getRewardRates(categoryId, selectedDate);
    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_WITH_MASTER_PRODUCT
    );
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = categoryJson[ApiKey._API_DATA_KEY];
      setAvailableCategories(data);
      setSelectedCategoryName(
        data.find((x) => x.id === categoryId).originalTitle
      );
    }
    setIsInit(false);
  };

  const getRewardRates = async (catId, selectedDate) => {
    fetch(ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=false&id=" + catId, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          setRewardsData(data['rewardsData']);
          setCurrentDateTime(moment(data["currentDateTime"]));
          let tempSelectedDate = selectedDate;
          //setSelectedDate();
          //if (isInit) { setSelectedDate(moment(data["currentDateTime"]).clone().startOf('day')); tempSelectedDate = moment(data["currentDateTime"]).clone().startOf('day');}
          let tempAvailableMasterProduct = [];
          let tempProductRewardsData = [];
          data["data"].map((masterProduct, index) => {
            let temp = data["productRewardsData"].find(x => moment(x['date']).isSame(tempSelectedDate) && x['categoryId'] === catId && x['productId'] === masterProduct['id']);
            if (typeof(temp) !== "undefined"){
              tempAvailableMasterProduct.push(masterProduct); //to show each masterProduct icon
              tempProductRewardsData.push(temp); //to show the rewards rates under each masterProduct icon
            }
          })
          setAvailableMasterProduct(tempAvailableMasterProduct);
          setProductRewardsData(tempProductRewardsData);
        }
      });
      setDataRetrieved(true);
  };

  const handleSelectedDate = (days) => {
    _dispatch(setBusy());
    let tempSelectedDate = selectedDate.clone().add(days, "days");
      if (rewardsData[tempSelectedDate.format("YYYY-MM-DD")] !== undefined) {
          if (
              tempSelectedDate.isSameOrBefore(
                  currentDateTime.clone().add(2, "weeks")
              ) &&
              tempSelectedDate.isSameOrAfter(currentDateTime.clone().add(-2, "weeks"))
          ) {
              setSelectedDate(tempSelectedDate);
              setDataRetrieved(false);
              getRewardRates(categoryId, tempSelectedDate);
          }
      }
    _dispatch(setIdle());
  };

  const handleSelectCategory = (category) => {
    setCategoryId(category.id);
    setSelectedCategoryName(category.originalTitle);
    setDataRetrieved(false);
    getRewardRates(category.id, selectedDate);
  };

  return (
    <>
      <div id="reward-detail">
        <Header
          history={_history}
          url={!window.ReactNativeWebView ? WebUrl._URL_REWARDS : null}
          state={{ selectedDate: selectedDate.format('YYYY-MM-DD') }}
          title={t("REWARDS_DETAIL")}
        />
        <div>
          <div
            className="rewards-detail-banner"
            style={{ backgroundImage: `url(${tempBanner})` }}
          >
          </div>
          <Slider
            className="rewards-detail-category center"
            centerMode={true}
            focusOnSelect={true}
            infinite={true}
            swipeToSlide={true}
            waitForAnimate={true}
            arrows={false}
            slidesToShow={5}
            slidesToScroll={1}
            speed={500}
          >
            {availableCategories
              .filter(
                (x) =>
                  x.originalTitle !== "V.Sports" && x.originalTitle !== "All"
              )
              .map((category, index) => {
                return (
                  <div style={{ width: "100px" }}>
                    <div
                      className="category-icon-wrapper"
                      onClick={() => {
                        if (categoryId !== category.id) {
                          handleSelectCategory(category);
                        }
                      }}
                      style={{
                        height: category.id === categoryId ? "55px" : "40px",
                        width: category.id === categoryId ? "55px" : "40px",
                        borderRadius:
                          category.id === categoryId ? "28px" : "20px",
                      }}
                    >
                      <img
                        className="category-icon"
                        src={require("../../assets/img/icon/" +
                          category.originalTitle +
                          ".svg")}
                      />
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="rewards-detail">
          <div className="category-name">{selectedCategoryName}</div>
          <div
            className="rewards-calendar card card-package"
            style={{ marginBottom: "20px" }}
          >
            <div className="card-body p-0">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 15px",
                  userSelect: "none",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(-7)}
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                  >
                    {t("LAST_WEEK")}
                  </div>
                  <div className="rewards-calendar-month">
                    {selectedDate.format("MMMM")}
                  </div>
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(7)}
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(7, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                  >
                    {t("NEXT_WEEK")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div
                    className="rewards-calendar-day" //selectedDay - 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-3, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(-1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(-1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day"
                    /*selectedDay*/ style={{
                      background: "linear-gradient(180deg, #FE9F00, #FFCF7D)",
                    }}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + selectedDate.day()) % 7
                        ).label
                      }
                    </div>
                    <div>{moment(selectedDate).format("DD")}</div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(1, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(2, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(2, "weeks"))
                            || rewardsData[selectedDate
                                .clone()
                                .add(3, "days").format("YYYY-MM-DD")] == undefined)
                        ? "grey"
                        : "unset",
                    }}
                    onClick={() => handleSelectedDate(3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(3, "days").format("DD")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card card-package"
            style={{ backgroundColor: "#022554", marginBottom: "20px" }}
          >
            <div className="card-body p-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  color: activeTheme === 0 ? "#002C68" : "#ffffff",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Grand Total Rewards Earn:</div>
                  <div>
                    <span>RM</span>
                    <span style={{ fontSize: "25px" }}>88,888.88</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Link to={WebUrl._URL_REWARDS_HISTORY}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div
                        style={{
                          marginRight: "5px",
                          color: activeTheme === 0 ? "#002C68" : "#ffffff",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {t("REWARDS_RECORD")}{" "}
                      </div>
                      <Icon
                        path={mdiHistory}
                        title={t("REWARDS_RECORD")}
                        size={1}
                        color={activeTheme === 0 ? "#002C68" : "#ffffff"}
                      />
                    </div>
                  </Link>
                  <button
                    className="btn btn-block btn-brand-gradient"
                    style={{
                      width: "100px",
                      borderRadius: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("CLAIM")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="master-product-grid">
            {dataRetrieved && availableMasterProduct.length > 0 && availableMasterProduct.map((masterProduct, index) => {
              return (
                <>
                  <div className="card-wrapper">
                    <div className="card card-package">
                      <div className="card-body p-0">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <div className="product-image-wrapper">
                            <div
                              className="product-image"
                              style={{
                                backgroundImage: `url(${masterProduct.productImage})`,
                              }}
                            ></div>
                          </div>
                          <div className="product-rate">{dataRetrieved && productRewardsData.length > 0 && typeof(productRewardsData.find(x => x['productId'] === masterProduct['id'])) !== "undefined" ? productRewardsData.find(x => x['productId'] === masterProduct['id']).comm + "%" : ""/*(rewardsData && rewardsData[selectedDate.format('YYYY-MM-DD')] && rewardsData[selectedDate.format('YYYY-MM-DD')][masterProduct.id]) ? rewardsData[selectedDate.format('YYYY-MM-DD')][masterProduct.id] : ""*/}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {dataRetrieved && availableMasterProduct.length === 0 &&
              <div>
                <span style={{ color: activeTheme === 0 ? "black" : "white" }}>{t("NO_DATA_FOUND")}</span>
              </div>
            }
            {!dataRetrieved &&
              <Spinner
                animation="border"
                role="status"
                size="sm"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsDetail;
