import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm } from "react-hook-form";
import { keys, first, last } from 'lodash';
import { SessionKey } from "../../util/Constant";
import "moment/locale/ms";
import { updateUserData } from "../../redux/AuthAction";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";

const Rewards = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var { t } = useTranslation(LanguageKey._PRIMARY);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [bannerData, setBannerData] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(moment().startOf('day'));
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rewardsData, setRewardData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [activeKeyList, setActiveKeyList] = useState([]);
  const [showPageData, setShowPageData] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [grandTotalRewards, setGrandTotalRewards] = useState(0);
  const [gameCategoryData, setGameCategoryData] = useState([]);
  const [showCategoryData, setShowCategoryData] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );
  let _locale = "";
  const languageCode = localStorage.getItem(SessionKey._LANGUAGE)

  const rightMenuList = [
    {
      title: "REWARDS_RECORD",
      url: WebUrl._URL_REWARDS_HISTORY,
      from: _history.location.state?.from
    }
  ];

  switch (languageCode) {
    case "en":
      _locale = "en-gb";
      break;
    case "zh":
      _locale = "zh-cn";
      break;
    case "th":
      _locale = "th";
      break;
    case "id":
      _locale = "id";
      break;
    case "vi":
      _locale = "vi";
      break;
    case "ms":
      _locale = "ms";
      break;
    default:
      _locale = "en";
  }

  const _DAY_OF_WEEK = [
    { label: t("MON_L"), value: 1 },
    { label: t("TUE_L"), value: 2 },
    { label: t("WED_L"), value: 3 },
    { label: t("THU_L"), value: 4 },
    { label: t("FRI_L"), value: 5 },
    { label: t("SAT_L"), value: 6 },
    { label: t("SUN_L"), value: 0 },
  ];

  const getUserRewardBanner = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_REWARD_BANNER);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannerData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  const getProductCategory = async () => {
    let categoryData = [];
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      categoryData = responseJson[ApiKey._API_DATA_KEY];
      let tempActiveKeyList = [];
      categoryData.map((category, index) => {
        tempActiveKeyList.push({ id: category.id, active: false });
      });
      setActiveKeyList(tempActiveKeyList);
      setGameCategories(categoryData);
    }
  }

  const getRewardSchedules = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_REWARDS_SCHEDULES + "?all=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempScheduleData = responseJson[ApiKey._API_DATA_KEY];
      let tempSchDataObj = {};
      tempScheduleData.map((schData) => {
        if (!tempSchDataObj[schData["date"]]) {
          tempSchDataObj[schData["date"]] = {};
        }
        if (!tempSchDataObj[schData["date"]][schData["productName"]]) {
          tempSchDataObj[schData["date"]][schData["productName"]] = { rate: schData["comm"], productImage: schData["productImage"], categoryTitle: schData["categoryTitle"] };
        }
      })
      let tempDateList = Object.keys(tempSchDataObj).sort();
      setDateList(tempDateList);
      setScheduleData(tempSchDataObj);
      getCategoryRewardRates();
    }
  }

  const getCategoryRewardRates = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      let temp = Object.keys(tempObj).sort();
      var chosenDate = moment().startOf('day');
      for (var i = 0; i < temp.length; i++) {
        if (data.filter(x => x.date === temp[i] && x.claimed === "False").length > 0) {
          chosenDate = moment(temp[i]);
          break; //exit for loop
        }
      }
      setSelectedDate(chosenDate);
      setRewardData(tempObj);
      setGrandTotalRewards(grandTotalReward);
      setGameCategoryData(categoryTotal);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    setShowPageData(true);
  }

  function init() {
    getUserRewardBanner();
    getProductCategory();
    getRewardSchedules();
  };

  useEffect(() => {
    setShowPageData(false);
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    {
      _dispatch(setBusy());

            let responseJson = await ApiEngine.post(
                ApiUrl._API_CLAIM_REWARD,
                createMultiPartFormBody({ date: selectedDate.clone().format("YYYY-MM-DD"), all: true })

      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
      _dispatch(setIdle());

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(updateUserData());
        init();
      }
    }
  }

  const handleSelectedDate = (days) => {
    _dispatch(setBusy());

    let tempSelectedDate = selectedDate.clone().add(days, "days");
    if (
      tempSelectedDate.isSameOrBefore(currentDateTime.clone().add(1, "weeks")) &&
      tempSelectedDate.isSameOrAfter(currentDateTime.clone().add(-1, "weeks")) &&
      tempSelectedDate.isSameOrAfter(moment(dateList[0]).startOf('day')) &&
      tempSelectedDate.isSameOrBefore(moment(dateList[dateList.length - 1]).startOf('day'))
    ) {
      setSelectedDate(tempSelectedDate);
    }
    _dispatch(setIdle());
  };

  return (
    <>
      <Header
        history={_history}
        url={WebUrl._URL_MAIN}
        title={t("PROMOTION")}
        hasBackButton={
          (_history.location.state?.from == WebUrl._URL_MAIN ||
            _history.location.state?.from == WebUrl._URL_REWARDS_HISTORY) ? true : false
        }
        hasRightMenu={true}
        rightMenuList={rightMenuList}
        state={_history.location.state?.from}
      />
      <div className="rewards-claim-container">
        <div className="first">
          <div className="title">
            {t("GRAND_TOTAL_REWARD_CLAIMABLE")}
          </div>
          <div id="setShowCategoryData" className="details" onClick={() => setShowCategoryData(!showCategoryData)}>
            {numberWithCurrencyFormat2(grandTotalRewards, 2, true)}
            <div>
              <VIcon
                icon={themeIcons.info}
                className="theme-icon-size-15"
              />
            </div>
          </div>
        </div>
        <div className="second">
          {rewardsData && numberWithCurrencyFormat2(grandTotalRewards, 2, true) != "0.00" &&
            <button
              disabled={!rewardsData || grandTotalRewards === 0 || numberWithCurrencyFormat2(grandTotalRewards, 2, true) == "0.00"}
              onClick={() => rewardsData && numberWithCurrencyFormat2(grandTotalRewards, 2, true) != "0.00" && preSubmit()}
            >
              {t("CLAIM")}
            </button>
          }
        </div>
      </div>
      <div className="hr-right mt-3 mb-3"></div>
      <div className="custom-two-col-box">
        <div className="first">
          <div className="title">
            {t("TOTAL_EARNED")}
          </div>
          <div className="details">
            {numberWithCurrencyFormat2(rewardsData && rewardsData[selectedDate.format("YYYY-MM-DD")]
              ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmount']
              : "0", 2, true)}
          </div>
        </div>
        <div className="second">
          <div className="title">
            {t("TOTAL_CLAIMABLE")}
          </div>
          <div className="details">
            {numberWithCurrencyFormat2(rewardsData && rewardsData[selectedDate.format("YYYY-MM-DD")]
              ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable']
              : "0", 2, true)}
          </div>
        </div>
      </div>
      <div className="reminder font11 text-center">
        <i>{t("NOTICE_REWARDS", { startHour: 15, endHour: 16 })}</i>
      </div>
      <div className="hr-left mt-3 mb-3"></div>
      <div id="rewards-homepage">
        <div>
          <div className="rewards-calendar card card-package">
            <div className="card-body p-0">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 15px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(-7)}
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-7, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-7, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-7, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "#888888"
                        : "#3369FF",
                    }}
                  >
                    <div className="font18 user-select-none">{"<"}</div>
                    {/* <img src={require("../../assets/img/v3/arrow-left.png")} alt="claim" className="img-responsive" /> */}
                    {/*<VIcon
                      icon={themeIcons.angleLeft}
                      className="theme-icon-size-20 left"
                    />*/}
                  </div>
                  <div className="rewards-calendar-month">
                    {t(selectedDate.locale(_locale).format("MMMM"))}
                  </div>
                  <div
                    className="rewards-calendar-week"
                    onClick={() => handleSelectedDate(7)}
                    style={{
                      cursor: selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks")
                          || selectedDate
                            .clone()
                            .add(7, "days")
                            .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: selectedDate
                        .clone()
                        .add(7, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks")
                          || selectedDate
                            .clone()
                            .add(7, "days")
                            .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "#888888"
                        : "#3369FF",
                    }}
                  >
                    <div className="font18 user-select-none">{">"}</div>
                    {/* <img src={require("../../assets/img/v3/arrow-right-blue.png")} alt="claim" className="img-responsive" /> */}
                    {/*<VIcon
                      icon={themeIcons.angleRight}
                      className="theme-icon-size-20 right"
                    />*/}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: '10px'
                  }}
                >
                  <div
                    className="rewards-calendar-day" //selectedDay - 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-3, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-3, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-3, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(-3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-3, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-2, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-2, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-2, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(-2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay - 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-1, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(-1, "days")
                        .isBefore(currentDateTime.clone().add(-1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(-1, "days")
                          .isBefore(moment(dateList[0]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(-1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() - 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(-1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day active"
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + selectedDate.day()) % 7
                        ).label
                      }
                    </div>
                    <div>{moment(selectedDate).format("DD")}</div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 1 day
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(1, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(1, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(1, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(1)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 1)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(1, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 2 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(2, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(2, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(2, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(2)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 2)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(2, "days").format("DD")}
                    </div>
                  </div>

                  <div
                    className="rewards-calendar-day" //selectedDay + 3 days
                    style={{
                      cursor: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(3, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "not-allowed"
                        : "pointer",
                      color: (selectedDate
                        .clone()
                        .add(3, "days")
                        .isAfter(currentDateTime.clone().add(1, "weeks"))
                        || selectedDate
                          .clone()
                          .add(3, "days")
                          .isAfter(moment(dateList[dateList.length - 1]).startOf('day')))
                        ? "#888888"
                        : "var(--theme-color-text-base)",
                    }}
                    onClick={() => handleSelectedDate(3)}
                  >
                    <div>
                      {
                        _DAY_OF_WEEK.find(
                          (x) => x.value === (7 + (selectedDate.day() + 3)) % 7
                        ).label
                      }
                    </div>
                    <div>
                      {moment(selectedDate).add(3, "days").format("DD")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="search-container mt-3">
          <input type='text' placeholder={t("SEARCH")} />
          <div className="search-btn">
            <img src={require("../../assets/img/v3/search-blue.png")} alt="search" />
          </div>
        </div>*/}
        <div className="hr-right mt-3 mb-3"></div>
        <div className="rewards-game-list-container">
          {(scheduleData &&
            Object.keys(scheduleData).length > 0 &&
            scheduleData[selectedDate.format('YYYY-MM-DD')] &&
            Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).length > 0) ?
            Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).sort().map((productName, index) => {
              if (scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate > 0) {
                return (
                  <div className="rewards-game-item">
                    <div>
                      <img
                        src={scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].productImage}
                        alt={productName}
                        className="w-100"
                      />
                    </div>
                    <div className="title mt-2">
                      {productName}
                    </div>
                    <div className="details">
                      {scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate}%
                    </div>
                  </div>
                )
              }
            }) :
            <div>{t("NO_DATA_FOUND")}</div>}
        </div>
      </div>
      {showConfirmation &&
        <>
          <div className="popup-background-wrapper" onClick={() => setShowConfirmation(false)}>

            <div className="popup-data-section">
              <div className="confirm-claim-icon">
                <img
                  src={require("../../assets/img/v3/claim-ok.png")}
                  alt='claim'
                  className="img-responsive m-auto"
                />
              </div>
              <div className="text-center font24 mt-3">
                <div className="">{t("CONFIRM_CLAIM")}</div>
                <div>RM {numberWithCurrencyFormat(grandTotalRewards, 2, true)}</div>
              </div>
              <div className="claim-btn-container mt-4">
                <div className="rewards-cancel-btn" onClick={() => setShowConfirmation(false)}>{t("CANCEL")}</div>
                <div className="rewards-confirm-btn" onClick={() => submitForm()}>{t("CONFIRM")}</div>
              </div>
            </div>
          </div>
        </>
      }
      {
        showCategoryData &&
        <>
          <div className="popup-background-wrapper" onClick={() => setShowCategoryData(false)}>
          </div>
          <div className="popup-data-section">
            <div className="category-data-header text-center font16">
              <div>{t("REWARD_INFO")}</div>
            </div>
            <div className="mt-3">
              {gameCategories.filter(x => x.originalTitle !== "All" && x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "Fish Games" && x.originalTitle !== "RNG").map((category, index) => {
                return (
                  <Fragment key={index}>
                    <div className="reward-info-item mb-2">
                      <div className="data-left">{category.title}</div>
                      <div className="data-right">{numberWithCurrencyFormat(gameCategoryData[category.originalTitle] ? gameCategoryData[category.originalTitle]['totalAmount'] : "0", 2, true)}</div>
                    </div>
                  </Fragment>
                )
              })}
            </div>
            <div className="popup-close-btn mt-4">
              <button className="btn custom-btn w-100" onClick={() => setShowCategoryData(false)}>{t("CLOSE")}</button>
            </div>
          </div>
        </>
      }
    </>
  )
}


export default Rewards;