import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { WebUrl } from '../../util/Constant';
import Header from "../../components/custom/Header";
import { useHistory, useLocation } from "react-router-dom";
import parse from 'react-html-parser';

const PromotionDetail = props => {
  let { t } = useTranslation();
  let _dispatch = useDispatch();
  let _history = useHistory();
  let _location = useLocation();
  const [promotion, setPromotion] = useState({});

  useEffect(() => {
    if (_location.state && _location.state.promotion) {
      setPromotion(_location.state.promotion);
    }
  }, [_location.state]);

  return (
    <>
      <Header
        history={_history}
        url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_MAIN}
        title={t("PROMOTION_DETAIL")}
        hasBackButton={true}
        state={{ from: window.location.pathname }}
      />
      <div id="promotion-detail">
        <div className="promotion-detail-wrapper">
          <div className="detail-img">
            <img src={promotion.mobileImage} />
          </div>
          <div className="detail-title">
            {promotion.title}
          </div>
          <div className="detail-content">
            {parse(promotion.content)}
          </div>
        </div>
      </div>
    </>
  )
}

export default PromotionDetail;