import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PlayTechLobby from './customLobbies/PlayTechLobby';
import DefaultLobby from './customLobbies/DefaultLobby';

/// <summary>
/// Author: -
/// </summary>
const GameLobby = (props) => {
    var _history = useHistory();
    var { t } = useTranslation();
    const [lobby, setLobby] = useState();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const Name = params.get('name');
        const Id = params.get('id');
        const languageId = params.get('languageId');
        switch (Name) {
            case 'PlayTech':
                setLobby(<PlayTechLobby id={Id} name={Name} languageId={languageId} />);
                break;
            default:
                setLobby(<DefaultLobby id={Id} name={Name} languageId={languageId} />);
                break;
        }
    }, []);

    return (
        <>
            {lobby}
        </>
    );
};

export default GameLobby;