import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

const Result4DTable = ({ color, type, titleColor, poolData }) => {
	const { t } = useTranslation();
	const formatDate = moment(poolData.drawDateString, "DD/MM/YYYY").format(
		"YYYY-MM-DD"
	);

	return (
		<div className="white-background card-body p-0 result-card-body">
			<div
				className="d-flex justify-content-between draw-date-no-section white-background"
				style={{
					borderBottom: "2px solid " + `${color}`,
				}}
			>
				<div className="date-type text-nowrap font-12 font-bold ">
					{t("DRAW")} : {poolData.drawDateString}
					<span>
						{" "}
						{"("}
						{t(moment(formatDate).format("dddd"))}
						{")"}
					</span>
				</div>
			</div>

			{type === "section-3" && (
				<div className="table-responsive">
					<table className="table font-16 font-bold mb-0">
						<thead>
							<tr>
								<th
									colSpan="5"
									style={{
										borderBottom: "1px solid " + `${color}`,
									}}
								>
									<span className="prize-type text-nowrap">
										{t("1ST")}&nbsp;
									</span>
									<span className="prize-type-num">{poolData.p_4d_1st}</span>
								</th>
							</tr>
							<tr>
								<th
									colSpan="5"
									style={{
										borderBottom: "1px solid " + `${color}`,
									}}
								>
									<span className="prize-type text-nowrap">
										{t("2ND")}&nbsp;
									</span>
									<span className="prize-type-num">{poolData.p_4d_2nd}</span>
								</th>
							</tr>
							<tr>
								<th
									colSpan="5"
									style={{
										borderBottom: "1px solid " + `${color}`,
									}}
								>
									<span className="prize-type text-nowrap">
										{t("3RD")}&nbsp;
									</span>
									<span className="prize-type-num">{poolData.p_4d_3rd}</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th
									colSpan="5"
									className="resultCell"
									style={{
										background: color,
									}}
								>
									<div
										className="text-uppercase prize-label"
										style={{
											color: titleColor,
										}}
									>
										{t("SPECIAL")}
									</div>
								</th>
							</tr>

							<tr>
								<td className="resultCell">{poolData.p_starter0}</td>
								<td className="resultCell">{poolData.p_starter1}</td>
								<td className="resultCell">{poolData.p_starter2}</td>
								<td className="resultCell">{poolData.p_starter3}</td>
							</tr>
							<tr>
								<td className="resultCell">{poolData.p_starter4}</td>
								<td className="resultCell">{poolData.p_starter5}</td>
								<td className="resultCell">{poolData.p_starter6}</td>
								<td className="resultCell">{poolData.p_starter7}</td>
							</tr>
							<tr>
								<td className="resultCell">{poolData.p_starter8}</td>
								<td className="resultCell">{poolData.p_starter9}</td>
								<td className="resultCell">{poolData.p_starter10}</td>
								<td className="resultCell">{poolData.p_starter11}</td>
							</tr>
							<tr>
								<td className="resultCell"></td>
								<td className="resultCell">{poolData.p_starter12}</td>
								<td className="resultCell"></td>
								<td className="resultCell"></td>
							</tr>

							<tr>
								<th
									colSpan="5"
									className="resultCell"
									style={{
										background: color,
									}}
								>
									<div
										className="text-uppercase prize-label"
										style={{
											color: titleColor,
										}}
									>
										{t("CONSOLATION")}
									</div>
								</th>
							</tr>

							<tr>
								<td className="resultCell">{poolData.p_consolation0}</td>
								<td className="resultCell">{poolData.p_consolation1}</td>
								<td className="resultCell">{poolData.p_consolation2}</td>
								<td className="resultCell">{poolData.p_consolation3}</td>
							</tr>
							<tr>
								<td className="resultCell">{poolData.p_consolation4}</td>
								<td className="resultCell">{poolData.p_consolation5}</td>
								<td className="resultCell">{poolData.p_consolation6}</td>
								<td className="resultCell">{poolData.p_consolation7}</td>
							</tr>
							<tr>
								<td className="resultCell"></td>
								<td className="resultCell">{poolData.p_consolation8}</td>
								<td className="resultCell">{poolData.p_consolation9}</td>
								<td className="resultCell p-b-40"></td>
							</tr>
						</tbody>
					</table>
				</div>
			)}

			{type === "section-2" && (
				<table className="table font-16 font-bold mb-0">
					<thead>
						<tr>
							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_1st}</span>
							</th>

							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_4th}</span>
							</th>
						</tr>
						<tr>
							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_2nd}</span>
							</th>
							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_5th}</span>
							</th>
						</tr>
						<tr>
							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_3rd}</span>
							</th>
							<th
								colSpan="2"
								style={{
									borderBottom: "1px solid " + `${color}`,
								}}
							>
								<span className="prize-type text-nowrap">{t("6TH")}&nbsp;</span>
								<span className="prize-type-num">{poolData.p_5d_6th}</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th
								colSpan="6"
								className="resultCell"
								style={{
									background: color,
								}}
							>
								<div
									className="prize-label"
									style={{
										color: titleColor,
									}}
								>
									6D
								</div>
							</th>
						</tr>

						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
							</th>
							<td colSpan="6">
								<span className="prize-type-num">{poolData.p_6d_1st}</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num">{poolData.p_6d_2nd_a}</span>
							</td>
							<td>
								<span className="text-or text-lowercase">{t("OR")}</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num">{poolData.p_6d_2nd_b}</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num">{poolData.p_6d_3rd_a}</span>
							</td>
							<td>
								<span className="text-or text-lowercase">{t("OR")}</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num">{poolData.p_6d_3rd_b}</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num">{poolData.p_6d_4th_a}</span>
							</td>
							<td>
								<span className="text-or text-lowercase">{t("OR")}</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num">{poolData.p_6d_4th_b}</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num">{poolData.p_6d_5th_a}</span>
							</td>
							<td>
								<span className="text-or text-lowercase">{t("OR")}</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num">{poolData.p_6d_5th_b}</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}

			{type === "section-1" && (
				<table className="table font-16 font-bold mb-0">
					<thead></thead>
					<tbody>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("1ST")}&nbsp;</span>
							</th>
							<td colSpan="6">
								<span className="prize-type-num">{poolData.p_6d_1st}</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("2ND")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_2nd_a}
								</span>
							</td>
							<td>
								<span
									className="text-or text-lowercase"
								>
									{t("OR")}
								</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_2nd_b}
								</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("3RD")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_3rd_a}
								</span>
							</td>
							<td>
								<span
									className="text-or text-lowercase"
								>
									{t("OR")}
								</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_3rd_b}
								</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("4TH")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_4th_a}
								</span>
							</td>
							<td>
								<span
									className="text-or text-lowercase"
								>
									{t("OR")}
								</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_4th_b}
								</span>
							</td>
						</tr>
						<tr>
							<th>
								<span className="prize-type text-nowrap">{t("5TH")}&nbsp;</span>
							</th>
							<td className="text-left">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_5th_a}
								</span>
							</td>
							<td>
								<span
									className="text-or text-lowercase"
								>
									{t("OR")}
								</span>
							</td>
							<td className="text-right">
								<span className="prize-type-num letter-spacing-num">
									{poolData.p_6d_5th_b}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</div>
	);
};

Result4DTable.propTypes = {
	color: PropTypes.string.isRequired,
	type: PropTypes.string,
	titleColor: PropTypes.string.isRequired,
	poolData: PropTypes.object.isRequired,
};

Result4DTable.defaultProps = {
	type: "section-3", // section-2, section-1
};

export default Result4DTable;
