import React, { useState, useEffect } from "react";
import Header from "../../components/custom/Header";
import { useHistory } from "react-router-dom";
import { WebUrl, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import moment from "moment";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useSelector } from "react-redux";

const RewardsHistory = (props) => {
    var _history = useHistory();
    var { t } = useTranslation(LanguageKey._PRIMARY);
    const [startDate, setStartDate] = useState(
        moment().startOf("week").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("week").format("YYYY-MM-DD")
    );
    const [walletUrl, setWalletUrl] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const selectedTheme = useSelector(state => state.authState.userData.selectedTheme);
    const [rewardHistory, setRewardHistory] = useState([]);
    const [searchTrigger, setSearchTrigger] = useState(Date.now());

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getWalletStatement(startDate, endDate, selectedDate)
        }
    }, [selectedDate]);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getWalletStatement(start, end, selected = "") {
        let apiUrl =
            ApiUrl._API_GET_MEMBER_REWARD_HISTORY +
            "?StartDate=" +
            moment(start).format("YYYY-MM-DD") +
            "&EndDate=" +
            moment(end).format("YYYY-MM-DD") +
            "&v=" +
            Date.now();
        if (!stringIsNullOrEmpty(selected)) {
            apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
        }
        setWalletUrl(apiUrl);
    }

    useEffect(() => {
        if (!stringIsNullOrEmpty(selectedDate)) {
            getWalletStatement(startDate, endDate, selectedDate);
        }
    }, [selectedDate]);

    useEffect(() => {
        getWalletStatement(startDate, endDate);
    }, [searchTrigger]);

    /// <summary>
    /// Author : -
    /// </summary>
    function getRecordFromDynamicLoad(data) {
        setRewardHistory(data);
    }

    return (
        <>
            <Header
                history={_history}
                url={!window.ReactNativeWebView ? WebUrl._URL_REWARDS : null}
                title={t("REWARDS_RECORD")}
                state={{ from: _history.location.state?.from }}
            />
            <div className="container" style={{ marginBottom: "10px", padding: "0" }}>
                <DateRangeFilter2
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onSave={(start, end) => {
                        getWalletStatement(start, end)
                        setSelectedDate("");
                    }}
                    options={true}
                />
                <div className="card card-package">
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table reportTable">
                                <thead>
                                    <tr>
                                        <th>{t("DATE")}</th>
                                        <th>{t("PRODUCT_NAME")}</th>
                                        <th>{t("CATEGORY")}</th>
                                        <th>{t("COMMISSION_RATE")}</th>
                                        <th style={{ textAlign: "right" }}>{t("VALID_TURNOVER")}</th>
                                        <th style={{ textAlign: "right" }}>{t("AMOUNT")}</th>
                                        <th style={{ textAlign: "right" }}>{t("CLAIMED_DATE")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    </tr>
                                    {<DynamicLoadTable returnRecord={getRecordFromDynamicLoad} pageSize={20} apiQuery={walletUrl} searchEnabled={true} isTable={true} render={(item, i) => {
                                        return (<tr key={i}>
                                          <td>{moment(item["date"], "MM/DD/YYYY HH:mm:ss").format("YYYY-MM-DD")}</td>
                                            <td>{item["productName"]}</td>
                                            <td>{item["categoryName"]}</td>
                                            <td>{numberWithCurrencyFormat(parseFloat(item["commissionPercent"]), 2)}</td>
                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["sales"]), 2)}</td>
                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(item["amount"]), 2)}</td>
                                          <td style={{ textAlign: "right" }}>{moment(item["claimedDate"], "MM/DD/YYYY HH:mm:ss").format("YYYY-MM-DD")}</td>
                                        </tr>)
                                    }} />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RewardsHistory;