import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../../components/custom/Header";
import { WebUrl } from "../../util/Constant";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";


/// <summary>
/// Author: -
/// </summary>
const TransactionHistory = (props) => {
    var { t } = useTranslation();
    var _history = useHistory();

    const _MENU_OPTION = [
        {
            name: "DEPOSIT_RECORD",
            imageUrl: require("../../assets/img/v3/reports_deposit-record.svg"),
            redirectUrl: WebUrl._URL_DEPOSIT_RECORD,
            icon:themeIcons.reportsDepositRecord,
        },
        {
            name: "WITHDRAWAL_RECORD",
            imageUrl: require("../../assets/img/v3/reports_withdrawal-record.svg"),
            redirectUrl: WebUrl._URL_WITHDRAWAL_RECORD,
            icon:themeIcons.reportsWithdrawalRecord,
        },
        {
            name: "WALLET_TRANSFER",
            imageUrl: require("../../assets/img/v3/walletTransfer.svg"),
            redirectUrl: WebUrl._URL_WALLET_TRANSFER_HISTORY,
            icon:themeIcons.walletTransfer,
        },
        {
            name: "INTERNAL_TRANSFER",
            imageUrl: require("../../assets/img/v3/reports_internal-transfer.svg"),
            redirectUrl: WebUrl._URL_INTERAL_TRASNFER_HISTORY,
            icon:themeIcons.transfer1,
        },
    ];

    return (
        <>
            <Header
                history={_history}
                url={WebUrl._URL_MAIN_WALLET}
          title={t("TRANSACTION_HISTORY")}
            />
            <div className="container mt-3" style={{ padding: 0 }}>
                <div className="report-menu">
                    {_MENU_OPTION.map((menu, index) => {

                        return (
                            <div key={index} className="card-panel m-b-10">
                                <Link to={{
                                    pathname: menu["redirectUrl"],
                                    state: {
                                        url: window.location.pathname,
                                    }
                                }}>
                                    <div className="report-menu-wrapper">
                                        <div>
                                            <div
                                                style={{
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <div className="icon-box">
                                                    <VIcon
                                                        icon={menu.icon}
                                                        className="theme-icon-size-20"
                                                    />
                                                </div>
                                                <div>{t(menu["name"])}</div>
                                            </div>
                                        </div>
                                        <div><i className="fas fa-chevron-right"></i></div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default TransactionHistory;