import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { setBannerPopupModal } from "../../redux/AppAction.js";
import { stringIsNullOrEmpty } from "../../util/Util";
import { ApiUrl, SessionKey } from "../../util/Constant.js"
import ApiEngine from "../../util/ApiEngine";

const BannerPopupModal = props => {
  const { isLoggedIn } = useSelector((state) => state.authState);
  const bannerPopupModal = useSelector((state) => state.appState.bannerPopupModal);
  const bannerPopupImage = useSelector((state) => state.appState.bannerPopupImage);
  const _dispatch = useDispatch();

  useEffect(() => {
  }, []);

  const readPopupBanner = async () => {
    _dispatch(setBannerPopupModal(false))
    if (isLoggedIn) {
      await ApiEngine.post(ApiUrl._API_MARK_POPUP_BANNER_AS_READ + "?isDesktop=false&languageCode=" +
        (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
          localStorage.getItem(SessionKey._LANGUAGE) : 'id'));
    }
  }

  return (
    <Modal
      fade={true}
      id="banner-popup-modal"
      contentClassName="banner-popup-modal-body"
      isOpen={bannerPopupModal && !stringIsNullOrEmpty(bannerPopupImage)}
      toggle={() => { readPopupBanner(); }}
      centered
    >
      <ModalBody className="w-auto">
        <button
          type="button"
          style={{
            alignSelf: "flex-end",
            backgroundColor: "transparent",
            border: "none",
            fontSize: "2rem",
            color: "#ffffff",
            float: "right",
            padding: "0px",
          }}
          onClick={() => {
            readPopupBanner();
          }}
          data-dismiss="modal"
          aria-hidden="true"
        >
          &times;
        </button>
        {!stringIsNullOrEmpty(bannerPopupImage) && <img src={bannerPopupImage} alt="Banner" className="w-100" /> }
      </ModalBody>
    </Modal>
  );
};

export default BannerPopupModal;