import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { map } from "lodash";
import { Modal, ModalBody, Alert } from "reactstrap";
import { useSelector } from "react-redux";
import { createFormBody, isObjectEmpty, numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../../util/Util.js";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, PanelType, TransactionType, WebUrl } from "../../util/Constant.js";
import ApiEngine from "../../util/ApiEngine.js";
import Header from '../../components/custom/Header';
import Numpad2 from "../../components/custom/Numpad2.js";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction.js";
import PinInput from "react-pin-input";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";
import { checkIsLoggedIn } from "../../redux/AuthAction.js";
import moment from "moment";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";
import { Spinner } from "react-bootstrap";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import SweetAlert from "react-bootstrap-sweetalert";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";

/// <summary>
/// Author : 
/// </summary>
const Withdrawal = props => {
    const { t, i18n } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalDate, setWithdrawalDate] = useState();
    const [paymentMethodActive, setPaymentMethodActive] = useState(0);
    const [withdrawalLimit, setWithdrawalLimit] = useState(0);
    const [withdrawalLimitBalance, setWithdrawalLimitBalance] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [bankOption, setBankOption] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    const [selectedBankAccountNumber, setSelectedBankAccountNumber] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [remember, setRemember] = useState(false);
    const [currentInput, setCurrentInput] = useState();
    const [submittedData, setSubmittedData] = useState({});
    const [startOnlineTime, setStartOnlineTime] = useState("");
    const [endOnlineTime, setEndOnlineTime] = useState("");
    const [withdrawalStatus, setWithdrawalStatus] = useState(true);
    const [showPinEntry, setShowPinEntry] = useState(false);
    const [theInterval, setTheInterval] = useState(false);
    const [pinEntered, setPinEntered] = useState(false);
    const [forgetPin, setForgetPin] = useState(false);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);

    const [pinNumber, setPinNumber] = useState("");
    const [enteredPinNumber, setEnteredPinNumber] = useState("");
    const [pinNumberFocused, setPinNumberFocused] = useState(0);
    const [pinNumberConfirm, setPinNumberConfirm] = useState("");
    const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
    const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
    const [pinNumberFinal, setPinNumberFinal] = useState("");
    const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
    const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
    const _COUNTDOWN_PERIOD = 120;
    const _SECONDS_IN_MS = 1000;
    const _ALERT_TIMEOUT = 3000;
    const [sentCode, setSentCode] = useState(false);
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [showHpErrorMessage, setShowHpErrorMessage] = useState(false);
    const [hpErrorMessage, setHpErrorMessage] = useState("");
    const [smsSent, setSmsSent] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [createPinEntry, setCreatePinEntry] = useState(false);
    const [hasRolloverBal, setHasRolloverBal] = useState(null);
    const [rolloverProgress, setRolloverProgress] = useState("");
    const [rolloverTarget, setRolloverTarget] = useState("");
    const [withdrawalCountdownPeriod, setWithdrawalCountdownPeriod] = useState(0);
    const [countdownStr, setCountdownStr] = useState("");
    const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
    const [countdownTime, setCountdownTime] = useState();
    const [headerMsg, setHeaderMsg] = useState("");
    const [hasPendingTxn, setHasPendingTxn] = useState(false);
    const [selectedBankToDelete, setSelectedBankToDelete] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [bankAccInfo, setBankAccInfo] = useState(false);
    const [selectedBankToView, setSelectedBankToView] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const rightMenuList = [
        {
            title: "WITHDRAWAL_RECORD",
            url: WebUrl._URL_WITHDRAWAL_RECORD
        }
    ];

    var _userData = useSelector(state => state.authState.userData);

    const elePin = useRef(null);
    const eleConfirm = useRef(null);
    const eleFinalPin = useRef(null);
    const keyboard = useRef();

    const { register, handleSubmit, errors, getValues } = useForm();
    const {
        register: registerFullname,
        handleSubmit: handleSubmitFullname,
        errors: errorsFullname,
        setValue: setValueFullname
  } = useForm();

  useEffect(() => {
    if (withdrawalCountdownPeriod > 0) {
      clearTimeout(countdownTime);
      setCountdownTime(
        setTimeout(() => {
          let tempPeriod = withdrawalCountdownPeriod - 1;
          setWithdrawalCountdownPeriod(tempPeriod);
          let tempX = moment.duration(tempPeriod);
          let tempMM = Math.floor(tempX["_milliseconds"] / 60);
          let tempSS = tempX["_milliseconds"] - tempMM * 60;
          setTimeDiffSeconds(tempX["_milliseconds"]);
          let tempStr =
            (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
            " " +
            t("MINUTES") +
            " " +
            (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
            " " +
            t("SECOND");
          setCountdownStr(tempStr);
        }, _SECONDS_IN_MS)
      );
    } else {
      setSmsSent(false);
    }
  }, [withdrawalCountdownPeriod]);

  const checkPendingTxn = async () => {
    setHeaderMsg("");
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PENDING_TRANSACTION
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      if (responseJson[ApiKey._API_DATA_KEY] != null) {
        clearTimeout(countdownTime);
        let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
        setTimeDiffSeconds(tempS);
        setWithdrawalCountdownPeriod(tempS < 0 ? 0 : tempS);
        setHasPendingTxn(true);
        setHeaderMsg(t("NEW_TXN_PROHIBITED"));
      }
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        checkMemberPinNumber();
        checkSystemSettings();
        checkPendingTxn();
        getMemberFullName();
        getMemberSavedBankAccount();
        getMemberWithdrawalLimit();
        getUserBank();
    }

    const getMemberFullName = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_FULLNAME);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            if (stringIsNullOrEmpty(data["userFullName"])) {
                setShowFullnamePopup(true);
            }
            else {
                setAccountHolderName(data["userFullName"]);
            }
        }
    }

    const getMemberSavedBankAccount = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_MEMBER);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setPaymentList(data);
        }
    }

    const getMemberWithdrawalLimit = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_WITHDRAWAL_LIMIT_BALANCE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWithdrawalLimit(responseJson[ApiKey._API_DATA_KEY].maxWithdrawalLimit);
            setWithdrawalLimitBalance(responseJson[ApiKey._API_DATA_KEY].remainWithdrawalBalance);
        }
    }

    const getUserBank = async () => {
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_USER_BANK + "?type=WITHDRAWAL"
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let bankList = [];
            responseJson[ApiKey._API_DATA_KEY].map((bank) => {
                bankList.push({
                    label: bank.bankName,
                    value: bank.id,
                    min: bank.minimumWithdrawal,
                    max: bank.maximumWithdrawal,
                    img: bank.img,
                });
            });
            setBankOption(bankList);
           /* setSelectedBank(bankList[0]);*/
        }
    }

    const handleSelectBankClick = (savedBank) => {
        let bankId = parseInt(savedBank.bankId);
        if (paymentMethodActive === savedBank.id) {
            setPaymentMethodActive(0);
            setSelectedBank({});
            setSelectedBankAccountNumber("");
        }
        else {
            setSelectedBankAccountNumber(savedBank.accountNumber);
            let tempBankOption = bankOption;
            let tempSelectedBank = tempBankOption.filter(x => x.value === bankId)[0];
            setSelectedBank(tempSelectedBank);
            setPaymentMethodActive(parseInt(savedBank.id));
        }
    }

    const hanldeNewBankOnClick = async (bankId) => {
        let tempBankOption = bankOption;
        let tempSelectedBank = tempBankOption.filter(x => x.value === parseInt(bankId))[0];
        setSelectedBank(tempSelectedBank);
    }

    const checkSubmitData = async (data, e) => {
        setSubmittedData(data);
        let tempWithdrawalStatus = checkSystemSettings();
        if (tempWithdrawalStatus) {
            setShowPinEntry(true);
        }
        else {
            _dispatch(
                showResponseMessage(
                    false,
                    t("WITHDRAWAL") + " " + t("CURRENTLY_OFFLINE")
                )
            );
            init();
        }
    }

    async function checkSystemSettings() {
        let settingsJson = await ApiEngine.get(
            ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=withdrawal"
        );
        if (
            settingsJson[ApiKey._API_SUCCESS_KEY] &&
            settingsJson[ApiKey._API_DATA_KEY] != null
        ) {
            let data = settingsJson[ApiKey._API_DATA_KEY];
            let startTime;
            let endTime;
            let status = false;
            data.map((keyValuePair, index) => {
                if (keyValuePair["key"] === "WithdrawalStartTime") {
                    startTime = keyValuePair["value"];
                }
                if (keyValuePair["key"] === "WithdrawalEndTime") {
                    endTime = keyValuePair["value"];
                }
                if (keyValuePair["key"] === "WithdrawalStatus") {
                    status = keyValuePair["value"] === "true";
                }
            });
            setStartOnlineTime(startTime);
            setEndOnlineTime(endTime);
            setWithdrawalStatus(status);

            return status;
        }
    }


    function onVerifyPinKeyPress(key) {
        let currentIndex = 0;
        let pinStr = enteredPinNumberFinal;
        if (key !== "{bksp}") {
            if (key === "{enter}") {
                if (pinEntered) {
                    setShowPinEntry(false);
                }
            }
            if (pinNumberFinal === "") {
                currentIndex = pinNumberFinalFocused;
                eleFinalPin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumberFinal(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFinalFocused(currentIndex);
                    eleFinalPin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberFinal(pinStr);
                    setPinEntered(true);
                }
            }
        }
        else {
            eleFinalPin["current"].clear();
            setPinNumberFinal("");
            setEnteredPinNumberFinal("");
            setPinNumberFinalFocused(currentIndex);
            eleFinalPin['current']['elements'][currentIndex].focus();
        }
    }


    async function sendPinCode() {
        if (withdrawalCountdownPeriod < 1) {
            let responseJson = await ApiEngine.get(
                ApiUrl._API_CHECK_IF_PHONE_VERIFIED
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setShowHpErrorMessage(false);
                var hpNum = responseJson[ApiKey._API_DATA_KEY];
                let params = {
                    phoneNumber: hpNum,
                    isShareholder: "false",
                };
                let smsJson = await ApiEngine.post(
                    ApiUrl._API_SEND_PIN_CODE,
                    createFormBody(params)
                );
                if (smsJson[ApiKey._API_SUCCESS_KEY]) {
                    setForgetPin(false);
                    setSmsSent(true);
                    setWithdrawalCountdownPeriod(_COUNTDOWN_PERIOD);
                } else {
                    setHpErrorMessage(smsJson[ApiKey._API_MESSAGE_KEY]);
                    setShowHpErrorMessage(true);
                }
            } else {
                setSmsSent(false);
                setHpErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
                setShowHpErrorMessage(true);
            }
        } else {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    async function onPinSubmit() {
        setShowPinEntry(false);
        let tempStatus = await checkSystemSettings();
        if (tempStatus) {
            if (pinEntered && pinNumberFinal !== '') {
                submitWithdrawal();
            }
        }
    }

    function resetFormField() {
        setSelectedBank({});
        setRemember(false);
        setSelectedBankAccountNumber("");
        setPaymentMethodActive(0);
    }

    const submitWithdrawal = async () => {
        let data = submittedData;
        _dispatch(setBusy());
        let params = {
            "transactionTypeId": TransactionType._WITHDRAWAL,
            "pinNumber": pinNumberFinal,
            "amount": data.amount,
            "channel": "",
            "panel": PanelType._MEMBERSITE,
            "bankName": selectedBank.label,
            "bankAccountNumber": selectedBankAccountNumber,
            "bankAccountHolderName": accountHolderName
        };

        let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setShowPinEntry(false);
            setWithdrawalAmount(data.amount);
            setIsWithdrawalSuccess(true);
            setWithdrawalDate(moment().format("YYYY-MM-DD HH:mm:ss"));
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
            resetFormField();
            _dispatch(checkIsLoggedIn());

            if (remember) {
                let saveAccountParams = {
                    "bankId": selectedBank.value,
                    "accountNumber": selectedBankAccountNumber,
                    "accountHolderName": accountHolderName
                }
                let saveResponseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_BANK_ACCOUNT, createFormBody(saveAccountParams));

                if (saveResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    getMemberSavedBankAccount();
                }
            }
            checkPendingTxn();
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        setPinEntered(false);
        setPinNumberFinal('');
        setEnteredPinNumberFinal('');
        setPinNumberFinalFocused(0);
        setShowPinEntry(false);
        _dispatch(setIdle());
    }

    async function checkMemberPinNumber() {
      let params = {};
            let pinJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_PIN_NUMBER, createFormBody(params));
            if (!pinJson[ApiKey._API_SUCCESS_KEY]) {
                var theInterval = setInterval(() => {
                    if (elePin['current'] == undefined)
                        return;

                    if (elePin['current']['values'][0] == '' || elePin['current']['values'][0] == undefined) {
                        elePin['current']['elements'][0].focus();
                    }
                    else if (elePin['current']['values'][1] == '' || elePin['current']['values'][1] == undefined) {
                        elePin['current']['elements'][1].focus();
                    }
                    else if (elePin['current']['values'][2] == '' || elePin['current']['values'][2] == undefined) {
                        elePin['current']['elements'][2].focus();
                    }
                    else if (elePin['current']['values'][3] == '' || elePin['current']['values'][3] == undefined) {
                        elePin['current']['elements'][3].focus();
                    } else if (eleConfirm['current']['values'][0] == '' || eleConfirm['current']['values'][0] == undefined) {
                        eleConfirm['current']['elements'][0].focus();
                    }
                    else if (eleConfirm['current']['values'][1] == '' || eleConfirm['current']['values'][1] == undefined) {
                        eleConfirm['current']['elements'][1].focus();
                    }
                    else if (eleConfirm['current']['values'][2] == '' || eleConfirm['current']['values'][2] == undefined) {
                        eleConfirm['current']['elements'][2].focus();
                    }
                    else if (eleConfirm['current']['values'][3] == '' || eleConfirm['current']['values'][3] == undefined) {
                        eleConfirm['current']['elements'][3].focus();
                    }
                    else {

                        var currpin = elePin['current']['values'][0] + elePin['current']['values'][1] + elePin['current']['values'][2] + elePin['current']['values'][3];
                        var currconfpin = eleConfirm['current']['values'][0] + eleConfirm['current']['values'][1] + eleConfirm['current']['values'][2] + eleConfirm['current']['values'][3];
                        if (currpin != currconfpin) {
                            setInvalidPin(true);
                            elePin['current'].clear();
                            eleConfirm['current'].clear();
                            elePin['current'].focus();
                        }
                        else {
                            setInvalidPin(false);
                            clearInterval(theInterval);
                            submitPin(currpin, currconfpin);
                        }
                    }

                }, 50);
                setTheInterval(theInterval);
                setCreatePinEntry(true);
            }
    }

    async function submitFullName(data) {
        let params = {
            "userFullName": data.userFullName,
        };
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
            createFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            setShowFullnamePopup(false);
            checkMemberPinNumber();
        }
        else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }
        init();
    }

    async function submitPin(pin, conf) {
        try {
            let params = {
                "pinNumber": pin,
                "confirmPinNumber": conf
            };
            let responseJson = await ApiEngine.post(ApiUrl._API_SET_MEMBER_PIN_NUMBER, createFormBody(params));
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCreatePinEntry(false);
            }
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t("CREATE") + " " + t(responseJson[ApiKey._API_MESSAGE_KEY])));

            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(0);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(0);
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    function onCreatePinKeyPress(key) {
        // 1st: have to know if entering for elePin or eleConfirm
        // 2nd: have to know which index is currently focused for altering the state.value
        // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
        // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

        let currentIndex = 0;
        let pinStr = enteredPinNumber;
        let pinConfirmStr = enteredPinNumberConfirm;
        if (key !== "{bksp}") {
            if (pinNumber === "") {
                currentIndex = pinNumberFocused;
                elePin['current']['elements'][currentIndex].state.value = key;
                pinStr += key;
                setEnteredPinNumber(pinStr);
                if (currentIndex !== 3) {
                    currentIndex++;
                    setPinNumberFocused(currentIndex);
                    elePin['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumber(pinStr);
                }
            }
            else {
                currentIndex = pinNumberConfirmFocused;
                eleConfirm['current']['elements'][currentIndex].state.value = key;
                pinConfirmStr += key;
                setEnteredPinNumberConfirm(pinConfirmStr);
                currentIndex++;
                setPinNumberConfirmFocused(currentIndex);
                if (currentIndex <= 3) {
                    eleConfirm['current']['elements'][currentIndex].focus();
                }
                else {
                    setPinNumberConfirm(pinConfirmStr);
                    submitPin(pinNumber, pinConfirmStr);
                }
            }
        }
        else {
            elePin["current"].clear();
            eleConfirm["current"].clear();
            setPinNumber("");
            setEnteredPinNumber("");
            setPinNumberFocused(currentIndex);
            setPinNumberConfirm("");
            setEnteredPinNumberConfirm("");
            setPinNumberConfirmFocused(currentIndex);
            elePin['current']['elements'][currentIndex].focus();
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getRolloverData();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getRolloverData() {
        var status = null;
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var data = responseJson[ApiKey._API_DATA_KEY];
            data.filter((x) => x.status === "Active")
                .map((value, index) => {
                    setRolloverProgress(value.rolloverProgress);
                    setRolloverTarget(value.rolloverTarget);
                    status = value.status;
                });
        }
        if (stringIsNullOrEmpty(status)) {
            setHasRolloverBal(false);
        } else {
            setHasRolloverBal(true);
        }
    }

  const trailingActions = (value) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          let tempSelectedBank = paymentList.filter(x => x.id === value)[0];
          setSelectedBankToDelete(tempSelectedBank);
          setConfirmDelete(true);
        }}
      >
        <div className="swipe-delete downline-groups">
          <img
            src={require("../../assets/img/v3/delete.png")}

            alt="icon"
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const deleteBank = async (memberAccountId) => {
    var responseJson = await ApiEngine.post(
      ApiUrl._API_DELETE_MEMBER_BANK_ACCOUNT +
      "?memberAccountId=" +
      memberAccountId
    );
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    getMemberSavedBankAccount();
  };

    return (
        <>
            <Header
                history={_history}
                url={_location.state && _location.state.url ? _location.state.url : WebUrl._URL_MAIN}
                title={t("WITHDRAW")}
                hasBackButton={
                  _history.location.state?.from == WebUrl._URL_MAIN ||
                    _history.location.state?.from == WebUrl._URL_MAIN_WALLET ||
                    _history.location.state?.from == WebUrl._URL_WITHDRAWAL_RECORD ? true : false
                }
                hasRightMenu={true}
                rightMenuList={rightMenuList}
                state={{ from: window.location.pathname }}
            />
            {(withdrawalStatus && (moment().isSameOrAfter(moment(moment().format('YYYY-MM-DD') + " " + startOnlineTime)) && moment().isBefore(moment(moment().add(1, 'day').format('YYYY-MM-DD') + " " + endOnlineTime)))) ?
           <>
            {hasRolloverBal === null ? 
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" role="status"></Spinner>
              </div>
            :
              !hasRolloverBal ?
                <>
                  {hasPendingTxn && (
                    <div className="alert alert-warning">
                      {timeDiffSeconds > 0
                        ? t("PLEASE_WAIT") +
                        " " +
                        countdownStr +
                        " " +
                        (t("FOR_WITHDRAWAL_APPROVAL"))
                        : headerMsg}
                    </div>
                  )}
                  <div className="withdraw-cash-balance-container">
                      <div className="first">
                          <div className="first-child">
                              {/* <img src={require("../../assets/img/v3/dollar.svg")} alt="dollar" /> */}
                              <VIcon
                                    icon={themeIcons.dollar}
                                    className="theme-icon-size-15"
                                />
                          </div>
                          <div>
                              {t("CURRENT_BALANCE")}
                          </div>
                      </div>
                      <div className="second">
                          {numberWithCurrencyFormat2(_userData.walletBalance, 2, true)}
                      </div>
                  </div>
                  
                  {paymentList.length > 0 && <>
                    <h4 className="mt-4 mb-3">{t("SAVED_BANK_ACCOUNT")}</h4>
                    {paymentList &&
                      paymentList.length > 0 &&
                      map(paymentList, (item, index) => {
                        return (
                          <SwipeableList
                            fullSwipe={true}
                            type={ListType.IOS}
                            className="withdrawal-bank-list"
                          >
                            <SwipeableListItem
                              trailingActions={trailingActions(item.id)}
                              threshold={0.25}
                            >
                              <div key={index} onClick={(e) => { e.preventDefault(); handleSelectBankClick(item); }}>
                                <div className="payment-custom-radio">
                                  <div className="payment-custom-radio-label">
                                    <div>
                                      <input type="radio" className="payment-custom-radio-input" name="paymentMethod" checked={parseInt(item.id) === paymentMethodActive} />
                                          <div className="checkmark-logo">
                                                        {!stringIsNullOrEmpty(item.bankLogo) ? 
                                                            <img style={{ width: "30px", height: "30px" }}
                                                                src={item.bankLogo}
                                                                alt={item.bankName}></img> :
                                                            <i class="fa fa-university fa-lg" aria-hidden="true"></i>
                                                        }
                                      </div>
                                      <div>
                                        <div className="checkmark-title">
                                          {item.bankName}
                                        </div>
                                        <div className="checkmark-details font11 mt-2">
                                          {item.accountNumber.length > 4 ? ("*").repeat(item.accountNumber.length - 4) + item.accountNumber.substring(item.accountNumber.length - 4) : item.accountNumber}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    style={{ whiteSpace: "nowrap" }}
                                    className="btn-delete"
                                    onClick={() => {
                                      let tempSelectedBank = paymentList.filter(x => x.id === item.id)[0];
                                      setSelectedBankToDelete(tempSelectedBank);
                                      setConfirmDelete(true);
                                    }}
                                  >
                                    <VIcon
                                      icon={themeIcons.delete}
                                      className="theme-icon-size-15"
                                    />
                                  </button>
                                  <div>
                                    {parseInt(item.id) === paymentMethodActive ?
                                    //   (
                                    //     <img src={require("../../assets/img/v3/arrow-right-blue.png")} alt="up"  />
                                    //   )
                                    //   :
                                    //   (
                                    //     <img src={require("../../assets/img/v3/arrow-right.png")} alt="down"  />
                                    //   )
                                      (
                                        <VIcon
                                          icon={themeIcons.angleRight}
                                          className="theme-icon-size-20 up"
                                          onClick={() => { setSelectedBankToView(item); setBankAccInfo(true); }}
                                        />
                                      )
                                      :
                                      (
                                        <VIcon
                                          icon={themeIcons.angleRight}
                                          className="theme-icon-size-20 down"
                                          onClick={() => { setSelectedBankToView(item); setBankAccInfo(true); }}
                                        />
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </SwipeableListItem>
                          </SwipeableList>
                        );
                      })
                    }
                    {paymentList.length === 0 && <div><span>{t("NO_DATA_FOUND")}</span></div>}
                  </>}
                  <SweetAlert
                    show={confirmDelete}
                    custom={true}
                    customIcon={require("../../assets/img/sweetalert_warning.png")}
                    showCancel
                    title={t("DELETE_ACCOUNT") + selectedBankToDelete.accountNumber + "?"}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnCssClass="custom-cancel-btn"
                    confirmBtnCssClass="custom-btn"
                    confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
                    onConfirm={() => {
                      deleteBank(selectedBankToDelete.id);
                      setConfirmDelete(false);
                    }}
                    onCancel={() => {
                      setConfirmDelete(false);
                    }}
                  />
                  {bankOption && bankOption.length > 0 &&
                      <form onSubmit={handleSubmit(checkSubmitData)}>
                          <div className="payment-form pb-5">
                              <div className="custom-input-container">
                                  <h4 className="mt-4 mb-3">{t("BANK")}</h4>
                                  <div className="input-with-icon-container">
                                        <div className="custom-select-container" onClick={() => { setPaymentMethodActive(0); setSelectedBankAccountNumber(""); setShowDropdown(!showDropdown) }}>
                                            <div className="custom-select-field-2">
                                                {selectedBank && !isObjectEmpty(selectedBank) ? (
                                                    <div>
                                                        <div>
                                                            {!stringIsNullOrEmpty(selectedBank.img) ? 
                                                            <img style={{ width: "30px", height: "30px" }}
                                                                src={selectedBank.img}
                                                                alt={selectedBank.label}
                                                            />:
                                                            <i class="fa fa-university fa-lg" aria-hidden="true"></i>}
                                                        </div>
                                                        <div>
                                                            {selectedBank.label}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {t("PLEASE_SELECT_BANK")}
                                                    </div>
                                                )}
                                                <div>
                                                    {showDropdown ? (
                                                        <VIcon
                                                            icon={themeIcons.angleUp}
                                                            className="theme-icon-size-20"
                                                        />
                                                    ) : (
                                                        <VIcon
                                                            icon={themeIcons.angleDown}
                                                            className="theme-icon-size-20"
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                            <div className="bank-dropdown-container" style={showDropdown ? { display: 'block' } : { display: 'none' }}>
                                                {bankOption &&
                                                    bankOption.length &&
                                                    map(bankOption, (item, index) => {
                                                        return (
                                                            <div className="bank-dropdown-items" onClick={() => (hanldeNewBankOnClick(item.value))}>
                                                                <div className="bank-icon">
                                                                    {!stringIsNullOrEmpty(item.img) ? 
                                                                        <img style={{ width: "30px", height: "30px" }}
                                                                            src={ item.img}
                                                                            alt={item.label}
                                                                        /> : <i class="fa fa-university fa-lg" aria-hidden="true"></i>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {item.label}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            {!stringIsNullOrEmpty(selectedBankAccountNumber) && (!selectedBank || isObjectEmpty(selectedBank)) ? (<p class="invalid-feedback">{t("PLEASE_SELECT_BANK")}</p>) : (<></>)}
                                        </div>
                                  </div>
                              </div>
                              <div className="custom-input-container">
                                  <h4 className="mt-4 mb-3">{t("BANK_ACCOUNT_NUMBER")}</h4>
                                  <div className="input-with-icon-container">
                                      <input
                                          type="text"
                                          name="accountNumber"
                                          placeholder={t("PLEASE_ENTER_BANK_ACCOUNT_NUMBER")}
                                          value={selectedBankAccountNumber}
                                          readOnly={paymentMethodActive !== 0}
                                          onChange={(e) => setSelectedBankAccountNumber(e.target.value)}
                                          ref={register({
                                              required:
                                                  t("PLEASE_ENTER_BANK_ACCOUNT_NUMBER"),
                                              pattern: {
                                                  value: /^[0-9]+$/,
                                                  message:
                                                      t("INVALID_BANK_ACCOUNT_NUMBER"),
                                              },
                                          })}
                                      />
                                      {errors.accountNumber && <div className="invalid-feedback">{errors.accountNumber.message}</div>}
                                  </div>
                              </div>
                              <div className="custom-input-container">
                                  <h4 className="mt-4 mb-3">{t("BANK_ACCOUNT_HOLDER_NAME")}</h4>
                                  <div className="input-with-icon-container">
                                      <input
                                          type="text"
                                          value={accountHolderName}
                                          readOnly />
                                  </div>
                              </div>
                              <div className="withdrawal-name-alert alert alert-warning">
                                  <div className="withdrawal-warning-text">
                                      <img className="blue-warning-icon" src={BlueWarningIcon}/>
                                      <span class="text-yellow-lighter"><b>{t("FRINEDLY_REMINDER")}</b></span>{t("WITHDRAWAL_NAME_WARNING")}
                                  </div>
                              </div>
                              <div className="custom-input-container">
                                  <h4 className="mt-4 mb-3">{t("AMOUNT")}</h4>
                                  <div className="input-with-icon-container">
                                      <input
                                          type="number"
                                          readOnly
                                          placeholder={"0.00"}
                                          name="amount"
                                          onClick={(e) => {
                                              setCurrentInput(e.target);
                                          }}
                                          ref={register({
                                              required: t("PLEASE_ENTER_AMOUNT"),
                                              validate: {
                                                  minValue: value => parseFloat(value) > 0 && value >= selectedBank.min || t('VALUE_LESS_THAN_MINIMUM'),
                                                  maxValue: value => parseFloat(value) <= selectedBank.max || t('EXCEED_MAXIMUM_VALUE')
                                              },
                                              max: {
                                                  value: _userData.walletBalance,
                                                  message: t('PLEASE_ENTER_VALUE_NOT_EXCEED_YOUR_WALLET_BALANCE')
                                              }
                                          })}
                                      />
                                      {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>}
                                  </div>
                                   {selectedBank && !isObjectEmpty(selectedBank) && !stringIsNullOrEmpty(selectedBankAccountNumber) ? <p className="color-red font11 ml-3 mt-1">{"*"+t("MIN") + ":" + selectedBank.min} / {t("MAX") + ":" + selectedBank.max}</p> : <></>}
                                   {selectedBank && !isObjectEmpty(selectedBank) && !stringIsNullOrEmpty(selectedBankAccountNumber) && withdrawalLimit && withdrawalLimit !== 0 ? <p className="color-red font11 ml-3 mt-1">{"*"+t("AVAILABLE_WITHDRAWAL_LIMIT")} : {numberWithCurrencyFormat2(withdrawalLimitBalance, 2, true)} / {numberWithCurrencyFormat2(withdrawalLimit, 2, true)}</p> : <></>}
                              </div>
                              {paymentMethodActive === 0 && <div>
                                  <label className="custom-checkbox mt-4">
                                      <input
                                          type="checkbox"
                                          className="form-checkbox-label"
                                          name="remember"
                                          ref={register()}
                                          onChange={(e) => setRemember(e.target.checked)}
                                          value={false}
                                          checked={remember} />
                                      <span className="checkmark"></span>
                                      {t("REMEMBER_BANK")}
                                  </label>
                              </div>}
                              <button type="submit" className="btn custom-btn mt-4 w-100" >{t("SUBMIT")}</button>
                          </div>
                      </form>
                  }
                  {currentInput &&
                      <Numpad2
                          currentBalance={_userData.walletBalance}
                          currentInput={currentInput}
                          setCurrentInput={(e) => {
                              setCurrentInput(e);
                          }}
                          contentClassName="popup-transfer-numpad"
                      />
                  }
                    <Modal
                        fade={false}
                        contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
                        backdrop={true} 
                        isOpen={showPinEntry}
                        centered
                    >
                        <ModalBody>
                            <div className="display-wrapper">
                                <button
                                    type="button"
                                    style={{
                                        alignSelf: "flex-end",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        marginRight: "15px",
                                        fontSize: "2rem",
                                        color: "#002e6c",
                                        marginTop: "-15px",
                                        float: "right",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                        clearInterval(theInterval);
                                        setShowPinEntry(false);
                                    }}
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
                                    {t("ENTER_TRANSACTION_PIN")}
                                </strong>
                                <div
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            onVerifyPinKeyPress("{bksp}");
                                        }
                                    }}
                                >
                                    <PinInput
                                        style={{ marginTop: "37px" }}
                                        ref={eleFinalPin}
                                        length={4}
                                        initialValue=""
                                        secret={true}
                                        focus={true}
                                        autoSelect={false}
                                        disabled={true}
                                        onChange={(value) => {
                                            onVerifyPinKeyPress(value);
                                        }}
                                        type="numeric"
                                        inputMode="number"
                                        inputStyle={{
                                            border: "0",
                                            margin: "0px 10px",
                                            width: "40px",
                                            height: "40px",
                                            backgroundColor: "transparent",
                                            borderBottom: "2px solid #002e6c",
                                        }}
                                        inputFocusStyle={{
                                            borderBottom: "2px solid #FFC159",
                                        }}
                                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                    />
                                </div>

                                <div
                                    className="text-brand text-center" 
                                    style={{ marginTop: "10px" }} 
                                    onClick={() => {
                                        setForgetPin(true);
                                        sendPinCode();
                                    }}
                                >
                                    {t("RESET_PIN")}
                                </div>
                                {smsSent && withdrawalCountdownPeriod > 0 && (
                                    <p
                                        className="text-brand"
                                        style={{
                                            textAlign: "center",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {t("PIN_SENT")} {t("RESEND_IN")} {withdrawalCountdownPeriod}{" "}
                                        {t("SECOND")}
                                    </p>
                                )}
                                {showHpErrorMessage && (
                                    <p
                                        className="text-brand text-red"
                                        style={{ textAlign: "center", marginTop: "5px" }}
                                    >
                                        {t(hpErrorMessage)}
                                    </p>
                                )}
                                {showAlert && (
                                    <div
                                        className="content content-full-width"
                                        style={{
                                            position: "fixed",
                                            top: "10px",
                                            left: "0",
                                            width: "100%",
                                            zIndex: 9999,
                                        }}
                                    >
                                        <div className="container">
                                            <Alert color="danger">
                                                {t("WAIT_TO_RESEND")}
                                            </Alert>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Keyboard
                                layout={{
                                    'default': [
                                        "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                    ]
                                }}
                                display={{
                                    '{bksp}': "<i class ='fas fa-backspace'></i>",
                                }}
                                theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                                keyboardRef={r => (keyboard.current = r)}
                                onKeyPress={onVerifyPinKeyPress}
                                disableButtonHold
                                disableCaretPositioning
                            />

                            <button
                                type="button"
                                className="btn btn-block btn-brand-gradient btn-done"
                                onClick={() => onPinSubmit()}
                            >
                                {t("SUBMIT_TRANSACTION")}
                            </button>
                        </ModalBody>
                    </Modal>
                  </> 
                  : 
                  <div>
                    <div className="main-wallet-list-container pb-5">
                      <div className="main-wallet-list-item-rollover-progress">
                          <div>
                              {t("ROLLOVER_PROGRESS")}
                          </div>
                          <div>
                              {numberWithCurrencyFormat2(rolloverProgress, 2, true)} {t("/")} {numberWithCurrencyFormat2(rolloverTarget, 2, true)}
                          </div>
                      </div>
                      <div className="offline-message">{t("WITHDRAWAL") + " " + t("ROLLOVER_NOT_REACH")}</div>
                  </div>
                </div>
            }</> :
            withdrawalStatus && !stringIsNullOrEmpty(startOnlineTime) && !stringIsNullOrEmpty(endOnlineTime) && (moment().isBefore(moment(moment().format('YYYY-MM-DD') + " " + startOnlineTime)) || moment().isSameOrAfter(moment(moment().format('YYYY-MM-DD') + " " + endOnlineTime))) ? 
            <div className="offline-message">{t("WITHDRAWAL") + " " + t("OFFLINE_FROM") + " " + endOnlineTime + " " + t("TO_SMALL_LETTER") + " " + startOnlineTime + ". " + t("APOLOGIES_INCONVENIENCE")}</div>
            :
            <div className="offline-message">{t("WITHDRAWAL") + " " + t("CURRENTLY_OFFLINE")}</div>
            }
                    {
                        //CREATE NEW PIN
                    }
                    <Modal
                        fade={false}
                        contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
                        backdrop={true}
                        isOpen={createPinEntry}
                        centered
                    >
                        <ModalBody>
                            <form>
                                <div className="display-wrapper">
                                <button type="button" style={{
                                    alignSelf: "flex-end", 
                                    backgroundColor: "transparent", 
                                    border: "none", 
                                    marginRight: "15px", 
                                    fontSize: "2rem", 
                                    color: "#002e6c", 
                                    marginTop: "-15px", 
                                    fontWeight: "bold",
                                }} 
                                onClick={() => { setCreatePinEntry(false); }} 
                                data-dismiss="modal" 
                                aria-hidden="true">
                                    <span>&times;</span>
                                </button>
                                <strong className="text-brand display-value" style={{ fontSize: "20px" }}>{t("CREATE_TRANSACTION_PIN")}</strong>
                                <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("ENTER_YOUR_PIN")}</div>
                                <PinInput
                                    ref={elePin}
                                    length={4}
                                    initialValue=""
                                    secret={true}
                                    focus={true}
                                    autoSelect={true}
                                    disabled={createPinEntry}
                                    type="numeric"
                                    onChange={(value, index) => {
                                        setPinNumberFocused(index);
                                    }}
                                    inputMode="numeric"
                                    inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid white" }}
                                    inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                    regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                />
                                <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("CONFIRM_YOUR_PIN")}</div>
                                    <PinInput
                                        ref={eleConfirm}
                                        length={4}
                                        initialValue=""
                                        secret={true}
                                        focus={true}
                                        autoSelect={true}
                                        disabled={createPinEntry}
                                        type="numeric"
                                        onChange={(value, index) => {
                                            setPinNumberConfirmFocused(index);
                                        }}
                                        inputMode="numeric"
                                        inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid white" }}
                                        inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                                    />
                                    <div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>

                                </div>
                                <Keyboard
                                    layout={{
                                        'default': [
                                            "1 2 3 4 5 6 7 8 9 . 0 {bksp}"
                                        ]
                                    }}
                                    display={{
                                        '{bksp}': "<i class ='fas fa-backspace'></i>",
                                    }}
                                    theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                                    keyboardRef={r => (keyboard.current = r)}
                                    onKeyPress={onCreatePinKeyPress}
                                    disableButtonHold
                                    disableCaretPositioning
                                />

                            </form>
                        </ModalBody>
                    </Modal>
                    {
                        //CREATE NEW USER FULL NAME
                    }
                    <Modal
                        id="custom-modal"
                        fade={false}
                        contentClassName="modal-brand modal-bottom modal-numpad"
                        isOpen={showFullnamePopup}
                        centered
                    >
                        <ModalBody>
                            <form onSubmit={handleSubmitFullname(submitFullName)}>
                                <div className="display-wrapper enter-fullname-modal">
                                    <strong className="reload-title text-yellow d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_FULL_NAME")}</strong>
                                    <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "12px", fontStyle: "italic" }}>{t("IN_YOUR_BANK_ACCOUNT")}</strong>
                                    <input
                                        type="text"
                                        name="userFullName"
                                        className="withdrawal-fullName-input"
                                        ref={registerFullname({
                                            required: 'FIELD_REQUIRED',
                                            pattern: { value: /^[a-z A-Z]+$/, message: "INVALID_NAME" }
                                        })}
                                        onChange={(e) => setValueFullname('userFullName', e.target.value)}
                                    />
                                    {errorsFullname.userFullName && <div className="invalid-feedback">{t(errorsFullname.userFullName.message)}</div>}
                                    <button type="submit" className="btn btn-block btn-brand-gradient" style={{ width: "200px" }}>{t("SUBMIT")}</button>
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                    {
                        //WITHDRAWAL STATUS
                    }
                    <Modal
                      fade={false}
                      contentClassName="modal-brand modal-center"
                      id="modal-center"
                      isOpen={isWithdrawalSuccess}
                      toggle={() => {
                        setIsWithdrawalSuccess(false);
                      }}
                      centered
                    >
                      <ModalBody>
                        <div className="modal-custom-header justify-content-center" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "29px",
                          color: "#EFEFEF"
                        }}>
                          <h2
                            className="mb-0"
                          >
                            {t("WITHDRAWAL_SUCCESS")}
                          </h2>
                          <div className="text-center mt-4">
                            <img
                              src={require("../../assets/img/v3/approved-modal.png")}
                              alt={t("APPROVED")}
                            />
                          </div>
                          <div className="text-center mt-4">
                            {t("PAID_DEPOSIT", { amount: withdrawalAmount })}
                          </div>
                        </div>
                        <table className="table text-brand table-reload-detail text-white">
                          <tr>
                            <th>{t("WITHDRAWAL_DATE_TIME")}</th>
                            <th style={{ textAlign: "right" }}>{withdrawalDate}</th>
                          </tr>
                          <tr>
                            <th>{t("REFERENCE_NUMBER")}</th>
                            <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
                          </tr>
                        </table>
                        <button
                          className="btn btn-block btn-brand-gradient"
                          onClick={() => {
                            setIsWithdrawalSuccess(false);
                          }}
                        >
                          {t("DONE")}
                        </button>
                      </ModalBody>
                    </Modal>

        {
          //BANK ACCOUNT INFO MODAL
        }
        <Modal
          fade={true}
          contentClassName="modal-brand modal-bottom modal-edit-sh"
          id="custom-modal"
          isOpen={bankAccInfo}
          centered
        >
          <ModalBody>
            <div className="edit-sh-data">
              <div className="font20 text-white text-center" style={{ marginBottom: "25px" }}>{t("BANK_ACCOUNT_DETAIL")}</div>
              <div className="">
                <div className="bank-acc-info">
                  <div className="data-label">{t("BANK")}</div>
                  <div className="data-info">{selectedBankToView.bankName}</div>
                </div>
                <div className="bank-acc-info">
                  <div className="data-label">{t("BANK_ACCOUNT_NUMBER")}</div>
                  <div className="data-info">{selectedBankToView.accountNumber}</div>
                </div>
                <div className="bank-acc-info">
                  <div className="data-label">{t("ACCOUNT_HOLDER_NAME")}</div>
                  <div className="data-info">{selectedBankToView.accountHolderName}</div>
                </div>
              </div>
              <button className="btn btn-block btn-brand-gradient mt-3" style={{ width: "200px" }} onClick={() => setBankAccInfo(false)} >{t("CLOSE")}</button>
            </div>
          </ModalBody>
        </Modal>
                </>
    );

}

            export default Withdrawal;