import React, { useState, useEffect, useRef } from "react";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Header from "../../components/custom/Header";
import moment from "moment";
import DateRangeFilter2 from "../../components/custom/DateRangeFilter2";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
	TransactionStatus,
} from "../../util/Constant";
import Icon from "@mdi/react";
import {
	mdiCheckCircle,
	mdiCloseCircle,
	mdiDotsHorizontalCircle,
} from "@mdi/js";

const WalletHistory = () => {
	let _history = useHistory();
	var _location = useLocation();
	const { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	const [withdrawalUrl, setWithdrawalUrl] = useState("");
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(moment());
	const [selectedDate, setSelectedDate] = useState("");
	const [currentItem, setItem] = useState([]);

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	useEffect(() => {
		getTransactionData(startDate, endDate);
	}, []);

	async function getTransactionData(start, end, selected = "") {
		let apiUrl =
			ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
			"?StartDate=" +
			moment(start).format("YYYY-MM-DD") +
			"&EndDate=" +
			moment(end).format("YYYY-MM-DD") +
			"&type=" +
			"1";

		if (!stringIsNullOrEmpty(selected)) {
			apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
		}
		setWithdrawalUrl(apiUrl);
	}

	useEffect(() => {
		if (!stringIsNullOrEmpty(selectedDate)) {
			getTransactionData(startDate, endDate, selectedDate);
		}
	}, [selectedDate]);

	return (
		<>
			<Header
				history={_history}
				url={
					!window.ReactNativeWebView
						? _location.state && _location.state.url
							? _location.state.url
							: WebUrl._URL_DEPOSIT
						: null
				}
				title={t("DEPOSIT_RECORD")}
				state={{ from: window.location.pathname }}
			/>
			<div className="container" style={{ marginBottom: "10px", paddingLeft: 0, paddingRight: 0 }}>
				<DateRangeFilter2
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					onSave={(start, end) => {
						setStartDate(moment(start));
						setEndDate(moment(end));
						getTransactionData(start, end);
						setSelectedDate("");
					}}
					options={true}
				/>
				<div className="card card-package">
					<div className="card-body p-0">
						<div className="table-responsive">
							<table className="table reportTable" style={{ color: "#002E6C" }}>
								<thead>
									<tr>
										<th>{t("DATE")}</th>
										<th>{t("STATUS")}</th>
										<th>{t("AMOUNT")}</th>
										<th>{t("DETAIL")}</th>
									</tr>
								</thead>
								<tbody>
									<DynamicLoadTable
										pageSize={20}
										apiQuery={withdrawalUrl}
										searchEnabled={true}
										isTable={true}
										render={(item, i) => {
											var statusHtml = "";
											switch (item["approvalStatus"]) {
												case TransactionStatus._APPROVED:
													statusHtml = (
														<span style={{ color: "#03BB3A" }}>
															{t("APPROVED")}
														</span>
													);
													break;
												case TransactionStatus._REJECTED:
													statusHtml = (
														<span style={{ color: "red" }}>
															{t("REJECTED")}
														</span>
													);
													break;
												case TransactionStatus._OPEN:
													statusHtml = (
														<span style={{ color: "#3369FF" }}>
															{t("PROCESSING")}
														</span>
													);
													break;
												default:
													statusHtml = (
														<span style={{ color: "#3369FF" }}>
															{t("PROCESSING")}
														</span>
													);
													break;
											}

											return (
												<tr key={i}>
													<td>{moment(item["date"], "DD/MM/YYYY").format("YYYY-MM-DD")}</td>
													<td>{statusHtml}</td>
													<td>
														{numberWithCurrencyFormat(
															parseFloat(item["amount"]),
															2
														)}
													</td>
													<td
														style={{ color: "#3369FF" }}
														onClick={() => {
															setItem(item);
															toggle();
														}}
													>
														{" "}
														{t("VIEW")}{" "}
													</td>
												</tr>
											);
										}}
									/>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div>
					<Modal
						id="custom-modal"
						fade={false}
						isOpen={modal}
						toggle={toggle}
						contentClassName="modal-brand modal-bottom"
					>
						<ModalBody style={{ padding: "56px 30px 49px" }}>
							<div className="modal-custom-header justify-content-center">
								<h2
									className="mb-0"
									style={{
										fontFamily: "Montserrat",
										fontWeight: "600",
										fontSize: "24px",
										lineHeight: "29px",
										color: "#EFEFEF",
									}}
								>
									{t("DEPOSIT_RECORD_DETAIL")}
								</h2>
							</div>
							{currentItem["approvalStatus"] == TransactionStatus._APPROVED && (
								<>
									<div className="text-center mt-4">
										<img
											src={require("../../assets/img/v3/approved-modal.png")}
											alt={t("APPROVED")}
										/>
									</div>
									<div className={"reload-modal-approved-text mt-2"}>
										{t("APPROVED")}
									</div>
								</>
							)}

							{currentItem["approvalStatus"] == TransactionStatus._REJECTED && (
								<>
									<div className="text-center mt-4">
										<img
											src={require("../../assets/img/v3/rejected-modal.png")}
											alt={t("REJECTED")}
										/>
									</div>
									<div className={"reload-modal-rejected-text mt-2"}>
										{t("REJECTED")}
									</div>
								</>
							)}

							{currentItem["approvalStatus"] == TransactionStatus._OPEN && (
								<>
									<div className="text-center mt-4">
										<img
											src={require("../../assets/img/v3/processing-modal.png")}
											alt={t("PROCESSING")}
										/>
									</div>
									<div className={"reload-modal-processing-text mt-2"}>
										{t("PROCESSING")}
									</div>
								</>
							)}

							<div
								className="reload-modal-amount mt-3"
								style={{
									fontFamily: "Montserrat",
									fontWeight: "700",
									fontSize: "20px",
									lineHeight: "24px",
									color: "#D9D9D9",
								}}
							>
								{numberWithCurrencyFormat(parseFloat(currentItem["amount"]), 2)}
							</div>
							<div
								className="reload-modal-date-balance-container mt-4"
								style={{
									fontFamily: "Montserrat",
									fontWeight: "500",
									fontSize: "12px",
									lineHeight: "15px",
									color: "#A4A4A4",
								}}
							>
								<div className="first">
									<div>{t("DATE_AND_TIME")}</div>
									
									<div>{moment(currentItem["dateTime"], "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</div>
								</div>

								<div className="third mt-2">
									<div>{t("NEW_BALANCE")}</div>
									<div>
										{numberWithCurrencyFormat(
											parseFloat(currentItem["postMemberPrimaryBalance"]),
											2
										)}
									</div>
								</div>
							</div>
							<div>
								<a
									className="btn custom-btn mt-5"
									onClick={() => setModal(false)}
								>
									{t("CLOSE")}
								</a>
							</div>
						</ModalBody>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default WalletHistory;
