import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    showResponseMessage,
    setBusy,
    setIdle,
} from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import Header from "../../components/custom/Header.js";
import ApiEngine from "../../util/ApiEngine.js";
import {
    numberWithCurrencyFormat,
} from "../../util/Util.js";
import { createMultiPartFormBody } from "../../util/Util.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, TransactionType, PanelType, SessionKey } from "../../util/Constant";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import ReactHtmlParser from "react-html-parser";

const LoyaltyVoucherDetails = () => {
    var _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var { t } = useTranslation(LanguageKey._PRIMARY);
    const userId = useSelector(
        (state) => state.authState.userData.userId
    );
    const languageCode = localStorage.getItem(SessionKey._LANGUAGE)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loyaltyData, setLoyaltyData] = useState([]);
    const [memberPoint, setMemberPoint] = useState(0);

    useEffect(() => {
        init();
    }, [_location.state.id])

    const init = async () => {
        _dispatch(setBusy());
        if (_location.state.id) {
            let apiUrl = ApiUrl._API_GET_LOYALTY_ITEM_BY_ID + "?itemId=" + _location.state.id + "&languageCode=" + languageCode;
            let responseJson = await ApiEngine.get(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                setLoyaltyData(data);
            }
        }
        getMemberLoyaltyPoints();
        _dispatch(setIdle());
    }

    const handleRedemption = async (data) => {
        _dispatch(setBusy());

        if (data["productType"] === "Voucher") {
            let transactionJson = await ApiEngine.post(
                ApiUrl._API_REDEEM_VOUCHER + "?itemId=" + data.id
            );

            if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(
                    showResponseMessage(
                        transactionJson[ApiKey._API_SUCCESS_KEY],
                        transactionJson[ApiKey._API_MESSAGE_KEY]
                    )
                );
                init();
            }
            else {
                _dispatch(
                    showResponseMessage(
                        transactionJson[ApiKey._API_SUCCESS_KEY],
                        transactionJson[ApiKey._API_MESSAGE_KEY]
                    )
                );
            }
        }
        else {
            let responseJson = await ApiEngine.post(
                ApiUrl._API_REDEEM_LOYALTY_ITEM + "?itemId=" + data.id
            );

            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                init();
            }
        }
        _dispatch(setIdle());
    };

    const getMemberLoyaltyPoints = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    return (
        <>
            {showConfirmation && (
                <SweetAlert
                    custom={true}
                    customIcon={require("../../assets/img/sweetalert_warning.png")}
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    cancelBtnCssClass="custom-cancel-btn"
                    confirmBtnCssClass="custom-btn"
                    confirmBtnStyle={{ boxShadow: "0px 5px 30px #3369ffb3" }}
                    title={t("CONFIRM_REDEEM")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleRedemption(loyaltyData);
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <Header
                history={_history}
                url={WebUrl._URL_LOYALTY_REDEMPTION}
                title={t("VOUCHER_DETAILS")}
            />
            <div className="pb-5">
                <div className="gamepoint-voucher-img-box">
                    <img src={loyaltyData.image} alt="gamepoints-details" className="w-100" />
                </div>
                <div className="font16 text-center mt-4">
                    {loyaltyData.name}
                </div>
                <div className="gamepoint-voucher-point-box mt-2">
                    <div className="first">
                        {t("POINTS")} :
                    </div>
                    <div className="second">
                        {numberWithCurrencyFormat(loyaltyData.pointsNeeded)}
                    </div>
                </div>
                <div className="hr-right mt-3 mb-3"></div>
                <div className="content-container">
                    {ReactHtmlParser(loyaltyData.mobileContent)}
                </div>
                <div className="btn-container mt-5">
                    {loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint >= loyaltyData.pointsNeeded ?
                        <button type="submit" className="btn btn-block btn-brand-gradient w-100" onClick={() => setShowConfirmation(true)}>{t("REDEEM")}</button>
                        : loyaltyData.redeemable === false && loyaltyData.stock > 0 && (memberPoint > loyaltyData.pointsNeeded || memberPoint < loyaltyData.pointsNeeded) ? // Claim limit reached
                            <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("REDEEMED")}</button>
                            : loyaltyData.redeemable === true && loyaltyData.stock > 0 && memberPoint < loyaltyData.pointsNeeded ? // Points not enough
                                <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("REDEEM")}</button>
                                :
                                <button type="submit" className="btn btn-block btn-brand-gradient w-100" disabled>{t("OUT_OF_STOCK")}</button>
                    }
                </div>
            </div>
        </>
    );
};

export default LoyaltyVoucherDetails;