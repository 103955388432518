import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DownloadApp = () => {
  var _dispatch = useDispatch();
  const { t } = useTranslation();

  const links = [{
    icon: "/img/downloadapp/apple.png",
    link: "https://apps.apple.com/us/app/m4d/id6451379122",
    word: "IOS"
  }, {
    icon: "/img/downloadapp/android.png",
    link: "https://play.google.com/store/apps/details?id=com.m4dresult.app",
    word: "ANDROID"
  }]

  const handleLinkOnClick = async (link) => {

    window.open(link, "_blank", "download-app")
  }


  return (
    <div className="download-app-container">
      <div className="download-app-header">
        {t("DOWNLOAD_APP_HEADER")}
      </div>
      <div className="download-app-description">
        {t("DOWNLOAD_APP_DESC")}
      </div>
      <div className="download-app-link">
        {links.map((item, index) => {
          return (<div className="download-app-button" key={"download-app-" + index} onClick={() => {
            handleLinkOnClick(item.link);
          }}>
            <img src={item.icon} alt="download app button" /> {t(item.word)}
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default DownloadApp;